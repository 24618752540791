import { useEstimateSwapFeeQuery } from "shared/api/swap";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { useChain } from "shared/providers/wagmi";
import { Currency, Fraction } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { useBestTrade } from "shared/v2-sdk/hooks";

import { useTradeOptions } from "./use-trade-options";

type Params = {
  currencyB: Currency | undefined;
};
export const useMaxNativeCurrencyValue = ({ currencyB }: Params) => {
  const chain = useChain();
  const native = Native.byChainId(chain.id);
  const balance = useCurrencyBalanceQuery(native);
  const tradeOptions = useTradeOptions();

  const { trade } = useBestTrade(native, currencyB, balance.data?.balanceCA);
  const swapFee = useEstimateSwapFeeQuery(trade, tradeOptions);

  const result =
    swapFee.data?.feeCA && balance.data?.balanceCA
      ? balance.data.balanceCA.subtract(
          swapFee.data?.feeCA.multiply(new Fraction(120, 100))
        )
      : undefined;

  return result;
};
