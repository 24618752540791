import { useMedia } from "react-use";

import { EmotionCSSProps } from "shared/types";

import logoSvg from "./logo.svg";
import smallLogoSvg from "./small-logo.svg";
import { logoCss } from "./styles";

type Props = {
  full?: boolean;
} & EmotionCSSProps;

export const Logo = ({ className, css }: Props) => {
  const isMobile = useMedia("(max-width: 768px)");
  return (
    <div className={className} css={[logoCss, css]}>
      <img src={isMobile ? smallLogoSvg : logoSvg} alt="OccamX" />
    </div>
  );
};
