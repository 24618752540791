import React from "react";
import {
  toast,
  ToastContent,
  ToastOptions,
  ToastPromiseParams,
} from "react-toastify";

import { Icon } from "../icon";
import { Spinner } from "../spinner";

export const notify = (
  content: ToastContent<unknown>,
  options?: ToastOptions
) => {
  return toast(content, options);
};

notify.success = (content: ToastContent<unknown>, options?: ToastOptions) => {
  return toast.success(content, {
    icon: <Icon className="text-malachite" name="success" size="20" />,
    ...options,
  });
};

notify.promise = <T extends unknown>(
  promise: Promise<T> | (() => Promise<T>),
  toastPromiseOptions: ToastPromiseParams<T> = {
    pending: {},
    success: {},
    error: {},
  },
  options?: ToastOptions
) => {
  const overwrittenToastPromiseOptions: ToastPromiseParams<T> = {
    pending:
      typeof toastPromiseOptions.pending === "string"
        ? toastPromiseOptions.pending
        : {
            icon: <Spinner theme="dodgerBlue" size="18" />,
            ...toastPromiseOptions.pending,
          },
    success:
      typeof toastPromiseOptions.success === "string"
        ? toastPromiseOptions.success
        : {
            icon: <Icon className="text-malachite" name="success" size="18" />,
            ...toastPromiseOptions.success,
          },
    error:
      typeof toastPromiseOptions.error === "string"
        ? toastPromiseOptions.error
        : {
            ...toastPromiseOptions.error,
            icon: <Icon className="text-redOrange" name="fail" size="18" />,
          },
  };

  return toast.promise<T>(promise, overwrittenToastPromiseOptions, {
    ...options,
  });
};

notify.error = (content: ToastContent<unknown>, options?: ToastOptions) => {
  return toast.error(content, {
    type: "error",
    icon: <Icon className="text-redOrange" name="fail" size="18" />,
    ...options,
  });
};

notify.submit = (content: ToastContent<unknown>, options?: ToastOptions) => {
  return toast.info(content, {
    type: "info",
    icon: <Icon className="text-dodgerBlue" name="submitted" />,
    ...options,
  });
};
