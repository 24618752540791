import { useState } from "react";
import { useMedia } from "react-use";

import { CurrencyEntity } from "entities/currency";
import { useCurrencies } from "entities/currency";
import { Currency } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Text } from "shared/ui/text";

import { ModalSelector } from "./modal";

type Props = {
  id?: string;
  disabled?: boolean;
  loading?: boolean;
  storageKey?: string;
  currencies?: Currency[];
  selectedCurrency: Currency | undefined;
  onSelect: (currency: Currency) => void;
};

export const Selector = ({
  id,
  disabled = false,
  loading = false,
  selectedCurrency,
  storageKey,
  currencies: currenciesProp,
  onSelect,
}: Props) => {
  const isXlDesktop = useMedia("(max-width: 1536px)");
  const defaultCurrencies = useCurrencies();
  const currencies = currenciesProp ?? defaultCurrencies;
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {selectedCurrency ? (
        <div
          id={id}
          className="flex cursor-pointer items-center px-2"
          onClick={() => !disabled && setOpen(true)}
        >
          <CurrencyEntity
            symbol={selectedCurrency.symbol}
            logoUrl={selectedCurrency.logoUrl}
            bordered={false}
            size={isXlDesktop ? "32" : "38"}
          />
          <Icon className="ml-2" name="chevronDown" size="14" />
        </div>
      ) : (
        <Button
          id={id}
          loading={loading}
          disabled={disabled}
          className="!rounded-xl"
          size="38"
          theme="blue"
          onClick={() => setOpen(true)}
        >
          <Text>Select token</Text>
          <Icon className="max ml-2 text-light" size="14" name="chevronDown" />
        </Button>
      )}
      <ModalSelector
        open={isOpen}
        storageKey={storageKey}
        selectedCurrency={selectedCurrency}
        onClose={() => setOpen(false)}
        currencies={currencies}
        onSelect={(currency) => {
          onSelect(currency);
          setOpen(false);
        }}
      />
    </>
  );
};
