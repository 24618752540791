import { humanodeMainnetChain, humanodeTestnet5Chain } from "./humanode";
import { milkomedaC1Chain, milkomedaC1TestnetChain } from "./milkomeda";

export const supportedChains =
  process.env.REACT_APP_STAND === "prod"
    ? [milkomedaC1Chain, humanodeMainnetChain]
    : [
        milkomedaC1Chain,
        milkomedaC1TestnetChain,
        humanodeTestnet5Chain,
        humanodeMainnetChain,
      ];
