import { Chain } from "wagmi";

export const humanodeTestnet5Chain: Chain = {
  id: 14853,
  name: "Humanode Testnet 5 Israfel",
  network: "humanode",
  nativeCurrency: {
    decimals: 18,
    name: "Humanode",
    symbol: "eHMND",
  },
  rpcUrls: {
    default: {
      http: ["https://explorer-rpc-http.testnet5.stages.humanode.io"],
      webSocket: ["wss://explorer-rpc-ws.testnet5.stages.humanode.io"],
    },
    public: {
      http: ["https://explorer-rpc-http.testnet5.stages.humanode.io"],
      webSocket: ["wss://explorer-rpc-ws.testnet5.stages.humanode.io"],
    },
  },
  blockExplorers: {
    default: {
      name: "BlockScout",
      url: "https://humanode.io",
    },
  },
  testnet: true,
};

export const humanodeMainnetChain: Chain = {
  id: 5234,
  name: "Humanode Mainnet",
  network: "humanode",
  nativeCurrency: {
    decimals: 18,
    name: "Humanode",
    symbol: "HMND",
  },
  rpcUrls: {
    default: {
      http: ["https://explorer-rpc-http.mainnet.stages.humanode.io/"],
      webSocket: ["wss://explorer-rpc-ws.mainnet.stages.humanode.io"],
    },
    public: {
      http: ["https://explorer-rpc-http.mainnet.stages.humanode.io/"],
      webSocket: ["wss://explorer-rpc-ws.mainnet.stages.humanode.io"],
    },
  },
  blockExplorers: {
    default: {
      name: "SubScan",
      url: "https://humanode.subscan.io/",
    },
  },
};
