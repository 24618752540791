import { Footer, Header } from "pages/ui";
import { PageSpinner } from "shared/ui/spinner";

export const SuspenseFallback = () => {
  return (
    <div className="bg-swapBg">
      <Header />
      <PageSpinner />
      <Footer />
    </div>
  );
};
