import { IconName } from "shared/ui/icon";

export const getWalletLogoName = (
  connectorId: string | undefined
): IconName | null => {
  let name: IconName | null = null;

  if (connectorId === "metaMask") name = "metamask";
  if (connectorId === "brave") name = "brave";
  if (connectorId === "ledger") name = "ledger";
  if (connectorId === "walletConnect") name = "walletconnect";

  return name;
};
