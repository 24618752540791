import { useNavigate, useOutletContext } from "react-router-dom";

import { CurrencyEntity } from "entities/currency";
import { PairLogoAndSymbol } from "entities/pair";
import { Price } from "entities/price";
import { ApproveAllowanceButton } from "features/approve-allowance";
import { ConnectWalletButton } from "features/connect-wallet";
import { InputCurrency } from "features/input-currency";
import { SwitchNetworkButton } from "features/switch-network";
import {
  usePairCurrencies,
  useRemoveLiquidityDetails,
  useRemoveLiquidityStatus,
} from "pages/pool/hooks";
import { RM_LQ_STATUSES } from "pages/pool/hooks/useRemoveLiquidityStatus";
import { LiquidityOutletContext } from "pages/pool/types";
import { useMiningPanelsQuery, useStakingBalanceQuery } from "shared/api/farm";
import { useRemoveLiquidityMutation } from "shared/api/pool";
import { useEstimateRemoveLiquidityFeeQuery } from "shared/api/pool/useEstimateRemoveLiquidityFeeQuery";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useStateX } from "shared/hooks";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Spinner } from "shared/ui/spinner";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

import { ReactComponent as Line } from "../../../ui/line.svg";

import { ConfirmModal } from "./confirm-modal";

type State = {
  amount: string;
  isConfirmOpen: boolean;
};

export const RemoveLiquidity = () => {
  const chain = useChain();
  const navigate = useNavigate();
  const { detailedPair } = useOutletContext<LiquidityOutletContext>();

  const liquidityToken = detailedPair.pair.liquidityToken;
  const [currencyA, currencyB] = usePairCurrencies(detailedPair.pair);
  const balanceInfoQuery = useCurrencyBalanceQuery(liquidityToken);

  const miningPanelQuery = useMiningPanelsQuery({
    select: (data) =>
      data.find((panel) =>
        panel.detailedPair.pair.liquidityToken.equals(liquidityToken)
      ),
  });

  const [{ amount, isConfirmOpen }, setState] = useStateX<State>({
    amount: "",
    isConfirmOpen: false,
  });

  const stakingBalanceQuery = useStakingBalanceQuery(
    miningPanelQuery.data?.address,
    liquidityToken
  );

  const stakingBalanceInUsdD = stakingBalanceQuery.data?.balanceD.mul(
    detailedPair.advancedInfo.priceLT.priceLTD
  );

  const liquidityTokenAmount = toCurrencyAmount(liquidityToken, amount || "0");

  const details = useRemoveLiquidityDetails({
    liquidityToken,
    tokenAmount: liquidityTokenAmount,
    detailedPair,
  });

  const feeQuery = useEstimateRemoveLiquidityFeeQuery({
    detailedPair,
    ltAmount: liquidityTokenAmount,
    details: details,
  });

  const { status, btnDisabled } = useRemoveLiquidityStatus({
    fee: feeQuery.data,
    token: liquidityToken,
    tokenAmount: liquidityTokenAmount,
    detailedPair,
  });

  const removeLiquidityMutation = useRemoveLiquidityMutation();

  return (
    <>
      <InputCurrency
        currency={liquidityToken}
        balance={balanceInfoQuery.data?.formatted}
        price={details.usdPrice}
        value={amount}
        onChange={(amount) => {
          setState({ amount });
        }}
        onMaxClick={() =>
          setState({ amount: balanceInfoQuery.data?.balanceCA.toExact() })
        }
      >
        <PairLogoAndSymbol
          tokenA={currencyA.wrapped}
          tokenB={currencyB.wrapped}
        />
      </InputCurrency>

      <div className="mt-4 flex items-center justify-between text-sm text-osloGray">
        <span className="mr-auto inline-flex">LT Locked:</span>
        {stakingBalanceQuery.isSuccess && (
          <span className="mr-2">{stakingBalanceQuery.data.formatted} LT</span>
        )}
        {stakingBalanceQuery.isFetching && stakingBalanceQuery.isLoading && (
          <Spinner size="12" theme="gray" />
        )}

        {stakingBalanceQuery.isSuccess &&
          stakingBalanceQuery.data.balanceCA.greaterThan(0) &&
          miningPanelQuery.data && (
            <>
              <span className="mr-2">
                ≈${stakingBalanceInUsdD?.toDecimalPlaces(2).toFixed()}
              </span>
              <span
                className="cursor-pointer text-dodgerBlue"
                onClick={() => {
                  miningPanelQuery.data &&
                    navigate(`/farm/unstake/${miningPanelQuery.data.address}`, {
                      state: {
                        from: `/pool/remove/${liquidityToken.address}`,
                      },
                    });
                }}
              >
                UNLOCK
              </span>
            </>
          )}
      </div>

      <div className="my-4 flex items-center justify-between">
        <Line />
        <div className="inline-flex -rotate-90 justify-center">
          <Icon size="25" name="back" />
        </div>
        <Line />
      </div>

      <div className="flex h-[56px] items-center justify-between space-x-4 rounded-base bg-black/5 px-4 py-3 text-[1.375rem] text-black">
        <div>
          {amount &&
            status !== "enterAmount" &&
            details.liquidityValueA?.toSignificant()}
        </div>
        <CurrencyEntity
          symbol={currencyA.symbol}
          logoUrl={currencyA.logoUrl}
          bordered={false}
          size="38"
        />
      </div>
      <div className="mt-3 flex h-[56px] items-center justify-between space-x-4 rounded-base bg-black/5 px-4 py-3 text-[1.375rem] text-black">
        <div>
          {amount &&
            status !== "enterAmount" &&
            details.liquidityValueB?.toSignificant()}
        </div>
        <CurrencyEntity
          symbol={currencyB.symbol}
          logoUrl={currencyB.logoUrl}
          bordered={false}
          size="38"
        />
      </div>

      <div className="mt-4 flex items-center justify-between px-2 text-sm text-osloGray max-sm:flex-wrap">
        <span className="whitespace-nowrap">
          Pool Share {details.poolShare.toSignificant(4)}%
        </span>

        {details.trade && (
          <Price
            price={details.trade.executionPrice}
            priceImpact={details.trade.priceImpact}
            initialInvert={true}
          />
        )}
      </div>
      {status === "connectWallet" ? (
        <ConnectWalletButton className="mt-5" />
      ) : status === "wrongNetwork" ? (
        <SwitchNetworkButton className="mt-5" />
      ) : status === "approve" ? (
        <ApproveAllowanceButton
          className="mt-5"
          currencyAmount={liquidityTokenAmount}
          spenderAddress={ROUTER_ADDRESS_MAP[chain.id]}
        />
      ) : RM_LQ_STATUSES.includes(status) ? (
        <Button
          disabled={btnDisabled}
          className="mt-5 w-full !py-4 !text-2xl"
          onClick={() => {
            setState({ isConfirmOpen: true });
          }}
        >
          {status === "enterAmount" && "Enter amount"}
          {status === "insufficientTokenBalance" &&
            `Insufficient ${liquidityToken.symbol} balance`}
          {status === "insufficientNativeTokenBalance" &&
            `No ${Native.byChainId(chain.id)} to pay fee`}
          {status === "insufficientLiquidity" && "Insufficient liquidity"}
          {status === "removeLiquidity" && "Remove Liquidity"}
        </Button>
      ) : null}

      {details.trade && (
        <ConfirmModal
          open={isConfirmOpen}
          trade={details.trade}
          details={details}
          fee={feeQuery.data}
          detailedPair={detailedPair}
          tokenAmount={liquidityTokenAmount}
          onClose={() => setState({ isConfirmOpen: false })}
          onConfirm={() => {
            removeLiquidityMutation.mutate({
              details,
              currencyA,
              currencyB,
              ltAmount: liquidityTokenAmount,
            });
            setState({ isConfirmOpen: false, amount: "" });
          }}
        />
      )}
    </>
  );
};
