import { useQuery } from "@tanstack/react-query";
import { BigNumber } from "ethers";
import invariant from "tiny-invariant";
import { useAccount, useProvider, useSigner } from "wagmi";

import {
  HumanodeFactory__factory,
  HumanodeRouter__factory,
  MilkomedaRouter__factory,
} from "shared/abi/types";
import { calculateFee, calculateMinimumReceived } from "shared/helpers";
import { isHumanodeRouter, isMilkomedaRouter } from "shared/helpers/is-router";
import { useTxSettings } from "shared/providers/TxSettings";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency, CurrencyAmount, Percent } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

import { poolKeys } from "./keys";

type Params = {
  currencyAmountIn: CurrencyAmount<Currency> | undefined;
  currencyAmountOut: CurrencyAmount<Currency> | undefined;
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
};

export function useEstimateAddLiquidityFeeQuery({
  currencyAmountIn,
  currencyAmountOut,
  currencyA,
  currencyB,
}: Params) {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const signerQuery = useSigner();
  const [txSettings] = useTxSettings();
  const { address, isConnected } = useAccount();
  const isHumanode = useIsHumanode();

  return useQuery(
    poolKeys.addLiquidityFee(chain.id),
    async () => {
      invariant(
        currencyAmountIn,
        "useEstimateAddLiquidityFeeQuery. currencyAmountIn is undefined"
      );
      invariant(
        currencyAmountOut,
        "useEstimateAddLiquidityFeeQuery. currencyAmountOut is undefined"
      );
      invariant(
        currencyA,
        "useEstimateAddLiquidityFeeQuery. currencyA not found"
      );
      invariant(
        currencyB,
        "useEstimateAddLiquidityFeeQuery. currencyB not found"
      );
      invariant(
        address,
        "useEstimateAddLiquidityFeeQuery. address is undefined"
      );
      invariant(
        signerQuery.data,
        "useEstimateAddLiquidityFeeQuery. address is undefined"
      );

      const routerFactory = isHumanode
        ? HumanodeRouter__factory
        : MilkomedaRouter__factory;

      const contractRouter = routerFactory.connect(
        ROUTER_ADDRESS_MAP[chain.id],
        signerQuery.data || provider
      );

      const slippage = new Percent(
        txSettings.slippageTolerance * 1000,
        100 * 1000
      );

      const deadline =
        Math.floor(Date.now() / 1000) + 60 * txSettings.transactionDeadline;

      const native =
        currencyA.isNative || currencyB.isNative
          ? Native.byChainId(chain.id)
          : undefined;

      let promise = Promise.resolve(BigNumber.from(0));

      if (native && isMilkomedaRouter(contractRouter)) {
        const token = currencyA.isToken ? currencyA : currencyB.wrapped;
        const tokenCurrencyAmount = currencyAmountIn.currency.isToken
          ? currencyAmountIn.wrapped
          : currencyAmountOut.wrapped;

        const nativeCurrencyAmount = currencyAmountIn.currency.isNative
          ? currencyAmountIn
          : currencyAmountOut;

        const tokenMinimalReceivedCA = calculateMinimumReceived(
          slippage,
          tokenCurrencyAmount
        ).wrapped;

        const nativeMinimalReceivedCA = calculateMinimumReceived(
          slippage,
          nativeCurrencyAmount
        );

        promise = contractRouter.estimateGas.addLiquidityADA(
          token.address,
          tokenCurrencyAmount.quotient.toString(),
          tokenMinimalReceivedCA.quotient.toString(),
          nativeMinimalReceivedCA.quotient.toString(),
          address,
          deadline,
          { value: nativeCurrencyAmount.quotient.toString() }
        );
      } else {
        const minimalReceivedAmountIn = calculateMinimumReceived(
          slippage,
          currencyAmountIn
        );

        const minimalReceivedAmountOut = calculateMinimumReceived(
          slippage,
          currencyAmountOut
        );

        promise = contractRouter.estimateGas.addLiquidity(
          currencyA.wrapped.address,
          currencyB.wrapped.address,
          currencyAmountIn.quotient.toString(),
          currencyAmountOut.quotient.toString(),
          minimalReceivedAmountIn.quotient.toString(),
          minimalReceivedAmountOut.quotient.toString(),
          address,
          deadline
        );
      }

      const { gasPrice } = await provider.getFeeData();
      const estimatedGas = await promise;

      return calculateFee(gasPrice, estimatedGas, chain.id);
    },
    {
      enabled: Boolean(
        address &&
          currencyA &&
          currencyB &&
          currencyAmountIn?.greaterThan(0) &&
          currencyAmountOut?.greaterThan(0) &&
          isConnected &&
          signerQuery.isSuccess
      ),
    }
  );
}
