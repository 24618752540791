import classNames from "classnames";

import { Percent } from "shared/sdk-core";
import { EmotionCSSProps } from "shared/types";

import { getPriceImpactData } from "../utils/get-price-impact-data";

type Props = {
  priceImpact: Percent;
  showBrackets?: boolean;
} & EmotionCSSProps;

export const PriceImpact = ({
  priceImpact,
  className,
  showBrackets = false,
}: Props) => {
  const { cls, formatted } = getPriceImpactData(priceImpact);

  const textWithBrackets = `(-${formatted})`;

  return (
    <div className={classNames(className, cls)}>
      {showBrackets ? textWithBrackets : formatted}
    </div>
  );
};
