import { ReactNode, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";
import { ConnectorData, useAccount, useSwitchNetwork } from "wagmi";

import { supportedChains } from "shared/config/networks";
import { useDefaultChain } from "shared/providers/default-chain/provider";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";
import { EmotionCSSProps } from "shared/types";
import { Dropdown, DropdownItem } from "shared/ui/dropdown";
import { Icon } from "shared/ui/icon";
import { ChainId } from "shared/v2-sdk/constants";

import { useChangeNetwork } from "../hooks/use-change-network";

const CHAIN_MAP: Record<number, { title: string; icon: ReactNode }> = {
  [ChainId.MILKOMEDA_ADA]: {
    title: "Milkomeda",
    icon: <Icon name={"milkomeda"} className="mr-2" />,
  },
  [ChainId.MILKOMEDA_ADA_TESTNET]: {
    title: "Milkomeda Testnet",
    icon: <Icon name={"milkomeda"} className="mr-2" />,
  },
  [ChainId.HUMANODE_TESTNET]: {
    title: "Humanode Testnet 5",
    icon: (
      <img src="/tokens/humanode.png" alt="humanode" className="mr-2 h-6 w-6" />
    ),
  },
  [ChainId.HUMANODE_MAINNET]: {
    title: "Humanode",
    icon: (
      <img src="/tokens/humanode.png" alt="humanode" className="mr-2 h-6 w-6" />
    ),
  },
};

type Props = EmotionCSSProps;

export const Select = ({ className }: Props) => {
  const hasSwitchRequestRef = useRef(false);
  const { isConnected } = useAccount();
  const isSupportedChain = useIsSupportedChain();
  const { switchNetwork } = useSwitchNetwork();
  const [_, setDefaultChain] = useDefaultChain();

  const selectedChain = useChain();

  const showWrongBtn = isConnected && !isSupportedChain;

  const [searchParams, setSearchParams] = useSearchParams({
    chainId: selectedChain.id.toString(),
  });

  const chainId = searchParams.get("chainId");

  // TODO: Probably it can be better
  useEffect(() => {
    if (!switchNetwork || hasSwitchRequestRef.current) return;
    hasSwitchRequestRef.current = true;
    const parsedChainId = chainId ? Number.parseInt(chainId) : undefined;

    if (parsedChainId) {
      searchParams.set("chainId", parsedChainId.toString());
      setSearchParams(searchParams);
      switchNetwork(parsedChainId);
    }
  }, [switchNetwork, chainId, searchParams, setSearchParams]);

  useChangeNetwork(({ chain }: ConnectorData) => {
    if (chain?.unsupported) {
      searchParams.delete("chainId");
      setSearchParams(searchParams, { replace: true });
      return;
    }
  });

  return (
    <Dropdown
      placement="bottom-end"
      className="z-50"
      dropdownClassName="bg-wildSand bg-opacity-80 z-50"
      button={
        <Dropdown.Button className={className}>
          {selectedChain && !showWrongBtn ? (
            <>
              {CHAIN_MAP[selectedChain.id].icon}
              <span className="inline-block max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap max-xl:inline-block max-sm:hidden">
                {CHAIN_MAP[selectedChain.id].title}
              </span>
            </>
          ) : (
            <>
              <Icon className="text-red" name="warning" size="20" />
              <span className="text-red max-xl:inline-block max-sm:hidden">
                Wrong network
              </span>
            </>
          )}
          <Icon size="14" name="chevronDown" />
        </Dropdown.Button>
      }
    >
      {supportedChains.map((chain) => {
        return (
          <DropdownItem
            key={chain.id}
            className={cn("items-center bg-wildSand bg-opacity-80")}
            onClick={() => {
              searchParams.set("chainId", chain.id.toString());
              setSearchParams(searchParams, { replace: true });
              if (!isConnected) setDefaultChain(chain);
              switchNetwork?.(chain.id);
            }}
          >
            {CHAIN_MAP[chain.id].icon}
            {CHAIN_MAP[chain.id].title}
            {selectedChain?.id === chain.id && !showWrongBtn && (
              <Icon name="check" className="ml-4" />
            )}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};
