import tw, { css } from "twin.macro";

export const SIZES = {
  "12": tw`w-[12px] h-[12px] border`,
  "18": tw`w-[18px] h-[18px] border-2`,
  "66": tw`w-16 h-16 border-8`,
};

export const THEMES = {
  red: tw`border-red border-b-tiara`,
  reversedRed: tw`border-tiara border-b-red`,
  blue: tw`border-mariner border-b-tiara `,
  gray: tw`border-osloGray border-b-tiara`,
  dodgerBlue: tw`border-dodgerBlue border-b-quillGray`,
};

export const spinnerCss = css`
  ${tw`inline-block rounded-full w-full h-full border-b-dodgerBlue`}
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
