import React, { useState } from "react";
import { motion } from "framer-motion";

import { EmotionCSSProps } from "shared/types";

import { toggleCheckedCss, toggleCss, toggleDisabledCss } from "./styles";

type Props = {
  id?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
} & EmotionCSSProps;

export const Toggle = ({
  id,
  className,
  css: cssProp,
  checked: checkedProp,
  defaultChecked = false,
  disabled = false,
  onChange,
}: Props) => {
  const [checkedState, setCheckedState] = useState(defaultChecked);
  const checked = checkedProp ?? checkedState;
  return (
    <label
      id={`label-${id}`}
      css={[
        toggleCss,
        checked && toggleCheckedCss,
        disabled && toggleDisabledCss,
        cssProp,
      ]}
      className={className}
    >
      <motion.div
        animate={{ transition: { duration: 0.15 }, x: checked ? 20 : 0 }}
      >
        <div className="h-[22px] w-[22px] rounded-full bg-light" />
      </motion.div>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          if (disabled) return;

          setCheckedState(e.target.checked);
          onChange?.(e);
        }}
        className="invisible absolute left-0 top-0 h-0 w-0 appearance-none"
      />
    </label>
  );
};
