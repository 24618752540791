import { MiningPanel, useStakingBalanceQuery } from "shared/api/farm";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { CurrencyAmount, Fraction, Percent } from "shared/sdk-core";

export type StakingInfo = ReturnType<typeof useStakingInfo>;

export const useStakingInfo = (miningPanel: MiningPanel, amount?: string) => {
  const ltToken = miningPanel.detailedPair.pair.liquidityToken;
  const ltAmountCA = toCurrencyAmount(ltToken, amount || "0");
  const balanceInfoQuery = useCurrencyBalanceQuery(ltToken);
  const stakingBalanceQuery = useStakingBalanceQuery(
    miningPanel.address,
    ltToken
  );

  if (!balanceInfoQuery.data || !stakingBalanceQuery.data) return undefined;

  const balanceCA = balanceInfoQuery.data.balanceCA;
  const stakingBalanceCA = stakingBalanceQuery.data.balanceCA;
  const newBalanceCA = stakingBalanceCA.add(ltAmountCA);
  const totalStakedCA = toCurrencyAmount(ltToken, miningPanel.totalStakedLT);

  const capitalEfficiencyPercentFR = newBalanceCA.asFraction.divide(
    balanceCA.add(stakingBalanceCA).asFraction
  );

  const dailyRateCA =
    miningPanel && miningPanel.rewardsToken && miningPanel.rewardsPerSecond
      ? CurrencyAmount.fromRawAmount(
          miningPanel.rewardsToken,
          miningPanel.rewardsPerSecond
        ).multiply(60 * 60 * 24)
      : undefined;

  const poolSharePercentFR = newBalanceCA.asFraction.divide(
    totalStakedCA.asFraction
  );

  const rewardsAvailableCA = toCurrencyAmount(
    miningPanel.rewardsToken,
    miningPanel.rewardsAvailable
  );

  const rewardsClaimedCA = toCurrencyAmount(
    miningPanel.rewardsToken,
    miningPanel.rewardsClaimed
  );

  const rewardsEarnedCA = toCurrencyAmount(
    miningPanel.rewardsToken,
    miningPanel.rewardsTotal
  );

  return {
    stakingBalance: stakingBalanceQuery.data,
    ltBalance: balanceInfoQuery.data,

    capitalEfficiencyPercent: normalizePercent(capitalEfficiencyPercentFR),
    calcStakingBalance: newBalanceCA,
    dailyRateCA,
    poolSharePercent: normalizePercent(poolSharePercentFR),
    rewardsAvailableCA,
    rewardsClaimedCA,
    rewardsEarnedCA,
  };
};

function normalizePercent(percent: Fraction) {
  if (percent.greaterThan(1)) return new Percent(100, 100);
  if (percent.lessThan(0)) return new Percent(0, 100);
  return new Percent(percent.numerator, percent.denominator);
}
