import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from "react";
import { useLocalStorage } from "react-use";
import invariant from "tiny-invariant";

import { LS_KEYS } from "shared/constants";
import { useChain } from "shared/providers/wagmi";

export type State = {
  slippageTolerance: number;
  transactionDeadline: number;
};

const DEFAULT_TX_SETTINGS: State = {
  slippageTolerance: 1,
  transactionDeadline: 30,
};

const TxSettingsContext = React.createContext<
  [State, Dispatch<SetStateAction<State>>, () => void] | undefined
>(undefined);

export const TxSettingsProvider = ({ children }: PropsWithChildren) => {
  const chain = useChain();
  const result = useLocalStorage<State>(
    LS_KEYS.txSettings(chain.id),
    DEFAULT_TX_SETTINGS
  );

  return (
    <TxSettingsContext.Provider
      value={
        result as [
          State,
          React.Dispatch<React.SetStateAction<State>>,
          () => void
        ]
      }
    >
      {children}
    </TxSettingsContext.Provider>
  );
};

export const useTxSettings = () => {
  const context = useContext(TxSettingsContext);

  invariant(context, "useTxSettings must be used within TxSettingsProvider");

  return context;
};
