import Decimal from "decimal.js";

import { Validator } from "../use-input";

export const decimalValidators = (
  decimals: number | null | undefined = 0
): Validator[] => [
  (value: string) => {
    if (!value) return true;
    const parsedValue = value.replaceAll(",", ".");
    try {
      const number = new Decimal(parsedValue);
      const decimalPlaces = number.decimalPlaces();
      return Boolean(typeof decimals === "number" && decimalPlaces <= decimals);
    } catch (error) {
      return false;
    }
  },
  (value: string) => {
    if (decimals === 0) {
      return !value.includes(".");
    }
    return true;
  },
  (value: string) => {
    if (value[0] === "0" && /\d/.test(value[1])) return false;
    return true;
  },
];
