import { useNavigate } from "react-router-dom";
import { Decimal } from "decimal.js";
import numeral from "numeral";
import { toDecimal } from "utils/numbers";

import { useStakingInfo } from "entities/farm/hooks/use-staking-info";
import { PairLogoAndSymbol } from "entities/pair";
import { MiningPanel } from "shared/api/farm";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Icon } from "shared/ui/icon";
import { Img } from "shared/ui/img";
import { USDT } from "shared/v2-sdk/constants";

export type FarmCardProps = {
  position: Decimal | undefined;
  miningPanel: MiningPanel;
};
const nameFn = (a: string, b: string) => `${a} + ${b}`;

export const Card = ({ miningPanel, position }: FarmCardProps) => {
  const navigate = useNavigate();
  const chain = useChain();

  const stakingInfo = useStakingInfo(miningPanel);
  const stakingBalanceInUSD = stakingInfo?.stakingBalance.balanceD.mul(
    miningPanel.detailedPair.advancedInfo.priceLT.priceLTD
  );
  const rewardsToken = miningPanel.rewardsToken;

  const tvl = toCurrencyAmount(
    USDT[chain.id],
    miningPanel.detailedPair.advancedInfo.tvl
  );

  const dailyRate = stakingInfo?.dailyRateCA?.toFixed(0);
  const apy = toDecimal(miningPanel.apy).mul(100).toFixed(0);

  const allStaked =
    stakingBalanceInUSD?.greaterThan(0) &&
    stakingInfo?.ltBalance.balanceCA.equalTo(0);

  const showPosition =
    stakingInfo?.ltBalance.balanceCA.greaterThan(0) ||
    stakingBalanceInUSD?.greaterThan(0);

  const showStakeAndClaim = position?.greaterThan(0);

  return (
    <div className="farmCard-shadow relative overflow-hidden rounded-[20px] border border-light bg-athensGray">
      <div className="farmCard-gradient relative flex flex-col p-8">
        <PairLogoAndSymbol
          size="32"
          tokenA={miningPanel.detailedPair.pair.token0}
          tokenB={miningPanel.detailedPair.pair.token1}
          nameFn={nameFn}
        />

        <div className="stroke-gradient my-6 h-[1px] w-full" />
        <div className="mb-5 flex items-center justify-between text-2xl leading-7">
          <div className="mr-8 text-dodgerBlue">{apy}%</div>
          <div className="text-osloGray">APY</div>
        </div>
        <div className="mb-2 flex items-center justify-between text-lg leading-5">
          <div className="mr-2 text-black">
            ${numeral(tvl.toFixed(0)).format("0,0")}
          </div>
          <div className="text-osloGray">TVL</div>
        </div>
        <div className="flex items-center justify-between text-lg leading-5">
          <div className="flex items-center space-x-1">
            <Img
              className="h-5 w-5 rounded-[50%]"
              src={rewardsToken?.logoUrl}
              alt={rewardsToken?.symbol}
            />
            <span>{dailyRate}</span>
          </div>
          <div className="text-osloGray">Daily Rate</div>
        </div>
        <div className="stroke-gradient my-6 h-[1px] w-full" />
        <div className="mb-1 flex justify-between text-sm leading-5 text-black">
          <span>My Position</span>
          <span>Rewards Earned</span>
        </div>
        <div className="mb-6 flex justify-between text-lg leading-5">
          <span className="text-dodgerBlue">
            {showPosition ? (
              <>
                ${stakingBalanceInUSD?.toSignificantDigits(2).toFixed()}
                {!allStaked && (
                  <>
                    {" "}
                    /{" "}
                    <span className="">
                      ${position?.toSignificantDigits(2).toFixed()}
                    </span>
                  </>
                )}
              </>
            ) : (
              "$0"
            )}
          </span>
          <div className="flex items-center space-x-1">
            <Img
              className="h-5 w-5 rounded-[50%]"
              src={rewardsToken?.logoUrl}
              alt={rewardsToken?.symbol}
            />
            <span>
              {stakingInfo?.rewardsAvailableCA?.greaterThan(0)
                ? stakingInfo?.rewardsAvailableCA.toSignificant(6)
                : "0"}
            </span>
          </div>
        </div>
        {showStakeAndClaim ? (
          <div className="relative h-10 w-full items-center rounded-[8px] border border-dodgerBlue text-lg leading-6">
            <button
              onClick={() => navigate(`/farm/stake/${miningPanel.address}`)}
              className="absolute left-0 h-full w-1/2 rounded-[5px] bg-dodgerBlue text-white"
            >
              Stake
            </button>
            <button
              onClick={() => navigate(`/farm/claim/${miningPanel.address}`)}
              className="relative left-1/2 h-full w-1/2 text-dodgerBlue"
            >
              Claim
            </button>
          </div>
        ) : (
          <button
            onClick={() =>
              navigate(
                `/zzap?currencyAddress=${miningPanel.detailedPair.pair.token0.address}&poolAddress=${miningPanel.detailedPair.pair.liquidityToken.address}`
              )
            }
            className="flex h-10 items-center justify-center rounded-[5px] border border-dodgerBlue bg-dodgerBlue text-lg leading-6 text-white"
          >
            <Icon name="zzap" className="mr-3" />
            Zzap and Stake
          </button>
        )}
      </div>
    </div>
  );
};
