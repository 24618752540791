import { ButtonHTMLAttributes } from "react";

import { ButtonContent } from "./button-content";
import {
  btnCss,
  btnDisabledCss,
  OUTLINE_THEMES,
  SHAPES,
  SIZES,
  THEMES,
} from "./styles";
import { CustomProps } from "./types";

type Props = CustomProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof CustomProps>;

export const Button = ({
  css: cssProp,
  className,

  theme = "dodgerBlue",
  shape = "rect",
  size = "40",

  loading = false,
  reverse = false,
  outline = false,
  disabled = false,

  iconName,
  iconCss,
  iconClassName,

  children,
  ...props
}: Props) => {
  const btnThemeCss = outline ? OUTLINE_THEMES[theme] : THEMES[theme];
  const btnShapeCss = SHAPES[shape];
  const btnSizeCss = SIZES[`size${size}`];

  const btnStyles = {
    className,
    css: [
      btnCss,
      btnThemeCss,
      btnShapeCss,
      btnSizeCss,
      (disabled || loading) && btnDisabledCss,
      cssProp,
    ],
  };

  const content = (
    <ButtonContent
      theme={theme}
      loading={loading}
      reverse={reverse}
      iconName={iconName}
      iconCss={iconCss}
      iconClassName={iconClassName}
      size={size}
    >
      {children}
    </ButtonContent>
  );

  return (
    <button {...btnStyles} {...props}>
      {content}
    </button>
  );
};
