import React, { PropsWithChildren } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";
import { Icon } from "shared/ui/icon";
import { Link as UiLink } from "shared/ui/link";
import { Spinner } from "shared/ui/spinner";

type Props = {
  status: "success" | "error" | "pending" | "submitted";
  to: string;
} & EmotionCSSProps;

export const Link = ({
  status,
  to,
  className,
  css: cssProp,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn("flex items-center justify-between", className)}
      css={cssProp}
    >
      <UiLink className="inline-flex" to={to} showIcon>
        {children}
      </UiLink>

      <div className="ml-auto inline-flex">
        {status === "pending" && <Spinner size="18" />}
        {status === "success" && (
          <Icon className="text-malachite" name="success" size="20" />
        )}
        {status === "error" && (
          <Icon className="text-redOrange" name="fail" size="20" />
        )}
        {status === "submitted" && (
          <Icon className="text-dodgerBlue" name="submitted" size="20" />
        )}
      </div>
    </div>
  );
};
