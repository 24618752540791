import React from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

export const Footer = ({ className, css }: EmotionCSSProps) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer
      css={css}
      className={cn(
        "textSecondary mt-auto flex px-[111px] max-sm:px-3",
        className
      )}
    >
      © {currentYear} OccamX DAO
    </footer>
  );
};
