import { DetailedPair } from "shared/api/pool";
import { useCurrencyBalanceQuery, useUSDExchangeRate } from "shared/api/token";
import { CurrencyAmount, Percent, Token } from "shared/sdk-core";
import { useBestTrade } from "shared/v2-sdk/hooks";

import { usePairCurrencies } from "./usePairCurrencies";

type Params = {
  liquidityToken: Token;
  tokenAmount: CurrencyAmount<Token>;
  detailedPair: DetailedPair;
};

export type RemoveLiquidityDetails = ReturnType<
  typeof useRemoveLiquidityDetails
>;
export const useRemoveLiquidityDetails = ({
  liquidityToken,
  tokenAmount,
  detailedPair,
}: Params) => {
  const tokenBalanceQuery = useCurrencyBalanceQuery(liquidityToken);
  const totalSupply = detailedPair.advancedInfo.totalSupply;

  const [currencyA, currencyB] = usePairCurrencies(detailedPair.pair);

  const fraction = tokenBalanceQuery.data?.balanceCA
    .subtract(tokenAmount)
    .asFraction.divide(totalSupply.totalSupplyCA.asFraction);

  const poolShare = fraction?.greaterThan(0)
    ? new Percent(fraction.numerator, fraction.denominator)
    : new Percent(0, 1);

  let liquidityValueA: CurrencyAmount<Token> | undefined = undefined;
  let liquidityValueB: CurrencyAmount<Token> | undefined = undefined;

  try {
    liquidityValueA = detailedPair.pair.getLiquidityValue(
      detailedPair.pair.token0,
      totalSupply.totalSupplyCA,
      tokenAmount,
      false
    );

    liquidityValueB = detailedPair.pair.getLiquidityValue(
      detailedPair.pair.token1,
      totalSupply.totalSupplyCA,
      tokenAmount,
      false
    );
  } catch (error) {
    console.error(error);
  }

  const { trade } = useBestTrade(currencyA, currencyB, liquidityValueA);

  const usdPriceA = useUSDExchangeRate(liquidityValueA);
  const usdPriceB = useUSDExchangeRate(liquidityValueB);

  const usdPrice =
    usdPriceB && usdPriceA ? usdPriceA.add(usdPriceB) : undefined;

  return {
    poolShare,
    liquidityValueA,
    liquidityValueB,
    trade,
    usdPrice,
  };
};
