import { useTokenApproveMutation } from "shared/api/token";
import { useCheckSolvency } from "shared/api/token/useCheckSolvency";
import { useTokenApproveEstimateFeeQuery } from "shared/api/token/useTokenApproveEstimateFeeQuery";
import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { EmotionCSSProps } from "shared/types";
import { Button } from "shared/ui/button";
import { notify } from "shared/ui/toast";
import { NATIVE } from "shared/v2-sdk/constants";

type Props = {
  onSuccess?: () => void;
  spenderAddress: string;
  currencyAmount: CurrencyAmount<Currency>;
  isLoading?: boolean;
} & EmotionCSSProps;

export const ApproveButton = ({
  className,
  css: cssProp,
  spenderAddress,
  currencyAmount,
  isLoading,
  onSuccess,
}: Props) => {
  const chain = useChain();
  const currency = currencyAmount?.currency;
  const feeQuery = useTokenApproveEstimateFeeQuery({
    currency,
    spenderAddress,
    currencyAmount: currencyAmount,
  });

  const isSolvent = useCheckSolvency({
    fee: feeQuery.isSuccess ? feeQuery.data : undefined,
    currencyAmount,
  });
  const approveMutation = useTokenApproveMutation();

  const onApprove = () => {
    if (!currency || currency.isNative) return;

    approveMutation.mutate(
      {
        token: currency,
        spenderAddress,
        currencyAmount: currencyAmount.wrapped,
      },
      {
        onSuccess: () => {
          onSuccess?.();
        },
        onError: (error) => {
          if (
            error &&
            typeof error === "object" &&
            "code" in error &&
            "reason" in error
          ) {
            notify.error(
              error.code === "ACTION_REJECTED"
                ? "Transaction has been rejected by user"
                : `Transaction rejected with reason: ${error.reason}`
            );
          }
        },
        onSettled: () => {},
      }
    );
  };

  return (
    <Button
      id={"approve-token-btn"}
      loading={isLoading}
      disabled={approveMutation.isLoading || !isSolvent}
      size="66"
      className={className}
      css={cssProp}
      onClick={onApprove}
    >
      {!isSolvent &&
        !approveMutation.isLoading &&
        `No ${NATIVE[chain.id].symbol} balance to pay fee`}
      {isSolvent &&
        approveMutation.isLoading &&
        `Approving ${currency.symbol} spending...`}
      {isSolvent &&
        !approveMutation.isLoading &&
        `Approve ${currency.symbol} spending`}
    </Button>
  );
};
