import { toDecimal } from "utils/numbers";
import { useAccount } from "wagmi";

import { useHasAllowanceQuery } from "features/approve-allowance";
import { useCheckSolvency, useCurrencyBalanceQuery } from "shared/api/token";
import { Fee } from "shared/helpers";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";
import { TradeTypes } from "shared/v2-sdk/hooks";

export const SWAP_STATUSES = [
  "selectCurrencyA",
  "selectCurrencyB",
  "enterAmount",
  "insufficientLiquidity",
  "insufficientBalance",
  "insufficientNativeTokenBalance",
  "swapAnyway",
  "swap",
];

export type Status =
  | "connectWallet"
  | "wrongNetwork"
  | "approve"
  | "selectCurrencyA"
  | "selectCurrencyB"
  | "enterAmount"
  | "insufficientLiquidity"
  | "insufficientBalance"
  | "insufficientNativeTokenBalance"
  | "swapAnyway"
  | "swap";

type Params = {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  currencyAmountIn: CurrencyAmount<Currency> | undefined;
  currencyAmountOut: CurrencyAmount<Currency> | undefined;
  fee: Fee | undefined;
  trade: TradeTypes | undefined;
};

export const useSwapStatus = ({
  currencyA,
  currencyB,
  currencyAmountIn,
  currencyAmountOut,
  fee,
  trade,
}: Params) => {
  const chain = useChain();
  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();

  const hasAllowanceQuery = useHasAllowanceQuery({
    currencyAmount: currencyAmountIn,
    spenderAddress: ROUTER_ADDRESS_MAP[chain.id],
  });

  const isSolvent = useCheckSolvency({
    fee,
    currencyAmount: currencyAmountIn,
  });

  const currencyABalanceQuery = useCurrencyBalanceQuery(currencyA);

  const isEnoughBalance = () => {
    const result =
      currencyABalanceQuery.isSuccess &&
      typeof currencyA?.decimals === "number" &&
      currencyAmountIn?.lessThanOrEqual(
        currencyABalanceQuery.data.balanceCA.asFraction
      );

    return result;
  };

  const tradeIsNotAvailable = !trade || !trade?.route;
  const hasAtLeastOneAmount = currencyAmountIn || currencyAmountOut;

  const getStatus = (): Status => {
    if (!isConnected) return "connectWallet";
    if (isConnected && !isSupported) return "wrongNetwork";
    if (!currencyA) return "selectCurrencyA";
    if (!currencyB) return "selectCurrencyB";
    if (!currencyAmountIn || currencyAmountIn.equalTo(0)) return "enterAmount";
    if (tradeIsNotAvailable && hasAtLeastOneAmount)
      return "insufficientLiquidity";
    if (!isEnoughBalance()) return "insufficientBalance";
    if (!isSolvent) return "insufficientNativeTokenBalance";
    if (hasAllowanceQuery.data === false && currencyA.isToken) return "approve";
    if (toDecimal(trade?.priceImpact.toSignificant()).greaterThan(5))
      return "swapAnyway";
    return "swap";
  };

  const status = getStatus();

  const getBtnDisabled = () => {
    if (
      status === "selectCurrencyA" ||
      status === "selectCurrencyB" ||
      status === "enterAmount" ||
      status === "insufficientBalance" ||
      status === "insufficientNativeTokenBalance" ||
      status === "insufficientLiquidity"
    )
      return true;

    return false;
  };

  return { status, isBtnDisabled: getBtnDisabled() };
};
