import { Currency, CurrencyAmount, Fraction, Percent } from "shared/sdk-core";
import { ONE } from "shared/v2-sdk/constants";

export const calculateMinimumReceived = (
  slippage: Percent,
  amount: CurrencyAmount<Currency>
) => {
  const slippageAdjustedAmountOut = new Fraction(ONE)
    .add(slippage)
    .invert()
    .multiply(amount.quotient).quotient;

  return CurrencyAmount.fromRawAmount(
    amount.currency,
    slippageAdjustedAmountOut
  );
};
