import { useQuery } from "@tanstack/react-query";
import { BigNumber } from "ethers";
import invariant from "tiny-invariant";
import { useProvider, useSigner } from "wagmi";

import {
  HumanodeRouter__factory,
  MilkomedaRouter__factory,
} from "shared/abi/types";
import { isZero } from "shared/helpers";
import { calculateFee } from "shared/helpers/calculateFee";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency, TradeType } from "shared/sdk-core";
import { Router, Trade, TradeOptions } from "shared/v2-sdk";
import {
  ROUTER_ADDRESS_MAP,
  SWAP_METHODS_BY_CHAIN,
} from "shared/v2-sdk/constants";

import { swapKeys } from "./keys";

export const useEstimateSwapFeeQuery = (
  trade: Trade<Currency, Currency, TradeType> | undefined,
  tradeOptions: TradeOptions | undefined
) => {
  const chain = useChain();
  const signerQuery = useSigner();
  const provider = useProvider({ chainId: chain.id });

  const isHumanode = useIsHumanode();

  const query = useQuery(
    swapKeys.estimateFee(trade),
    async () => {
      invariant(
        tradeOptions,
        "useEstimateFeeQuery. tradeOptions is not defined"
      );

      invariant(
        trade,
        "useEstimateFeeQuery. trade should be have Trade<Currency, Currency, TradeType> type. Check 'enabled' option. queryFn have to be called with trade and"
      );

      const routerFactory = isHumanode
        ? HumanodeRouter__factory
        : MilkomedaRouter__factory;

      const contractRouter = routerFactory.connect(
        ROUTER_ADDRESS_MAP[chain.id],
        signerQuery.data || provider
      );

      const swapParameters = Router.swapCallParameters(trade, tradeOptions);

      invariant(
        swapParameters.methodName,
        "useEstimateFeeQuery. methodName did not figure out"
      );

      const NAMES_MAP = SWAP_METHODS_BY_CHAIN[chain.id];
      const algoMethodName = NAMES_MAP[swapParameters.methodName];
      const args = swapParameters.args;

      const method = (contractRouter.estimateGas as any)[algoMethodName] as (
        ...params: any[]
      ) => Promise<BigNumber>;

      const { gasPrice } = await provider.getFeeData();
      const options =
        !swapParameters.value || isZero(swapParameters.value)
          ? {}
          : { value: swapParameters.value };

      let estimatedGas = BigNumber.from(1_000_000);

      try {
        estimatedGas = await method(...args, options);
      } catch (error) {
        console.error(error);
      }

      return calculateFee(gasPrice, estimatedGas, chain.id);
    },
    {
      enabled: Boolean(tradeOptions && trade),
    }
  );

  return query;
};
