import { useAccount } from "wagmi";

import { MiningPanel } from "shared/api/farm";
import { toCurrencyAmount } from "shared/helpers";
import { useIsSupportedChain } from "shared/providers/wagmi";

type Status = "connectWallet" | "switchNetwork" | "nothingToClaim" | "claim";

export const useClaimStatus = (miningPanel: MiningPanel) => {
  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();

  const getStatus = (): Status => {
    if (!isConnected) return "connectWallet";
    if (!isSupported) return "switchNetwork";
    if (
      miningPanel.rewardsToken &&
      miningPanel.rewardsAvailable &&
      toCurrencyAmount(
        miningPanel.rewardsToken,
        miningPanel.rewardsAvailable
      ).lessThanOrEqual(0)
    )
      return "nothingToClaim";

    return "claim";
  };

  const status = getStatus();

  const getBtnText = () => {
    if (status === "nothingToClaim") return "No rewards";
    if (status === "claim") return "Claim";
  };

  return {
    status,
    btnText: getBtnText(),
    btnDisabled: status === "nothingToClaim",
  };
};
