import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import { Currency, Percent, Price as UniPrice } from "shared/sdk-core";
import { Text } from "shared/ui/text";

import { usePrice } from "../hooks/use-price";
import { getPriceImpactData } from "../utils/get-price-impact-data";

type Props = {
  initialInvert?: boolean;
  price: UniPrice<Currency, Currency>;
  showUsdPrice?: boolean;
  priceImpact?: Percent;
  className?: string;
};

export const Price = ({
  price,
  showUsdPrice = false,
  priceImpact,
  className,
  initialInvert = false,
  children,
}: PropsWithChildren<Props>) => {
  const [tradePrice, invert] = usePrice(price, initialInvert);

  if (!tradePrice) return null;

  const priceImpactData = getPriceImpactData(priceImpact);

  return (
    <Text
      className={classNames(
        "ml-auto inline-flex cursor-pointer select-none items-center whitespace-nowrap text-sm text-osloGray max-sm:ml-0 max-sm:mt-2",
        priceImpactData.status === "high" && "text-redOrange",
        className
      )}
      onClick={invert}
    >
      {tradePrice.text}
      {showUsdPrice && tradePrice.usdPrice && (
        <span className="ml-1">≈ ${tradePrice.usdPrice.toSignificant(6)}</span>
      )}
    </Text>
  );
};
