import { PropsWithChildren } from "react";

import { EmotionCSSProps } from "shared/types";
import { Text } from "shared/ui/text";

import { tabCss } from "./styles";
import * as THEMES from "./themes.styles";

type Props = {
  id?: string;
  theme?: keyof typeof THEMES;
  active: boolean;
  onClick?: () => void;
} & EmotionCSSProps;

export const Tab = ({
  id,
  theme = "light",
  className,
  css,
  children,
  active = false,
  onClick,
}: PropsWithChildren<Props>) => {
  const tabTheme = THEMES[theme];
  return (
    <div
      id={id}
      css={[tabCss, tabTheme.default, active && tabTheme.active, css]}
      onClick={onClick}
      className={className}
    >
      <Text>{children}</Text>
    </div>
  );
};
