import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccount, useProvider, useSigner } from "wagmi";

import { StackingOcxABI__factory, Staking__factory } from "shared/abi/types";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { OWN_TOKEN } from "shared/v2-sdk/constants";

import { tokenKeys } from "../token/keys";
import { useTxMutation, useTxWaitMutation } from "../transtaction";

import { ocxKeys } from "./ocxKeys";

type Params = {
  contractAddress: string;
  currencyAmount: CurrencyAmount<Token>;
};

export const useStakeOcxMutation = () => {
  const chain = useChain();
  const queryClient = useQueryClient();
  const { address } = useAccount();
  const provider = useProvider({ chainId: chain.id });
  const { data: signer } = useSigner();
  const ocxToken = OWN_TOKEN[chain.id];

  const txMutation = useTxMutation();
  const txWaitMutation = useTxWaitMutation();

  return useMutation(
    async ({ currencyAmount, contractAddress }: Params) => {
      const desc = `Stake ${currencyAmount.toSignificant(6)} ${
        ocxToken.symbol
      }`;
      const contract = StackingOcxABI__factory.connect(
        contractAddress,
        signer || provider
      );

      const estimatedGas = await contract.estimateGas.stake(
        currencyAmount.quotient.toString()
      );

      const txPromise = contract.stake(currencyAmount.quotient.toString(), {
        gasLimit: estimatedGas.mul(120).div(100),
      });

      const tx = await txMutation.mutateAsync({ txPromise, desc });

      const receipt = await txWaitMutation.mutateAsync({ tx, desc });
      return receipt;
    },
    {
      onSuccess: (_data, params) => {
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.currencyAmount.currency.address
          )
        );
        queryClient.refetchQueries(
          ocxKeys.stakingBalance(chain.id, address, params.contractAddress)
        );

        queryClient.invalidateQueries(
          tokenKeys.allowance(
            params.currencyAmount.currency.address,
            address,
            params.contractAddress
          )
        );
      },
    }
  );
};
