import { useState } from "react";

import { CurrencyEntity } from "entities/currency";
import { ApproveAllowanceButton } from "features/approve-allowance";
import { ConnectWalletButton } from "features/connect-wallet";
import { InputCurrency } from "features/input-currency";
import { SwitchNetworkButton } from "features/switch-network";
import {
  useEstimateFeeStakeOcxQuery,
  useStakeOcxMutation,
} from "shared/api/ocx";
import { useTokenToUSDExchangeRate } from "shared/api/token/useTokenToUSDExchangeRate";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { OCX_STAKE_CONTRACT_ADDRESS, OWN_TOKEN } from "shared/v2-sdk/constants";

import { useStakeOcxStatus, useStakingOcxInfo } from "../hooks";

import { ConfirmModal } from "./ConfirmModal";
import { StakingInfo } from "./StakingInfo";
import { Stats } from "./Stats";

export const StakeOcxPage = () => {
  const chain = useChain();
  const ocxToken = OWN_TOKEN[chain.id];
  const [amount, setAmount] = useState("");
  const currencyAmount = toCurrencyAmount(ocxToken, amount);
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const ocxToUSDRate = useTokenToUSDExchangeRate(ocxToken, currencyAmount);
  const stakingOcxInfo = useStakingOcxInfo(amount);
  const feeQuery = useEstimateFeeStakeOcxQuery({ amountCA: currencyAmount });

  const { status, btnDisabled, btnText } = useStakeOcxStatus(currencyAmount);

  const stakeOcxMutation = useStakeOcxMutation();

  return (
    <>
      <InputCurrency
        value={amount}
        id="stake-input"
        balance={stakingOcxInfo?.balance.balanceCA.toSignificant(6)}
        currency={ocxToken}
        price={ocxToUSDRate.trade?.route.midPrice.quote(currencyAmount)}
        onChange={(value) => {
          setAmount(value);
        }}
        onMaxClick={() => {
          stakingOcxInfo?.balance.balanceCA &&
            setAmount(stakingOcxInfo?.balance.balanceCA.toExact());
        }}
      >
        <CurrencyEntity
          symbol={ocxToken.symbol}
          logoUrl={ocxToken.logoUrl}
          bordered={false}
          size="38"
        />
      </InputCurrency>
      <Stats
        capitalEfficiencyPercent={stakingOcxInfo?.capitalEfficiencyPercent}
        calcPoolShare={stakingOcxInfo?.poolSharePercent}
        calcStakingBalance={stakingOcxInfo?.calcStakingBalance}
        dailyRate={
          stakingOcxInfo
            ? `${stakingOcxInfo.dailyRateCA?.toSignificant(6) ?? "0"} ${
                ocxToken.symbol
              } daily`
            : undefined
        }
        apy={stakingOcxInfo?.apy || "0"}
        totalStaked={stakingOcxInfo?.totalStakedCA?.toSignificant(6) || "0"}
        tvl={stakingOcxInfo?.tvl || "0"}
      />
      {status === "connectWallet" && (
        <ConnectWalletButton className="mt-4 w-full" />
      )}
      {status === "switchNetwork" && (
        <SwitchNetworkButton className="mt-4 w-full" />
      )}
      {status === "approve" && (
        <ApproveAllowanceButton
          currencyAmount={currencyAmount}
          className="mt-4 w-full"
          spenderAddress={OCX_STAKE_CONTRACT_ADDRESS[chain.id]}
        />
      )}
      {[
        "enterAmount",
        "stake",
        "insufficientBalance",
        "insufficientNativeTokenBalance",
      ].includes(status) && (
        <Button
          id="main-stake-btn"
          size="66"
          disabled={btnDisabled}
          className="mt-4 w-full text-2xl"
          onClick={() => {
            setConfirmOpen(true);
          }}
        >
          {btnText}
        </Button>
      )}

      <StakingInfo className="mt-8" stakingInfo={stakingOcxInfo} />

      {stakingOcxInfo && (
        <ConfirmModal
          isOpen={isConfirmOpen}
          amount={amount}
          fee={feeQuery.data}
          onClose={() => setConfirmOpen(false)}
          stakingInfo={stakingOcxInfo}
          onConfirm={() => {
            stakeOcxMutation.mutate({
              contractAddress: OCX_STAKE_CONTRACT_ADDRESS[chain.id],
              currencyAmount: currencyAmount,
            });
            setConfirmOpen(false);
          }}
        />
      )}
    </>
  );
};
