import React from "react";
import { toDecimal } from "utils/numbers";

import { Balance } from "entities/balance";
import { CurrencyEntity } from "entities/currency";
import { useTokenToUSDExchangeRate } from "shared/api/token/useTokenToUSDExchangeRate";
import { toCurrencyAmount } from "shared/helpers";
import { Currency } from "shared/sdk-core";

type Props = {
  token: Currency;
  balance?: string;
  onAdd?: () => void;
};

export const Row = ({ token, balance, onAdd }: Props) => {
  const currencyAmount = toCurrencyAmount(token, balance);
  const priceInUsdt = useTokenToUSDExchangeRate(token, currencyAmount);

  const balanceD = toDecimal(balance);

  return (
    <div className="grid w-full grid-cols-2 justify-self-end">
      <CurrencyEntity
        bordered={false}
        size="38"
        name={token.name}
        symbol={token.symbol}
        logoUrl={token.logoUrl}
        nameElement={
          onAdd ? (
            <div
              className="hover:text-redMalibu ml-1 cursor-pointer text-dodgerBlue"
              onClick={onAdd}
            >
              ADD
            </div>
          ) : undefined
        }
      />
      {balanceD?.greaterThan(0) && (
        <Balance
          balance={balanceD.toPrecision(6)}
          convertedBalance={priceInUsdt.trade?.route.midPrice
            .quote(currencyAmount)
            .toSignificant(6)}
        />
      )}
    </div>
  );
};
