import { AddressZero } from "@ethersproject/constants";
import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useBlockNumber, useProvider } from "wagmi";

import { useChain } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";

import { fetchCurrencyBalance } from "./helpers";
import { tokenKeys } from "./keys";

export const useCurrencyBalanceQuery = (currency: Currency | undefined) => {
  const { address } = useAccount();
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });

  const query = useQuery(
    tokenKeys.balance(
      chain.id,
      address,
      currency?.isToken ? currency.address : AddressZero
    ),
    async () => {
      invariant(address, "useCurrencyBalanceQuery. address is undefined");
      invariant(currency, "useCurrencyBalanceQuery. currency is undefined");
      return await fetchCurrencyBalance(currency, address, provider);
    },
    {
      enabled: Boolean(address && currency),
    }
  );

  return query;
};
