import { EmotionCSSProps } from "shared/types";
import { Button, ButtonSize } from "shared/ui/button";
import { useWalletModal } from "widgets/wallet";

type Props = {
  size?: ButtonSize;
  isLoading?: boolean;
} & EmotionCSSProps;

export const Btn = ({ className, isLoading, css, size = "66" }: Props) => {
  const [_, setWalletModalOpen] = useWalletModal();

  const onConnect = () => {
    setWalletModalOpen({
      open: true,
      step: "list",
    });
  };
  return (
    <Button
      loading={isLoading}
      id="connect-wallet-btn"
      size={size}
      className={className}
      css={css}
      onClick={onConnect}
    >
      Connect wallet
    </Button>
  );
};
