import React, { useState } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
import debounce from "lodash/debounce";
import { noop } from "utils";

import { EmotionCSSProps } from "shared/types";

export type TabType = {
  title: string;
  route: string;
  render(): JSX.Element | string;
};

type TabsProps = {
  activeTab: string;
  tabs: TabType[];
  onClick: (tab: TabType) => void;
} & EmotionCSSProps;

export const TerminalTabs = ({
  tabs,
  activeTab,
  onClick,
  className,
}: TabsProps) => {
  const [activeTabId, setActiveTabId] = useState(activeTab);

  const tabRefs = tabs.reduce((acc, item) => {
    acc[item.route] = React.createRef();
    return acc;
  }, {} as Record<string, React.RefObject<any>>);

  return (
    <div
      className={cn(
        className,
        "relative overflow-hidden rounded-[5px] border-4 border-dodgerBlue bg-dodgerBlue"
      )}
    >
      <ul className="flex justify-between">
        {tabs.map((tab) => (
          <Tab
            id={`tab-${tab.route}`}
            key={tab.route}
            ref={tabRefs[tab.route]}
            tab={tab}
            active={activeTabId === tab.route}
            onClick={() => {
              onClick(tab);
              setActiveTabId(tab.route);
            }}
          />
        ))}
      </ul>
      <AnimatedBackground refs={tabRefs} activeTabId={activeTabId} />
    </div>
  );
};

type TabProps = {
  id?: string;
  tab: TabType;
  ref: React.RefObject<HTMLLIElement>;
  active?: boolean;
  onClick?(): void;
};

const Tab = React.forwardRef<HTMLLIElement, TabProps>(
  ({ active, tab, id, onClick = noop }, ref) => (
    <li
      id={id}
      ref={ref}
      key={tab.route}
      onClick={onClick}
      className={cn(
        "z-10 cursor-pointer rounded-[5px] px-4 py-1 text-lg leading-5",
        {
          "text-white": !active,
          "text-black": active,
        }
      )}
    >
      {tab.title}
      {/*<Link to={`/${tab.route}/asdf`}>{tab.title}</Link>*/}
    </li>
  )
);

type AnimatedBackgroundProps = {
  refs: Record<string, React.MutableRefObject<HTMLElement>>;
  activeTabId: string;
};

const AnimatedBackground = ({ refs, activeTabId }: AnimatedBackgroundProps) => {
  const [{ x, width }, setAttributes] = React.useState({
    x: 0,
    width: 0,
  });

  const updateAttributes = React.useCallback(() => {
    if (refs && refs[activeTabId]) {
      setAttributes({
        x: refs[activeTabId].current.offsetLeft,
        width: refs[activeTabId].current.getBoundingClientRect().width,
      });
    }
  }, [activeTabId, refs]);

  React.useEffect(() => {
    updateAttributes();
  }, [activeTabId, refs, updateAttributes]);

  React.useEffect(() => {
    const recalculateAttrs = debounce(() => {
      updateAttributes();
    }, 500);

    window.addEventListener("resize", recalculateAttrs);
    return () => {
      window.removeEventListener("resize", recalculateAttrs);
    };
  });

  return (
    <motion.div
      className="absolute top-0 z-0 h-full rounded-[5px] bg-white"
      animate={{
        x,
        width,
      }}
    />
  );
};
