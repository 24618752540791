import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getAddress } from "ethers/lib/utils";
import { toDecimal } from "utils/numbers";
import { useProvider } from "wagmi";

import { useCurrenciesMap } from "entities/currency";
import {
  HumanodeFactory__factory,
  UniswapV2Factory__factory,
  UniswapV2Pair__factory,
} from "shared/abi/types";
import { toCurrencyAmount } from "shared/helpers";
import { useGraphQlSdk } from "shared/providers/graph-ql-client";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { FACTORY_ADDRESS_MAP, Pair, USDT } from "shared/v2-sdk";

import { fetchPair } from "../token/helpers/fetchPair";

import { poolKeys } from "./keys";
import { PoolAdvancedInfo } from "./types";

export type DetailedPair = { pair: Pair; advancedInfo: PoolAdvancedInfo };

export const useDetailedPairsQuery = <TData = DetailedPair[]>(
  options?: Omit<
    UseQueryOptions<
      DetailedPair[],
      Error,
      TData,
      ReturnType<typeof poolKeys.allPairs>
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const chain = useChain();
  const graphqlSdk = useGraphQlSdk();
  const provider = useProvider({ chainId: chain.id });
  const currenciesMap = useCurrenciesMap();

  const isHumanode = useIsHumanode();

  const query = useQuery(
    poolKeys.allPairs(chain.id, currenciesMap),
    async () => {
      const factoryContract = isHumanode
        ? HumanodeFactory__factory.connect(
            FACTORY_ADDRESS_MAP[chain.id],
            provider
          )
        : UniswapV2Factory__factory.connect(
            FACTORY_ADDRESS_MAP[chain.id],
            provider
          );

      const pairsCountBn = await factoryContract.allPairsLength();
      const pairsCount = pairsCountBn.toNumber();

      const promises = Array.from({ length: pairsCount }).map((_, idx) => {
        return factoryContract.allPairs(idx);
      });

      const { getAllPools } = await graphqlSdk.GetAllPools();

      const pairAddresses = await Promise.all(promises);
      const pairPromises = pairAddresses.map(async (address) => {
        const pairContract = UniswapV2Pair__factory.connect(address, provider);
        const [token0Address, token1Address] = await Promise.all([
          pairContract.token0(),
          pairContract.token1(),
        ]);

        const token0 = currenciesMap[token0Address];
        const token1 = currenciesMap[token1Address];

        if (!token0 || !token1) return;

        const pair = await fetchPair({
          pairAddress: address,
          tokenA: token0.wrapped,
          tokenB: token1.wrapped,
          provider,
          chainId: chain.id,
        });

        return pair;
      });

      const pairs = await Promise.all(pairPromises.filter(Boolean));
      const filteredPairs = pairs.filter(Boolean);
      return filteredPairs.map((pair) => {
        const relatedPool = getAllPools.find(
          (p) =>
            getAddress(p.liquidityToken.contract) ===
            getAddress(pair.liquidityToken.address)
        );
        const totalSupplyCA = toCurrencyAmount(
          pair.liquidityToken,
          relatedPool?.totalSupply
        );
        const totalSupply = {
          formatted: relatedPool?.totalSupply
            ? totalSupplyCA.toSignificant(6)
            : "N/A",
          totalSupplyD: toDecimal(relatedPool?.totalSupply),
          totalSupplyCA,
        };

        const priceLTCA = toCurrencyAmount(
          USDT[chain.id],
          relatedPool?.priceLT
        );

        const advancedInfo: PoolAdvancedInfo = {
          priceLT: {
            formatted: relatedPool?.priceLT ? priceLTCA.toSignificant() : "N/A",
            priceLTD: toDecimal(relatedPool?.priceLT),
            priceLTCA,
          },
          totalSupply: totalSupply,
          volume24: relatedPool?.volume24 ?? "N/A",
          tvl: relatedPool?.tvl ?? "N/A",
          fees24: relatedPool?.fees24 ?? "N/A",
        };

        return { pair, advancedInfo };
      }) as DetailedPair[];
    },
    {
      enabled: Boolean(provider),
      staleTime: 1000 * 60 * 5,
      ...options,
    }
  );

  return query;
};
