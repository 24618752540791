import React, { PropsWithChildren } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

type Props = {
  id?: string;
  onClick?: () => void;
} & EmotionCSSProps;

export const Item = ({
  id,
  onClick,
  children,
  className,
  css: cssProp,
}: PropsWithChildren<Props>) => {
  return (
    <div
      css={cssProp}
      id={id}
      className={cn(
        "flex cursor-pointer items-center px-4 py-3 text-black transition-colors hover:bg-tiara hover:bg-opacity-20",
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
