import { useTokenToUSDExchangeRate } from "shared/api/token/useTokenToUSDExchangeRate";
import { toCurrencyAmount } from "shared/helpers";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { useBestTrade } from "shared/v2-sdk/hooks";

type Params = {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  currencyAmountIn: CurrencyAmount<Currency> | undefined;
  currencyAmountOut: CurrencyAmount<Currency> | undefined;
};

export const useCurrenciesTrades = ({
  currencyA,
  currencyB,
  currencyAmountIn,
  currencyAmountOut,
}: Params) => {
  const { trade: tradeCurrencyAToCurrencyB } = useBestTrade(
    currencyA,
    currencyB,
    toCurrencyAmount(currencyA, "1")
  );

  const { trade: tradeCurrencyBToCurrencyA } = useBestTrade(
    currencyB,
    currencyA,
    toCurrencyAmount(currencyB, "1")
  );

  const { trade: currencyAToUSDTRate } = useTokenToUSDExchangeRate(
    currencyA,
    toCurrencyAmount(currencyA, "1")
  );

  const { trade: currencyBToUSDTRate } = useTokenToUSDExchangeRate(
    currencyB,
    toCurrencyAmount(currencyB, "1")
  );

  const { trade: currencyAPriceInUSDT } = useTokenToUSDExchangeRate(
    currencyA,
    currencyAmountIn
  );

  const { trade: currencyBPriceInUSDT } = useTokenToUSDExchangeRate(
    currencyB,
    currencyAmountOut
  );

  return {
    tradeCurrencyAToCurrencyB,
    tradeCurrencyBToCurrencyA,
    currencyAToUSDTRate: tradeCurrencyBToCurrencyA?.outputAmount
      ? currencyAToUSDTRate?.route.midPrice.quote(
          tradeCurrencyBToCurrencyA.outputAmount
        )
      : undefined,
    currencyBToUSDTRate: tradeCurrencyAToCurrencyB?.outputAmount
      ? currencyBToUSDTRate?.route.midPrice.quote(
          tradeCurrencyAToCurrencyB.outputAmount
        )
      : undefined,
    currencyAPriceInUSDT: currencyAmountIn
      ? currencyAPriceInUSDT?.route.midPrice.quote(currencyAmountIn)
      : undefined,
    currencyBPriceInUSDT: currencyAmountOut
      ? currencyBPriceInUSDT?.route.midPrice.quote(currencyAmountOut)
      : undefined,
  };
};
