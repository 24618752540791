import React from "react";
import numeral from "numeral";
import { toDecimal } from "utils/numbers";

type Props = {
  apy: string | null | undefined;
  tvl: string | null | undefined;
  totalStaked: string;
  name?: string;
};

export const AdvancedInfo = ({ apy, tvl, totalStaked, name = "LT" }: Props) => {
  return (
    <div className="grid grid-cols-2">
      {apy && (
        <>
          <div className="mb-1 text-sm text-osloGray">APY</div>
          <div className="mb-1 text-right text-sm">{apy}%</div>
        </>
      )}

      {tvl && (
        <>
          <div className="mb-1 text-sm text-osloGray">TVL</div>
          <div className="mb-1 text-right text-sm">
            ${numeral(tvl).format("0,00")}
          </div>
        </>
      )}

      {totalStaked && (
        <>
          <div className="text-sm text-osloGray">{name} Staked</div>
          <div className="text-right text-sm">
            {toDecimal(totalStaked).toSignificantDigits(6).toFixed()} {name}
          </div>
        </>
      )}
    </div>
  );
};
