import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import { useLocalStorage } from "react-use";
import invariant from "tiny-invariant";
import { useAccount, useTransaction } from "wagmi";

import { LS_KEYS } from "shared/constants";
import { shortenAddress } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";

export type TxHistoryItem = {
  hash: `0x${string}`;
  text: string;
  status: "pending" | "success" | "error" | "submitted";
};

const MAX_ITEM = 3;

export type TxHistoryContextType = {
  items: TxHistoryItem[];
  set: React.Dispatch<React.SetStateAction<TxHistoryItem[]>>;
  pushRef: React.MutableRefObject<
    (item: Omit<TxHistoryItem, "id">) => {
      item: Omit<TxHistoryItem, "id">;
      state: TxHistoryItem[];
    }
  >;
  updateRef: React.MutableRefObject<
    (item: TxHistoryItem) => {
      state: TxHistoryItem[];
    }
  >;
};

const TxHistoryContext = React.createContext<TxHistoryContextType | undefined>(
  undefined
);

export const TxHistoryProvider = ({ children }: PropsWithChildren) => {
  const chain = useChain();
  const { address } = useAccount();
  const [items = [], setLsState] = useLocalStorage<TxHistoryItem[]>(
    LS_KEYS.txHistory(chain.id, shortenAddress(address)),
    []
  );

  useTransaction();

  const push = (item: Omit<TxHistoryItem, "id">) => {
    const newState = [item, ...items].slice(0, MAX_ITEM);
    setLsState(newState);

    return { item, state: newState };
  };
  const pushRef = useRef(push);
  pushRef.current = push;

  const update = (item: TxHistoryItem) => {
    const newItems = items.map((i) => {
      if (i.hash === item.hash) return { ...i, ...item };
      return i;
    });

    setLsState(newItems);

    return { state: newItems };
  };

  const updateRef = useRef(update);
  updateRef.current = update;

  const txHistory = {
    items,
    updateRef,
    pushRef,
    set: setLsState as React.Dispatch<React.SetStateAction<TxHistoryItem[]>>,
  };

  return (
    <TxHistoryContext.Provider value={txHistory}>
      {children}
    </TxHistoryContext.Provider>
  );
};

export const useTxHistory = () => {
  const context = useContext(TxHistoryContext);
  invariant(context, "useTxHistory must be used within a TxHistoryProvider");
  return context;
};
