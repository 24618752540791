import { usePairCurrencies } from "pages/pool/hooks";
import { DetailedPair } from "shared/api/pool";
import { shortenAddress, toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Icon } from "shared/ui/icon";
import { Modal } from "shared/ui/modal";
import { notify } from "shared/ui/toast";
import { EXPLORER_ADDRESS_MAP } from "shared/v2-sdk/constants";
import { useBestTrade } from "shared/v2-sdk/hooks";

import { TradeFields } from "./trade-fields";

type Props = {
  detailedPair: DetailedPair;
  isOpen: boolean;
  onClose: () => void;
};

export const InfoModal = ({ detailedPair, isOpen = false, onClose }: Props) => {
  const chain = useChain();

  const [currencyA, currencyB] = usePairCurrencies(detailedPair.pair);

  const { trade } = useBestTrade(
    currencyA,
    currencyB,
    toCurrencyAmount(currencyA, "1")
  );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Body>
        <Modal.Header onIconClick={onClose}>Pool Details</Modal.Header>

        <div className="mt-8 grid auto-cols-min grid-cols-[auto,auto]">
          <div className="text-sm text-osloGray">Pool Contract</div>
          <div className="flex items-center justify-end gap-x-1 text-right text-sm">
            {shortenAddress(detailedPair.pair.liquidityToken.address)}
            <Icon
              className="cursor-pointer text-dodgerBlue"
              size="14"
              name="copy"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(
                    detailedPair.pair.liquidityToken.address
                  );
                } catch (error) {
                  notify.error("Something went wrong");
                }
              }}
            />
            <a
              target="_blank"
              href={`${EXPLORER_ADDRESS_MAP[chain.id]}/address/${
                detailedPair.pair.liquidityToken.address
              }`}
              rel="noreferrer"
            >
              <Icon
                className="cursor-pointer text-dodgerBlue"
                size="14"
                name="link"
              />
            </a>
          </div>

          <div className="mt-1 text-sm text-osloGray">
            {currencyA.symbol} Contract
          </div>
          <div className="mt-1 flex items-center justify-end gap-x-1 text-right text-sm">
            {shortenAddress(currencyA.wrapped.address)}
            <Icon
              className="cursor-pointer text-dodgerBlue"
              size="14"
              name="copy"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(
                    currencyA.wrapped.address
                  );
                } catch (error) {
                  notify.error("Something went wrong");
                }
              }}
            />
            <a
              target="_blank"
              href={`${EXPLORER_ADDRESS_MAP[chain.id]}/token/${
                currencyA.wrapped.address
              }`}
              rel="noreferrer"
            >
              <Icon
                className="cursor-pointer text-dodgerBlue"
                size="14"
                name="link"
              />
            </a>
          </div>

          <div className="mt-1 text-sm text-osloGray">
            {currencyB.symbol} Contract
          </div>
          <div className="mt-1 flex items-center justify-end gap-x-1 text-right text-sm">
            {shortenAddress(currencyB.wrapped.address)}
            <Icon
              className="cursor-pointer text-dodgerBlue"
              size="14"
              name="copy"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(
                    currencyB.wrapped.address
                  );
                } catch (error) {
                  notify.error("Something went wrong");
                }
              }}
            />
            <a
              target="_blank"
              href={`${EXPLORER_ADDRESS_MAP[chain.id]}/token/${
                currencyB.wrapped.address
              }`}
              rel="noreferrer"
            >
              <Icon
                className="cursor-pointer text-dodgerBlue"
                size="14"
                name="link"
              />
            </a>
          </div>

          {trade && <TradeFields trade={trade} />}
        </div>
      </Modal.Body>
    </Modal>
  );
};
