import { Provider } from "@wagmi/core";
import { toDecimal } from "utils/numbers";

import { Staking__factory } from "shared/abi/types";
import { CurrencyAmount, Token } from "shared/sdk-core";

export const fetchStakingBalance = async (
  address: string,
  stakingContractAddress: string,
  provider: Provider,
  ltToken: Token
) => {
  const contract = Staking__factory.connect(stakingContractAddress, provider);
  const balanceBN = await contract.stakes(address);
  const balanceCA = CurrencyAmount.fromRawAmount(ltToken, balanceBN.toString());
  const balanceD = toDecimal(balanceCA.toSignificant());
  const formatted = balanceCA.toSignificant(6);

  return { balanceD, formatted, balanceCA, token: ltToken };
};
