import { ReactNode, useState } from "react";

import { EmotionCSSProps } from "shared/types";

import { tabsCss } from "./styles";
import { Tab } from "./tab";
import * as THEMES from "./themes.styles";

export type TabsProps<Value> = {
  id?: string;
  tabs: Value[];
  keyAccessor: (option: Value) => string | number;
  renderValue: (option: Value) => ReactNode;
  activeTab?: Value;
  defaultActiveTab?: Value;
  theme?: keyof typeof THEMES;
  onChange?: (tab: Value) => void;
};

export const Tabs = <Value extends unknown>({
  id,
  className,
  css,
  theme = "dark",
  keyAccessor,
  renderValue,
  defaultActiveTab,
  tabs,
  onChange,
  ...props
}: TabsProps<Value> & EmotionCSSProps) => {
  const [activeTabState, setActiveTabState] = useState<Value | null>(
    defaultActiveTab || null
  );
  const activeTab =
    props.activeTab !== undefined ? props.activeTab : activeTabState;

  const tabsThemeCss = THEMES[theme];

  return (
    <div css={[tabsCss, tabsThemeCss, css]} className={className}>
      {tabs?.map((tab) => {
        const key = keyAccessor(tab);
        return (
          <Tab
            id={id ? `${id}-${key}` : undefined}
            theme={theme}
            key={key}
            active={Boolean(activeTab && keyAccessor(activeTab) === key)}
            onClick={() => {
              setActiveTabState(tab);
              onChange?.(tab);
            }}
          >
            {renderValue(tab)}
          </Tab>
        );
      })}
    </div>
  );
};
