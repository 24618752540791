import cn from "classnames";
import { Connector, useAccount, useConnect } from "wagmi";

import { WalletConnector, WalletConnectorStatus } from "entities/wallet";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";
import { Modal } from "shared/ui/modal";

import { getWalletLogoName } from "../../helpers";

type Props = {
  onStepChange: () => void;
};

export const ConnectorList = ({ onStepChange }: Props) => {
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const { connector, isConnected } = useAccount();

  const onSelectWalletConnector = (
    walletConnector: Connector,
    status: WalletConnectorStatus
  ) => {
    if (status !== "connected" && status !== "connecting") {
      connect({ connector: walletConnector });
      return;
    }

    if (status === "connected") {
      onStepChange();
    }
  };

  const getStatusConnector = (
    walletConnector: Connector
  ): WalletConnectorStatus => {
    if (isLoading && pendingConnector?.id === walletConnector.id)
      return "connecting";

    if (!walletConnector.ready) return "unsupported";

    if (error && pendingConnector?.id === walletConnector.id) return "error";

    if (isConnected && connector?.id === walletConnector.id) return "connected";
  };

  return (
    <>
      <div className="mt-8">
        {connectors.map((walletConnector) => {
          const status = getStatusConnector(walletConnector);
          const logoName = getWalletLogoName(walletConnector.id);
          return (
            <WalletConnector
              id={`${walletConnector.id}-wallet-btn`}
              className={cn("mt-4 first:mt-0")}
              key={walletConnector.id}
              status={status}
              name={walletConnector.name}
              onClick={() => onSelectWalletConnector(walletConnector, status)}
              logo={
                logoName ? (
                  <Icon className="ml-5" name={logoName} size="22" />
                ) : null
              }
            />
          );
        })}
      </div>

      <Modal.Footer className="flex flex-row items-center justify-center text-osloGray max-sm:flex-col max-sm:justify-center">
        By connecting a wallet you accept{" "}
        <Link to="#1" className="ml-1 text-base underline">
          Terms of Use
        </Link>
        .
      </Modal.Footer>
    </>
  );
};
