import tw, { css } from "twin.macro";

export const logoCss = tw`flex items-center relative select-none`;

export const mainTextCss = css`
  ${tw`text-xl text-woodsmoke`}
  display: inline-flex;
  transform: translateY(1px);
`;
export const secondaryTextCss = css`
  ${tw`text-xs opacity-50`}
  display: inline-flex;
  transform: translateY(-1px);
`;
