import { ReactNode } from "react";
import { useMutation } from "@tanstack/react-query";
import { ContractTransaction } from "ethers";

import { useTransactionModal } from "entities/transaction";

type Params = {
  txPromise: Promise<ContractTransaction> | any;
  desc: string;
  body?: ReactNode;
};

export const useTxMutation = () => {
  const setTxModalState = useTransactionModal();

  return useMutation(
    async ({ txPromise }: Params) => {
      return await txPromise;
    },
    {
      onMutate: ({ desc }) => {
        setTxModalState({
          open: true,
          status: "pending",
          desc,
        });
      },
      onSuccess: (_data, { desc }, ctx) => {
        setTxModalState({
          open: true,
          status: "submitted",
          desc,
        });
      },
      onError: (_data, { desc }, ctx) => {
        setTxModalState({
          status: "error",
          desc,
        });
      },
    }
  );
};
