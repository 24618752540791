import { useState } from "react";

import { useUSDExchangeRate } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { Currency, CurrencyAmount, Price } from "shared/sdk-core";

export type State = {
  usdPrice: CurrencyAmount<Currency> | undefined;
  formattedPrice: string;
  invertedSymbol: string | undefined;
  symbol: string | undefined;
  text: string;
};

export const usePrice = (
  price: Price<Currency, Currency>,
  initialInvert = false
) => {
  const [isInvert, setIsInvert] = useState(initialInvert);

  const usdPrice = useUSDExchangeRate(
    toCurrencyAmount(isInvert ? price.baseCurrency : price.quoteCurrency, "1")
  );

  const invert = () => {
    setIsInvert(!isInvert);
  };

  const { baseCurrency, quoteCurrency } = price;

  const formattedPrice = isInvert
    ? price.toSignificant(6)
    : price.invert().toSignificant(6);

  const symbol = isInvert ? quoteCurrency.symbol : baseCurrency.symbol;
  const invertedSymbol = isInvert ? baseCurrency.symbol : quoteCurrency.symbol;

  const text = `1 ${invertedSymbol} = ${formattedPrice || "-"} ${symbol}`;

  const state: State = {
    usdPrice,
    formattedPrice,
    invertedSymbol,
    symbol,
    text,
  };

  return [state, invert] as const;
};
