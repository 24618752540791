import { useQueries } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useProvider } from "wagmi";

import { useChain } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";

import { fetchCurrencyBalance } from "./helpers";
import { tokenKeys } from "./keys";

export const useCurrenciesBalancesQuery = (
  currencies: (Currency | undefined)[] = []
) => {
  const { address } = useAccount();
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });

  const queries = useQueries({
    queries: currencies.map((currency) => {
      const key = tokenKeys.balance(
        chain.id,
        address,
        currency?.isToken ? currency.address : undefined
      );

      return {
        queryKey: key,
        queryFn: async () => {
          invariant(address, "useCurrencyBalanceQuery. address is undefined");
          invariant(currency, "useCurrencyBalanceQuery. currency is undefined");

          return await fetchCurrencyBalance(currency, address, provider);
        },
        staleTime: 1000 * 60 * 5,
        enabled: Boolean(address && currency),
      };
    }),
  });

  return queries;
};
