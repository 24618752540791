import React, { createContext, PropsWithChildren, ReactNode } from "react";

import { useStateX } from "shared/hooks";

import { TxModal } from "../ui/modal";

const ModalContext = createContext<React.Dispatch<Partial<State>> | undefined>(
  undefined
);

type State = {
  open: boolean;
  status: "pending" | "submitted" | "error";
  desc: ReactNode;
  body: ReactNode;
};

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useStateX<State>({
    open: false,
    status: "pending",
    desc: null,
    body: null,
  });

  return (
    <ModalContext.Provider value={setState}>
      <>{children}</>
      <TxModal
        desc={state.desc}
        status={state.status}
        open={state.open}
        onClose={() => {
          setState({ open: false, status: "pending", body: null, desc: null });
        }}
      >
        {state.body}
      </TxModal>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error(
      "useTransactionModal must be used within a TransactionModalProvider"
    );
  }
  return context;
};
