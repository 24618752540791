import { CurrencyAmount, Fraction, Percent } from "shared/sdk-core";
import { TradeTypes } from "shared/v2-sdk/hooks";

const ONE_HUNDRED_PERCENT = new Percent("1");

export const calcLiquidityProvidersFee = (trade: TradeTypes) => {
  const { numerator, denominator } = trade.inputAmount.divide(
    trade.inputAmount
  );

  const overallPercent = new Percent(numerator, denominator);

  const feePercent = trade.route.pairs.reduce((currentFee) => {
    return currentFee.multiply(
      ONE_HUNDRED_PERCENT.subtract(new Fraction(3000, 1_000_000))
    );
  }, ONE_HUNDRED_PERCENT);

  const percent = overallPercent.multiply(feePercent);

  const realizedPercent = new Percent(percent.numerator, percent.denominator);

  const realizedAmount = CurrencyAmount.fromRawAmount(
    trade.inputAmount.currency,
    trade.inputAmount.multiply(realizedPercent).quotient
  );

  const lpFee = trade.inputAmount.subtract(realizedAmount);
  const lpPercent = overallPercent.subtract(realizedPercent);

  return {
    lpPercent,
    lpFee,
  };
};
