import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Decimal from "decimal.js";
import { ethers } from "ethers";
import { toDecimal } from "utils/numbers";

import { PairLogoAndSymbol } from "entities/pair";
import { DetailedPair } from "shared/api/pool";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Icon } from "shared/ui/icon";
import { USDT } from "shared/v2-sdk";
import { WNATIVE } from "shared/v2-sdk/constants";

import { InfoModal } from "../info-modal/info-modal";

const nameFn = (a: string, b: string) => `${a} - ${b}`;

export const Row = ({
  detailedPair,
  position,
}: {
  detailedPair: DetailedPair;
  position: Decimal | undefined;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pair, advancedInfo } = detailedPair;
  const chain = useChain();
  const tokenA = detailedPair.pair.token0;
  const tokenB = detailedPair.pair.token1;
  const navigate = useNavigate();

  const hasAdvancedInfo = advancedInfo.tvl !== "N/A";

  const tvl = hasAdvancedInfo
    ? toCurrencyAmount(USDT[chain.id], advancedInfo.tvl)
    : "N/A";

  const tokenABalanceQuery = useCurrencyBalanceQuery(pair.token0);
  const tokenBBalanceQuery = useCurrencyBalanceQuery(pair.token1);
  const ltBalance = useCurrencyBalanceQuery(pair.liquidityToken);

  const targetToken =
    tokenABalanceQuery.isSuccess && tokenBBalanceQuery.isSuccess
      ? tokenABalanceQuery.data.balanceD.lessThan(
          tokenBBalanceQuery.data.balanceD
        )
        ? tokenB
        : tokenA
      : tokenA;

  const rowId = `pool-row-${tokenA.symbol}/${tokenB.symbol}`;
  return (
    <>
      <div
        id={rowId}
        className="grid grid-cols-[14rem,0.5fr,0.5fr,0.5fr,0.5fr,1fr] items-center gap-3 rounded-md bg-[#686868] bg-opacity-5 px-6 py-5 text-right max-lg:grid-cols-[14rem,1fr,1fr,1fr,1fr] max-sm:grid-cols-[1fr,1fr,1fr,1fr] max-sm:px-2 max-sm:py-3 max-sm:text-left"
      >
        <div
          id={`${rowId}-pool-column`}
          className="flex cursor-pointer items-center text-left max-sm:col-span-full max-sm:mb-3"
          onClick={() => setIsOpen(true)}
        >
          <span className="mr-8 hidden text-sm text-osloGray max-sm:mr-2 max-sm:flex">
            Pool
          </span>
          <PairLogoAndSymbol
            size="32"
            textClassName="!text-[18px]"
            tokenA={tokenA}
            tokenB={tokenB}
            nameFn={nameFn}
          />
        </div>
        <div id={`${rowId}-tvl-column`}>
          {typeof tvl === "string" ? (
            tvl
          ) : (
            <>
              <span className="hidden text-sm text-osloGray max-sm:flex">
                TVL
              </span>
              ${tvl.toSignificant(5)}
            </>
          )}
        </div>
        <div id={`${rowId}-volume-column`}>
          <span className="hidden text-sm text-osloGray max-sm:flex">
            24H Volume
          </span>
          {hasAdvancedInfo
            ? `$${toCurrencyAmount(
                USDT[chain.id],
                advancedInfo.volume24
              ).toSignificant(2)}`
            : "N/A"}
        </div>
        <div id={`${rowId}-fees-column`}>
          <span className="hidden whitespace-nowrap text-sm text-osloGray max-sm:flex">
            24H Fees
          </span>
          {hasAdvancedInfo
            ? `$${toCurrencyAmount(
                USDT[chain.id],
                advancedInfo.fees24
              ).toSignificant(2)}`
            : "N/A"}
        </div>
        <div id={`${rowId}-positions-column`}>
          <span className="hidden whitespace-nowrap text-sm text-osloGray max-sm:flex">
            My positions
          </span>
          {hasAdvancedInfo
            ? `$${position?.toSignificantDigits(2).toFixed() ?? 0}`
            : `${ltBalance.data?.balanceCA.toSignificant(2)} LT`}
          {}
        </div>

        <div className="max-lg:col-span-full max-sm:mt-3">
          <div className="inline-flex h-10 items-center rounded-[25px] border border-dodgerBlue text-lg leading-6">
            {toDecimal(advancedInfo.tvl).greaterThan(10) && targetToken && (
              <button
                id={`zzap-btn-${tokenA.symbol}/${tokenB.symbol}`}
                onClick={() => {
                  const search = new URLSearchParams({
                    currencyAddress: targetToken.equals(WNATIVE[chain.id])
                      ? ethers.constants.AddressZero
                      : targetToken.address,
                    poolAddress: pair.liquidityToken.address,
                  }).toString();

                  navigate(
                    {
                      pathname: "/zzap",
                      search,
                    },
                    {
                      state: {
                        from: "/pool",
                      },
                    }
                  );
                }}
                className="flex h-full items-center space-x-2 rounded-[25px] bg-dodgerBlue px-6 text-white"
              >
                <Icon size="20" name="spaceShip" />
                <span>Zzap</span>
              </button>
            )}

            <button
              id={`add-liquidity-btn-${tokenA.symbol}/${tokenB.symbol}`}
              onClick={() =>
                navigate(`/pool/add/${pair.liquidityToken.address}`)
              }
              className="flex h-full items-center space-x-2 px-6 text-dodgerBlue"
            >
              <Icon size="25" name="add" />
              <span>Add</span>
            </button>
          </div>
        </div>
      </div>
      <InfoModal
        detailedPair={detailedPair}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      />
    </>
  );
};
