import { HTMLAttributes } from "react";
import tw from "twin.macro";

import { EmotionCSSProps } from "shared/types";

import { SIZES } from "./styles";
import * as ICON_ELEMENTS from "./svg";

export type Name = keyof typeof ICON_ELEMENTS;
export type Size = keyof typeof SIZES;

type Props = {
  size?: keyof typeof SIZES;
  name: Name;
  onClick?: () => void;
} & EmotionCSSProps &
  HTMLAttributes<HTMLSpanElement>;

export const Icon = ({
  className,
  css,
  name,
  size,
  onClick,
  ...props
}: Props) => {
  const IconElement = ICON_ELEMENTS[name];

  return (
    <span
      className={className}
      css={[tw`flex items-center`, size && SIZES[size], css]}
      onClick={onClick}
      {...props}
    >
      {<IconElement css={size && SIZES[size]} />}
    </span>
  );
};
