import { PropsWithChildren, ReactNode, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Options } from "@popperjs/core";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { useClickOutside } from "shared/hooks";
import { useCombineRef } from "shared/hooks";
import { EmotionCSSProps } from "shared/types";

import { Portal } from "../portal";

import { THEMES, tooltipCss } from "./styles";

const popperModifiers = [
  {
    name: "offset",
    options: {
      offset: [0, 10],
    },
  },
  {
    name: "preventOverflow",
    options: {
      padding: 8,
    },
  },
];

type CommonProps = PropsWithChildren<{
  disabled?: boolean;
  portal?: boolean;
  element: ReactNode;
  theme?: keyof typeof THEMES;
  defaultVisibility?: boolean;
}> &
  Omit<Partial<Options>, "modifiers"> &
  EmotionCSSProps;

type TriggerClickProps = {
  trigger: "click";
  delay?: never;
} & CommonProps;

type TriggerHoverProps = {
  trigger?: "hover";
  delay?: number;
} & CommonProps;

type Props = TriggerClickProps | TriggerHoverProps;

export const Tooltip = ({
  className,
  disabled = false,
  css: cssProps,
  portal = true,
  element,
  children,
  theme = "light",
  trigger = "hover",
  delay = 100,
  defaultVisibility = false,
  ...popperProps
}: Props) => {
  const delayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [referenceElement, setReference] = useState<HTMLElement | null>(null);
  const [popperElement, setPopper] = useState<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(defaultVisibility);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    ...popperProps,
    modifiers: popperModifiers,
  });

  const boundingElementRef = useClickOutside<HTMLDivElement>(() => {
    if (trigger !== "click") return;
    setVisible(false);
  });

  const combinedRef = useCombineRef(boundingElementRef, setReference);

  const content = (
    <div
      className="relative z-50"
      ref={setPopper}
      style={styles.popper}
      {...attributes.popper}
    >
      <motion.div
        css={[tooltipCss, THEMES[theme]]}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {element}
      </motion.div>
    </div>
  );
  return (
    <>
      <div
        className={cn(
          "inline-flex cursor-pointer items-center",
          disabled && "cursor-default",
          className
        )}
        css={cssProps}
        ref={combinedRef}
        onClick={() => {
          if (trigger !== "click") return;
          setVisible(!isVisible);
        }}
        onMouseOver={() => {
          if (trigger !== "hover") return;
          delayTimeoutRef.current = setTimeout(() => {
            setVisible(true);
          }, delay);
        }}
        onMouseOut={() => {
          if (trigger !== "hover") return;
          delayTimeoutRef.current && clearInterval(delayTimeoutRef.current);
          setVisible(false);
        }}
      >
        {children}
      </div>
      <AnimatePresence initial={false}>
        {isVisible &&
          !disabled &&
          (portal ? <Portal>{content}</Portal> : content)}
      </AnimatePresence>
    </>
  );
};
