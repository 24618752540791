import { useOutletContext } from "react-router-dom";

import { CurrencyEntity } from "entities/currency";
import { ApproveAllowanceButton } from "features/approve-allowance";
import { ConnectWalletButton } from "features/connect-wallet";
import { InputCurrency } from "features/input-currency";
import { SwitchNetworkButton } from "features/switch-network";
import { useEstimateAddLiquidityFeeQuery } from "shared/api/pool";
import { useAddLiquidityMutation } from "shared/api/pool/useAddLiquidityMutation";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useStateX } from "shared/hooks";
import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount, Token } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { PriceCalculator } from "shared/ui/price-calculator";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

import {
  useAddLiquidityDetails,
  useAddLiquidityStatus,
  useMaxNativeCurrencyValue,
  usePairCurrencies,
} from "../../hooks";
import { LiquidityOutletContext } from "../../types";

import { ConfirmModal } from "./ConfirmModal";

type State = {
  amountIn: string;
  amountOut: string;
  isConfirmOpen: boolean;
};

export const AddLiquidity = () => {
  const chain = useChain();
  const { detailedPair } = useOutletContext<LiquidityOutletContext>();

  const addLiquidityMutation = useAddLiquidityMutation();
  const [{ amountIn, amountOut, isConfirmOpen }, setState] = useStateX<State>({
    amountIn: "",
    amountOut: "",
    isConfirmOpen: false,
  });

  const [currencyA, currencyB] = usePairCurrencies(detailedPair.pair);

  const currencyABalanceQuery = useCurrencyBalanceQuery(currencyA);
  const currencyBBalanceQuery = useCurrencyBalanceQuery(currencyB);

  const currencyAmountIn = toCurrencyAmount(currencyA, amountIn);
  const currencyAmountOut = toCurrencyAmount(currencyB, amountOut);

  const feeQuery = useEstimateAddLiquidityFeeQuery({
    currencyAmountIn,
    currencyAmountOut,
    currencyA,
    currencyB,
  });

  const liquidityDetails = useAddLiquidityDetails({
    currencyA,
    currencyB,
    currencyAmountIn,
    currencyAmountOut,
    detailedPair,
  });

  const {
    currencyAPriceInUSDT,
    currencyBPriceInUSDT,
    currencyAToUSDTRate,
    currencyBToUSDTRate,
    tradeCurrencyAToCurrencyB,
    tradeCurrencyBToCurrencyA,
    poolShare,
  } = liquidityDetails;

  const maxValueCA = useMaxNativeCurrencyValue({
    currencyA,
    currencyB,
    pair: detailedPair.pair,
  });

  const { status, btnDisabled } = useAddLiquidityStatus({
    currencyA,
    currencyB,
    fee: feeQuery.data,
    currencyAmountIn,
    currencyAmountOut,
  });

  const calculatePrice = (
    token: Token,
    currencyAmount: CurrencyAmount<Currency>
  ) => {
    const price = detailedPair.pair
      .priceOf(token)
      .quote(currencyAmount.wrapped);

    return price;
  };

  const onChangeAmountIn = (amountIn: string) => {
    const calc = calculatePrice(
      currencyA.wrapped,
      toCurrencyAmount(currencyA, amountIn || "0")
    );

    setState({
      amountIn,
      amountOut: amountIn ? calc.toSignificant() : "",
    });
  };

  const onChangeAmountOut = (amountOut: string) => {
    const calc = calculatePrice(
      currencyB.wrapped,
      toCurrencyAmount(currencyB.wrapped, amountOut || "0")
    );
    setState({
      amountIn: amountOut ? calc.toSignificant() : "",
      amountOut,
    });
  };

  const priceCalculatorProps = {
    currencyA,
    currencyB,
    currencyBToCurrencyARate: tradeCurrencyBToCurrencyA?.outputAmount,
    currencyAToUSDRate: currencyAToUSDTRate,
    currencyAToCurrencyBRate: tradeCurrencyAToCurrencyB?.outputAmount,
    currencyBToUSDRate: currencyBToUSDTRate,
    isHighPriceImpact: false,
    toggled: true,
  };

  return (
    <>
      <InputCurrency
        id={`input-tokenA`}
        currency={currencyA}
        value={amountIn}
        onChange={onChangeAmountIn}
        maxBtnLoading={
          (currencyA.isNative && !maxValueCA) ||
          currencyABalanceQuery.isFetching
        }
        onMaxClick={() => {
          if (currencyA.isNative && maxValueCA) {
            onChangeAmountIn(maxValueCA.toExact());
            return;
          }
          onChangeAmountIn(
            currencyABalanceQuery.data?.balanceCA.toExact() || ""
          );
        }}
        balance={currencyABalanceQuery.data?.formatted}
        price={currencyAPriceInUSDT}
      >
        <CurrencyEntity
          symbol={currencyA.symbol}
          logoUrl={currencyA.logoUrl}
          bordered={false}
          size="38"
        />
      </InputCurrency>
      <div className="my-4 flex justify-center text-dodgerBlue">
        <Icon size="25" name="add" />
      </div>
      <InputCurrency
        id={`input-tokenB`}
        currency={currencyB}
        value={amountOut}
        onChange={onChangeAmountOut}
        maxBtnLoading={
          (currencyB.isNative && !maxValueCA) ||
          currencyBBalanceQuery.isFetching
        }
        onMaxClick={() => {
          if (currencyB.isNative && maxValueCA) {
            onChangeAmountOut(maxValueCA.toExact());
            return;
          }
          onChangeAmountOut(
            currencyBBalanceQuery.data?.balanceCA.toExact() || ""
          );
        }}
        balance={currencyBBalanceQuery.data?.formatted}
        price={currencyBPriceInUSDT}
      >
        <CurrencyEntity
          symbol={currencyB.symbol}
          logoUrl={currencyB.logoUrl}
          bordered={false}
          size="38"
        />
      </InputCurrency>
      <div className="my-5 flex items-center justify-between px-2 text-sm text-osloGray max-sm:flex-wrap">
        <span className="whitespace-nowrap">
          Pool Share {poolShare.mul(100).toSignificantDigits(2).toFixed()}%
        </span>
        <PriceCalculator {...priceCalculatorProps} />
      </div>
      {status === "connectWallet" && <ConnectWalletButton />}
      {status === "wrongNetwork" && <SwitchNetworkButton />}
      {status === "approveCurrencyA" && currencyA.isToken && (
        <ApproveAllowanceButton
          currencyAmount={currencyAmountIn}
          spenderAddress={ROUTER_ADDRESS_MAP[chain.id]}
        />
      )}
      {status === "approveCurrencyB" && currencyB.isToken && (
        <ApproveAllowanceButton
          currencyAmount={currencyAmountOut}
          spenderAddress={ROUTER_ADDRESS_MAP[chain.id]}
        />
      )}
      {[
        "enterAmount",
        "insufficientCurrencyABalance",
        "insufficientCurrencyBBalance",
        "insufficientNativeTokenBalance",
        "addLiquidity",
      ].includes(status) && (
        <Button
          id={"add-liquidity-btn"}
          className="mt-auto"
          disabled={btnDisabled}
          size="66"
          onClick={() => {
            status === "addLiquidity" &&
              setState({ isConfirmOpen: !isConfirmOpen });
          }}
        >
          {status === "enterAmount" && "Enter amount"}
          {status === "insufficientCurrencyABalance" &&
            `Insufficient ${currencyA.symbol} balance`}
          {status === "insufficientCurrencyBBalance" &&
            `Insufficient ${currencyB.symbol} balance`}
          {status === "insufficientNativeTokenBalance" &&
            `No ${Native.byChainId(chain.id).symbol} to pay fee`}
          {status === "addLiquidity" && "Supply"}
        </Button>
      )}

      {liquidityDetails && status === "addLiquidity" && (
        <ConfirmModal
          isOpen={isConfirmOpen}
          onClose={() => {
            setState({ isConfirmOpen: !isConfirmOpen });
          }}
          currencyA={currencyA}
          currencyB={currencyB}
          currencyAmountIn={currencyAmountIn}
          currencyAmountOut={currencyAmountOut}
          details={liquidityDetails}
          fee={feeQuery.data}
          onConfirm={() => {
            addLiquidityMutation.mutate({
              currencyA,
              currencyB,
              currencyAmountIn,
              currencyAmountOut,
            });
            setState({
              isConfirmOpen: !isConfirmOpen,
              amountIn: "",
              amountOut: "",
            });
          }}
        />
      )}
    </>
  );
};
