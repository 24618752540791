import { Row } from "./row";

type Props = {
  children: React.ReactNode;
  id?: string;
};

export function PoolTable({ children, id }: Props) {
  return (
    <div
      id={`pool-table-wrapper`}
      className="poolTable-shadow rounded-[20px] border border-light bg-light bg-opacity-50 px-10 py-8 max-lg:rounded-3xl max-lg:p-2"
    >
      <div
        id={`pool-table-header`}
        className="mb-8 grid grid-cols-[14rem,0.5fr,0.5fr,0.5fr,0.5fr,1fr] gap-3 px-6 text-right text-lg leading-5 text-osloGray max-lg:grid-cols-[14rem,1fr,1fr,1fr,1fr] max-sm:hidden"
      >
        <span id={`pool-table-header-pool-column`} className="text-left ">
          Pool
        </span>
        <span id={`pool-table-header-tvl-column`} className="text-right">
          TVL
        </span>
        <span id={`pool-table-header-volume-column`}>24H Volume</span>
        <span id={`pool-table-header-fees-column`}>24H Fees</span>
        <span id={`pool-table-header-positions-column`}>My Position</span>
        <span />
      </div>

      <div id={`pool-table-body`} className="flex flex-col space-y-5">
        {children}
      </div>
    </div>
  );
}

PoolTable.Row = Row;
