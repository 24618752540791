import React, { useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getAddress } from "ethers/lib/utils";

import { FormWrapper } from "pages/ui";
import { useDetailedPairsQuery } from "shared/api/pool";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";
import { Modal } from "shared/ui/modal";
import { Spinner } from "shared/ui/spinner";
import { TabType, TerminalTabs } from "shared/ui/terminal-tabs";
import { TransactionSettingsModal } from "widgets/settings-modal";

import { LiquidityOutletContext } from "../types";

const tabs = [
  {
    title: "Add",
    route: "pool/add",
    render: () => <div>Add</div>,
  },
  {
    title: "Remove",
    route: "pool/remove",
    render: () => <div>Remove</div>,
  },
] as TabType[];

export const PoolWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { address } = useParams();
  const detailedPairQuery = useDetailedPairsQuery({
    select: (data) =>
      address
        ? data.find(
            (detailedPair) =>
              getAddress(detailedPair.pair.liquidityToken.address) ===
              getAddress(address)
          )
        : undefined,
  });
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  if (detailedPairQuery.isSuccess && !detailedPairQuery.data) {
    return <Navigate replace={true} to="/pools" />;
  }

  return (
    <FormWrapper>
      <Modal.Header
        id="pool-terminal-modal-header"
        iconName="settings"
        onIconClick={() => {
          setSettingsModalOpen(true);
        }}
      >
        <div className="relative flex grow items-center justify-between">
          <div className="flex w-full items-center max-sm:flex-wrap max-sm:gap-y-3">
            <Link
              id={`pool-terminal-modal-header-back`}
              to="/pool"
              className="cursor-pointer"
            >
              <Icon
                name="back"
                size="20"
                className="flex flex-col justify-center"
              />
            </Link>
            <div className="ml-6 mr-3 text-[1.375rem] leading-[1.6rem] text-black">
              Pool
            </div>
            <TerminalTabs
              className="ml-auto mr-14 max-sm:ml-0"
              onClick={(tab) => {
                navigate(`/${tab.route}/${address}`);
              }}
              activeTab={
                location.pathname.includes("add")
                  ? tabs[0].route
                  : tabs[1].route
              }
              tabs={tabs}
            />
          </div>
        </div>
      </Modal.Header>

      <div className="mb-6 h-[1px] w-full" />
      {detailedPairQuery.isLoading && detailedPairQuery.isFetching && (
        <div className="flex h-[355px] items-center justify-center">
          <Spinner size="66" />
        </div>
      )}
      {detailedPairQuery.isSuccess && (
        <Outlet
          context={
            { detailedPair: detailedPairQuery.data } as LiquidityOutletContext
          }
        />
      )}
      <TransactionSettingsModal
        open={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}
      />
    </FormWrapper>
  );
};
