import { PropsWithChildren } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { Portal } from "../portal";

import { Body } from "./body";
import { Footer } from "./footer";
import { Header } from "./header";

type Props = {
  onClose?: () => void;
  open?: boolean;
  closeByClickOverlay?: boolean;
};

export const Modal = ({
  open,
  onClose,
  closeByClickOverlay = true,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <AnimatePresence>
      {open && (
        <Portal className="z-50">
          <motion.div
            className={
              "fixed inset-0 z-50 flex overflow-y-scroll bg-black bg-opacity-80"
            }
            initial={{ opacity: 0 }}
            animate={{ transition: { duration: 0.3 }, opacity: 1 }}
            exit={{ transition: { duration: 0.3 }, opacity: 0 }}
            onClick={(e) => {
              if (e.target === e.currentTarget && closeByClickOverlay) {
                onClose?.();
              }
            }}
          >
            <div className="flex-grow-1 relative m-auto flex flex-col overflow-y-auto py-2 max-sm:w-full max-sm:px-2">
              {children}
            </div>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
