import { useMiningPanelsQuery } from "shared/api/farm";
import { DetailedPair, useDetailedPairsQuery } from "shared/api/pool";
import { useCurrenciesBalancesQuery } from "shared/api/token/useCurrenciesBalancesQuery";

export const usePairPositions = (detailedPairsParam?: DetailedPair[]) => {
  const miningPanelsQuery = useMiningPanelsQuery();
  const detailedPairsQuery = useDetailedPairsQuery();

  const miningPanels = miningPanelsQuery.data ?? [];
  const detailedPairs = detailedPairsParam ?? detailedPairsQuery.data ?? [];

  const pairBalancesQueries = useCurrenciesBalancesQuery(
    detailedPairs.map(({ pair }) => pair.liquidityToken)
  );

  const pairPositions = detailedPairs.map((detailedPair, idx) => {
    const miningPanel = miningPanels.find((miningPanel) =>
      miningPanel.detailedPair?.pair.liquidityToken.equals(
        detailedPair.pair.liquidityToken
      )
    );

    const balanceInfo = pairBalancesQueries[idx].data;

    const positionD = miningPanel
      ? balanceInfo?.balanceD.add(miningPanel?.stakingBalance.balanceD)
      : balanceInfo?.balanceD;

    const positionUSD = positionD?.mul(
      detailedPair.advancedInfo.priceLT.priceLTD
    );

    return {
      detailedPair,
      positionUSD: positionUSD,
      positionD,
      stakingBalance: miningPanel?.stakingBalance,
      balance: balanceInfo,
    };
  });

  return pairPositions;
};
