import React from "react";
import cn from "classnames";
import { css } from "twin.macro";

import { EmotionCSSProps } from "shared/types";

import { Icon, IconName } from "../icon";

import { useInput, Validator } from "./use-input";

type CustomProps = {
  label?: string;
  iconName?: IconName;
  cancelable?: boolean;
  validators?: Validator[];
  inputClassName?: string;
  inputCss?: EmotionCSSProps["css"];
  onBlur?: (data: { value: string; isValid: boolean | null }) => void;
  onChange: (data: { value: string; isValid: boolean | null }) => void;
} & EmotionCSSProps;

type Props = CustomProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof CustomProps>;

const inputCss = css`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const Input = ({
  label,
  iconName,
  className,
  inputClassName = "",
  inputCss: inputCssProp,
  css: cssProp,
  cancelable = false,
  validators,
  onChange,
  onBlur,
  ...props
}: Props) => {
  const { isValid, handleChange, handleBlur, handleReset, value } = useInput({
    value: props.value,
    onChange,
    onBlur,
    validators,
  });

  return (
    <label
      css={cssProp}
      className={cn(
        "flex w-full flex-col items-center",
        isValid === false && "",
        isValid === true && "",
        className
      )}
    >
      {label && <span className="inline-flex">{label}</span>}
      <div className="relative w-full text-osloGray">
        {iconName && (
          <Icon
            className="absolute left-4 top-1/2 inline-flex -translate-y-1/2"
            size="14"
            name={iconName}
          />
        )}
        <input
          css={[inputCss, inputCssProp]}
          className={cn(
            iconName && "pl-9",
            cancelable && "pr-9",
            "inline-flex w-full rounded-md border border-solid border-light bg-light bg-opacity-50 px-4 py-[10px] text-black outline-none transition-colors placeholder:text-osloGray focus:border-dodgerBlue focus:border-opacity-50",
            inputClassName
          )}
          {...props}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
        />
        {Boolean(cancelable && value) && (
          <Icon
            onClick={handleReset}
            className="absolute right-4 top-1/2 inline-flex -translate-y-1/2 cursor-pointer select-none p-[9px]"
            size="32"
            name="close"
          />
        )}
      </div>
    </label>
  );
};
