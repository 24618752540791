import { parseUnits } from "ethers/lib/utils.js";
import { toDecimal } from "utils/numbers";

import {
  Currency,
  CurrencyAmount,
  NativeCurrency,
  Rounding,
  Token,
} from "shared/sdk-core";

type Input = Currency | undefined;
type Output<T extends Input> = T extends Token
  ? CurrencyAmount<Token>
  : T extends NativeCurrency
  ? CurrencyAmount<NativeCurrency>
  : T extends Currency
  ? CurrencyAmount<Currency>
  : undefined;

export const toCurrencyAmount = <T extends Input>(
  currency: T,
  rawAmount: string | undefined = "0"
): Output<T> => {
  const amount = rawAmount || "0";

  try {
    const preparedAmount = toDecimal(amount)
      .toFixed(currency?.decimals ?? 0, Rounding.ROUND_DOWN)
      .valueOf();
    const parsedAmount = parseUnits(preparedAmount, currency?.decimals);

    const result =
      parsedAmount && currency
        ? CurrencyAmount.fromRawAmount(currency, parsedAmount.toString())
        : undefined;
    return result as Output<T>;
  } catch (error) {
    console.error(error);
    return undefined as Output<T>;
  }
};
