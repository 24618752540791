import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toDecimal } from "utils/numbers";

import { PairLogoAndSymbol } from "entities/pair";
import { ConnectWalletButton } from "features/connect-wallet";
import { InputCurrency } from "features/input-currency";
import { SwitchNetworkButton } from "features/switch-network";
import { FarmOutletContext } from "pages/farm/types";
import { useUnstakeMutation } from "shared/api/farm";
import { useEstimateFeeUnstakeQuery } from "shared/api/farm/useEstimateFeeUnstakeQuery";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { USDT } from "shared/v2-sdk";

import { useStakingInfo } from "../../../entities/farm/hooks/use-staking-info";
import { useUnstakeStatus } from "../hooks/use-unstake-status";

import { ConfirmModal } from "./confirm-modal";
import { Stats } from "./Stats";

export const UnstakePage = () => {
  const chain = useChain();
  const [amount, setAmount] = useState("");
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { miningPanel } = useOutletContext<FarmOutletContext>();
  const detailedPair = miningPanel.detailedPair;
  const amountCA = toCurrencyAmount(detailedPair.pair.liquidityToken, amount);

  const stakingInfo = useStakingInfo(miningPanel, `-${amount}`);

  const feeQuery = useEstimateFeeUnstakeQuery({ miningPanel, amount });

  const unstakeMutation = useUnstakeMutation();

  const { btnDisabled, btnText, status } = useUnstakeStatus(
    miningPanel,
    amountCA
  );

  const price = toDecimal(amount)
    .mul(detailedPair.advancedInfo.priceLT.priceLTD)
    .toString();

  return (
    <>
      <InputCurrency
        value={amount}
        id="stake-input"
        balance={stakingInfo?.stakingBalance.formatted}
        currency={detailedPair.pair.liquidityToken}
        price={amount ? toCurrencyAmount(USDT[chain.id], price) : undefined}
        onChange={(value) => {
          setAmount(value);
        }}
        maxLabel="Staked"
        onMaxClick={() => {
          stakingInfo &&
            setAmount(stakingInfo.stakingBalance.balanceCA.toExact());
        }}
      >
        <PairLogoAndSymbol
          tokenA={detailedPair.pair.token0}
          tokenB={detailedPair.pair.token1}
        />
      </InputCurrency>
      <Stats
        capitalEfficiencyPercent={stakingInfo?.capitalEfficiencyPercent}
        calcPoolShare={stakingInfo?.poolSharePercent}
        calcStakingBalance={stakingInfo?.calcStakingBalance}
        dailyRate={
          stakingInfo
            ? `${stakingInfo.dailyRateCA?.toSignificant(6) ?? "0"} ${
                miningPanel.rewardsToken?.symbol
              } daily`
            : undefined
        }
        miningPanel={miningPanel}
      />
      {status === "connectWallet" && (
        <ConnectWalletButton className="mt-4 w-full" />
      )}
      {status === "switchNetwork" && (
        <SwitchNetworkButton className="mt-4 w-full" />
      )}
      {["enterAmount", "unstake", "insufficientBalance"].includes(status) && (
        <Button
          id="main-stake-btn"
          size="66"
          disabled={btnDisabled}
          className="mt-4 w-full text-2xl"
          onClick={() => {
            setConfirmOpen(true);
          }}
        >
          {btnText}
        </Button>
      )}

      {stakingInfo && (
        <ConfirmModal
          isOpen={isConfirmOpen}
          btnText="Confirm Unstake"
          title="You Unstake"
          amount={amount}
          miningPanel={miningPanel}
          fee={feeQuery.data}
          onClose={() => setConfirmOpen(false)}
          stakingInfo={stakingInfo}
          onConfirm={() => {
            unstakeMutation.mutate({
              address: miningPanel.address,
              currencyAmount: toCurrencyAmount(
                detailedPair.pair.liquidityToken,
                amount
              ),
            });
            setConfirmOpen(false);
          }}
        />
      )}
    </>
  );
};
