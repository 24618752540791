import { captureException, withScope } from "@sentry/react";

export const reportToSentry = (
  message: string,
  extras: Record<string, unknown>,
  tags: Record<string, string> = {}
) => {
  withScope((scope) => {
    scope.setTags(tags);
    scope.setExtras(extras);
    captureException(new Error(message));
  });
};
