import tw, { css } from "twin.macro";

const commonOutlineCss = css`
  ${tw`bg-transparent transition-colors`}
`;

export const red = css`
  ${commonOutlineCss}

  ${tw`border-red text-light`}

  &:hover {
    ${tw`border-redHover`}
  }

  &:active,
  &:focus {
    ${tw`border-redActive`}
  }
`;

export const blue = css`
  ${commonOutlineCss}

  ${tw`border-mariner text-mariner bg-transparent`}
`;

export const dodgerBlue = css`
  ${commonOutlineCss}

  ${tw`border-dodgerBlue text-dodgerBlue bg-white`}
`;

export const lightDodgerBlue = css`
  ${commonOutlineCss}

  ${tw`border-dodgerBlue text-dodgerBlue bg-white`}
`;

export const transparentLight20 = css`
  ${commonOutlineCss}

  ${tw`border-light20 text-light`}
`;

export const transparentLight50 = css`
  ${tw`border-light50 text-black`}
`;
