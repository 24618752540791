import { PropsWithChildren } from "react";
import tw, { css } from "twin.macro";

import { Icon, IconName } from "../icon";
import { Spinner, THEMES as SPINNER_THEMES } from "../spinner";
import { Text } from "../text";

import {
  btnContentCss,
  btnContentReversedCss,
  loadingCss,
  THEMES,
} from "./styles";
import { BUTTON_SIZE_TO_ICON_MAP, CustomProps } from "./types";

type Props = {
  theme: keyof typeof THEMES;
  loading: boolean;
  reverse: boolean;
  iconName?: IconName;
  iconClassName?: string;
  iconCss?: CustomProps["iconCss"];
  size: Required<CustomProps>["size"];
};

const BUTTON_TO_SPINNER_MAP: Record<
  keyof typeof THEMES,
  keyof typeof SPINNER_THEMES
> = {
  red: "reversedRed",
  transparentLight20: "red",
  transparentLight50: "red",
  blue: "blue",
  dodgerBlue: "dodgerBlue",
  lightDodgerBlue: "dodgerBlue",
};

export const ButtonContent = ({
  iconCss: iconCssProp,
  iconName,
  iconClassName,
  loading,
  reverse,
  size,
  children,
  theme,
}: PropsWithChildren<Props>) => {
  const iconCss = css`
    ${reverse ? tw`ml-3` : tw`mr-3`}
    ${iconCssProp}
  `;

  return (
    <>
      {loading && (
        <span css={loadingCss}>
          <Spinner theme={BUTTON_TO_SPINNER_MAP[theme]} size="18" />
        </span>
      )}

      <span
        css={[
          btnContentCss,
          reverse && btnContentReversedCss,
          loading && tw`opacity-0`,
        ]}
      >
        {iconName && (
          <Icon
            className={iconClassName}
            css={iconCss}
            size={BUTTON_SIZE_TO_ICON_MAP[size]}
            name={iconName}
          />
        )}
        {typeof children === "string" ? <Text>{children}</Text> : children}
      </span>
    </>
  );
};
