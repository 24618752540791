import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ethers } from "ethers";
import invariant from "tiny-invariant";
import { useAccount, useProvider } from "wagmi";

import { ERC20__factory } from "shared/abi/types";
import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount, MaxUint256Bn } from "shared/sdk-core";

import { tokenKeys } from "./keys";

export const useCurrencyAllowance = <TData = CurrencyAmount<Currency>>(
  currency: Currency | undefined,
  spender: string,
  options?: UseQueryOptions<
    CurrencyAmount<Currency>,
    Error,
    TData,
    ReturnType<typeof tokenKeys.allowance>
  > & { extraEnabled?: boolean }
) => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const { address } = useAccount();

  const extraEnabled = options?.extraEnabled ?? true;

  const query = useQuery(
    tokenKeys.allowance(
      currency?.isNative ? ethers.constants.AddressZero : currency?.address,
      address,
      spender
    ),
    async () => {
      invariant(address, "useCurrencyAllowance. address is undefined");
      invariant(currency, "useCurrencyAllowance. currency is undefined");

      if (currency.isNative)
        return CurrencyAmount.fromRawAmount(currency, MaxUint256Bn.toString());

      const tokenContract = ERC20__factory.connect(currency.address, provider);
      const bn = await tokenContract.allowance(address, spender);

      const ca = CurrencyAmount.fromRawAmount(currency, bn.toString());
      return ca;
    },
    {
      enabled: Boolean(address && currency) && extraEnabled,
      staleTime: 1000 * 5 * 60,
      ...options,
    }
  );

  return query;
};
