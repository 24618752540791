import { useQuery } from "@tanstack/react-query";
import { Chain } from "wagmi";

import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { ChainId, TOKEN_LIST_MAP, WNATIVE } from "shared/v2-sdk/constants";

import { deserializeTokens } from "./helpers";
import { tokenKeys } from "./keys";
import { ApiToken, ApiTokenResponse } from "./types";

const fetchCurrencies = async (chain: Chain, isHumanode: boolean) => {
  const response = await fetch(TOKEN_LIST_MAP[chain.id]);
  const currentTokenList = (await response.json()) as ApiTokenResponse;

  const apiTokens = currentTokenList.tokens.filter((token) => {
    return token.chainId === chain.id;
  }) as ApiToken[];

  const tokens = deserializeTokens(apiTokens).map((token) =>
    token.equals(WNATIVE[chain.id]) ? WNATIVE[chain.id] : token
  );
  const currencies: Currency[] = [
    isHumanode ? undefined : Native.byChainId(chain.id),
    ...tokens,
  ].filter(Boolean);

  return currencies;
};

export const useCurrenciesQuery = () => {
  const chain = useChain();
  const isHumanode = useIsHumanode();

  return useQuery(
    tokenKeys.currencies(chain.id),
    () => fetchCurrencies(chain, isHumanode),
    {
      staleTime: Infinity,
    }
  );
};
