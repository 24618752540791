import { Provider } from "@wagmi/core";

import { UniswapV2Pair__factory } from "shared/abi/types";
import { getPairTokenDisplayData } from "shared/helpers";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { Pair } from "shared/v2-sdk";

type Params = {
  pairAddress: string;
  provider: Provider;
  tokenA: Token;
  tokenB: Token;
  chainId: number;
};

export const fetchPair = async ({
  pairAddress,
  provider,
  tokenA,
  tokenB,
  chainId,
}: Params) => {
  const contract = UniswapV2Pair__factory.connect(pairAddress, provider);
  const [reserve0, reserve1] = await contract.getReserves();
  const [token0, token1] = tokenA.sortsBefore(tokenB)
    ? [tokenA, tokenB]
    : [tokenB, tokenA];

  const token0DisplayData = getPairTokenDisplayData(token0, chainId);
  const token1DisplayData = getPairTokenDisplayData(token1, chainId);

  const pair = new Pair(
    CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
    CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
    token0.symbol && token1.symbol
      ? `${token0DisplayData.symbol}/${token1DisplayData.symbol}`
      : undefined,
    token0.name && token1.name
      ? `${token0DisplayData.name}/${token1DisplayData.name}`
      : undefined
  );

  return pair;
};
