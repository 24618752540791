import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ethers } from "ethers";
import { useAccount, useProvider, useSigner } from "wagmi";

import { useCurrencies, useCurrenciesMap } from "entities/currency";
import { Staking__factory } from "shared/abi/types";
import { useChain } from "shared/providers/wagmi";

import { tokenKeys } from "../token/keys";
import { useTxMutation, useTxWaitMutation } from "../transtaction";

import { farmsKeys } from "./keys";

type Params = {
  address: string;
  amount: string;
  rewardTokenSymbol: string;
};

const useClaimMutation = () => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const { data: signer } = useSigner();

  const queryClient = useQueryClient();
  const { address } = useAccount();

  const txMutation = useTxMutation();
  const txWaitMutation = useTxWaitMutation();

  const currencies = useCurrencies();

  return useMutation(
    async ({ amount, rewardTokenSymbol, address }: Params) => {
      const desc = `Claim ${amount} ${rewardTokenSymbol}`;
      const contract = Staking__factory.connect(address, signer || provider);

      const estimatedGas = await contract.estimateGas.getReward();

      const txPromise = contract.getReward({
        gasLimit: estimatedGas.mul(120).div(100),
      });

      const tx = await txMutation.mutateAsync({ txPromise, desc });

      const receipt = await txWaitMutation.mutateAsync({ tx, desc });
      return receipt;
    },
    {
      onSuccess: (data, params) => {
        const rewardToken = currencies.find(
          (currency) => currency.symbol === params.rewardTokenSymbol
        );
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            rewardToken?.isToken
              ? rewardToken.address
              : ethers.constants.AddressZero
          )
        );
        queryClient.refetchQueries(farmsKeys.all);
      },
    }
  );
};

export default useClaimMutation;
