import { Modal } from "shared/ui/modal";

import { Connector } from "./connector";
import { ConnectorList } from "./connector-list";

type Props = {
  step: "list" | "connector";
  open: boolean;
  onClose: () => void;
  onChange: (step: "connector" | "list") => void;
};

export const Wallet = ({ open, onClose, onChange, step }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <Modal.Header onIconClick={onClose}>Wallet</Modal.Header>
        {step === "list" && (
          <ConnectorList
            onStepChange={() => {
              onChange("connector");
            }}
          />
        )}
        {step === "connector" && (
          <Connector
            onStepChange={() => {
              onChange("list");
            }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
