import React, { useRef, useState } from "react";

export type Validator = (value: string) => boolean;
export type Value = { value: string; isValid: boolean | null };

type Props = {
  value?: React.InputHTMLAttributes<HTMLInputElement>["value"];
  validators?: Validator[];
  onBlur?: (value: Value) => void;
  onChange: (value: Value) => void;
};
export const useInput = ({ validators, onChange, onBlur, ...props }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [valueState, setValue] = useState("");
  const [isValid, setValid] = useState<boolean | null>(null);
  const value = props.value !== undefined ? props.value : valueState;

  const validate = (value: string) => {
    if (!validators || !validators.length) return null;
    const valid = validators.every((validator) => validator(value));
    setValid(valid);
    return valid;
  };

  const handleChange = (value: string) => {
    const valid = validate(value);
    setValue(value);
    onChange({ value, isValid: valid === null ? true : valid });
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const valid = validate(e.target.value);
    setValue(e.target.value);
    onBlur?.({
      value: e.target.value,
      isValid: valid === null ? true : valid,
    });
  };

  const handleReset = () => {
    setValue("");
    setValid(null);
    onChange({ value: "", isValid: null });
  };

  return { value, handleChange, handleBlur, isValid, handleReset, ref };
};
