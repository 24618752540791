import { Provider } from "@wagmi/core";
import Decimal from "decimal.js";
import { BigNumber } from "ethers";
import { toDecimal } from "utils/numbers";

import { ERC20__factory } from "shared/abi/types";
import { Currency, CurrencyAmount } from "shared/sdk-core";

export type CurrencyBalanceResponse = {
  currency: Currency;
  balanceD: Decimal;
  balanceCA: CurrencyAmount<Currency>;
  formatted: string;
};

export const fetchCurrencyBalance = async (
  currency: Currency,
  address: string,
  provider: Provider
) => {
  if (currency.isNative) {
    const balanceBn = await provider.getBalance(address);
    return prepareResult(currency, balanceBn);
  }

  const contract = ERC20__factory.connect(currency.address, provider);

  const balanceBn = await contract.balanceOf(address);
  return prepareResult(currency, balanceBn);
};

const prepareResult = (currency: Currency, balanceBn: BigNumber) => {
  const balanceD = toDecimal(balanceBn.toString()).div(
    toDecimal(10).pow(currency.decimals)
  );

  const result: CurrencyBalanceResponse = {
    currency: currency,
    balanceD,
    balanceCA: CurrencyAmount.fromRawAmount(currency, balanceBn.toString()),
    formatted: balanceD.toSignificantDigits(6, Decimal.ROUND_DOWN).toFixed(),
  };

  return result;
};
