import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Address: string;
  Decimal: string;
  Session: string;
  Time: string;
};

export type BestTrade = {
  __typename?: "BestTrade";
  path: Array<Maybe<Scalars["Address"]>>;
  price: Scalars["Decimal"];
  priceImpact: Scalars["Decimal"];
  volumeIn: Scalars["Decimal"];
  volumeOut: Scalars["Decimal"];
};

export type Epoch = {
  __typename?: "Epoch";
  endTime: Scalars["Time"];
  rewardsPerSecond: Scalars["Decimal"];
  startTime: Scalars["Time"];
};

export type LiquidityMiningAccount = {
  __typename?: "LiquidityMiningAccount";
  accountData: LiquidityMiningAccountData;
  address: Scalars["Address"];
};

export type LiquidityMiningAccountData = {
  __typename?: "LiquidityMiningAccountData";
  rewardTokenBalance: Scalars["Decimal"];
  rewardsAvailable: Scalars["Decimal"];
  rewardsClaimed: Scalars["Decimal"];
  rewardsToken: Token;
  rewardsTotal: Scalars["Decimal"];
  stakingBalance: Scalars["Decimal"];
};

export type LiquidityMiningCommonData = {
  __typename?: "LiquidityMiningCommonData";
  address: Scalars["Address"];
  apy?: Maybe<Scalars["Decimal"]>;
  currentEpoch?: Maybe<Epoch>;
  poolAddress: Scalars["Address"];
  rewardsToken: Token;
  totalStakedLT: Scalars["Decimal"];
  totalStakedUSD: Scalars["Decimal"];
};

export type LiquidityMiningList = {
  __typename?: "LiquidityMiningList";
  list: Array<LiquidityMiningListElem>;
};

export type LiquidityMiningListElem = {
  __typename?: "LiquidityMiningListElem";
  accountData?: Maybe<LiquidityMiningAccountData>;
  commonData: LiquidityMiningCommonData;
};

export type Pool = {
  __typename?: "Pool";
  address: Scalars["Address"];
  fees24: Scalars["Decimal"];
  liquidityToken: Token;
  priceLT: Scalars["Decimal"];
  token0: Token;
  token1: Token;
  totalSupply: Scalars["Decimal"];
  tvl: Scalars["Decimal"];
  volume24: Scalars["Decimal"];
};

export type Query = {
  __typename?: "Query";
  getAllPools: Array<Pool>;
  getBestTrade: BestTrade;
  getLiquidityMiningAccountData: LiquidityMiningAccount;
  getLiquidityMiningList: LiquidityMiningList;
  stakingInfo: StakingInfo;
};

export type QueryGetBestTradeArgs = {
  trade: Trade;
};

export type QueryGetLiquidityMiningAccountDataArgs = {
  account: Scalars["Address"];
  address: Scalars["Address"];
};

export type QueryGetLiquidityMiningListArgs = {
  account?: InputMaybe<Scalars["Address"]>;
};

export type QueryStakingInfoArgs = {
  account?: InputMaybe<Scalars["Address"]>;
  address?: InputMaybe<Scalars["Address"]>;
  contract?: InputMaybe<Scalars["String"]>;
};

export type StakingAccountData = {
  __typename?: "StakingAccountData";
  rewardAvailable: Scalars["Decimal"];
  rewardClaimed: Scalars["Decimal"];
  rewardTotal: Scalars["Decimal"];
  rewardTotalUSD: Scalars["Decimal"];
};

export type StakingCommonData = {
  __typename?: "StakingCommonData";
  address: Scalars["Address"];
  apy: Scalars["Decimal"];
  contract: Scalars["String"];
  currentEpoch?: Maybe<Epoch>;
  rewardToken: Token;
  stakingToken: Token;
  totalStaked: Scalars["Decimal"];
  totalStakedUSD: Scalars["Decimal"];
};

export type StakingInfo = {
  __typename?: "StakingInfo";
  accountData?: Maybe<StakingAccountData>;
  commonData: StakingCommonData;
};

export type Token = {
  __typename?: "Token";
  contract: Scalars["Address"];
  decimal: Scalars["Int"];
  name: Scalars["String"];
  symbol: Scalars["String"];
};

export type Trade = {
  tokenIn: Scalars["Address"];
  tokenOut: Scalars["Address"];
  volumeIn: Scalars["Decimal"];
};

export type GetLiquidityMiningListQueryVariables = Exact<{
  account?: InputMaybe<Scalars["Address"]>;
}>;

export type GetLiquidityMiningListQuery = {
  __typename?: "Query";
  getLiquidityMiningList: {
    __typename?: "LiquidityMiningList";
    list: Array<{
      __typename?: "LiquidityMiningListElem";
      commonData: {
        __typename?: "LiquidityMiningCommonData";
        poolAddress: string;
        apy?: string | null;
        address: string;
        totalStakedUSD: string;
        totalStakedLT: string;
        currentEpoch?: {
          __typename?: "Epoch";
          rewardsPerSecond: string;
        } | null;
      };
      accountData?: {
        __typename?: "LiquidityMiningAccountData";
        stakingBalance: string;
        rewardsAvailable: string;
        rewardsTotal: string;
        rewardsClaimed: string;
        rewardsToken: {
          __typename?: "Token";
          contract: string;
          name: string;
          symbol: string;
          decimal: number;
        };
      } | null;
    }>;
  };
};

export type StakingInfoWithoutSessionQueryVariables = Exact<{
  account?: InputMaybe<Scalars["Address"]>;
  address?: InputMaybe<Scalars["Address"]>;
  contract?: InputMaybe<Scalars["String"]>;
}>;

export type StakingInfoWithoutSessionQuery = {
  __typename?: "Query";
  stakingInfo: {
    __typename?: "StakingInfo";
    accountData?: {
      __typename?: "StakingAccountData";
      rewardAvailable: string;
      rewardClaimed: string;
      rewardTotal: string;
      rewardTotalUSD: string;
    } | null;
    commonData: {
      __typename?: "StakingCommonData";
      address: string;
      apy: string;
      contract: string;
      totalStaked: string;
      totalStakedUSD: string;
      currentEpoch?: {
        __typename?: "Epoch";
        endTime: string;
        rewardsPerSecond: string;
        startTime: string;
      } | null;
      rewardToken: {
        __typename?: "Token";
        contract: string;
        decimal: number;
        name: string;
      };
    };
  };
};

export type GetAllPoolsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPoolsQuery = {
  __typename?: "Query";
  getAllPools: Array<{
    __typename?: "Pool";
    tvl: string;
    volume24: string;
    fees24: string;
    priceLT: string;
    totalSupply: string;
    liquidityToken: {
      __typename?: "Token";
      name: string;
      contract: string;
      decimal: number;
    };
    token0: {
      __typename?: "Token";
      name: string;
      contract: string;
      decimal: number;
    };
    token1: {
      __typename?: "Token";
      name: string;
      contract: string;
      decimal: number;
    };
  }>;
};

export const GetLiquidityMiningListDocument = gql`
  query GetLiquidityMiningList($account: Address) {
    getLiquidityMiningList(account: $account) {
      list {
        commonData {
          poolAddress
          apy
          address
          totalStakedUSD
          totalStakedLT
          currentEpoch {
            rewardsPerSecond
          }
        }
        accountData {
          stakingBalance
          rewardsToken {
            contract
            name
            symbol
            decimal
          }
          rewardsAvailable
          rewardsTotal
          rewardsClaimed
        }
      }
    }
  }
`;
export const StakingInfoWithoutSessionDocument = gql`
  query StakingInfoWithoutSession(
    $account: Address
    $address: Address
    $contract: String
  ) {
    stakingInfo(account: $account, address: $address, contract: $contract) {
      accountData {
        rewardAvailable
        rewardClaimed
        rewardTotal
        rewardTotalUSD
      }
      commonData {
        address
        apy
        contract
        currentEpoch {
          endTime
          rewardsPerSecond
          startTime
        }
        rewardToken {
          contract
          decimal
          name
        }
        totalStaked
        totalStakedUSD
      }
    }
  }
`;
export const GetAllPoolsDocument = gql`
  query GetAllPools {
    getAllPools {
      tvl
      volume24
      fees24
      priceLT
      totalSupply
      liquidityToken {
        name
        contract
        decimal
      }
      token0 {
        name
        contract
        decimal
      }
      token1 {
        name
        contract
        decimal
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    GetLiquidityMiningList(
      variables?: GetLiquidityMiningListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GetLiquidityMiningListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetLiquidityMiningListQuery>(
            GetLiquidityMiningListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "GetLiquidityMiningList",
        "query"
      );
    },
    StakingInfoWithoutSession(
      variables?: StakingInfoWithoutSessionQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<StakingInfoWithoutSessionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StakingInfoWithoutSessionQuery>(
            StakingInfoWithoutSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "StakingInfoWithoutSession",
        "query"
      );
    },
    GetAllPools(
      variables?: GetAllPoolsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GetAllPoolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllPoolsQuery>(GetAllPoolsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetAllPools",
        "query"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
