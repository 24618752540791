import classNames from "classnames";

import { getPairTokenDisplayData } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Token } from "shared/sdk-core/entities/token";
import placeholder from "shared/ui/icon/svg/tokenPlaceholder.svg";
import { Img } from "shared/ui/img";

type Sizes = "32" | "24";

type Props = {
  tokenA: Token | undefined;
  tokenB: Token | undefined;
  nameFn?(a: string, b: string): string;
  size?: Sizes;
  hideName?: boolean;
  textClassName?: string;
};

function defaultNameFormat(a: string, b: string) {
  return `${a}/${b}`;
}

export const LogoAndSymbol = ({
  tokenA,
  tokenB,
  nameFn = defaultNameFormat,
  size = "24",
  hideName,
  textClassName = "",
}: Props) => {
  const chain = useChain();
  const cn: Record<Sizes, string> = {
    "32": "h-8 w-8",
    "24": "h-6 w-6",
  };

  const tokenADisplayData = tokenA
    ? getPairTokenDisplayData(tokenA, chain.id)
    : undefined;
  const tokenBDisplayData = tokenB
    ? getPairTokenDisplayData(tokenB, chain.id)
    : undefined;

  return (
    <div className="flex items-center">
      <div className="relative flex items-center">
        <Img
          className={classNames(
            "rounded-[50%] max-sm:h-7 max-sm:w-7",
            cn[size]
          )}
          src={tokenA?.logoUrl}
          alt={tokenADisplayData?.symbol}
          fallbackUrl={placeholder}
        />
        <Img
          className={classNames(
            "relative -left-3 rounded-[50%] max-sm:h-7 max-sm:w-7",
            cn[size]
          )}
          src={tokenB?.logoUrl}
          alt={tokenBDisplayData?.symbol}
          fallbackUrl={placeholder}
        />
      </div>
      {hideName ? null : (
        <span
          className={classNames(
            "whitespace-nowrap text-[22px] leading-7 text-black max-sm:text-xl",
            textClassName
          )}
        >
          {tokenA &&
            tokenB &&
            nameFn(
              tokenADisplayData?.symbol ?? "",
              tokenBDisplayData?.symbol ?? ""
            )}
        </span>
      )}
    </div>
  );
};
