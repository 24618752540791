import { useNavigate } from "react-router-dom";
import Decimal from "decimal.js";
import { matchSorter } from "match-sorter";
import { toDecimal } from "utils/numbers";

import { PoolTable } from "entities/pool";
import { useDetailedPairsQuery } from "shared/api/pool";
import { useStateX } from "shared/hooks";
import { Dropdown, DropdownItem } from "shared/ui/dropdown";
import { Icon } from "shared/ui/icon";
import { Input } from "shared/ui/input";
import { Spinner } from "shared/ui/spinner";
import { Toggle } from "shared/ui/toggle";
import { Tooltip } from "shared/ui/tooltip";

import { usePairPositions } from "../hooks";

type SortByType = "TVL" | "Vol";

type State = {
  isMyPositions: boolean;
  search: string;
  sortBy: SortByType;
};

const dropdownFilterItems: { label: string; id: SortByType }[] = [
  {
    label: "High TVL First",
    id: "TVL",
  },
  {
    label: "High Volume First",
    id: "Vol",
  },
];

export const Pools = () => {
  const navigate = useNavigate();
  const [{ isMyPositions, search, sortBy }, setState] = useStateX<State>({
    isMyPositions: false,
    search: "",
    sortBy: "TVL",
  });

  const detailedPairsQuery = useDetailedPairsQuery();
  const detailedPairs = detailedPairsQuery.data ?? [];

  const pairPositions = usePairPositions(detailedPairs);

  const filteredDetailedPairs = detailedPairs.filter((_detailedPair, idx) => {
    const hasPosition = Boolean(pairPositions[idx].positionD?.greaterThan(0));
    if (isMyPositions) return hasPosition;

    return true;
  });

  const searchedDetailedPairs = search
    ? matchSorter(filteredDetailedPairs, search, {
        keys: [
          (d) => d.pair.liquidityToken.symbol?.split("/").join("/-") ?? "",
          "pair.liquidityToken.address",
        ],
      })
    : filteredDetailedPairs;

  const sortedDetailedPairs = searchedDetailedPairs.sort((a, b) => {
    if (sortBy === "TVL") {
      const aTVLD = toDecimal(a.advancedInfo.tvl);
      const bTVLD = toDecimal(b.advancedInfo.tvl);

      return aTVLD.greaterThan(bTVLD) ? -1 : 1;
    }

    if (sortBy === "Vol") {
      const aVolumeD = toDecimal(a.advancedInfo.volume24);
      const bVolumeD = toDecimal(b.advancedInfo.volume24);

      return aVolumeD.greaterThan(bVolumeD) ? -1 : 1;
    }

    return 0;
  });

  return (
    <>
      <div className="mx-auto w-[1200px] px-3 py-0 pb-[60px] pt-[52px] max-xl:w-full">
        <h2 className="mb-4 flex items-center text-3xl text-black">
          Pools{" "}
          <Tooltip
            element={
              <div className="w-72">
                By adding liquidity you'll earn 0.25% of all trades on a pair
                proportional to your share of the pool. Fees are added to the
                pool, accrue in real time and can be claimed by withdrawing your
                liquidity.
              </div>
            }
          >
            <Icon name="info" size="14" className="ml-3 text-osloGray" />
          </Tooltip>
        </h2>
        <div className="flex flex-wrap items-center justify-between gap-y-3">
          <div className="flex items-center space-x-4 max-sm:space-x-1">
            <div className="text-sm leading-5">
              <Input
                id="pools-search"
                cancelable
                iconName="search"
                placeholder="Search by name or paste an address"
                value={search}
                onChange={({ value }) => setState({ search: value })}
              />
            </div>
            <div className="mr-3 whitespace-nowrap text-base leading-5">
              My Positions
            </div>
            <Toggle
              id={"toggle-my-positions"}
              checked={isMyPositions}
              onChange={() => {
                setState({ isMyPositions: !isMyPositions });
              }}
              className="!self-center"
            />
          </div>
          <div className="flex items-center space-x-4 max-sm:flex-wrap max-sm:gap-y-3 max-sm:space-x-0">
            <Dropdown
              id="sort-dropdown-btn"
              button={sortBy === "Vol" ? "High Volume First" : "High TVL First"}
            >
              {dropdownFilterItems.map((item) => (
                <DropdownItem
                  id={`sort-dropdown-item-${item.id}`}
                  key={item.id}
                  onClick={() => setState({ sortBy: item.id })}
                >
                  <span>{item.label}</span>
                </DropdownItem>
              ))}
            </Dropdown>
            <div className="flex h-10 items-center rounded-[8px] border border-dodgerBlue text-lg leading-6">
              <button
                id={`general-zzap-btn`}
                onClick={() => navigate("/zzap")}
                className="flex h-full items-center space-x-2 rounded-[5px] bg-dodgerBlue px-11 text-white max-sm:px-4"
              >
                <Icon size="20" name="spaceShip" />
                <span>Zzap</span>
              </button>
              <button
                id={`general-add-liquidity-btn`}
                onClick={() => navigate("/add")}
                className="flex h-full items-center space-x-2 px-11 text-dodgerBlue max-sm:px-4"
              >
                <Icon size="25" name="add" />
                <span>Add</span>
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <PoolTable>
            {detailedPairsQuery.isFetching && detailedPairsQuery.isLoading && (
              <div className="flex justify-center">
                <Spinner size="66" />
              </div>
            )}
            {sortedDetailedPairs.map((detailedPair) => {
              const pairPosition = pairPositions.find((pairPosition) => {
                return detailedPair.pair.liquidityToken.equals(
                  pairPosition.detailedPair.pair.liquidityToken
                );
              });

              return (
                <PoolTable.Row
                  key={detailedPair.pair.liquidityToken.address}
                  detailedPair={detailedPair}
                  position={pairPosition?.positionUSD?.toSignificantDigits(
                    6,
                    Decimal.ROUND_DOWN
                  )}
                />
              );
            })}
            {search !== "" && sortedDetailedPairs.length === 0 ? (
              <div className="text-center text-sm text-osloGray">
                Nothing found
              </div>
            ) : null}
          </PoolTable>
        </div>
      </div>
    </>
  );
};
