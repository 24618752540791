import { ReactNode } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

type Props = {
  balance: ReactNode;
  convertedBalance?: ReactNode;
} & EmotionCSSProps;

export const Balance = ({
  className,
  css: cssProp,
  balance,
  convertedBalance,
}: Props) => {
  return (
    <div css={cssProp} className={cn("flex flex-col items-end", className)}>
      {typeof balance === "string" ? (
        <span className="inline-flex text-[22px] leading-7 text-black">
          {balance}
        </span>
      ) : (
        balance
      )}
      {typeof convertedBalance === "string" ? (
        <span className="inline-flex text-sm text-osloGray">
          ≈ $ {convertedBalance}
        </span>
      ) : (
        convertedBalance
      )}
    </div>
  );
};
