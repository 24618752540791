import { usePrice } from "entities/price";
import { TradeTypes } from "shared/v2-sdk/hooks";

type Props = {
  trade: TradeTypes;
};

export const TradeFields = ({ trade }: Props) => {
  const [tradeInfo] = usePrice(trade.executionPrice);
  const [tradeInvertedInfo] = usePrice(trade.executionPrice, true);

  return (
    <>
      <div className="mt-1 text-sm text-osloGray">{tradeInfo.symbol} Rate</div>
      <div className="mt-1 flex items-center justify-end gap-x-1 text-right text-sm">
        {tradeInfo.usdPrice && (
          <span className="text-osloGray">
            ≈ $ {tradeInfo.usdPrice?.toSignificant()}
          </span>
        )}

        {tradeInfo.text}
      </div>
      <div className="mt-1 text-sm text-osloGray">
        {tradeInvertedInfo.symbol} Rate
      </div>
      <div className="mt-1 flex items-center justify-end gap-x-1 text-right text-sm">
        {tradeInvertedInfo.usdPrice && (
          <span className="text-osloGray">
            ≈ $ {tradeInvertedInfo.usdPrice?.toSignificant()}
          </span>
        )}
        {tradeInvertedInfo.text}
      </div>
    </>
  );
};
