import React from "react";
import cn from "classnames";

import { BlockNumber } from "entities/block-number";
import { EmotionCSSProps } from "shared/types";

type Props = EmotionCSSProps;
export const Footer = ({ className, css }: Props) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer
      css={css}
      className={cn("flex items-center justify-between", className)}
    >
      <span className="inline-flex text-sm text-osloGray">
        © {currentYear} OccamX
      </span>
      <BlockNumber />
    </footer>
  );
};
