import React from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

import { SIZES, spinnerCss, THEMES } from "./styles";

type Props = {
  theme?: keyof typeof THEMES;
  size?: keyof typeof SIZES;
} & EmotionCSSProps;

export const Spinner = ({
  size,
  theme = "dodgerBlue",
  className,
  css: cssProp,
}: Props) => {
  return (
    <div className={cn("inline-flex items-center", className)} css={[cssProp]}>
      <span css={[spinnerCss, THEMES[theme], size && SIZES[size]]}></span>
    </div>
  );
};
