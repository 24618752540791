import { ReactNode } from "react";

type Props = {
  type?: "success" | "error" | "pending" | "submit";
  title?: ReactNode;
  desc: ReactNode;
  externalUrl?: string;
};

const TITLE_MAP = {
  success: "Transaction Successful",
  error: "Transaction Failed",
  pending: "Transaction Pending",
  submit: "Transaction Submitted",
};

export const Toast = ({
  desc,
  externalUrl,
  type = "success",
  ...props
}: Props) => {
  const title = Object.hasOwn(props, "title") ? props.title : TITLE_MAP[type];
  return (
    <div className="flex w-72 flex-col">
      {typeof title === "string" ? (
        <span className="inline-flex text-lg text-black">{title}</span>
      ) : (
        title
      )}
      {typeof desc === "string" ? (
        <span className="mt-1 inline-flex text-sm text-black">{desc}</span>
      ) : (
        desc
      )}
      {externalUrl && (
        <a
          className="mt-1 inline-flex text-sm text-dodgerBlue"
          href={externalUrl}
          target="_blank"
          rel="noreferrer"
        >
          View on explorer
        </a>
      )}
    </div>
  );
};
