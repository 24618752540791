import { PropsWithChildren } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
import { HTMLMotionProps } from "framer-motion";

import { EmotionCSSProps } from "shared/types";

type Props = HTMLMotionProps<"div"> & EmotionCSSProps;

export const FormWrapper = ({
  children,
  css: cssProp,
  className,
}: PropsWithChildren<Props>) => {
  const cls = cn(
    className,
    "farmCard-shadow relative m-auto flex flex-col z-10 max-sm:min-w-full max-xl:min-w-[500px] max-w-[550px] w-1/3 overflow-hidden rounded-[30px] border border-white bg-athensGray max-sm:w-full max-sm:rounded-[30px]"
  );

  return (
    <motion.div css={[cssProp]} className={cls}>
      <div className="absolute h-full w-full bg-swapCardBg bg-cover bg-no-repeat" />
      <div className="relative flex flex-col px-8 pb-10 pt-5 max-2xl:px-6 max-sm:p-6">
        {children}
      </div>
    </motion.div>
  );
};
