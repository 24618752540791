import { HTMLAttributes, PropsWithChildren } from "react";

import { EmotionCSSProps } from "shared/types";

export const Text = ({
  children,
  className,
  css,
  ...props
}: PropsWithChildren<EmotionCSSProps & HTMLAttributes<HTMLSpanElement>>) => {
  return (
    <span {...props} css={css} className={className}>
      {children}
    </span>
  );
};
