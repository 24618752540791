import cn from "classnames";

import { useStateX } from "shared/hooks";
import { EmotionCSSProps } from "shared/types";
import { Icon } from "shared/ui/icon";
import { Validator } from "shared/ui/input";
import { Tabs } from "shared/ui/tabs";
import { Tooltip } from "shared/ui/tooltip";

import { TabInput } from "./tab-input";

const PERCENTS = [0.1, 0.5, 1, 3];

const validators: Validator[] = [
  (value) => {
    if (!value) return true;
    try {
      Number(value);
      return true;
    } catch {
      return false;
    }
  },
  (value) => {
    if (value[0] === "0" && /\d/.test(value[1])) return false;
    return true;
  },
  (value) => {
    if (!value.includes(".")) return value.length <= 4;

    const afterDot = value.slice(value.indexOf(".") + 1);

    return afterDot.length <= 2;
  },
];

type Props = {
  value: number;
  onChange: ({ slippageTolerance }: { slippageTolerance: number }) => void;
} & EmotionCSSProps;

export const SlippageTolerance = ({
  value,
  onChange,
  className,
  css,
}: Props) => {
  const [state, setState] = useStateX({
    activeTab: PERCENTS.includes(value)
      ? PERCENTS.find((v) => v === value)
      : PERCENTS[1],
    customValue: PERCENTS.includes(value) ? "" : value.toString(),
  });

  const { activeTab, customValue } = state;

  return (
    <div css={css} className={cn("relative flex flex-col", className)}>
      <Tooltip
        portal={false}
        placement="top"
        className="self-baseline text-sm text-osloGray"
        element={
          <div className="max-w-[240px]">
            Your transaction will revert if the price changes unfavorably by
            more than this percentage.
          </div>
        }
      >
        <span className="mr-1 inline-flex">Slippage Tolerance</span>
        <Icon name="info" size="14" />
      </Tooltip>

      <div className="flex items-end">
        <Tabs
          id="slippage-tolerance"
          className="mt-3"
          theme="light"
          tabs={PERCENTS}
          activeTab={customValue ? undefined : activeTab}
          keyAccessor={(tab) => tab}
          renderValue={(tab) => `${tab}%`}
          onChange={(tab) => {
            setState({ customValue: "", activeTab: tab });
            onChange({ slippageTolerance: tab });
          }}
        />
        <div className="ml-3 inline-flex w-1/4 items-center max-sm:w-full">
          <TabInput
            id="slippage-tolerance-custom"
            value={customValue}
            validators={validators}
            onChange={(value) => {
              setState({ customValue: value });
              onChange({ slippageTolerance: Number(value) });
            }}
          />
          <span className="ml-1 inline-flex text-osloGray">%</span>
        </div>
      </div>

      {customValue && (
        <div
          id="slippage-tolerance-custom-alert"
          className="absolute -bottom-5 left-0 text-sm text-orangePeel"
        >
          Custom
        </div>
      )}
    </div>
  );
};
