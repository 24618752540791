import tw from "twin.macro";

const icon14Css = tw`h-3.5 w-3.5`;
const icon18Css = tw`h-[18px] w-[18px]`;
const icon20Css = tw`w-5 h-5`;
const icon22Css = tw`h-[22px] w-[22px]`;
const icon25Css = tw`h-[25px] w-[25px]`;
const icon32Css = tw`w-8 h-8`;
const icon66Css = tw`h-[66px] w-[66px]`;

export const SIZES = {
  "14": icon14Css,
  "18": icon18Css,
  "20": icon20Css,
  "22": icon22Css,
  "25": icon25Css,
  "32": icon32Css,
  "66": icon66Css,
};
