import { useCallback } from "react";
import { parseUnits, toDecimal } from "utils/numbers";

import { usePairQuery } from "shared/api/token";
import { Currency, CurrencyAmount, Token } from "shared/sdk-core";

interface Options {
  currencyA?: Currency;
  currencyB?: Currency;
}
export default function useCalculateSecondTokenValue({
  currencyA,
  currencyB,
}: Options) {
  const pairQuery = usePairQuery(currencyA, currencyB);

  return useCallback(
    (token: Token | undefined, input: string) => {
      if (!token) {
        return "";
      }

      const tokenAAmount = CurrencyAmount.fromRawAmount(
        token,
        parseUnits(input, token.decimals).toString()
      );
      const tokenBCalculcatedAmount = pairQuery?.data
        ?.priceOf(token)
        .quote(tokenAAmount?.wrapped)
        .toSignificant();

      if (toDecimal(input).eq(0)) {
        return "";
      }

      return tokenBCalculcatedAmount ?? "";
    },
    [pairQuery]
  );
}
