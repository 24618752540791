import React, { PropsWithChildren } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

type Props = EmotionCSSProps;
export const Body = ({
  children,
  className,
  css: cssProp,
}: PropsWithChildren<Props>) => {
  return (
    <div
      css={cssProp}
      className={cn(
        className,
        "m-auto w-[500px] min-w-[500px] rounded-[20px] bg-athensGray p-10 max-sm:w-full max-sm:min-w-full max-sm:rounded-3xl max-sm:p-5"
      )}
    >
      {children}
    </div>
  );
};
