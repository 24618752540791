import React from "react";
import cn from "classnames";

import { useChain } from "shared/providers/wagmi";
import { EmotionCSSProps } from "shared/types";
import { OWN_TOKEN } from "shared/v2-sdk/constants";

import { StakingOcxInfo } from "../hooks/useStakingOcxInfo";

type Props = {
  stakingInfo: StakingOcxInfo;
} & EmotionCSSProps;

export const StakingInfo = ({ stakingInfo, className }: Props) => {
  const chain = useChain();
  const ocxToken = OWN_TOKEN[chain.id];

  return (
    <div
      className={cn(
        className,
        "flex justify-between border-t border-quillGray pt-6"
      )}
    >
      <div className="inline-flex items-center text-sm md:text-lg">
        <span className="inline-flex text-osloGray">Staking APY:</span>
        <span className="ml-1 inline-flex">{stakingInfo?.apy ?? "0"}%</span>
      </div>
      <div className="inline-flex items-center text-sm md:text-lg">
        <span className="inline-flex text-osloGray">Total staked:</span>
        <span className="ml-1 inline-flex">
          {stakingInfo?.totalStakedCA?.toSignificant(6) ?? "0"}{" "}
          {ocxToken.symbol}
        </span>
      </div>
    </div>
  );
};
