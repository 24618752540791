import { toDecimal } from "utils/numbers";

import { useOcxStakingInfoQuery } from "shared/api/ocx";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, Fraction, Percent } from "shared/sdk-core";
import { OWN_TOKEN } from "shared/v2-sdk/constants";

export type StakingOcxInfo = ReturnType<typeof useStakingOcxInfo>;

export const useStakingOcxInfo = (amount?: string) => {
  const chain = useChain();
  const ocxToken = OWN_TOKEN[chain.id];
  const amountCA = toCurrencyAmount(ocxToken, amount || "0");
  const ocxStakingInfoQuery = useOcxStakingInfoQuery();
  const balanceQuery = useCurrencyBalanceQuery(ocxToken);

  if (!balanceQuery.isSuccess) return undefined;

  const calc = (data: Exclude<typeof ocxStakingInfoQuery.data, undefined>) => {
    const balanceCA = balanceQuery.data.balanceCA;
    const stakingBalanceCA = data.accountData.stakingBalance.balanceCA;
    const newBalanceCA = stakingBalanceCA.add(amountCA);
    const totalStakedCA = data.commonData.totalStakedCA;

    const capitalEfficiencyPercentFR = newBalanceCA.asFraction.divide(
      balanceCA.add(stakingBalanceCA).asFraction
    );

    const dailyRateCA = CurrencyAmount.fromRawAmount(
      ocxToken,
      toDecimal(data.commonData.rewardsPerSeconds)
        .mul(toDecimal(10).pow(ocxToken.decimals))
        .toFixed(0)
    ).multiply(60 * 60 * 24);

    const poolSharePercentFR = newBalanceCA.asFraction.divide(
      totalStakedCA.asFraction
    );

    const rewardsAvailableCA = data.accountData.rewardAvailable;

    const rewardsClaimedCA = data.accountData.rewardClaimed;
    const rewardsEarnedCA = data.accountData.rewardTotal;

    return {
      stakingBalance: data.accountData.stakingBalance,
      balance: balanceQuery.data,

      capitalEfficiencyPercent: normalizePercent(capitalEfficiencyPercentFR),
      calcStakingBalance: newBalanceCA,
      dailyRateCA,
      poolSharePercent: normalizePercent(poolSharePercentFR),
      rewardsAvailableCA,
      rewardsClaimedCA,
      rewardsEarnedCA,
      apy: toDecimal(data.commonData.apy).mul(100).toDecimalPlaces(2).toFixed(),
      totalStakedCA,
      tvl: data.commonData.tvl,
    };
  };

  const result = ocxStakingInfoQuery.isSuccess
    ? calc(ocxStakingInfoQuery.data)
    : undefined;
  return {
    ...result,
    balance: balanceQuery.data,
  };
};

function normalizePercent(percent: Fraction) {
  if (percent.greaterThan(1)) return new Percent(100, 100);
  if (percent.lessThan(0)) return new Percent(0, 100);
  return new Percent(percent.numerator, percent.denominator);
}
