import classNames from "classnames";
import { useAccount } from "wagmi";

import { getPriceImpactData, Price } from "entities/price";
import { EmotionCSSProps } from "shared/types";
import { Icon } from "shared/ui/icon";
import { Tooltip } from "shared/ui/tooltip";
import { TradeTypes } from "shared/v2-sdk/hooks";

import { AutoRouter } from "../auto-route";

import { AdvancedDetails } from "./advanced-details";

type Props = {
  trade: TradeTypes | undefined;
  isLoading: boolean;
} & EmotionCSSProps;

export const Details = ({ className, trade, isLoading }: Props) => {
  const { isConnected } = useAccount();

  const priceImpactData = getPriceImpactData(trade?.priceImpact);

  return (
    <div
      className={classNames(
        "my-4",
        "max-sm: flex h-[28px] w-full items-center max-sm:h-auto max-sm:max-h-[50px] max-sm:flex-wrap",
        className
      )}
    >
      {isConnected && <AutoRouter isLoading={isLoading} trade={trade} />}
      {trade && (
        <>
          <Price price={trade.executionPrice} priceImpact={trade.priceImpact} />
          <Tooltip
            disabled={!trade}
            element={trade && <AdvancedDetails trade={trade} />}
          >
            <div className="px-1 py-2">
              <Icon
                name="info"
                size="14"
                className={classNames(
                  "ml-1 text-osloGray",
                  priceImpactData.status === "high" && "text-redOrange",
                  !trade && "text-opacity-70"
                )}
              />
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};
