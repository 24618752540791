import cn from "classnames";

import { useCurrencies } from "entities/currency";
import { EmotionCSSProps } from "shared/types";
import { Pair } from "shared/v2-sdk";

import { LogoAndSymbol } from "./logo-and-symbol";

type Props = {
  id?: string;
  pair: Pair;
  size?: "32" | "24";
  bordered?: boolean;
  showSymbol?: boolean;
  showName?: boolean;
} & EmotionCSSProps;

export const PairEntity = ({
  id,
  pair,
  size,
  css: cssProp,
  bordered,
  className,
  showSymbol = true,
  showName = false,
}: Props) => {
  const currencies = useCurrencies();
  const tokenA = currencies.find((t) => t.wrapped.equals(pair.token0))?.wrapped;
  const tokenB = currencies.find((t) => t.wrapped.equals(pair.token1))?.wrapped;

  return (
    <div
      id={id}
      css={cssProp}
      className={cn(
        "flex flex-col",
        bordered &&
          "rounded-full border border-solid border-tiara py-0.5 pl-1 pr-2",
        className
      )}
    >
      <LogoAndSymbol
        size={size}
        tokenA={tokenA}
        tokenB={tokenB}
        hideName={!showSymbol}
      />
      {showName && (
        <div className="mt-1 inline-block w-52 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-osloGray">
          {pair.liquidityToken.name}
        </div>
      )}
    </div>
  );
};
