import { useSwitchNetwork } from "wagmi";

import { milkomedaC1Chain } from "shared/config/networks/milkomeda";
import { EmotionCSSProps } from "shared/types";
import { Button, ButtonSize } from "shared/ui/button";
import { notify } from "shared/ui/toast";

type Props = {
  size?: ButtonSize;
  isLoading?: boolean;
} & EmotionCSSProps;

export const Btn = ({ css, isLoading, className, size = "66" }: Props) => {
  const { switchNetworkAsync } = useSwitchNetwork();
  const onSwitch = async () => {
    try {
      await switchNetworkAsync?.(milkomedaC1Chain.id);
    } catch (error) {
      if (
        error &&
        typeof error === "object" &&
        "name" in error &&
        error.name === "AddChainError"
      ) {
        notify.error(
          `${milkomedaC1Chain.name} hasn't been added to the wallet!`
        );
      }
    }
  };

  return (
    <Button
      id={"switch-network-btn"}
      loading={isLoading}
      onClick={onSwitch}
      size={size}
      iconName="plug"
      className={className}
      css={css}
    >
      Wrong network
    </Button>
  );
};
