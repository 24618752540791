import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";
import { Native, Pair } from "shared/v2-sdk";
import { WNATIVE } from "shared/v2-sdk/constants";

type UsePairCurrenciesResult<T extends Pair | undefined> = T extends Pair
  ? [Currency, Currency]
  : [undefined, undefined];

export const usePairCurrencies = <T extends Pair | undefined>(
  pair: T
): UsePairCurrenciesResult<T> => {
  const chain = useChain();
  const isHumanode = useIsHumanode();

  const currencyA: Currency | undefined = pair?.token0.equals(WNATIVE[chain.id])
    ? isHumanode
      ? Native.byChainId(chain.id).wrapped
      : Native.byChainId(chain.id)
    : pair?.token0;
  const currencyB: Currency | undefined = pair?.token1.equals(WNATIVE[chain.id])
    ? isHumanode
      ? Native.byChainId(chain.id).wrapped
      : Native.byChainId(chain.id)
    : pair?.token1;

  return [currencyA, currencyB] as UsePairCurrenciesResult<T>;
};
