import { ChainId } from "shared/v2-sdk/constants";

import { useChain } from "./use-chain";

export const isHumanode = (chainId: number) => {
  return (
    chainId === ChainId.HUMANODE_TESTNET || chainId === ChainId.HUMANODE_MAINNET
  );
};

export const useIsHumanode = () => {
  const chain = useChain();

  return isHumanode(chain.id);
};
