export const LS_KEYS = {
  swap: (chainId: number | string) => `swap:${chainId}`,
  zzap: (chainId: number | string) => `zzap:${chainId}`,
  txSettings: (chainId: number | string) => `txSettings:${chainId}`,
  tokenSelector: (chainId: number | string, extraKey = "latestSelected") =>
    `tokenSelector:${extraKey}:${chainId}`,
  pairSelector: (chainId: number | string, extraKey = "latestSelected") =>
    `pairSelector:${extraKey}:${chainId}`,
  txHistory: (chainId: number | string, address: string | undefined = "") =>
    `txHistory:${chainId}:${address}`,
  customTokens: (chainId: number | string) => `customTokens:${chainId}`,
  defaultChain: `defaultChain`,
};
