import styles from "./styles.module.css";

import { PropsWithChildren } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

import { Header } from "./header";
import { Item } from "./item";

type Props = {} & EmotionCSSProps;
export const List = ({
  className,
  css: cssProp,
  children,
}: PropsWithChildren<Props>) => {
  // TODO: custom scrollbar
  return (
    <div
      css={[cssProp]}
      className={cn("h-[300px] w-full overflow-auto", className, styles.list)}
    >
      <div className="flex h-auto w-full flex-col overflow-hidden">
        {children}
      </div>
    </div>
  );
};

List.Header = Header;
List.Item = Item;
