import cn from "classnames";

import { Token } from "shared/sdk-core";
import { EmotionCSSProps } from "shared/types";
import tokenPlaceholder from "shared/ui/icon/svg/tokenPlaceholder.svg";
import { Img } from "shared/ui/img";

type Props = {
  token: Token;
} & EmotionCSSProps;

const Preview = ({ token, css: cssProp, className }: Props) => {
  return (
    <div
      css={cssProp}
      className={cn(
        "flex flex-col items-center rounded-3xl border border-solid border-tiara p-4",
        className
      )}
    >
      <Img
        className="h-6 w-6"
        src={token.logoUrl}
        alt={token.symbol}
        fallbackUrl={tokenPlaceholder}
      />
      <span className="mt-1 inline-flex text-lg">{token.symbol}</span>
      <span className="mt-1 inline-flex text-sm text-osloGray">
        {token.name}
      </span>
      <span className="mt-1 inline-flex text-sm text-dodgerBlue">
        {token.address}
      </span>
    </div>
  );
};

export default Preview;
