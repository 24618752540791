import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import cn from "classnames";
import { BigNumber } from "ethers";
import { toDecimal } from "utils/numbers";
import { useAccount } from "wagmi";

import { PriceImpact } from "entities/price";
import { Currency, CurrencyAmount, Percent } from "shared/sdk-core";
import { EmotionCSSProps } from "shared/types";
import { decimalValidators } from "shared/ui/input";

type Props = {
  debounceDelay?: number;
  value: string;
  currency: Currency | undefined | null;
  balance: BigNumber | string | undefined | null;
  price?: CurrencyAmount<Currency>;
  priceImpact?: Percent;
  onChange: (value: string) => void;
  maxLabel?: string;
  showMaxBtn?: boolean;
  isLoading?: boolean;
  maxBtnLoading?: boolean;
  onMaxClick?: (value: string) => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> &
  EmotionCSSProps;

export const Input = ({
  className,
  css: cssProp,

  value,
  currency,
  balance,

  children,
  price,
  priceImpact,
  maxLabel = "Balance",

  showMaxBtn: showMaxBtnProp = true,
  maxBtnLoading = false,

  debounceDelay = 800,

  onChange,
  onMaxClick,
  ...props
}: PropsWithChildren<Props>) => {
  const { isConnected } = useAccount();
  const [val, setVal] = useState(value);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(false);
    setVal(value);
  }, [value]);

  useDebounce(
    () => {
      enabled && onChange(val);
    },
    debounceDelay,
    [val]
  );

  const decimals = currency?.decimals ?? 18;

  const handleChange = (potentialValue: string) => {
    setEnabled(true);
    const isValid = decimalValidators(decimals).every((validator) =>
      validator(potentialValue)
    );

    if (isValid && decimals) {
      potentialValue = potentialValue.replaceAll(",", ".");
    }
    if (!isValid && potentialValue[0] === "0" && /\d/.test(potentialValue[1])) {
      potentialValue = potentialValue[1];
    }

    if (isValid) setVal(potentialValue);
  };

  const preparedBalance = balance ? toDecimal(balance.toString()) : undefined;
  const showMaxBtn =
    preparedBalance && preparedBalance.greaterThan(0) && showMaxBtnProp;

  return (
    <div
      className={cn(
        "relative flex w-full items-start rounded-lg border border-quillGray border-opacity-40 bg-white",
        "focus-within:border-dodgerBlue",
        className
      )}
    >
      <input
        placeholder="0.0"
        autoComplete="off"
        {...props}
        className={cn(
          "mr-auto inline-flex w-full border-none bg-transparent pb-10 pl-3 pt-3 text-2xl outline-none max-2xl:pb-8",
          !children && "pr-4"
        )}
        value={val}
        onChange={(e) => handleChange(e.target.value)}
      />
      <div className="inline-flex shrink-0 pl-2 pr-4 pt-2.5">{children}</div>

      <div className="absolute bottom-2 right-0 flex w-full items-center px-4">
        {price && (
          <div className="mr-auto flex text-sm text-osloGray">
            ≈ $ {price.toSignificant(6)}
            {priceImpact && (
              <PriceImpact
                className="ml-2"
                showBrackets
                priceImpact={priceImpact}
              />
            )}
          </div>
        )}
        {isConnected && (
          <span className="ml-auto inline-flex items-center text-sm text-osloGray">
            {showMaxBtn && (
              <span
                id={`${props.id}-max-btn`}
                className={cn(
                  "mr-2 inline-flex py-1 text-sm ",
                  maxBtnLoading
                    ? "cursor-default text-osloGray"
                    : "cursor-pointer text-dodgerBlue"
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  if (maxBtnLoading) return;
                  onMaxClick?.(preparedBalance.toFixed(currency?.decimals));
                }}
              >
                MAX
              </span>
            )}
            {maxLabel ? (
              <span>
                {maxLabel}:{" "}
                {typeof balance === "string"
                  ? balance
                  : preparedBalance?.greaterThan(0)
                  ? preparedBalance?.toFixed(currency?.decimals)
                  : "0"}
              </span>
            ) : null}
          </span>
        )}
      </div>
    </div>
  );
};
