import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { toDecimal } from "utils/numbers";
import { useAccount, useProvider, useSigner } from "wagmi";

import { RemoveLiquidityDetails, usePairCurrencies } from "pages/pool/hooks";
import {
  HumanodeRouter__factory,
  MilkomedaRouter__factory,
} from "shared/abi/types";
import { calculateFee, toCurrencyAmount } from "shared/helpers";
import { isMilkomedaRouter } from "shared/helpers/is-router";
import { useTxSettings } from "shared/providers/TxSettings";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

import { poolKeys } from "./keys";
import { DetailedPair } from "./useDetailedPairsQuery";

type Params = {
  detailedPair: DetailedPair;
  ltAmount: CurrencyAmount<Token>;
  details: RemoveLiquidityDetails;
};

export const useEstimateRemoveLiquidityFeeQuery = ({
  detailedPair,
  ltAmount,
  details,
}: Params) => {
  const { address } = useAccount();
  const [txSettings] = useTxSettings();
  const [currencyA, currencyB] = usePairCurrencies(detailedPair.pair);
  const chain = useChain();
  const signerQuery = useSigner();
  const provider = useProvider({ chainId: chain.id });
  const isHumanode = useIsHumanode();

  return useQuery(
    poolKeys.removeLiquidityFee(chain.id),
    async () => {
      invariant(
        address,
        "useEstimateRemoveLiquidityFeeQuery. address is undefined"
      );
      invariant(
        provider,
        "useEstimateRemoveLiquidityFeeQuery. provider is undefined"
      );
      invariant(
        signerQuery.data,
        "useEstimateRemoveLiquidityFeeQuery. signerQuery.data is undefined"
      );

      invariant(
        details.liquidityValueA,
        "useEstimateRemoveLiquidityFeeQuery. details.liquidityValueA is undefined"
      );

      const routerFactory = isHumanode
        ? HumanodeRouter__factory
        : MilkomedaRouter__factory;

      const contractRouter = routerFactory.connect(
        ROUTER_ADDRESS_MAP[chain.id],
        signerQuery.data || provider
      );

      const native =
        currencyA.isNative || currencyB.isNative
          ? Native.byChainId(chain.id)
          : undefined;

      const deadline =
        Math.floor(Date.now() / 1000) + 60 * txSettings.transactionDeadline;

      const { gasPrice } = await provider.getFeeData();

      if (native && isMilkomedaRouter(contractRouter)) {
        const token = currencyA.isToken ? currencyA : currencyB.wrapped;

        const tokenAmount = details.liquidityValueA.currency.equals(token)
          ? details.liquidityValueA
          : details.liquidityValueB;

        const nativeAmount = details.liquidityValueA.currency.equals(token)
          ? details.liquidityValueB
          : details.liquidityValueA;

        const amountTokenMin = toCurrencyAmount(
          token,
          toDecimal(tokenAmount?.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const amountNativeMin = toCurrencyAmount(
          Native.byChainId(chain.id),
          toDecimal(nativeAmount?.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const estimatedGas =
          await contractRouter.estimateGas.removeLiquidityADA(
            token.address,
            ltAmount.quotient.toString(),
            amountTokenMin.quotient.toString(),
            amountNativeMin.quotient.toString(),
            address,
            deadline
          );

        return calculateFee(gasPrice, estimatedGas, chain.id);
      } else {
        const tokenA = currencyA.wrapped;
        const tokenB = currencyB.wrapped;

        const tokenAAmount = details.liquidityValueA?.currency.equals(tokenA)
          ? details.liquidityValueA
          : details.liquidityValueB;

        const tokenBAmount = details.liquidityValueB?.currency.equals(tokenB)
          ? details.liquidityValueB
          : details.liquidityValueA;

        const amountTokenMin = toCurrencyAmount(
          tokenB,
          toDecimal(tokenAAmount?.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const amountTokenBMin = toCurrencyAmount(
          Native.byChainId(chain.id),
          toDecimal(tokenBAmount.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const estimatedGas = await contractRouter.estimateGas.removeLiquidity(
          tokenA.address,
          tokenB.address,
          ltAmount.quotient.toString(),
          amountTokenMin.quotient.toString(),
          amountTokenBMin.quotient.toString(),
          address,
          deadline
        );

        return calculateFee(gasPrice, estimatedGas, chain.id);
      }
    },
    {
      enabled: Boolean(
        ltAmount.greaterThan(0) &&
          signerQuery.isSuccess &&
          address &&
          details.liquidityValueA &&
          details.liquidityValueB
      ),
    }
  );
};
