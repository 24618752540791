import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccount, useProvider, useSigner } from "wagmi";

import { Staking__factory } from "shared/abi/types";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";

import { tokenKeys } from "../token/keys";
import { useTxMutation, useTxWaitMutation } from "../transtaction";

import { farmsKeys } from "./keys";

type Params = {
  address: string;
  currencyAmount: CurrencyAmount<Token>;
};

const useUnstakeMutation = () => {
  const chain = useChain();
  const queryClient = useQueryClient();
  const { address } = useAccount();
  const provider = useProvider({ chainId: chain.id });
  const { data: signer } = useSigner();

  const txMutation = useTxMutation();
  const txWaitMutation = useTxWaitMutation();

  return useMutation(
    async ({ currencyAmount, address }: Params) => {
      const desc = `Unstake ${currencyAmount.toSignificant(6)} LT`;
      const contract = Staking__factory.connect(address, signer || provider);

      const estimatedGas = await contract.estimateGas.unstake(
        currencyAmount.quotient.toString(),
        "0"
      );

      const txPromise = contract.unstake(
        currencyAmount.quotient.toString(),
        "0",
        {
          gasLimit: estimatedGas.mul(120).div(100),
        }
      );

      const tx = await txMutation.mutateAsync({ txPromise, desc });

      const receipt = await txWaitMutation.mutateAsync({ tx, desc });
      return receipt;
    },
    {
      onSuccess: (data, params) => {
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.currencyAmount.currency.address
          )
        );
        queryClient.refetchQueries(
          farmsKeys.stakingBalance(chain.id, address, params.address)
        );
      },
    }
  );
};

export default useUnstakeMutation;
