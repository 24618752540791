import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { USDT } from "shared/v2-sdk";
import { useBestTrade } from "shared/v2-sdk/hooks";

export const useTokenToUSDExchangeRate = (
  currency: Currency | undefined,
  currencyAmount: CurrencyAmount<Currency> | undefined
) => {
  const chain = useChain();
  const usdToken = USDT[chain.id];

  const trade = useBestTrade(currency, usdToken, currencyAmount);
  return trade;
};
