import { useQuery } from "@tanstack/react-query";
import { getAddress } from "ethers/lib/utils.js";
import invariant from "tiny-invariant";
import { useAccount } from "wagmi";

import { toCurrencyAmount } from "shared/helpers";
import { useGraphQlSdk } from "shared/providers/graph-ql-client";
import { useChain } from "shared/providers/wagmi";
import { OWN_TOKEN, USDT } from "shared/v2-sdk/constants";

import { ocxKeys } from "./ocxKeys";
import { useOcxStakingBalanceQuery } from "./useOcxStakingBalanceQuery";

export const useOcxStakingInfoQuery = () => {
  const chain = useChain();
  const graphqlSdk = useGraphQlSdk();
  const { address } = useAccount();
  const stakingBalanceQuery = useOcxStakingBalanceQuery();
  const ocxToken = OWN_TOKEN[chain.id];

  return useQuery(
    ocxKeys.stakingInfo(chain.id, address),
    async () => {
      invariant(
        stakingBalanceQuery.data,
        "useOcxStakingInfoQuery. stakingBalanceQuery.data is undefined"
      );
      const response = await graphqlSdk.StakingInfoWithoutSession({
        account: address?.toLowerCase(),
      });

      const result = {
        accountData: {
          stakingBalance: stakingBalanceQuery.data,
          rewardAvailable: toCurrencyAmount(
            ocxToken,
            response.stakingInfo.accountData?.rewardAvailable
          ),
          rewardClaimed: toCurrencyAmount(
            ocxToken,
            response.stakingInfo.accountData?.rewardClaimed
          ),
          rewardTotal: toCurrencyAmount(
            ocxToken,
            response.stakingInfo.accountData?.rewardTotal
          ),
          rewardTotalUSD: toCurrencyAmount(
            USDT[chain.id],
            response.stakingInfo.accountData?.rewardTotal
          ),
        },
        commonData: {
          address: getAddress(response.stakingInfo.commonData.address),
          apy: response.stakingInfo.commonData.apy,
          rewardsPerSeconds:
            response.stakingInfo.commonData.currentEpoch?.rewardsPerSecond ||
            "0",
          totalStakedCA: toCurrencyAmount(
            ocxToken,
            response.stakingInfo.commonData.totalStaked
          ),
          tvl: response.stakingInfo.commonData.totalStakedUSD,
        },
      };

      return result;
    },
    { enabled: Boolean(address && stakingBalanceQuery.isSuccess) }
  );
};
