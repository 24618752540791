import React, { cloneElement, isValidElement } from "react";
import { jsx } from "@emotion/react";

const emotionClone = (
  child: React.ReactNode,
  extraProps: Record<string, unknown> = {},
  ref?: React.LegacyRef<HTMLElement>
) => {
  if (!isValidElement(child)) return child;

  if (child.props.css) {
    return cloneElement(child, {
      ref,
      ...child.props,
      ...extraProps,
      css: [extraProps.css, child.props.css].filter(Boolean),
    });
  }

  return jsx(child.type, {
    key: child.key,
    ref,
    ...child.props,
    ...extraProps,
    className: `${extraProps.className ?? ""} ${child.props.className ?? ""}`,
    css: extraProps.css,
  });
};

export default emotionClone;
