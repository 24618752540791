import React from "react";
import { ToastContainer as RTToastContainer } from "react-toastify";

import { Icon } from "../icon";

import { toastContainerCss } from "./styles";

export const ToastContainer = () => {
  return (
    <RTToastContainer
      css={toastContainerCss}
      className="max-md:!bottom-0 max-md:top-auto"
      closeButton={({ closeToast }) => (
        <button
          className="absolute right-0 top-0 p-5 text-dodgerBlue transition-colors hover:text-malibu active:text-malibu"
          onClick={closeToast}
        >
          <Icon className="h-[18px] w-[18px]" name="close" />
        </button>
      )}
    />
  );
};
