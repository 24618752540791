import { useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getAddress } from "ethers/lib/utils";
import invariant from "tiny-invariant";

import { FormWrapper } from "pages/ui";
import { useMiningPanelsQuery } from "shared/api/farm";
import { Icon } from "shared/ui/icon";
import { Modal } from "shared/ui/modal";
import { Spinner } from "shared/ui/spinner";
import { TabType, TerminalTabs } from "shared/ui/terminal-tabs";
import { TransactionSettingsModal } from "widgets/settings-modal";

import { FarmOutletContext } from "../types";

type LocationState = {
  from: string | undefined;
};

const tabs = [
  {
    title: "Stake",
    route: "farm/stake",
    render: () => <div>Stake</div>,
  },
  {
    title: "Unstake",
    route: "farm/unstake",
    render: () => <div>Unstake</div>,
  },
  {
    title: "Claim",
    route: "farm/claim",
    render: () => <div>Claim</div>,
  },
] as TabType[];

export const FarmWrapper = () => {
  const location = useLocation();
  const { from } = (location.state as LocationState) || { from: "" };
  const navigate = useNavigate();
  const { address } = useParams();

  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const miningListQuery = useMiningPanelsQuery({
    select: (miningPanels) => {
      invariant(address, "FarmWrapper. address is undefined");
      return miningPanels.find(
        (miningPanel) => getAddress(miningPanel.address) === getAddress(address)
      );
    },
  });

  if (!address || (miningListQuery.isSuccess && !miningListQuery.data))
    return <Navigate to="/farms" />;

  const onBack = () => {
    if (!from) {
      navigate("/farms");
      return;
    }

    if (from?.includes("/pool/remove")) {
      return navigate(-1);
    }
  };

  const activeTab =
    tabs.find((tab) => location.pathname.includes(tab.route)) ?? tabs[0];

  return (
    <>
      <div className="absolute left-0 top-[70%] flex h-1/2 w-1/4 -translate-y-1/2 bg-farmMonkey bg-contain bg-no-repeat" />
      <FormWrapper>
        <>
          <Modal.Header
            className="mb-8"
            headerClassName="max-sm:flex-wrap max-sm:gap-y-3"
            id="swap-settings-modal-header"
            iconName="settings"
            onIconClick={() => {
              setSettingsOpen(true);
            }}
          >
            <span id="back-arrow" onClick={onBack} className="cursor-pointer">
              <Icon
                name="back"
                size="20"
                className="flex flex-col justify-center"
              />
            </span>
            <div className="ml-6 mr-3 text-[1.375rem] leading-[1.6rem] text-black">
              Farm
            </div>
            <TerminalTabs
              className="ml-auto mr-12 max-sm:ml-0"
              onClick={(tab) => {
                navigate(`/${tab.route}/${address}`);
              }}
              activeTab={activeTab.route}
              tabs={tabs}
            />
          </Modal.Header>

          {miningListQuery.isSuccess && miningListQuery.data ? (
            <Outlet
              context={
                {
                  miningPanel: miningListQuery.data,
                } as FarmOutletContext
              }
            />
          ) : (
            <div className="flex justify-center">
              <Spinner className="mx-auto" size="66" />
            </div>
          )}
        </>
      </FormWrapper>

      <TransactionSettingsModal
        open={isSettingsOpen}
        onClose={() => {
          setSettingsOpen(false);
        }}
      />
    </>
  );
};
