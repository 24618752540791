import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAccount } from "wagmi";

import { useGraphQlSdk } from "shared/providers/graph-ql-client";

import { poolKeys } from "./keys";
import { LiquidityMiningListItem } from "./types";

const useLiquidityMiningListQuery = <TData = LiquidityMiningListItem[]>(
  options?: Omit<
    UseQueryOptions<
      LiquidityMiningListItem[],
      Error,
      TData,
      ReturnType<typeof poolKeys.miningList>
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const graphqlSdk = useGraphQlSdk();
  const { address } = useAccount();

  return useQuery(
    poolKeys.miningList(address, graphqlSdk),
    async () => {
      const response = await graphqlSdk.GetLiquidityMiningList({
        account: address,
      });

      const result = response.getLiquidityMiningList.list;
      return result;
    },
    {
      refetchInterval: 5000,
      // enabled: Boolean(address),
      ...options,
    }
  );
};

export default useLiquidityMiningListQuery;
