import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccount, useProvider, useSigner } from "wagmi";

import { ERC20__factory } from "shared/abi/types";
import { getPairTokenDisplayData } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, MaxUint256Bn, Token } from "shared/sdk-core";

import { useTxWaitMutation } from "../transtaction";

import { tokenKeys } from "./keys";

type Params = {
  token: Token;
  currencyAmount?: CurrencyAmount<Token>;
  spenderAddress: string;
};

export const useTokenApproveMutation = () => {
  const txWaitMutation = useTxWaitMutation();
  const chain = useChain();

  const queryClient = useQueryClient();
  const provider = useProvider({ chainId: chain.id });
  const signer = useSigner();
  const { address } = useAccount();

  return useMutation(
    async ({ token, currencyAmount, spenderAddress }: Params) => {
      if (!signer) return;
      const tokenContract = ERC20__factory.connect(
        token.address,
        signer.data || provider
      );
      const stringifiedValue =
        currencyAmount?.quotient.toString() ?? MaxUint256Bn;

      const estimatedGas = await tokenContract.estimateGas.approve(
        spenderAddress,
        stringifiedValue
      );

      const tx = await tokenContract.approve(spenderAddress, stringifiedValue, {
        gasLimit: estimatedGas.mul(120).div(100),
      });

      const displayData = token
        ? getPairTokenDisplayData(token, chain.id)
        : undefined;

      const receipt = await txWaitMutation.mutateAsync({
        tx,
        desc: `Approve ${displayData?.symbol} spending`,
      });

      return receipt;
    },
    {
      onSuccess: (_, { token, spenderAddress }) => {
        queryClient.invalidateQueries(
          tokenKeys.allowance(token.address, address, spenderAddress)
        );
      },
    }
  );
};
