import React from "react";

import { Logo } from "shared/ui/logo";

export const PageSpinner = () => {
  return (
    <div className="bg-#2C3944 fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center">
      <Logo />
    </div>
  );
};
