import { parseUnits, toDecimal } from "utils/numbers";

import { DetailedPair } from "shared/api/pool";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { InsufficientInputAmountError } from "shared/v2-sdk";

import { useCurrenciesTrades } from "./useCurrenciesTrades";

type Params = {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  currencyAmountIn: CurrencyAmount<Currency> | undefined;
  currencyAmountOut: CurrencyAmount<Currency> | undefined;
  detailedPair: DetailedPair | undefined;
};

export type AddLiquidityDetails = ReturnType<typeof useAddLiquidityDetails>;

export const useAddLiquidityDetails = ({
  currencyA,
  currencyB,
  currencyAmountIn,
  currencyAmountOut,
  detailedPair,
}: Params) => {
  const balanceQuery = useCurrencyBalanceQuery(
    detailedPair?.pair.liquidityToken
  );

  const currenciesTrades = useCurrenciesTrades({
    currencyA,
    currencyB,
    currencyAmountIn,
    currencyAmountOut,
  });

  const totalSupply = detailedPair?.advancedInfo.totalSupply;
  const hasPool = Boolean(detailedPair?.pair.liquidityToken);

  let liquidityMinted = undefined;

  if (!hasPool && currencyAmountIn && currencyAmountOut) {
    liquidityMinted = toDecimal(
      parseUnits(
        currencyAmountIn.toFixed(),
        currencyA?.decimals ?? 0
      ).toString()
    )
      .mul(
        toDecimal(
          parseUnits(
            currencyAmountOut.toFixed(),
            currencyB?.decimals ?? 0
          ).toString()
        )
      )
      .sqrt()
      .div(10 ** 18)
      .toFixed();
  } else {
    try {
      liquidityMinted =
        currencyAmountIn && currencyAmountOut && detailedPair && totalSupply
          ? detailedPair.pair
              .getLiquidityMinted(
                totalSupply.totalSupplyCA,
                currencyAmountIn.wrapped,
                currencyAmountOut.wrapped
              )
              .toSignificant(6)
          : undefined;
    } catch (error) {
      if (error instanceof InsufficientInputAmountError) {
      } else {
        console.error(error);
      }
    }
  }

  const poolShare =
    balanceQuery.data && totalSupply
      ? balanceQuery.data?.balanceD
          .plus(toDecimal(liquidityMinted))
          .div(totalSupply.totalSupplyD)
      : toDecimal(0);

  return {
    poolShare,
    liquidityMinted,
    ...currenciesTrades,
  };
};
