import { css } from "twin.macro";

export type Size = "36" | "18";

export const wrapper = (countItems: number, size: Size) => css`
  position: relative;
  overflow: hidden;
  margin-right: calc(-${size}px * 0.33333 * ${countItems - 1});
`;

export const imageStackCss = (countItems: number, size: Size) => css`
  display: grid;
  grid-template-columns: repeat(${countItems}, minmax(0, ${size}px));
  grid-template-rows: ${size}px;
`;

export const imageCss = (idx: number, size: Size) => css`
  width: ${size}px;
  height: ${size}px;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-end: 2;
  z-index: calc(5 - ${idx});
  margin-left: calc(${size}px * 0.666666 * ${idx});
`;
