import { useMemo } from "react";

import { useDetailedPairsQuery } from "shared/api/pool";
import { Currency, CurrencyAmount, TradeType } from "shared/sdk-core";

import { Pair, Trade } from "../entities";

export type TradeTypes =
  | Trade<Currency, Currency, TradeType.EXACT_INPUT>
  | Trade<Currency, Currency, TradeType.EXACT_OUTPUT>;

type TradeResult = {
  status: "noRouteFound" | "success";
  trade: TradeTypes | undefined;
};

export const useBestTrade = (
  input: Currency | undefined,
  output: Currency | undefined,
  currencyAmountIn: CurrencyAmount<Currency> | undefined,
  tradeType: TradeType = TradeType.EXACT_INPUT,
  currencyAmountOut?: CurrencyAmount<Currency> | undefined
): TradeResult => {
  const pairsQuery = useDetailedPairsQuery({
    select: (data) => data.map(({ pair }) => pair),
  });

  return useMemo(() => {
    return bestTrade(
      pairsQuery.data,
      input,
      output,
      currencyAmountIn,
      tradeType,
      currencyAmountOut
    );
  }, [
    pairsQuery.data,
    currencyAmountIn,
    currencyAmountOut,
    input,
    output,
    tradeType,
  ]);
};

export const bestTrade = (
  pairs: Pair[] = [],
  input: Currency | undefined,
  output: Currency | undefined,
  currencyAmountIn: CurrencyAmount<Currency> | undefined,
  tradeType: TradeType = TradeType.EXACT_INPUT,
  currencyAmountOut?: CurrencyAmount<Currency> | undefined
) => {
  if (!pairs || pairs.length === 0 || !input || !output)
    return {
      status: "noRouteFound" as const,
      trade: undefined,
    };

  let trade:
    | Trade<Currency, Currency, TradeType.EXACT_INPUT>
    | Trade<Currency, Currency, TradeType.EXACT_OUTPUT>
    | undefined = undefined;

  if (currencyAmountIn && tradeType === TradeType.EXACT_INPUT) {
    [trade] = Trade.bestTradeExactIn(pairs, currencyAmountIn, output, {
      maxNumResults: 1,
    });
  }

  if (currencyAmountOut && tradeType === TradeType.EXACT_OUTPUT) {
    [trade] = Trade.bestTradeExactOut(pairs, input, currencyAmountOut, {
      maxNumResults: 1,
    });
  }

  return {
    trade,
    status: trade ? ("success" as const) : ("noRouteFound" as const),
  };
};

export const trade = (
  pairs: Pair[] = [],
  input: Currency | undefined,
  output: Currency | undefined,
  currencyAmountIn: CurrencyAmount<Currency> | undefined,
  tradeType: TradeType = TradeType.EXACT_INPUT,
  currencyAmountOut?: CurrencyAmount<Currency> | undefined
) => {
  if (!pairs || pairs.length === 0 || !input || !output)
    return {
      status: "noRouteFound" as const,
      trade: undefined,
    };

  let trade:
    | Trade<Currency, Currency, TradeType.EXACT_INPUT>
    | Trade<Currency, Currency, TradeType.EXACT_OUTPUT>
    | undefined = undefined;

  if (currencyAmountIn && tradeType === TradeType.EXACT_INPUT) {
    [trade] = Trade.bestTradeExactIn(pairs, currencyAmountIn, output, {
      maxNumResults: 1,
    });
  }

  if (currencyAmountOut && tradeType === TradeType.EXACT_OUTPUT) {
    [trade] = Trade.bestTradeExactOut(pairs, input, currencyAmountOut, {
      maxNumResults: 1,
    });
  }

  return {
    trade,
    status: trade ? ("success" as const) : ("noRouteFound" as const),
  };
};
