import { useCustomCurrencies } from "entities/currency";
import { Token } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Modal } from "shared/ui/modal";
import { notify } from "shared/ui/toast";

import Preview from "./preview";

type Props = {
  open: boolean;
  token: Token;
  onClose: () => void;
  onAdd?: () => void;
};

export const AddModal = ({ token, open, onClose, onAdd }: Props) => {
  const [_, addCustomToken] = useCustomCurrencies();

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body className="w-[500px]">
        <Modal.Header onIconClick={onClose} iconName="close">
          Unknown Token Import
        </Modal.Header>
        <Icon
          name="warning"
          className="mx-auto mb-10 mt-10 inline-flex h-16 w-16 text-dodgerBlue"
        />
        <p className="text-center text-osloGray">
          This token doesn't appear on the token list. Make sure this is the
          token that you want to trade. Anyone can create a token with any name,
          including creating fake versions of existing tokens and tokens that
          claim to represent projects that do not have a token.
        </p>
        <Preview className="my-8" token={token} />
        <Button
          className="w-full"
          size="66"
          onClick={() => {
            addCustomToken(token);
            onAdd?.();
            notify.success(`Token ${token.symbol} has been added`);
          }}
        >
          Add Token and Continue
        </Button>
      </Modal.Body>
    </Modal>
  );
};
