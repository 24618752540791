import { Token } from "shared/sdk-core";

import { ApiToken } from "../types";

export const serializeToken = (token: Token): ApiToken => ({
  chainId: token.chainId,
  address: token.address,
  decimals: token.decimals,
  symbol: token.symbol ?? "",
  name: token.name ?? "",
  logoURI: token.logoUrl ?? "",
});

export const serializeTokens = (tokens: Token[]): ApiToken[] => {
  return tokens.map((t) => serializeToken(t));
};
