import React from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";
import { Dropdown, DropdownItem } from "shared/ui/dropdown";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";
import { Text } from "shared/ui/text";

import { EXTERNAL_LINKS } from "../constants";

const Menu = ({ className }: Omit<EmotionCSSProps, "css">) => {
  return (
    <Dropdown
      placement="bottom-end"
      button={
        <button
          className={cn(
            "hover:text-redMalibu p-1 text-dodgerBlue outline-none transition-colors active:text-malibu",
            className
          )}
        >
          <Icon name="menu" size="22" />
        </button>
      }
      dropdownClassName="w-[200px] mt-2"
    >
      {EXTERNAL_LINKS.map((menuItem) => {
        return (
          <DropdownItem key={menuItem.href}>
            <Link
              className="w-full justify-between text-black"
              iconClass="text-dodgerBlue"
              showIcon
              to={menuItem.href}
            >
              <Text className="inline-flex text-black">{menuItem.label}</Text>
            </Link>
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};

export default Menu;
