import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { getAddress } from "ethers/lib/utils";
import invariant from "tiny-invariant";
import { toDecimal } from "utils/numbers";
import { useAccount, useProvider } from "wagmi";

import { useCurrencies } from "entities/currency";
import { toCurrencyAmount } from "shared/helpers";
import { useGraphQlSdk } from "shared/providers/graph-ql-client";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";

import { DetailedPair, useDetailedPairsQuery } from "../pool";
import { poolKeys } from "../pool/keys";

export type MiningPanel = {
  apy: string | null | undefined;
  rewardsPerSecond: string | undefined;
  address: string;
  totalStakedLT: string;
  totalStakedUSD: string;
  detailedPair: DetailedPair;
  rewardsAvailable: string | undefined;
  rewardsClaimed: string | undefined;
  rewardsToken: Token | undefined;
  rewardsTotal: string | undefined;
  stakingBalance: {
    formatted: string;
    balanceD: Decimal;
    balanceCA: CurrencyAmount<Token> | undefined;
  };
};

export const useMiningPanelsQuery = <TData = MiningPanel[]>(
  options?: Omit<
    UseQueryOptions<
      MiningPanel[],
      Error,
      TData,
      ReturnType<typeof poolKeys.miningPanels>
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const chain = useChain();
  const { address } = useAccount();
  const currencies = useCurrencies();
  const graphqlSdk = useGraphQlSdk();
  const provider = useProvider({ chainId: chain.id });

  const detailedPairsQuery = useDetailedPairsQuery();

  const query = useQuery(
    poolKeys.miningPanels(chain.id, address),
    async () => {
      const detailedPairs = detailedPairsQuery.data;
      invariant(
        detailedPairs,
        "useMiningPanelsQuery. detailedPairs is undefined"
      );
      invariant(provider, "useMiningPanelsQuery. provider is undefined");

      const { getLiquidityMiningList } =
        await graphqlSdk.GetLiquidityMiningList({ account: address });

      const miningList = getLiquidityMiningList.list.map((miningPanel) => {
        const poolAddress = getAddress(miningPanel.commonData.poolAddress);
        const detailedPair = detailedPairs.find(
          (detailedPair) =>
            getAddress(detailedPair.pair.liquidityToken.address) === poolAddress
        );

        if (!detailedPair) {
          console.log("Check this miningPanel", {
            miningPanel,
            poolAddr: miningPanel.commonData.poolAddress.toLowerCase(),
            detailedPairs: detailedPairs.map((p) =>
              p.pair.liquidityToken.address.toLowerCase()
            ),
          });
          return null;
        }

        const rewardsToken = currencies.find((currency) => {
          return currency.isNative || !miningPanel.accountData
            ? false
            : getAddress(currency.address) ===
                getAddress(miningPanel.accountData.rewardsToken.contract);
        }) as Token | undefined;

        const balanceD = toDecimal(miningPanel.accountData?.stakingBalance);

        const stakingBalanceInfo = {
          formatted: balanceD
            .toSignificantDigits(6, Decimal.ROUND_DOWN)
            .toFixed(),
          balanceD: toDecimal(miningPanel.accountData?.stakingBalance),
          balanceCA: toCurrencyAmount(
            detailedPair?.pair.liquidityToken,
            miningPanel.accountData?.stakingBalance
          ),
        };

        const result = {
          apy: miningPanel.commonData.apy,
          rewardsPerSecond:
            miningPanel.commonData.currentEpoch?.rewardsPerSecond,
          address: getAddress(miningPanel.commonData.address),
          totalStakedLT: miningPanel.commonData.totalStakedLT,
          totalStakedUSD: miningPanel.commonData.totalStakedUSD,
          detailedPair,
          rewardsAvailable: miningPanel.accountData?.rewardsAvailable,
          rewardsClaimed: miningPanel.accountData?.rewardsClaimed,
          rewardsToken,
          rewardsTotal: miningPanel.accountData?.rewardsTotal,
          stakingBalance: stakingBalanceInfo,
        };

        return result as MiningPanel;
      });
      return miningList.filter(Boolean);
    },
    {
      staleTime: 1000 * 60,
      enabled: Boolean(provider && detailedPairsQuery.isSuccess),
      ...options,
    }
  );

  return query;
};
