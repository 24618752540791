export const farmsKeys = {
  all: ["farms"],
  balances: (chain: number) => [...farmsKeys.all, "balances", chain],
  stakingBalance: (
    chain: number,
    userAddress: string | undefined,
    stakingContractAddress: string | undefined
  ) => [
    ...farmsKeys.all,
    ...farmsKeys.balances(chain),
    userAddress,
    stakingContractAddress,
  ],
  estimateStake: (
    chain: number,
    userAddress: string | undefined,
    stakingContractAddress: string | undefined
  ) => [
    ...farmsKeys.all,
    "estimateStake",
    chain,
    userAddress,
    stakingContractAddress,
  ],
  estimateUnstake: (
    chain: number,
    userAddress: string | undefined,
    stakingContractAddress: string | undefined
  ) => [
    ...farmsKeys.all,
    "estimateUnstake",
    chain,
    userAddress,
    stakingContractAddress,
  ],
  estimateClaim: (
    chain: number,
    userAddress: string | undefined,
    stakingContractAddress: string | undefined
  ) => [
    ...farmsKeys.all,
    "estimateClaim",
    chain,
    userAddress,
    stakingContractAddress,
  ],
};
