import { Address, useToken } from "wagmi";

import { useCurrencyBalanceQuery } from "shared/api/token";
import { useChain } from "shared/providers/wagmi";
import { Token } from "shared/sdk-core";

type Options = {
  enabled?: boolean;
};

export const useCustomTokenQuery = (
  address: Address | undefined,
  options: Options = {}
) => {
  const chain = useChain();
  const customTokenQuery = useToken({
    chainId: chain.id,
    address,
    ...options,
  });

  const customToken =
    customTokenQuery.isSuccess && customTokenQuery.data
      ? new Token(
          chain.id,
          customTokenQuery.data.address,
          customTokenQuery.data.decimals,
          customTokenQuery.data.symbol,
          customTokenQuery.data.name
        )
      : undefined;

  const customTokenBalanceQuery = useCurrencyBalanceQuery(customToken);

  return {
    isFetching:
      customTokenBalanceQuery.isFetching || customTokenQuery.isFetching,
    isLoading: customTokenBalanceQuery.isLoading || customTokenQuery.isLoading,
    isSuccess: customTokenBalanceQuery.isSuccess && customTokenQuery.isSuccess,
    isError: customTokenBalanceQuery.isError || customTokenQuery.isError,
    errors: [customTokenQuery.error, customTokenBalanceQuery.error],
    data:
      customToken && customTokenBalanceQuery
        ? {
            token: customToken,
            balance: customTokenBalanceQuery.data,
          }
        : undefined,
  };
};
