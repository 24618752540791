import { ReactNode } from "react";
import { useMutation } from "@tanstack/react-query";
import { ContractTransaction } from "ethers";

import { TransactionToast } from "entities/transaction";
import { useTxHistory } from "shared/providers/TxHistory";
import { useChain } from "shared/providers/wagmi";
import { notify } from "shared/ui/toast";
import { EXPLORER_ADDRESS_MAP } from "shared/v2-sdk/constants";

type Tx = {
  tx: ContractTransaction | any;
  desc: string;
  renderPending?: ReactNode;
  renderSuccess?: ReactNode;
  renderError?: ReactNode;
};

export const useTxWaitMutation = () => {
  const chain = useChain();
  const { updateRef, pushRef } = useTxHistory();

  return useMutation(
    async ({ tx, ...options }: Tx) => {
      const promise = tx.wait();
      const explorerLink = `${EXPLORER_ADDRESS_MAP[chain.id]}/tx/${tx.hash}`;

      notify.promise(promise, {
        pending: {
          render: options?.renderPending ?? (
            <TransactionToast
              type="pending"
              desc={options.desc}
              externalUrl={explorerLink}
            />
          ),
        },
        success: {
          render: options?.renderSuccess ?? (
            <TransactionToast
              type="success"
              desc={options.desc}
              externalUrl={explorerLink}
            />
          ),
        },
        error: {
          render: options?.renderError ?? (
            <TransactionToast
              type="error"
              desc={options.desc}
              externalUrl={explorerLink}
            />
          ),
        },
      });

      const receipt = await promise;
      return receipt;
    },
    {
      onMutate: ({ tx, desc }) => {
        const { state, item } = pushRef.current({
          hash: tx.hash,
          text: desc,
          status: "pending",
        });

        return { txItem: item, txItems: state };
      },
      onSuccess: (data, _params, ctx) => {
        return (
          ctx &&
          updateRef.current({
            ...ctx.txItem,
            hash: data.transactionHash,
            text: ctx.txItem.text,
            status: "success",
          })
        );
      },
      onError: (error, _params, ctx) => {
        console.dir(error);
        ctx &&
          updateRef.current({
            ...ctx.txItem,
            hash: ctx.txItem.hash,
            status: "error",
          });
      },
    }
  );
};
