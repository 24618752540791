import { BigNumber } from "ethers";
import { toDecimal } from "utils/numbers";

import { Native } from "shared/v2-sdk";

import { calculateGasMargin } from "./calculateGasMargin";
import { toCurrencyAmount } from "./toCurrencyAmount";

export const calculateFee = (
  gasPrice: BigNumber | null,
  estimatedGas: BigNumber,
  chainId: number
) => {
  const native = Native.byChainId(chainId);
  const gasLimit = calculateGasMargin(estimatedGas);

  const gasPriceD = toDecimal(gasPrice?.toString()).div(toDecimal(10).pow(9));
  const gasLimitD = toDecimal(gasLimit.toString()).div(toDecimal(10).pow(9));

  const feeCA = toCurrencyAmount(
    native,
    gasPrice?.mul(gasLimit.toString()).toString()
  );
  const feeD = gasPriceD.mul(gasLimitD);

  return {
    feeD: feeD,
    feeCA: feeCA.divide(1000000000).divide(1000000000),
    gasLimitD,
    gasPriceD,
    gasLimit,
    gasPrice,
  };
};

export type Fee = ReturnType<typeof calculateFee>;
