import cn from "classnames";

import { SelectNetwork } from "features/select-network";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { Dropdown, DropdownItem } from "shared/ui/dropdown";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";
import { Logo } from "shared/ui/logo";
import { Text } from "shared/ui/text";
import { BRIDGES_LINKS } from "shared/v2-sdk/constants";

import { ConnectButton } from "./ConnectButton";
import { HeaderLink } from "./HeaderLink";
import Menu from "./Menu";
import { MobileMenu } from "./MobileMenu";
import { useHeaderLinks } from "./use-header-links";

export const Header = () => {
  const chain = useChain();
  const links = useHeaderLinks();
  const bridgesLinks = BRIDGES_LINKS[chain.id];

  return (
    <header className="relative mx-auto flex w-full items-center justify-between px-20 py-5 max-2xl:px-3 max-xl:px-0 max-sm:py-4">
      <Logo />
      <div className="ml-6 mr-3 max-xl:hidden">
        <ul className="relative flex gap-x-3 max-2xl:gap-x-2">
          {links.map((link, idx) => {
            return (
              <li key={link.to}>
                <HeaderLink idx={idx} {...link} />
              </li>
            );
          })}

          <li className="">
            <Dropdown
              dropdownClassName="mt-2 z-50"
              placement="bottom-start"
              button={
                <div>
                  <Button
                    id={`header-button-bridge`}
                    iconName="exit"
                    // iconClassName="text-dodgerBlue"
                    theme={"lightDodgerBlue"}
                    className={cn(
                      `w-[114px] max-lg:w-auto`,
                      "pointer-events-none text-dodgerBlue",
                      "pointer-events-auto !border-transparent opacity-75 hover:opacity-75",
                      "light-dodger-blue-hover-shadow-hover"
                    )}
                  >
                    Bridge
                    <Icon className="ml-2" name="chevronDown" size="14" />
                  </Button>
                </div>
              }
            >
              {bridgesLinks.map((bridge) => {
                return (
                  <DropdownItem key={bridge.link}>
                    <Link
                      className="w-full justify-between text-black"
                      iconClass="text-dodgerBlue"
                      showIcon
                      to={bridge.link}
                    >
                      <Text className="inline-flex text-black">
                        {bridge.label}
                      </Text>
                    </Link>
                  </DropdownItem>
                );
              })}
            </Dropdown>
          </li>
        </ul>
      </div>

      <SelectNetwork className="ml-auto mr-3 flex-shrink-0" />
      <ConnectButton className="mr-3" />
      <div className="ml-3 hidden max-xl:flex">
        <MobileMenu />
      </div>
      <div className="flex max-2xl:ml-0 max-xl:hidden">
        <Menu />
      </div>
    </header>
  );
};
