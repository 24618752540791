import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useLocalStorage } from "react-use";
import invariant from "tiny-invariant";

import { ApiToken, deserializeTokens, serializeToken } from "shared/api/token";
import { LS_KEYS } from "shared/constants";
import { useChain } from "shared/providers/wagmi";
import { Currency, Token } from "shared/sdk-core";

const CustomCurrenciesContext = createContext<
  | [
      Currency[],
      (token: Token) => void,
      React.Dispatch<React.SetStateAction<ApiToken[] | undefined>>
    ]
  | undefined
>(undefined);

export const CustomCurrenciesProvider = ({ children }: PropsWithChildren) => {
  const chain = useChain();
  const [customTokensList, setState] = useLocalStorage<ApiToken[]>(
    LS_KEYS.customTokens(chain.id),
    []
  );

  const addCustomToken = useCallback(
    (token: Token) => {
      const serializedToken = serializeToken(token);

      setState(
        customTokensList
          ? [...customTokensList, serializedToken]
          : [serializedToken]
      );
    },
    [customTokensList, setState]
  );

  const contextValue = useMemo(() => {
    return [
      deserializeTokens(customTokensList ?? []) as Currency[],
      addCustomToken,
      setState,
    ] as [Currency[], typeof addCustomToken, typeof setState];
  }, [customTokensList, addCustomToken, setState]);

  return (
    <CustomCurrenciesContext.Provider value={contextValue}>
      {children}
    </CustomCurrenciesContext.Provider>
  );
};

export const useCustomCurrencies = () => {
  const context = useContext(CustomCurrenciesContext);
  invariant(
    context,
    "useCustomCurrencies must have be within CustomCurrencyProvider"
  );

  return context;
};
