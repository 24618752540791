import React, { PropsWithChildren } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

type Props = EmotionCSSProps;

export const Footer = ({
  className,
  css: cssProp,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div css={cssProp} className={cn("mt-auto flex pt-8", className)}>
      {children}
    </div>
  );
};
