import { Token } from "shared/sdk-core";
import { Pair } from "shared/v2-sdk";

export const getPairAddress = (
  tokenA: Token | undefined,
  tokenB: Token | undefined
) => {
  try {
    return tokenA && tokenB && !tokenA.equals(tokenB)
      ? Pair.getAddress(tokenA, tokenB)
      : undefined;
  } catch (error: any) {
    console.error(
      error.msg,
      `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
      `chainId: ${tokenA?.chainId}`
    );

    return undefined;
  }
};
