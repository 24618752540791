import { PropsWithChildren, ReactNode } from "react";
import cn from "classnames";

import { Button } from "shared/ui/button";
import { Icon, IconName } from "shared/ui/icon";
import { Modal } from "shared/ui/modal";
import { Spinner } from "shared/ui/spinner";

type Props = {
  open: boolean;
  desc?: ReactNode;
  status: "pending" | "error" | "submitted";
  onClose: () => void;
};

const getHeader = (status: Props["status"]) => {
  if (status === "error") return "Transaction Failed";
  if (status === "pending") return "Waiting for Confirmation";
  if (status === "submitted") return "Transaction Submitted";
};
export const TxModal = ({
  open,
  status,
  desc,
  children,
  onClose,
}: PropsWithChildren<Props>) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <Modal.Header className="text-xl" onIconClick={onClose}>
          {getHeader(status)}
        </Modal.Header>
        <div className="flex flex-col items-center">
          <div className="mt-10 flex w-full items-center justify-center">
            {status === "pending" ? (
              <Spinner size="66" />
            ) : (
              <Icon
                className={cn(
                  status === "error" && "text-redOrange",
                  status === "submitted" && "text-dodgerBlue"
                )}
                name={getIconName(status)}
                size="66"
              />
            )}
          </div>
          <span className="mt-8 flex">{desc}</span>

          {status === "pending" && (
            <span className="mt-8 flex text-osloGray">
              Confirm this transaction in your wallet.
            </span>
          )}
          {status === "error" && (
            <>
              <span className="mt-8 flex text-osloGray">
                Something went wrong.
              </span>
              <Button className="mt-8 w-full" size="66" onClick={onClose}>
                Close
              </Button>
            </>
          )}
          {status === "submitted" && (
            <>
              <span className="mt-8 flex text-osloGray">
                Transaction is yet to be finalised by the blockchain.
              </span>
              {/* <Link to="#">View on Algoscan</Link> */}
            </>
          )}
          {children}
        </div>
      </Modal.Body>
    </Modal>
  );
};

function getIconName(status: Exclude<Props["status"], "pending">): IconName {
  if (status === "error") return "fail";
  return "submitted";
}
