import { PropsWithChildren, useLayoutEffect } from "react";
import { motion } from "framer-motion";

import { FormWrapper } from "pages/ui";

import monk from "./monk.png";

let animate = true;

export const Animations = ({ children }: PropsWithChildren) => {
  useLayoutEffect(() => {
    animate = false;
  }, []);

  return (
    <>
      <motion.img
        className="pointer-events-none absolute bottom-0 left-[60%] flex h-[407px] min-w-[382px] -translate-x-1/2 max-xl:left-[50%]  max-xl:w-1/3 max-lg:hidden"
        initial={animate ? { opacity: 0 } : { opacity: 1, x: "25%" }}
        animate={{
          transition: { duration: 0.7, delay: 0.3 },
          opacity: 1,
          x: "25%",
        }}
        exit={{ opacity: 0 }}
        src={monk}
        alt="monk"
      />
      <FormWrapper>{children}</FormWrapper>
    </>
  );
};
