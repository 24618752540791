import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ethers } from "ethers";
import invariant from "tiny-invariant";
import { toDecimal } from "utils/numbers";
import { useAccount, useProvider, useSigner } from "wagmi";

import { RemoveLiquidityDetails } from "pages/pool/hooks";
import {
  HumanodeRouter__factory,
  MilkomedaRouter__factory,
} from "shared/abi/types";
import { toCurrencyAmount } from "shared/helpers";
import { isMilkomedaRouter } from "shared/helpers/is-router";
import { useTxSettings } from "shared/providers/TxSettings";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency, CurrencyAmount, Token } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

import { tokenKeys } from "../token/keys";
import { useTxMutation, useTxWaitMutation } from "../transtaction";

import {
  estimateRemoveLiquidityFee,
  RemoveLiquidityNativeParams,
  RemoveLiquidityTokenParams,
} from "./estimateRemoveLiquidityFee";
import { poolKeys } from "./keys";

type Params = {
  currencyA: Currency;
  currencyB: Currency;
  details: RemoveLiquidityDetails;
  ltAmount: CurrencyAmount<Token>;
};

export const useRemoveLiquidityMutation = () => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const signerQuery = useSigner();
  const { address } = useAccount();
  const [txSettings] = useTxSettings();
  const queryClient = useQueryClient();

  const txMutation = useTxMutation();
  const txWaitMutation = useTxWaitMutation();

  const isHumanode = useIsHumanode();

  return useMutation(
    async ({ currencyA, currencyB, details, ltAmount }: Params) => {
      invariant(
        details.liquidityValueA,
        "useRemoveLiquidityMutation. details.liquidityValueA is undefined"
      );
      invariant(
        details.liquidityValueB,
        "useRemoveLiquidityMutation. details.liquidityValueB is undefined"
      );
      invariant(address, "useRemoveLiquidityMutation. address is undefined");
      invariant(
        signerQuery.data,
        "useRemoveLiquidityMutation. signerQuery.data is undefined"
      );

      const routerFactory = isHumanode
        ? MilkomedaRouter__factory
        : HumanodeRouter__factory;

      const contractRouter = routerFactory.connect(
        ROUTER_ADDRESS_MAP[chain.id],
        signerQuery.data || provider
      );

      const native =
        currencyA.isNative || currencyB.isNative
          ? Native.byChainId(chain.id)
          : undefined;

      const deadline =
        Math.floor(Date.now() / 1000) + 60 * txSettings.transactionDeadline;

      let promise;

      if (native && isMilkomedaRouter(contractRouter)) {
        const token = currencyA.isToken ? currencyA : currencyB.wrapped;

        const tokenAmount = details.liquidityValueA.currency.equals(token)
          ? details.liquidityValueA
          : details.liquidityValueB;

        const nativeAmount = details.liquidityValueA.currency.equals(token)
          ? details.liquidityValueB
          : details.liquidityValueA;

        const amountTokenMin = toCurrencyAmount(
          token,
          toDecimal(tokenAmount?.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const amountNativeMin = toCurrencyAmount(
          Native.byChainId(chain.id),
          toDecimal(nativeAmount.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const params: RemoveLiquidityNativeParams = {
          tokenAddress: token.address,
          ltAmount: ltAmount.quotient.toString(),
          amountTokenMin: amountTokenMin.quotient.toString(),
          amountNativeMin: amountNativeMin.quotient.toString(),
          address,
          deadline,
        };

        const fee = await estimateRemoveLiquidityFee({
          contract: contractRouter,
          signer: signerQuery.data,
          params,
          chainId: chain.id,
        });

        promise = contractRouter.removeLiquidityADA(
          params.tokenAddress,
          params.ltAmount,
          params.amountTokenMin,
          params.amountNativeMin,
          params.address,
          params.deadline,
          {
            gasLimit: fee.gasLimit,
          }
        );
      } else {
        const tokenA = currencyA.wrapped;
        const tokenB = currencyB.wrapped;

        const tokenAAmount = details.liquidityValueA?.currency.equals(tokenA)
          ? details.liquidityValueA
          : details.liquidityValueB;

        const tokenBAmount = details.liquidityValueB?.currency.equals(tokenB)
          ? details.liquidityValueB
          : details.liquidityValueA;

        const amountTokenAMin = toCurrencyAmount(
          tokenA,
          toDecimal(tokenAAmount?.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const amountTokenBMin = toCurrencyAmount(
          tokenB,
          toDecimal(tokenBAmount.toSignificant())
            .mul(txSettings.slippageTolerance)
            .div(100)
            .toFixed()
        );

        const params: RemoveLiquidityTokenParams = {
          tokenAAddress: tokenA.address,
          tokenBAddress: tokenB.address,
          ltAmount: ltAmount.quotient.toString(),
          amountTokenAMin: amountTokenAMin.quotient.toString(),
          amountTokenBMin: amountTokenBMin.quotient.toString(),
          address,
          deadline,
        };

        const fee = await estimateRemoveLiquidityFee({
          contract: contractRouter,
          signer: signerQuery.data,
          params,
          chainId: chain.id,
        });

        promise = contractRouter.removeLiquidity(
          params.tokenAAddress,
          params.tokenBAddress,
          params.ltAmount,
          params.amountTokenAMin,
          params.amountTokenBMin,
          params.address,
          params.deadline,
          { gasLimit: fee.gasLimit }
        );
      }

      const desc = `Removal ${details.liquidityValueA.toSignificant(6)} ${
        currencyA.symbol
      } and ${details.liquidityValueB.toSignificant(6)} ${currencyB.symbol}`;
      const tx = await txMutation.mutateAsync({ txPromise: promise, desc });
      const receipt = await txWaitMutation.mutateAsync({ tx, desc });
      return receipt;
    },
    {
      onSuccess: (data, params) => {
        queryClient.refetchQueries(
          poolKeys.totalSupply(chain.id, params.ltAmount.currency.address)
        );
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.currencyA.isToken
              ? params.currencyA.address
              : ethers.constants.AddressZero
          )
        );
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.currencyB.isToken
              ? params.currencyB.address
              : ethers.constants.AddressZero
          )
        );
        queryClient.refetchQueries(
          tokenKeys.balance(chain.id, address, params.ltAmount.currency.address)
        );

        queryClient.invalidateQueries(
          tokenKeys.allowance(
            params.ltAmount.currency.address,
            address,
            ROUTER_ADDRESS_MAP[chain.id]
          )
        );
      },
    }
  );
};
