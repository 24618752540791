import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

import { Footer } from "../Footer";
import { Header } from "../Header";

export const Layout = ({
  className,
  css: cssProp,
}: PropsWithChildren & EmotionCSSProps) => {
  return (
    <div className="flex grow flex-col px-4">
      <div
        className={cn(
          "fixed inset-0 -z-50",
          "bg-swapBg",
          "flex h-full flex-col bg-cover bg-center bg-no-repeat max-sm:px-2",
          className
        )}
        css={[cssProp]}
      />
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};
