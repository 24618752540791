import cn from "classnames";

import { CurrencyEntity } from "entities/currency";
import { getPriceImpactData } from "entities/price";
import { usePrice } from "entities/price/hooks/use-price";
import { useAdvancedDetails } from "pages/swap/hooks";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Modal } from "shared/ui/modal";
import { Native } from "shared/v2-sdk";
import { TradeTypes } from "shared/v2-sdk/hooks";

type Props = {
  trade: TradeTypes;

  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmModal = ({ trade, open, onClose, onConfirm }: Props) => {
  const chain = useChain();

  const [tradePrice] = usePrice(trade.executionPrice);
  const [invertedTradePrice] = usePrice(trade.executionPrice, true);

  const { lpFee, minimalReceivedCA, allowedSlippage, feeCA } =
    useAdvancedDetails(trade);

  const currencyA = trade.inputAmount.currency;
  const currencyB = trade.outputAmount.currency;
  const amountIn = trade.inputAmount.toSignificant();
  const amountOut = trade.outputAmount.toSignificant();

  const priceImpaceData = getPriceImpactData(trade.priceImpact);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body className="min-w-lg">
        <Modal.Header id="confirm-modal-header" onIconClick={onClose}>
          Confirm Swap
        </Modal.Header>
        <div className="mt-8 flex items-center justify-between rounded-3xl bg-black bg-opacity-5 px-4 py-2">
          <div id="amount-in" className="text-[22px]">
            {amountIn}
          </div>
          <CurrencyEntity
            id="confirm-modal-tokenA"
            size="38"
            bordered={false}
            symbol={currencyA.symbol}
            logoUrl={currencyA.logoUrl}
          />
        </div>
        <Icon
          className={"mx-auto my-3 text-dodgerBlue"}
          size="32"
          name="direction"
        />
        <div className="flex items-center justify-between rounded-3xl bg-black bg-opacity-5 px-4 py-2">
          <div id="amount-out" className="text-[22px]">
            {amountOut}
          </div>
          <CurrencyEntity
            id="confirm-modal-tokenB"
            size="38"
            bordered={false}
            symbol={currencyB.symbol}
            logoUrl={currencyB.logoUrl}
          />
        </div>
        <div className="mt-8 grid grid-cols-2">
          <div className="text-osloGray">{currencyA.symbol} Price</div>
          <div className="text-right">
            {tradePrice.usdPrice && (
              <span className="mr-1 text-osloGray">
                ≈ ${tradePrice.usdPrice.toSignificant(6)}
              </span>
            )}
            {tradePrice.formattedPrice} {currencyB.symbol}
          </div>
          <div className="mt-1 text-osloGray">{currencyB.symbol} Price</div>
          <div className="mt-1 text-right">
            {invertedTradePrice.usdPrice && (
              <span className="mr-1 text-osloGray ">
                ≈ ${invertedTradePrice.usdPrice.toSignificant(6)}
              </span>
            )}
            {invertedTradePrice.formattedPrice} {currencyA.symbol}
          </div>
          <div className="mt-1 text-osloGray">Liquidity Provider Fee</div>
          <div className="mt-1 text-right">
            {`${lpFee.toSignificant()} ${currencyA.symbol}`}
          </div>
          <div className="mt-1 text-osloGray">Price Impact</div>
          <div
            className={cn(
              "mt-1 text-right",
              priceImpaceData.status === "high" && "text-redOrange",
              priceImpaceData.status === "medium" && "text-orangePeel",
              priceImpaceData.status === "normal" && "text-osloGray"
            )}
          >
            {priceImpaceData.formatted}
          </div>
          <div className="mt-1 text-osloGray">Minimum Received</div>
          <div className="mt-1 text-right">
            {minimalReceivedCA?.toSignificant(6)} {currencyB.symbol}
          </div>
          <div className="mt-1 text-osloGray">Slippage Tolerance</div>
          <div className="mt-1 text-right text-osloGray">
            {allowedSlippage?.toSignificant()}%
          </div>
          <div className="mt-1 text-osloGray">Network Fee</div>
          <div className="mt-1 text-right ">
            {feeCA
              ? `≈ ${feeCA.toSignificant(6)} ${
                  Native.byChainId(chain.id).symbol
                }`
              : "Unknown"}
          </div>
        </div>

        <Modal.Footer>
          <Button
            id="confirm-btn"
            className="w-full"
            size="66"
            onClick={onConfirm}
          >
            Confirm Swap
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};
