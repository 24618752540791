import {
  useCurrencyAllowance,
  useCurrencyBalanceQuery,
} from "shared/api/token";
import { Currency, CurrencyAmount } from "shared/sdk-core";

type Params = {
  currencyAmount?: CurrencyAmount<Currency>;
  spenderAddress: string;
};

export const useHasAllowanceQuery = ({
  currencyAmount,
  spenderAddress,
}: Params) => {
  const currency = currencyAmount?.currency;
  const currencyBalanceQuery = useCurrencyBalanceQuery(currency);
  const currencyAllowanceQuery = useCurrencyAllowance(
    currency,
    spenderAddress,
    {
      extraEnabled: Boolean(
        currencyBalanceQuery.isSuccess &&
          currencyBalanceQuery.data &&
          currencyAmount
      ),
      select: (allowanceCA) => {
        if (!currencyBalanceQuery.data || !currencyAmount) return false;

        const hasTokenAllowance =
          currencyAmount.lessThanOrEqual(currencyBalanceQuery.data.balanceCA) &&
          currencyAmount.lessThanOrEqual(allowanceCA);

        return hasTokenAllowance;
      },
    }
  );

  return currencyAllowanceQuery;
};
