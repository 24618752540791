import { useMedia } from "react-use";
import { useAccount } from "wagmi";

import { shortenAddress } from "shared/helpers";
import { useIsSupportedChain } from "shared/providers/wagmi";
import { EmotionCSSProps } from "shared/types";
import { Button } from "shared/ui/button";
import { useWalletModal } from "widgets/wallet";

export const ConnectButton = ({ className, css: cssProp }: EmotionCSSProps) => {
  const isSupported = useIsSupportedChain();

  const [_, setWalletModalState] = useWalletModal();
  const { isConnected, address } = useAccount();

  const isMobile = useMedia("(max-width: 768px)");

  return (
    <Button
      className={className}
      css={cssProp}
      id="header-connect-wallet-btn"
      reverse
      iconName={isMobile ? undefined : "smallLogoOcx"}
      onClick={() => {
        const step =
          isSupported && isConnected && address ? "connector" : "list";

        setWalletModalState({
          open: true,
          step,
        });
      }}
    >
      {!isConnected && "Connect wallet"}
      {isConnected && address && shortenAddress(address)}
    </Button>
  );
};
