import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useProvider } from "wagmi";

import { StackingOcxABI__factory } from "shared/abi/types";
import { calculateFee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { OCX_STAKE_CONTRACT_ADDRESS } from "shared/v2-sdk/constants";

import { ocxKeys } from "./ocxKeys";

export const useEstimateFeeClaimOcxQuery = () => {
  const chain = useChain();
  const { address } = useAccount();
  const provider = useProvider({ chainId: chain.id });

  return useQuery(
    ocxKeys.estimateFeeClaimOcx(
      chain.id,
      address,
      OCX_STAKE_CONTRACT_ADDRESS[chain.id]
    ),
    async () => {
      invariant(address, "useEstimateFeeClaimOcxQuery. provider is undefined");
      const contract = StackingOcxABI__factory.connect(
        OCX_STAKE_CONTRACT_ADDRESS[chain.id],
        provider
      );

      const { gasPrice } = await provider.getFeeData();
      const estimatedGas = await contract.estimateGas.getReward();
      return calculateFee(gasPrice, estimatedGas, chain.id);
    },
    {
      enabled: Boolean(address && provider),
    }
  );
};
