import { PairLogoAndSymbol } from "entities/pair";
import { Fee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Modal } from "shared/ui/modal";
import { Native } from "shared/v2-sdk";

import type { AddLiquidityDetails } from "../../hooks";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  currencyAmountIn: CurrencyAmount<Currency> | undefined;
  currencyAmountOut: CurrencyAmount<Currency> | undefined;
  details: AddLiquidityDetails | undefined;
  fee: Fee | undefined;
  onConfirm: () => void;
};

export const ConfirmModal = ({
  isOpen,
  onClose,
  currencyA,
  currencyB,
  details,
  currencyAmountIn,
  currencyAmountOut,
  fee,
  onConfirm,
}: Props) => {
  const chain = useChain();
  if (
    !details ||
    !currencyA ||
    !currencyB ||
    !currencyAmountIn ||
    !currencyAmountOut
  ) {
    return null;
  }
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Body>
        <Modal.Header
          id={"add-liquidity-confirm-modal-header"}
          onIconClick={onClose}
        >
          You Receive
        </Modal.Header>
        <div className="mb-8 mt-3">
          <div className="flex items-center justify-between rounded-base bg-black/5 px-4 py-3 text-2xl text-black">
            <span className="mr-4 max-w-[130px] truncate">
              {details.liquidityMinted}
            </span>
            <PairLogoAndSymbol
              tokenA={currencyA.wrapped}
              tokenB={currencyB.wrapped}
            />
          </div>
        </div>
        <div className="mb-8 flex flex-col space-y-1 text-sm text-osloGray">
          <div className="flex items-center justify-between">
            <div>{currencyA.symbol} Deposited</div>
            <div id={"add-liquidity-confirm-currencyA-deposited"}>
              {details.currencyAPriceInUSDT &&
                `≈ $${details.currencyAPriceInUSDT?.toSignificant(6)}`}
              <span className="text-black">
                {currencyAmountIn.toSignificant(6)} {currencyA.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>{currencyB.symbol} Deposited</div>
            <div id={"add-liquidity-confirm-currencyB-deposited"}>
              {details.currencyBPriceInUSDT &&
                `≈ $${details.currencyBPriceInUSDT?.toSignificant(6)}`}
              <span className="text-black">
                {currencyAmountOut.toSignificant(6)} {currencyB.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>{currencyA.symbol} Price</div>
            <div id={"add-liquidity-confirm-currencyA-price"}>
              {details.currencyAToUSDTRate &&
                `≈ $${details.currencyAToUSDTRate?.toSignificant(6)}`}
              <span className="text-black">
                {details.tradeCurrencyAToCurrencyB?.outputAmount.toSignificant(
                  6
                )}{" "}
                {currencyB.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>{currencyB.symbol} Price</div>
            <div id={"add-liquidity-confirm-currencyB-price"}>
              {details.currencyBToUSDTRate &&
                `≈ $${details.currencyBToUSDTRate?.toSignificant(6)}`}
              <span className="text-black">
                {details.tradeCurrencyBToCurrencyA?.outputAmount.toSignificant(
                  6
                )}{" "}
                {currencyA.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Network Fee</div>
            <div
              id={"add-liquidity-confirm-network-fee"}
              className="text-black"
            >
              {fee
                ? `≈${fee.feeCA.toSignificant(6)} ${
                    Native.byChainId(chain.id).symbol
                  }`
                : "Unknown"}
            </div>
          </div>
        </div>
        <Button
          id={"add-liquidity-confirm-btn"}
          className="w-full !py-4 !text-2xl"
          onClick={onConfirm}
        >
          Confirm Supply
        </Button>
      </Modal.Body>
    </Modal>
  );
};
