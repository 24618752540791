import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContractTransaction, ethers } from "ethers";
import invariant from "tiny-invariant";
import { useAccount, useProvider, useSigner } from "wagmi";

import { useCurrenciesMap } from "entities/currency";
import {
  HumanodeRouter__factory,
  MilkomedaRouter__factory,
} from "shared/abi/types";
import { calculateMinimumReceived } from "shared/helpers";
import { isMilkomedaRouter } from "shared/helpers/is-router";
import { useTxSettings } from "shared/providers/TxSettings";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency, CurrencyAmount, Percent } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

import { getPairAddress } from "../token";
import { tokenKeys } from "../token/keys";
import { useTxMutation, useTxWaitMutation } from "../transtaction";

import {
  AddLiquidityNativeParams,
  AddLiquidityTokenParams,
  estimateAddLiquidityFee,
} from "./estimateAddLiquidityFee";
import { poolKeys } from "./keys";

type Params = {
  currencyAmountIn: CurrencyAmount<Currency>;
  currencyAmountOut: CurrencyAmount<Currency>;
  currencyA: Currency;
  currencyB: Currency;
};

export const useAddLiquidityMutation = () => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const signerQuery = useSigner();

  const txMutation = useTxMutation();
  const txWaitMutation = useTxWaitMutation();
  const { address } = useAccount();

  const [txSettings] = useTxSettings();

  const queryClient = useQueryClient();

  const isHumanode = useIsHumanode();

  const currenciesMap = useCurrenciesMap();

  return useMutation(
    async ({
      currencyAmountIn,
      currencyAmountOut,
      currencyA,
      currencyB,
    }: Params) => {
      invariant(
        signerQuery.data,
        "useAddLiquidityMutation. signer is undefined"
      );
      invariant(address, "useAddLiquidityMutation. address is undefined");

      const signer = signerQuery.data;
      const desc = `Supplying ${currencyAmountIn.toSignificant()} ${
        currencyA.symbol
      } and ${currencyAmountOut.toSignificant()} ${currencyB.symbol}`;

      const routerFactory = isHumanode
        ? HumanodeRouter__factory
        : MilkomedaRouter__factory;

      const contractRouter = routerFactory.connect(
        ROUTER_ADDRESS_MAP[chain.id],
        signer || provider
      );

      const slippage = new Percent(
        txSettings.slippageTolerance * 1000,
        100 * 1000
      );

      const deadline =
        Math.floor(Date.now() / 1000) + 60 * txSettings.transactionDeadline;

      const native =
        currencyA.isNative || currencyB.isNative
          ? Native.byChainId(chain.id)
          : undefined;

      let promise: Promise<ContractTransaction>;

      if (native && isMilkomedaRouter(contractRouter)) {
        const token = currencyA.isToken ? currencyA : currencyB.wrapped;
        const tokenCurrencyAmount = currencyAmountIn.currency.isToken
          ? currencyAmountIn.wrapped
          : currencyAmountOut.wrapped;

        const nativeCurrencyAmount = currencyAmountIn.currency.isNative
          ? currencyAmountIn
          : currencyAmountOut;

        const tokenMinimalReceivedCA = calculateMinimumReceived(
          slippage,
          tokenCurrencyAmount
        ).wrapped;

        const nativeMinimalReceivedCA = calculateMinimumReceived(
          slippage,
          nativeCurrencyAmount
        );

        const params: AddLiquidityNativeParams = {
          tokenAddress: token.address,
          tokenCurrencyAmount: tokenCurrencyAmount.quotient.toString(),
          tokenMinimalReceived: tokenMinimalReceivedCA.quotient.toString(),
          nativeMinimalReceived: nativeMinimalReceivedCA.quotient.toString(),
          accountAddress: address,
          deadline,
          nativeCurrencyAmount: nativeCurrencyAmount.quotient.toString(),
        };

        const fee = await estimateAddLiquidityFee(
          contractRouter,
          params,
          signer,
          chain.id
        );

        promise = contractRouter.addLiquidityADA(
          params.tokenAddress,
          tokenCurrencyAmount.quotient.toString(),
          tokenMinimalReceivedCA.quotient.toString(),
          nativeMinimalReceivedCA.quotient.toString(),
          address,
          deadline,
          {
            value: nativeCurrencyAmount.quotient.toString(),
            gasLimit: fee.gasLimit,
          }
        );
      } else {
        const minimalReceivedAmountIn = calculateMinimumReceived(
          slippage,
          currencyAmountIn
        );

        const minimalReceivedAmountOut = calculateMinimumReceived(
          slippage,
          currencyAmountOut
        );

        const params: AddLiquidityTokenParams = {
          tokenAAddress: currencyA.wrapped.address,
          tokenBAddress: currencyB.wrapped.address,
          currencyAmountIn: currencyAmountIn.quotient.toString(),
          currencyAmountOut: currencyAmountOut.quotient.toString(),
          minimalReceivedAmountIn: minimalReceivedAmountIn.quotient.toString(),
          minimalReceivedAmountOut:
            minimalReceivedAmountOut.quotient.toString(),
          accountAddress: address,
          deadline,
        };

        const fee = await estimateAddLiquidityFee(
          contractRouter,
          params,
          signer,
          chain.id
        );

        promise = contractRouter.addLiquidity(
          currencyA.wrapped.address,
          currencyB.wrapped.address,
          currencyAmountIn.quotient.toString(),
          currencyAmountOut.quotient.toString(),
          minimalReceivedAmountIn.quotient.toString(),
          minimalReceivedAmountOut.quotient.toString(),
          address,
          deadline,
          { gasLimit: fee.gasLimit }
        );
      }

      const tx = await txMutation.mutateAsync({ txPromise: promise, desc });
      const receipt = await txWaitMutation.mutateAsync({ tx, desc });
      return receipt;
    },
    {
      onSuccess: (data, params) => {
        const ltAddress = getPairAddress(
          params.currencyA.wrapped,
          params.currencyB.wrapped
        );
        queryClient.refetchQueries(poolKeys.totalSupply(chain.id, ltAddress));
        queryClient.refetchQueries(poolKeys.allPairs(chain.id, currenciesMap));
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.currencyA.isToken
              ? params.currencyA.address
              : ethers.constants.AddressZero
          )
        );
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.currencyB.isToken
              ? params.currencyB.address
              : ethers.constants.AddressZero
          )
        );

        queryClient.invalidateQueries(
          tokenKeys.allowance(
            params.currencyA.isToken
              ? params.currencyA.address
              : ethers.constants.AddressZero,
            address,
            ROUTER_ADDRESS_MAP[chain.id]
          )
        );

        queryClient.invalidateQueries(
          tokenKeys.allowance(
            params.currencyB.isToken
              ? params.currencyB.address
              : ethers.constants.AddressZero,
            address,
            ROUTER_ADDRESS_MAP[chain.id]
          )
        );
      },
    }
  );
};
