export const ocxKeys = {
  all: ["ocx"],
  estimateFeeStakeOcx: (
    chainId: number | string,
    userAddress: string | undefined,
    contractAddress: string
  ) => [
    ...ocxKeys.all,
    "staking",
    "estimate",
    "stake",
    chainId,
    userAddress,
    contractAddress,
  ],
  estimateFeeUnstakeOcx: (
    chainId: number | string,
    userAddress: string | undefined,
    contractAddress: string
  ) => [
    ...ocxKeys.all,
    "staking",
    "estimate",
    "unstake",
    chainId,
    userAddress,
    contractAddress,
  ],
  estimateFeeClaimOcx: (
    chainId: number | string,
    userAddress: string | undefined,
    contractAddress: string
  ) => [
    ...ocxKeys.all,
    "staking",
    "estimate",
    "claim",
    chainId,
    userAddress,
    contractAddress,
  ],
  stakingBalance: (
    chainId: number | string,
    userAddress: string | undefined,
    contractAddress: string
  ) => [
    ...ocxKeys.all,
    "staking",
    "balance",
    chainId,
    userAddress,
    contractAddress,
  ],
  stakingInfo: (chainId: number | string, userAddress: string | undefined) => [
    ...ocxKeys.all,
    "staking",
    "info",
    chainId,
    userAddress,
  ],
};
