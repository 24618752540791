import { useEstimateSwapFeeQuery } from "shared/api/swap";
import { useTxSettings } from "shared/providers/TxSettings";
import { Percent } from "shared/sdk-core";
import { TradeTypes } from "shared/v2-sdk/hooks";

import { calcLiquidityProvidersFee } from "../utils/calc-liquidity-providers-percent";

import { useTradeOptions } from "./use-trade-options";

export const useAdvancedDetails = (trade: TradeTypes) => {
  const { lpFee } = calcLiquidityProvidersFee(trade);

  const tradeOptions = useTradeOptions();
  const [txSettings] = useTxSettings();
  const feeQuery = useEstimateSwapFeeQuery(trade, tradeOptions);

  const minimalReceivedCA = tradeOptions
    ? trade.minimumAmountOut(tradeOptions.allowedSlippage)
    : undefined;

  return {
    lpFee,
    feeCA: feeQuery.data?.feeCA,
    minimalReceivedCA,
    allowedSlippage:
      tradeOptions?.allowedSlippage ??
      new Percent(txSettings.slippageTolerance * 100, 10000),
  };
};
