import { CurrencyEntity } from "entities/currency";
import { usePrice } from "entities/price";
import { RemoveLiquidityDetails, usePairCurrencies } from "pages/pool/hooks";
import { DetailedPair } from "shared/api/pool";
import { Fee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Modal } from "shared/ui/modal";
import { Native } from "shared/v2-sdk";
import { TradeTypes } from "shared/v2-sdk/hooks";

type Props = {
  open: boolean;
  trade: TradeTypes;
  details: RemoveLiquidityDetails;
  tokenAmount: CurrencyAmount<Token>;
  detailedPair: DetailedPair;
  fee: Fee | undefined;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmModal = ({
  open,
  tokenAmount,
  detailedPair,
  trade,
  details,
  onConfirm,
  fee,
  onClose,
}: Props) => {
  const chain = useChain();
  const [currencyA, currencyB] = usePairCurrencies(detailedPair.pair);

  const [currencyATradeInfo] = usePrice(trade.executionPrice);
  const [currencyBTradeInfo] = usePrice(trade.executionPrice, true);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <Modal.Header onIconClick={onClose}>You Receive</Modal.Header>
        <div className="mb-8 mt-5 flex flex-col space-y-1 text-sm text-osloGray">
          <div className="mb-8 flex flex-col space-y-3">
            <div className="mt-3 flex items-center justify-between space-x-4 rounded-base bg-black/5 px-4 py-3 text-[1.375rem] text-black">
              <div>{details.liquidityValueA?.toSignificant(6)}</div>
              <CurrencyEntity
                bordered={false}
                size="38"
                symbol={currencyA.symbol}
                logoUrl={currencyA.logoUrl}
              />
            </div>
            <div className="mt-3 flex items-center justify-between space-x-4 rounded-base bg-black/5 px-4 py-3 text-[1.375rem] text-black">
              <div>{details.liquidityValueB?.toSignificant(6)}</div>
              <CurrencyEntity
                bordered={false}
                size="38"
                symbol={currencyB.symbol}
                logoUrl={currencyB.logoUrl}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Liquidity Tokens Burnt</div>
            <div>
              ≈ ${details.usdPrice?.toSignificant()}{" "}
              <span className="text-black">
                {tokenAmount.toSignificant(6)} LT
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>{currencyA.symbol} Price</div>
            <div>
              ≈ ${currencyATradeInfo.usdPrice?.toSignificant()}{" "}
              <span className="text-black">
                {currencyATradeInfo.formattedPrice} {currencyB.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>{currencyB.symbol} Price</div>
            <div>
              ≈ ${currencyBTradeInfo.usdPrice?.toSignificant()}{" "}
              <span className="text-black">
                {currencyBTradeInfo.formattedPrice} {currencyA.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Your Pool Share</div>
            <div>
              <span className="text-black">
                {details.poolShare.toSignificant(4)}%{" "}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Network Fee</div>
            <div className="text-black">
              {fee
                ? `≈${fee.feeCA.toSignificant(6)} ${
                    Native.byChainId(chain.id).symbol
                  }`
                : "Unknown"}
            </div>
          </div>
        </div>
        <Button className="w-full !py-4 !text-2xl" onClick={onConfirm}>
          Confirm Removal
        </Button>
      </Modal.Body>
    </Modal>
  );
};
