import {
  ComponentProps,
  ElementRef,
  forwardRef,
  PropsWithChildren,
} from "react";
import { twMerge } from "tailwind-merge";

import { EmotionCSSProps } from "shared/types";

import { Spinner } from "../spinner";

type Props = {
  loading?: boolean;
} & ComponentProps<"button"> &
  EmotionCSSProps;

export const Button = forwardRef<
  ElementRef<"button">,
  PropsWithChildren<Props>
>(({ className, children, loading, disabled, ...props }, ref) => {
  const cls = twMerge(
    "gap-x-3 px-5 py-2 font-medium relative text-sm text-dodgerBlue border border-dodgerBlue border-opacity-25 flex items-center justify-center rounded-lg outline-none transition-colors",
    disabled && "opacity-50",
    className
  );

  return (
    <button {...props} ref={ref} className={cls}>
      {loading && (
        <span className="relative opacity-0">
          <Spinner theme="dodgerBlue" />
        </span>
      )}
      {children}
    </button>
  );
});
