import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { USDT } from "shared/v2-sdk";
import { useBestTrade } from "shared/v2-sdk/hooks";

export const useUSDExchangeRate = (
  currencyAmount: CurrencyAmount<Currency> | undefined
) => {
  const chain = useChain();

  const { trade } = useBestTrade(
    currencyAmount?.currency,
    USDT[chain.id],
    currencyAmount
  );

  return currencyAmount
    ? trade?.route.midPrice.quote(currencyAmount)
    : undefined;
};
