import React, { PropsWithChildren, useContext, useState } from "react";
import { useLocalStorage } from "react-use";
import invariant from "tiny-invariant";
import { Chain } from "wagmi";

import { supportedChains } from "shared/config/networks";
import { milkomedaC1Chain } from "shared/config/networks/milkomeda";
import { LS_KEYS } from "shared/constants";

const DefaultChainContext = React.createContext<
  [Chain, (chain: Chain) => void] | undefined
>(undefined);

export const Provider = ({ children }: PropsWithChildren) => {
  const [defaultChain, setLsDefaultChain] = useLocalStorage<string | number>(
    LS_KEYS.defaultChain
  );
  const foundChain = supportedChains.find(
    ({ id }) => id.toString() === defaultChain?.toString()
  );
  const [state, setState] = useState(() => {
    return foundChain ?? milkomedaC1Chain;
  });
  const setDefaultChain = (chain: Chain) => {
    setLsDefaultChain(chain.id);
    setState(chain);
  };

  return (
    <DefaultChainContext.Provider value={[state, setDefaultChain]}>
      {children}
    </DefaultChainContext.Provider>
  );
};

export const useDefaultChain = () => {
  const ctx = useContext(DefaultChainContext);
  invariant(ctx, "useDefaultChain must be use within DefaultChainProvider");

  return ctx;
};
