import { useAccount } from "wagmi";

import { useHasAllowanceQuery } from "features/approve-allowance";
import { useDetailedPairsQuery } from "shared/api/pool";
import { useCheckSolvency, useCurrencyBalanceQuery } from "shared/api/token";
import { Fee } from "shared/helpers";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

type Status =
  | "connectWallet"
  | "wrongNetwork"
  | "approveCurrencyA"
  | "approveCurrencyB"
  | "enterAmount"
  | "insufficientCurrencyABalance"
  | "insufficientCurrencyBBalance"
  | "insufficientNativeTokenBalance"
  | "addLiquidity"
  | "createAPool";

type Params = {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  currencyAmountIn: CurrencyAmount<Currency> | undefined;
  currencyAmountOut: CurrencyAmount<Currency> | undefined;
  fee: Fee | undefined;
};

export const useAddLiquidityStatus = ({
  currencyA,
  currencyB,
  currencyAmountIn,
  currencyAmountOut,
  fee,
}: Params) => {
  const chain = useChain();
  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();

  const hasAllowanceAQuery = useHasAllowanceQuery({
    currencyAmount: currencyAmountIn,
    spenderAddress: ROUTER_ADDRESS_MAP[chain.id],
  });

  const hasAllowanceBQuery = useHasAllowanceQuery({
    currencyAmount: currencyAmountOut,
    spenderAddress: ROUTER_ADDRESS_MAP[chain.id],
  });

  const detailedPairsQuery = useDetailedPairsQuery();
  const detailedPair =
    currencyA && currencyB
      ? detailedPairsQuery.data?.find(
          ({ pair }) =>
            pair.involvesToken(currencyA.wrapped) &&
            pair.involvesToken(currencyB.wrapped)
        )
      : undefined;

  const hasPool = Boolean(detailedPair?.pair.liquidityToken);

  const isCurrencyASolvent = useCheckSolvency({
    fee,
    currencyAmount: currencyAmountIn,
  });

  const isCurrencyBSolvent = useCheckSolvency({
    fee,
    currencyAmount: currencyAmountOut,
  });

  const currencyABalanceQuery = useCurrencyBalanceQuery(currencyA);
  const currencyBBalanceQuery = useCurrencyBalanceQuery(currencyB);

  const isEnoughBalanceCurrencyA = () => {
    return (
      currencyAmountIn &&
      currencyAmountIn.greaterThan(0) &&
      currencyABalanceQuery.isSuccess &&
      typeof currencyA?.decimals === "number" &&
      currencyAmountIn.lessThanOrEqual(currencyABalanceQuery.data.balanceCA)
    );
  };

  const isEnoughBalanceCurrencyB = () => {
    return (
      currencyAmountOut &&
      currencyAmountOut.greaterThan(0) &&
      currencyBBalanceQuery.isSuccess &&
      currencyB &&
      typeof currencyB.decimals === "number" &&
      currencyAmountOut.lessThanOrEqual(currencyBBalanceQuery.data.balanceCA)
    );
  };

  const getStatus = (): Status => {
    if (!currencyA || !currencyB) return "enterAmount";
    if (!isConnected) return "connectWallet";
    if (isConnected && !isSupported) return "wrongNetwork";
    if (!currencyAmountIn || currencyAmountIn.equalTo(0)) return "enterAmount";
    if (!isEnoughBalanceCurrencyA()) return "insufficientCurrencyABalance";
    if (!isEnoughBalanceCurrencyB()) return "insufficientCurrencyBBalance";
    if (!isCurrencyASolvent) return "insufficientNativeTokenBalance";
    if (!isCurrencyBSolvent) return "insufficientNativeTokenBalance";
    if (hasAllowanceAQuery.data === false && currencyA.isToken)
      return "approveCurrencyA";
    if (hasAllowanceBQuery.data === false && currencyB.isToken)
      return "approveCurrencyB";
    if (!hasPool) return "createAPool";
    return "addLiquidity";
  };

  const status = getStatus();

  const getBtnDisabled = () => {
    if (
      status === "enterAmount" ||
      status === "insufficientCurrencyABalance" ||
      status === "insufficientCurrencyBBalance" ||
      status === "insufficientNativeTokenBalance"
    )
      return true;

    return false;
  };

  return { status, btnDisabled: getBtnDisabled() };
};
