import { useQueries, useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useProvider } from "wagmi";

import { useChain } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";

import { fetchPair } from "./helpers/fetchPair";
import { getPairAddress } from "./helpers";
import { tokenKeys } from "./keys";

export const usePairsQueries = (
  potentialPairs:
    | [Currency | undefined, Currency | undefined][]
    | undefined = []
) => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });

  const queries = useQueries({
    queries: potentialPairs.map(([currencyA, currencyB]) => {
      const tokenA = currencyA?.wrapped;
      const tokenB = currencyB?.wrapped;

      const pairAddress = getPairAddress(tokenA, tokenB);

      return {
        queryKey: tokenKeys.pair(chain.id, pairAddress),
        queryFn: async () => {
          invariant(tokenA, "usePairsQueries. tokenA is undefined");
          invariant(tokenB, "usePairsQueries. tokenB is undefined");
          invariant(pairAddress, "usePairsQueries. pairAddress is undefined");
          invariant(provider, "usePoolPairs. provider is undefined");

          return fetchPair({
            pairAddress,
            tokenA,
            tokenB,
            provider,
            chainId: chain.id,
          });
        },
        enabled: Boolean(pairAddress && tokenA && tokenB && potentialPairs),
      };
    }),
  });

  return queries;
};

export const usePairQuery = (
  currencyA: Currency | undefined,
  currencyB: Currency | undefined
) => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });

  const tokenA = currencyA?.wrapped;
  const tokenB = currencyB?.wrapped;

  const pairAddress = getPairAddress(tokenA, tokenB);

  return useQuery(
    tokenKeys.pair(chain.id, pairAddress),
    async () => {
      invariant(tokenA, "usePairsQueries. tokenA is undefined");
      invariant(tokenB, "usePairsQueries. tokenB is undefined");
      invariant(pairAddress, "usePairsQueries. pairAddress is undefined");
      invariant(provider, "usePoolPairs. provider is undefined");

      return fetchPair({
        pairAddress,
        tokenA,
        tokenB,
        provider,
        chainId: chain.id,
      });
    },
    {
      enabled: Boolean(pairAddress && tokenA && tokenB && provider),
    }
  );
};
