import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toDecimal } from "utils/numbers";

import { PairLogoAndSymbol } from "entities/pair";
import { ApproveAllowanceButton } from "features/approve-allowance";
import { ConnectWalletButton } from "features/connect-wallet";
import { InputCurrency } from "features/input-currency";
import { SwitchNetworkButton } from "features/switch-network";
import { FarmOutletContext } from "pages/farm/types";
import { useEstimateFeeStakeQuery, useStakeMutation } from "shared/api/farm";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { USDT } from "shared/v2-sdk";

import { useStakingInfo } from "../../../entities/farm/hooks/use-staking-info";
import { useStakeStatus } from "../hooks/use-stake-status";

import { ConfirmModal } from "./confirm-modal";
import { Stats } from "./Stats";

export const StakePage = () => {
  const chain = useChain();
  const [amount, setAmount] = useState("");
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { miningPanel } = useOutletContext<FarmOutletContext>();
  const detailedPair = miningPanel.detailedPair;

  const amountCA = toCurrencyAmount(detailedPair.pair.liquidityToken, amount);

  const stakingInfo2 = useStakingInfo(miningPanel, amount);
  const feeQuery = useEstimateFeeStakeQuery({ miningPanel, amount });

  const stakeMutation = useStakeMutation();

  const { btnDisabled, btnText, status } = useStakeStatus(miningPanel, amount);

  const price = toDecimal(amount)
    .mul(detailedPair.advancedInfo.priceLT.priceLTD)
    .toString();

  return (
    <>
      <InputCurrency
        value={amount}
        id="stake-input"
        balance={stakingInfo2?.ltBalance.formatted}
        currency={detailedPair.pair.liquidityToken}
        price={amount ? toCurrencyAmount(USDT[chain.id], price) : undefined}
        onChange={(value) => {
          setAmount(value);
        }}
        onMaxClick={() => {
          stakingInfo2 &&
            setAmount(stakingInfo2?.ltBalance.balanceCA.toExact());
        }}
      >
        <PairLogoAndSymbol
          tokenA={detailedPair.pair.token0}
          tokenB={detailedPair.pair.token1}
        />
      </InputCurrency>
      <Stats
        capitalEfficiencyPercent={stakingInfo2?.capitalEfficiencyPercent}
        calcPoolShare={stakingInfo2?.poolSharePercent}
        calcStakingBalance={stakingInfo2?.calcStakingBalance}
        dailyRate={
          stakingInfo2
            ? `${stakingInfo2.dailyRateCA?.toSignificant(6) ?? "0"} ${
                miningPanel.rewardsToken?.symbol
              } daily`
            : undefined
        }
        miningPanel={miningPanel}
      />
      {status === "connectWallet" && (
        <ConnectWalletButton className="mt-4 w-full" />
      )}
      {status === "switchNetwork" && (
        <SwitchNetworkButton className="mt-4 w-full" />
      )}
      {status === "approve" && amountCA && (
        <ApproveAllowanceButton
          className="mt-4 w-full"
          spenderAddress={miningPanel.address}
          currencyAmount={amountCA}
        />
      )}
      {["enterAmount", "stake", "insufficientBalance"].includes(status) && (
        <Button
          id="main-stake-btn"
          size="66"
          disabled={btnDisabled}
          className="mt-4 w-full text-2xl"
          onClick={() => {
            setConfirmOpen(true);
          }}
        >
          {btnText}
        </Button>
      )}

      {stakingInfo2 && (
        <ConfirmModal
          isOpen={isConfirmOpen}
          amount={amount}
          miningPanel={miningPanel}
          fee={feeQuery.data}
          onClose={() => setConfirmOpen(false)}
          stakingInfo={stakingInfo2}
          onConfirm={() => {
            stakeMutation.mutate({
              contractAddress: miningPanel.address,
              currencyAmount: toCurrencyAmount(
                detailedPair.pair.liquidityToken,
                amount
              ),
            });
            setConfirmOpen(false);
          }}
        />
      )}
    </>
  );
};
