export const tokenKeys = {
  all: ["token"],
  currencies: (chainId?: number) => [...tokenKeys.all, "currencies", chainId],
  list: (chainId?: number) => [...tokenKeys.all, "list", chainId],
  pair: (chainId: number, pairAddress?: string) => [
    ...tokenKeys.all,
    "pair",
    pairAddress,
    chainId,
  ],
  approveFee: (
    chainId: string | number,
    address: string | undefined,
    spenderAddress: string | undefined
  ) => [
    ...tokenKeys.all,
    "approve",
    chainId.toString(),
    address,
    spenderAddress,
  ],
  allAllowance: () => [...tokenKeys.all, "allowance"],
  allowance: (
    tokenAddress: string | undefined,
    ownerAddress: string | undefined,
    spenderAddress: string
  ) => [
    ...tokenKeys.allAllowance(),
    tokenAddress,
    ownerAddress,
    spenderAddress,
  ],
  balance: (
    chainId: number | string,
    accountAddress: string | undefined,
    address: string | undefined
  ) => [...tokenKeys.all, "balance", chainId, accountAddress, address],
  erc20Balance: (
    chainId: string | number,
    accountAddress: string | undefined = "",
    tokenAddress: string | undefined = ""
  ) => [
    ...tokenKeys.all,
    "erc20Balance",
    chainId.toString(),
    accountAddress,
    tokenAddress,
  ],
};
