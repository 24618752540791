import React from "react";
import cn from "classnames";

import { useStateX } from "shared/hooks";
import { EmotionCSSProps } from "shared/types";
import { Icon } from "shared/ui/icon";
import { Validator } from "shared/ui/input";
import { Tabs } from "shared/ui/tabs";
import { Tooltip } from "shared/ui/tooltip";

import { TabInput } from "./tab-input";

const MINUTES = [5, 30, 60, 90];
const validators: Validator[] = [
  (value) => !value || Number.isInteger(parseInt(value)),
  (value) => value.length <= 4,
];

type Props = {
  value: number;
  onChange: ({ transactionDeadline }: { transactionDeadline: number }) => void;
} & EmotionCSSProps;

export const TransactionDeadline = ({
  value,
  onChange,
  className,
  css,
}: Props) => {
  const [state, setState] = useStateX({
    activeTab: MINUTES.includes(value)
      ? MINUTES.find((v) => v === value)
      : MINUTES[1],
    customValue: MINUTES.includes(value) ? "" : value.toString(),
  });

  const { activeTab, customValue } = state;

  return (
    <div css={css} className={cn("relative flex flex-col", className)}>
      <Tooltip
        portal={false}
        className="z-50 items-center self-baseline text-sm text-osloGray"
        element={
          <div className="max-w-[240px]">
            Your transaction will revert if it is pending for more than this
            period of time.
          </div>
        }
      >
        <span className="mr-1 inline-flex">Transaction Deadline</span>
        <Icon name="info" size="14" />
      </Tooltip>

      <div className="flex flex-wrap items-end">
        <Tabs
          id="transaction-deadline"
          className="mr-3 mt-3"
          theme="light"
          tabs={MINUTES}
          activeTab={customValue ? undefined : activeTab}
          keyAccessor={(tab) => tab}
          renderValue={(tab) => <span className="px-[5px]">{tab}</span>}
          onChange={(tab) => {
            setState({ customValue: "", activeTab: tab });
            onChange({ transactionDeadline: tab });
          }}
        />
        <div className="inline-flex w-1/3 items-center max-sm:mt-3 max-sm:w-1/2">
          <TabInput
            id="transaction-deadline-custom"
            value={customValue}
            validators={validators}
            onChange={(value) => {
              setState({ customValue: value });
              onChange({ transactionDeadline: Number(value) });
            }}
          />
          <span className="ml-1 inline-flex text-osloGray">minutes</span>
        </div>
      </div>

      {customValue && (
        <div
          id="transaction-deadline-custom-alert"
          className="absolute -bottom-5 left-0 text-sm text-orangePeel"
        >
          Custom
        </div>
      )}
    </div>
  );
};
