import { useAccount } from "wagmi";

import { MiningPanel, useStakingBalanceQuery } from "shared/api/farm";
import { getPairTokenDisplayData } from "shared/helpers";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";

type Status =
  | "connectWallet"
  | "switchNetwork"
  | "enterAmount"
  | "insufficientBalance"
  | "unstake";

export const useUnstakeStatus = (
  miningPanel: MiningPanel,
  amountCA: CurrencyAmount<Currency>
) => {
  const chain = useChain();

  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();
  const liquidityToken = miningPanel.detailedPair.pair.liquidityToken;

  const stakingBalanceQuery = useStakingBalanceQuery(
    miningPanel.address,
    liquidityToken
  );

  const isEnoughBalance = () => {
    return (
      stakingBalanceQuery.isSuccess &&
      typeof liquidityToken.decimals === "number" &&
      amountCA?.lessThanOrEqual(stakingBalanceQuery.data.balanceCA)
    );
  };

  const getStatus = (): Status => {
    if (!isConnected) return "connectWallet";
    if (!isSupported) return "switchNetwork";
    if (amountCA.equalTo(0)) return "enterAmount";
    if (!isEnoughBalance()) return "insufficientBalance";

    return "unstake";
  };

  const status = getStatus();

  const getBtnDisabled = () => {
    return status === "enterAmount" || status === "insufficientBalance";
  };

  const getBtnText = () => {
    if (status === "enterAmount") return "Enter amount";
    if (status === "insufficientBalance")
      return `Insufficient ${
        getPairTokenDisplayData(miningPanel.detailedPair.pair.token0, chain.id)
          .symbol
      }/${
        getPairTokenDisplayData(miningPanel.detailedPair.pair.token1, chain.id)
          .symbol
      } balance`;

    return "Unstake";
  };

  return { status, btnDisabled: getBtnDisabled(), btnText: getBtnText() };
};
