import { useEstimateAddLiquidityFeeQuery } from "shared/api/pool";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";
import { Native, Pair } from "shared/v2-sdk";

type Params = {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  pair: Pair | undefined;
};

export const useMaxNativeCurrencyValue = ({
  currencyA,
  currencyB,
  pair,
}: Params) => {
  const chain = useChain();
  const native = Native.byChainId(chain.id);
  const balance = useCurrencyBalanceQuery(native);

  const currencyAmountIn = toCurrencyAmount(currencyA, "0.1");
  const currencyAmountOut =
    pair && currencyA
      ? pair
          .priceOf(currencyA.wrapped)
          .quote(toCurrencyAmount(currencyA.wrapped, "0.1"))
      : undefined;

  const feeQuery = useEstimateAddLiquidityFeeQuery({
    currencyA,
    currencyB,
    currencyAmountIn,
    currencyAmountOut,
  });

  const result =
    feeQuery.data?.feeCA && balance.data?.balanceCA
      ? balance.data.balanceCA.subtract(feeQuery.data.feeCA)
      : undefined;

  return result;
};
