import { useAccount, useDisconnect } from "wagmi";

import { shortenAddress } from "shared/helpers";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";

import { getWalletLogoName } from "../../helpers";

import History from "./history";

type Props = {
  onStepChange: () => void;
};

export const Connector = ({ onStepChange }: Props) => {
  const { address, connector } = useAccount();
  const { disconnect } = useDisconnect();

  if (!address || !connector) {
    onStepChange();
    return null;
  }

  const logoName = getWalletLogoName(connector.id);
  return (
    <div className="mt-8 flex flex-col">
      <div className="flex items-center rounded-3xl border border-solid border-tiara px-4 py-3">
        <Link className="mr-auto inline-flex" to="#" showIcon>
          {shortenAddress(address)}
        </Link>

        <button
          className="hover:text-redMalibu inline-flex p-2 text-dodgerBlue outline-none transition-colors active:text-malibu"
          onClick={onStepChange}
        >
          <Icon name="change" size="22" />
        </button>
        <button
          className="hover:text-redMalibu ml-8 inline-flex p-2 text-dodgerBlue outline-none transition-colors active:text-malibu"
          onClick={() => {
            disconnect();
            onStepChange();
          }}
        >
          <Icon name="logout" size="22" />
        </button>

        {logoName && <Icon className="ml-8" name={logoName} size="22" />}
      </div>

      <History />
    </div>
  );
};
