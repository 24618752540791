import { ImgHTMLAttributes } from "react";

import { EmotionCSSProps } from "shared/types";

import placeholder from "../icon/svg/tokenPlaceholder.svg";
import { Img } from "../img";

import { imageCss, imageStackCss, Size, wrapper } from "./styles";

const MAX_NUMBER_OF_TOKENS = 5;

export type ImageProps = { src?: string; alt?: string } & Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "src" | "alt"
>;

type Props<T extends ImageProps> = {
  images: T[];
  size?: Size;
} & EmotionCSSProps;

export const ImageStack = <T extends ImageProps>({
  className = "",
  css: cssProp,
  size = "18",
  images,
}: Props<T>) => {
  const slicedImages = images.slice(0, MAX_NUMBER_OF_TOKENS);
  return (
    <div css={wrapper(slicedImages.length, size)}>
      <ul
        className={className}
        css={[imageStackCss(slicedImages.length, size), cssProp]}
      >
        {slicedImages.map((props, idx) => {
          return (
            <li key={props.src} css={imageCss(idx, size)}>
              <Img src={props.src} alt={props.alt} fallbackUrl={placeholder} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
