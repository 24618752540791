import { HumanodeRouter, MilkomedaRouter } from "shared/abi/types";

export const isHumanodeRouter = (
  contract: HumanodeRouter | MilkomedaRouter
): contract is HumanodeRouter => {
  if ("swapExactTokensForADA" in contract) return false;
  return true;
};

export const isMilkomedaRouter = (
  contract: HumanodeRouter | MilkomedaRouter
): contract is MilkomedaRouter => {
  return "swapExactTokensForADA" in contract;
};
