import React, {
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import { usePopper } from "react-popper";
import { Options } from "@popperjs/core";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import emotionClone from "shared/helpers/emotionClone";
import { useClickOutside, useCombineRef } from "shared/hooks";
import { EmotionCSSProps } from "shared/types";
import { Icon } from "shared/ui/icon";

import { Portal } from "../portal";

import { Button } from "./button";
import { Item } from "./dropdown-item";

const popperModifiers = [
  {
    name: "offset",
    options: {
      offset: [0, 10],
    },
  },
];

type Props = {
  id?: string;
  button: ReactNode;
  defaultOpen?: boolean;
  dropdownClassName?: string;
} & EmotionCSSProps &
  Omit<Partial<Options>, "modifiers">;

export const Dropdown = ({
  id,
  className,
  css,
  children,
  button,
  defaultOpen = false,
  dropdownClassName,
  ...popperProps
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(defaultOpen);

  const [referenceElement, setReference] = useState<HTMLElement | null>(null);
  const [popperElement, setPopper] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    ...popperProps,
    modifiers: popperModifiers,
  });

  const boundingElementRef = useClickOutside<HTMLDivElement>(() => {
    setOpen(false);
  });

  const combinedRef = useCombineRef(boundingElementRef, setReference);

  const textButtonClasses = cn(
    className,
    `
  inline-flex w-full rounded-[5px] items-center justify-between space-x-[0.6rem]
  border border-solid border-light bg-light bg-opacity-50 py-[9px] px-4
  outline-none transition-colors 
  hover:border-dodgerBlue hover:border-opacity-50`
  );

  const displayButton =
    typeof button === "string" ? (
      <button className={textButtonClasses} id={id}>
        <div className="text-base leading-5 text-black">{button}</div>
        <div className="flex items-center space-x-[0.6rem]">
          <div className="h-[1.15rem] w-[0.05rem] bg-white" />
          <Icon
            name="chevronDown"
            size="14"
            className={`fill-current text-blackSecondary h-[14px] w-[14px] ${
              open ? "rotate-180" : "-rotate-0"
            } transform cursor-pointer`}
          />
        </div>
      </button>
    ) : (
      button
    );

  const cloneButton = emotionClone(
    displayButton,
    {
      onClick: () => {
        if (typeof button === "string") {
          setOpen(!open);
          return;
        }

        if (isValidElement(button)) {
          button.props?.onClick?.();
          setOpen(!open);
        }
      },
    },
    combinedRef
  );

  return (
    <>
      <AnimatePresence>
        {cloneButton}
        {open && (
          <Portal>
            <motion.div
              css={css}
              ref={setPopper}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={cn(
                `absolute bottom-0 left-0 inline-block w-auto overflow-hidden rounded-lg border border-solid border-light bg-athensGray shadow-lg`,
                dropdownClassName
              )}
              style={styles.popper}
              {...attributes.popper}
            >
              {children}
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
};

Dropdown.Button = Button;
Dropdown.Item = Item;
