import { useWaitForTransaction } from "wagmi";

import { TransactionLink } from "entities/transaction";
import { TxHistoryItem, useTxHistory } from "shared/providers/TxHistory";
import { useChain } from "shared/providers/wagmi";
import { EXPLORER_ADDRESS_MAP } from "shared/v2-sdk/constants";

type Props = {
  tx: TxHistoryItem;
};

export const HistoryTransaction = ({ tx }: Props) => {
  const chain = useChain();
  const { updateRef } = useTxHistory();

  useWaitForTransaction({
    hash: tx.hash,
    chainId: chain.id,
    enabled: tx.status !== "success" && tx.status !== "error",
    onSuccess: (data) => {
      if (!data) return;
      updateRef.current({
        ...tx,
        status: data.confirmations >= 1 ? "success" : "error",
      });
    },
  });

  return (
    <TransactionLink
      key={tx.text}
      className="w-full py-2 first:pt-0"
      status={tx.status}
      to={`${EXPLORER_ADDRESS_MAP[chain.id]}/tx/${tx.hash}`}
    >
      {tx.text}
    </TransactionLink>
  );
};
