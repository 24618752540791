import { useMemo } from "react";
import { useAccount } from "wagmi";

import { useTxSettings } from "shared/providers/TxSettings";
import { Percent } from "shared/sdk-core";
import { TradeOptions } from "shared/v2-sdk";

export const useTradeOptions = () => {
  const { address } = useAccount();
  const [txSettings] = useTxSettings();

  return useMemo(() => {
    const tradeOptions: TradeOptions | undefined =
      address && txSettings
        ? {
            ttl: txSettings.transactionDeadline * 60,
            allowedSlippage: new Percent(
              txSettings.slippageTolerance * 100,
              "10000"
            ),
            recipient: address,
          }
        : undefined;

    return tradeOptions;
  }, [address, txSettings]);
};
