import { useState } from "react";
import sortBy from "lodash/sortBy";
import { matchSorter } from "match-sorter";
import { toDecimal } from "utils/numbers";

import { FarmCard } from "entities/farm";
import { usePairPositions } from "pages/pool/hooks";
import { MiningPanel, useMiningPanelsQuery } from "shared/api/farm";
import { useDetailedPairsQuery } from "shared/api/pool";
import { getPairTokenDisplayData } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Dropdown, DropdownItem } from "shared/ui/dropdown";
import { Icon } from "shared/ui/icon";
import { Img } from "shared/ui/img";
import { Input } from "shared/ui/input";
import { Toggle } from "shared/ui/toggle";
import { Tooltip } from "shared/ui/tooltip";

type SortByType = "Rate" | "APY" | "TVL";

const SORT_OPTIONS: { label: string; id: SortByType }[] = [
  {
    label: "High Rate First",
    id: "Rate",
  },
  {
    label: "High APY First",
    id: "APY",
  },
  {
    label: "High TVL First",
    id: "TVL",
  },
];

const getMiningPanelName = (miningPanel: MiningPanel, chainId: number) =>
  `${
    getPairTokenDisplayData(miningPanel.detailedPair.pair.token0, chainId)
      .symbol
  } + ${getPairTokenDisplayData(
    miningPanel.detailedPair.pair.token1,
    chainId
  )}`;

const FarmPage = () => {
  const chain = useChain();
  const [sortField, setSortField] = useState<SortByType>("Rate");
  const [isMyPositions, setIsMyPositions] = useState(false);
  const [search, setSearch] = useState("");

  const detailedPairsQuery = useDetailedPairsQuery();
  const pairPositions = usePairPositions(detailedPairsQuery.data);

  const miningPanelsQuery = useMiningPanelsQuery({
    select: (miningPanels) => {
      const searchedPanels = search
        ? matchSorter(miningPanels, search, {
            keys: [
              (panel) => {
                const name = getMiningPanelName(panel, chain.id);
                return name.split(" + ").join("/-") ?? "";
              },
              (panel) => panel.address,
            ],
          })
        : miningPanels;

      const filteredPanels = isMyPositions
        ? searchedPanels.filter((miningPanel) => {
            const position = pairPositions.find((p) =>
              p.detailedPair.pair.liquidityToken.equals(
                miningPanel.detailedPair.pair.liquidityToken
              )
            );

            return position?.positionD?.greaterThan(0);
          })
        : searchedPanels;

      const sortedPanels = sortBy(filteredPanels, (panel) => {
        if (sortField === "APY") return toDecimal(panel.apy).toNumber();
        if (sortField === "TVL")
          return toDecimal(panel.detailedPair.advancedInfo.tvl).toNumber();
        if (sortField === "Rate")
          return toDecimal(panel.rewardsPerSecond).toNumber();
      }).reverse();

      return sortedPanels;
    },
  });

  const miningPanels = miningPanelsQuery.data;
  const dropdownButton = SORT_OPTIONS.find((f) => f.id === sortField);

  return (
    <>
      <div className="fixed z-[-1] h-screen w-screen">
        <div className="absolute left-0 top-[80%] flex h-1/2 w-1/4 -translate-y-1/2 bg-farmMonkey bg-contain bg-no-repeat" />
      </div>
      <div className="mx-auto w-[1200px] items-center px-3 py-0 pb-[60px] pt-[52px] max-xl:w-full">
        <h2 className="mb-4 inline-flex text-3xl text-black">
          Farms{" "}
          <Tooltip
            element={
              <div className="w-72">
                By adding liquidity you'll earn 0.25% of all trades on a pair
                proportional to your share of the pool. Fees are added to the
                pool, accrue in real time and can be claimed by withdrawing your
                liquidity.
              </div>
            }
          >
            <Icon name="info" size="14" className="ml-3 text-osloGray" />
          </Tooltip>
        </h2>
        <div className="flex flex-wrap items-center justify-between gap-y-3">
          <div className="flex items-center space-x-4">
            <div className="text-sm leading-5">
              <Input
                cancelable
                iconName="search"
                placeholder="Search by name or paste an address"
                value={search}
                onChange={({ value }) => setSearch(value)}
              />
            </div>
            <div className="mr-3 whitespace-nowrap text-base leading-5">
              My Positions
            </div>
            <Toggle
              checked={isMyPositions}
              onChange={() => {
                setIsMyPositions((s) => !s);
              }}
              className="!self-center"
            />
          </div>
          <div>
            <Dropdown button={dropdownButton?.label}>
              {SORT_OPTIONS.map((item) => (
                <DropdownItem
                  key={item.id}
                  onClick={() => setSortField(item.id)}
                >
                  <span>{item.label}</span>
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] justify-center gap-5">
          {miningPanels?.map((miningPanel, index) => {
            const position = pairPositions.find((p) =>
              p.detailedPair.pair.liquidityToken.equals(
                miningPanel.detailedPair.pair.liquidityToken
              )
            );
            return (
              <FarmCard
                position={position?.positionD}
                key={index}
                miningPanel={miningPanel}
              />
            );
          })}
        </div>
        {search && miningPanels?.length ? (
          <div className="text-center text-sm text-osloGray">Nothing found</div>
        ) : null}
      </div>
    </>
  );
};

export default FarmPage;
