import classNames from "classnames";

type DataRowProps = {
  label: string;
  value: string;
  id?: string;
};

export function DataRow({ label, value, id }: DataRowProps) {
  return (
    <div className="flex items-center justify-between text-sm text-osloGray">
      <span>{label}</span>
      <span id={id} className="text-black">
        {value}
      </span>
    </div>
  );
}

function Data({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        "flex flex-col space-y-1 text-sm text-osloGray",
        className
      )}
    >
      {children}
    </div>
  );
}

Data.Row = DataRow;

export default Data;
