import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  BrowserTracing,
  init,
  reactRouterV6Instrumentation,
  Replay,
} from "@sentry/react";
import Decimal from "decimal.js";

import App from "app/App";

import "@total-typescript/ts-reset";

import reportWebVitals from "./reportWebVitals";

console.log("REACT_APP_SENTRY=", process.env.REACT_APP_SENTRY);
if (
  process.env.REACT_APP_SENTRY === "on" &&
  process.env.NODE_ENV === "production"
) {
  init({
    dsn: "https://8f791e21378545e5bddfd9ec9b1747c3@o4505432424775680.ingest.sentry.io/4505494482518016",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

Decimal.set({ toExpPos: 20, precision: 20 });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    <div id="portal-root" />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
