import cn from "classnames";

import { CurrencyEntity } from "entities/currency";
import { Currency } from "shared/sdk-core";

const LAST_TOKEN_COUNT = 8;

type Props = {
  currencies: Currency[];
  selectedCurrency: Currency | undefined;
  onSelect: (Currency: Currency) => void;
};

export const LatestCurrencies = ({
  currencies,
  selectedCurrency,
  onSelect,
}: Props) => {
  return (
    <>
      {currencies && currencies.length > 0 && (
        <ul className="mx-10 mt-4 flex overflow-x-scroll scrollbar-hide max-sm:mx-0">
          {currencies.slice(0, LAST_TOKEN_COUNT).map((currency) => {
            return (
              <li
                key={currency.symbol}
                className={cn(
                  "ml-3 inline-flex shrink-0 cursor-pointer first:ml-0",
                  selectedCurrency?.equals(currency) &&
                    "cursor-default opacity-50"
                )}
                onClick={() => {
                  onSelect?.(currency);
                }}
              >
                <CurrencyEntity
                  size="32"
                  symbol={currency.symbol}
                  logoUrl={currency.logoUrl}
                />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
