import { useChain } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";
import { USDT } from "shared/v2-sdk";
import { bestTrade } from "shared/v2-sdk/hooks";

import { useDetailedPairsQuery } from "../pool";

import { useCurrenciesBalancesQuery } from "./useCurrenciesBalancesQuery";

export const useUSDRates = (currencies: Currency[] = []) => {
  const chain = useChain();
  const usdToken = USDT[chain.id];
  const pairsQuery = useDetailedPairsQuery({
    select: (data) => data.map(({ pair }) => pair),
  });

  const balancesQueries = useCurrenciesBalancesQuery(currencies);

  const trades = pairsQuery.isSuccess
    ? currencies.map((currency, idx) => {
        const balanceQuery = balancesQueries[idx];
        const balanceCA = balanceQuery.data?.balanceCA;
        return bestTrade(pairsQuery.data, currency, usdToken, balanceCA);
      })
    : undefined;

  return trades;
};
