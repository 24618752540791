import tw, { css } from "twin.macro";

export const red = css`
  ${tw`bg-red border-red text-light`}

  &:hover {
    ${tw`bg-redHover border-redHover`}
  }
  &:active,
  &:focus {
    ${tw`bg-redActive border-redActive`}
  }
`;

export const blue = css`
  ${tw`text-light bg-mariner border-mariner`}

  &:hover {
    ${tw`bg-mariner border-mariner border-opacity-90 bg-opacity-90`}
  }
`;

export const dodgerBlue = css`
  ${tw`text-light bg-dodgerBlue border-dodgerBlue`}

  &:hover {
    ${tw`bg-dodgerBlue border-dodgerBlue border-opacity-90 bg-opacity-90`}
  }
`;

export const lightDodgerBlue = css`
  ${tw`text-dodgerBlue bg-light border-light`}

  &:hover {
    ${tw`text-light bg-dodgerBlue border-dodgerBlue border-opacity-90 bg-opacity-90`}
  }
`;

export const transparentLight20 = css`
  ${tw`bg-light20 text-light border-light border-opacity-10`}
`;

export const transparentLight50 = css`
  ${tw`bg-light50 text-black border-light border-opacity-10`}
  &:hover {
    ${tw`bg-light bg-opacity-70 border-opacity-30`}
  }
`;
