import { useAccount } from "wagmi";

import { useHasAllowanceQuery } from "features/approve-allowance";
import { DetailedPair, useTotalSupplyQuery } from "shared/api/pool";
import { useCheckSolvency, useCurrencyBalanceQuery } from "shared/api/token";
import { Fee } from "shared/helpers";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

export const RM_LQ_STATUSES = [
  "removeLiquidity",
  "enterAmount",
  "insufficientTokenBalance",
  "insufficientLiquidity",
  "insufficientNativeTokenBalance",
] as const;

export const STATUSES = [
  "connectWallet",
  "wrongNetwork",
  "approve",
  ...RM_LQ_STATUSES,
] as const;

type Status = typeof STATUSES[number];

type Params = {
  fee: Fee | undefined;
  token: Token;
  tokenAmount: CurrencyAmount<Token>;
  detailedPair: DetailedPair;
};

export const useRemoveLiquidityStatus = ({
  fee,
  token,
  tokenAmount,
  detailedPair,
}: Params) => {
  const chain = useChain();
  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();
  const totalSupplyQuery = useTotalSupplyQuery(detailedPair.pair);

  const isSolvent = useCheckSolvency({
    fee,
    currencyAmount: tokenAmount,
  });

  const hasAllowanceQuery = useHasAllowanceQuery({
    currencyAmount: tokenAmount,
    spenderAddress: ROUTER_ADDRESS_MAP[chain.id],
  });

  const tokenBalanceQuery = useCurrencyBalanceQuery(token);

  const isEnoughBalanceToken = () => {
    return (
      tokenBalanceQuery.isSuccess &&
      typeof token.decimals === "number" &&
      tokenAmount.lessThanOrEqual(tokenBalanceQuery.data.balanceCA)
    );
  };

  const getStatus = (): Status => {
    if (!isConnected) return "connectWallet";
    if (isConnected && !isSupported) return "wrongNetwork";
    if (tokenAmount.equalTo(0)) return "enterAmount";
    if (!isEnoughBalanceToken()) return "insufficientTokenBalance";
    if (!isSolvent) return "insufficientNativeTokenBalance";
    if (totalSupplyQuery.data?.lessThan(tokenAmount)) {
      return "insufficientLiquidity";
    }

    if (hasAllowanceQuery.data === false) return "approve";
    return "removeLiquidity";
  };

  const status = getStatus();

  const getBtnDisabled = () => {
    if (
      status === "insufficientNativeTokenBalance" ||
      status === "insufficientTokenBalance" ||
      status === "insufficientLiquidity" ||
      status === "enterAmount"
    )
      return true;

    return false;
  };

  return { status, btnDisabled: getBtnDisabled() };
};
