import { PropsWithChildren } from "react";
import { useDebounce } from "react-use";
import cn from "classnames";
import { ethers } from "ethers";

import { useStateX } from "shared/hooks";
import { EmotionCSSProps } from "shared/types";
import { Input, Validator } from "shared/ui/input";

const VALIDATORS: Validator[] = [
  (value) => (value.includes("0x") ? ethers.utils.isAddress(value) : true),
];

type Props = {
  initialSearch?: string;
  onChange: (value: string) => void;
  onSelect?: (value: any) => void;
} & EmotionCSSProps;

export const SearchInput = ({
  initialSearch,
  className,
  css: cssProp,
  onChange,
}: PropsWithChildren<Props>) => {
  const [state, setState] = useStateX({
    search: initialSearch ?? "",
    isValid: true,
    enabled: false,
  });

  const { search, isValid, enabled } = state;

  useDebounce(
    () => {
      enabled && isValid && onChange(search);
    },
    200,
    [search]
  );

  return (
    <div className={cn("text-sm leading-5", className)} css={cssProp}>
      <Input
        id="currency-search"
        cancelable
        iconName="search"
        inputClassName={cn(!isValid && "!border-red")}
        placeholder="Search by name or paste an address"
        value={search}
        validators={VALIDATORS}
        onChange={({ value, isValid }) => {
          setState({
            search: value,
            enabled: true,
            isValid: isValid === null ? true : isValid,
          });
        }}
      />
    </div>
  );
};
