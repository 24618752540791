import { IconName } from "shared/ui/icon";

export type HeaderLink = {
  iconName: IconName;
  name: string;
  to: string;
  disabled: boolean;
};

export const EXTERNAL_LINKS = [
  {
    label: "Terms of Service",
    href: "https://occamx.gitbook.io/occamx-faq/terms-of-service",
  },
  {
    label: "Privacy Policy",
    href: "https://occamx.gitbook.io/occamx-faq/privacy-policy",
  },
  {
    label: "Documentation",
    href: "https://occamx.gitbook.io/occamx-faq/occamx-user-guide",
  },
];
