import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ethers } from "ethers";

import { useChangeNetwork } from "features/select-network/hooks/use-change-network";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import {
  ChainId,
  SWAP_CURRENCY_B_ADDRESS,
  WNATIVE,
} from "shared/v2-sdk/constants";

type State = {
  currencyA: string[];
  currencyB: string[];
};

const getDefaultCurrencies = (chainId: number, isHumanode: boolean) => {
  const currencyA = isHumanode
    ? WNATIVE[chainId].address
    : ethers.constants.AddressZero;
  const currencyB = SWAP_CURRENCY_B_ADDRESS[chainId];

  return { currencyA: [currencyA], currencyB: [currencyB] };
};

export const useSwapQueryParams = () => {
  const chain = useChain();
  const isHumanode = useIsHumanode();
  const [searchParams, setSearchParams] = useSearchParams(
    getDefaultCurrencies(chain.id, isHumanode)
  );

  useChangeNetwork(({ chain }) => {
    if (chain?.id) {
      const { currencyA, currencyB } = getDefaultCurrencies(
        chain.id,
        isHumanode
      );
      searchParams.set("currencyA", currencyA[0]);
      searchParams.set("currencyB", currencyB[0]);
      chain.unsupported
        ? searchParams.delete("chainId")
        : searchParams.set("chainId", chain.id.toString());
      setSearchParams(searchParams, { replace: true });
    }
  });

  const state: State = useMemo(() => {
    return {
      currencyA: searchParams.getAll("currencyA") as string[],
      currencyB: searchParams.getAll("currencyB") as string[],
    };
  }, [searchParams]);

  const setQuery = (partialState: State) => {
    const newState = {
      ...state,
      ...partialState,
    };
    setSearchParams(newState, {
      replace: true,
    });
  };

  return [state, setQuery] as [State, typeof setQuery];
};
