import { AddressZero } from "@ethersproject/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContractTransaction, errors } from "ethers";
import invariant from "tiny-invariant";
import { useAccount, useProvider, useSigner } from "wagmi";

import { HumanodeRouter__factory } from "shared/abi/types";
import { MilkomedaRouter__factory } from "shared/abi/types/factories/MilkomedaRouter__factory";
import { Fee } from "shared/helpers";
import {
  isActionRejectedError,
  isErrorContainsTransaction,
} from "shared/helpers/checkError";
import { reportToSentry } from "shared/helpers/reportToSentry";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency, CurrencyAmount, TradeType } from "shared/sdk-core";
import { notify } from "shared/ui/toast";
import {
  Router,
  Trade,
  TradeOptions,
  TradeOptionsDeadline,
} from "shared/v2-sdk";
import {
  ROUTER_ADDRESS_MAP,
  SWAP_METHODS_BY_CHAIN,
} from "shared/v2-sdk/constants";

import { tokenKeys } from "../token/keys";
import { useTxMutation, useTxWaitMutation } from "../transtaction";

type Options = {
  onTx?: () => void;
};

export const useSwapMutation = ({ onTx }: Options) => {
  const queryClient = useQueryClient();
  const chain = useChain();
  const { address } = useAccount();
  const provider = useProvider({ chainId: chain.id });
  const { data: signer } = useSigner();

  const txMutation = useTxMutation();
  const txWaitMutation = useTxWaitMutation();
  const isHumanode = useIsHumanode();

  return useMutation(
    async ({
      tradeOptions,
      trade,
      swapFee,
    }: // swapFee,
    {
      tradeOptions: TradeOptionsDeadline | TradeOptions;
      trade: Trade<Currency, Currency, TradeType>;
      swapFee: Fee;
    }) => {
      const routerFactory = isHumanode
        ? HumanodeRouter__factory
        : MilkomedaRouter__factory;
      const contractRouter = routerFactory.connect(
        ROUTER_ADDRESS_MAP[chain.id],
        signer || provider
      );

      const swapParameters = Router.swapCallParameters(trade, tradeOptions);

      invariant(
        swapParameters.methodName,
        "useSwapMutation. swapParameters.methodName is not defined"
      );

      const NAMES_MAP = SWAP_METHODS_BY_CHAIN[chain.id];
      const algoMethodName = NAMES_MAP[swapParameters.methodName];

      const args = swapParameters.args;

      const txPromise: Promise<ContractTransaction> = (contractRouter as any)[
        algoMethodName
      ](...args, {
        value: swapParameters.value,
        gasLimit: swapFee.gasLimit,
        gasPrice: swapFee.gasPrice,
      });

      const desc = `Swap ${trade.inputAmount.toSignificant()} ${
        trade.route.input.symbol
      } for ${trade.outputAmount.toSignificant()} ${trade.route.output.symbol}`;

      const tx = await txMutation.mutateAsync({ txPromise, desc });
      onTx?.();
      const receipt = await txWaitMutation.mutateAsync({
        tx,
        desc,
      });

      return receipt;
    },
    {
      onSuccess: async (data, params) => {
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.trade.inputAmount.currency.isToken
              ? params.trade.inputAmount.currency.address
              : AddressZero
          )
        );
        queryClient.refetchQueries(
          tokenKeys.balance(
            chain.id,
            address,
            params.trade.outputAmount.currency.isToken
              ? params.trade.outputAmount.currency.address
              : AddressZero
          )
        );

        queryClient.invalidateQueries(
          tokenKeys.allowance(
            params.trade.inputAmount.currency.isToken
              ? params.trade.inputAmount.currency.address
              : AddressZero,
            address,
            ROUTER_ADDRESS_MAP[chain.id]
          )
        );
      },
      onError: (err) => {
        const contractInterface = MilkomedaRouter__factory.createInterface();
        if (isActionRejectedError(err)) {
          notify.error("Transaction has been rejected by user");
          return;
        }

        if (isErrorContainsTransaction(err)) {
          notify.error(
            `Transaction has been rejected by reason: ${err.reason}`
          );
          reportToSentry("useSwapMutation. Tx rejected", {
            err,
            txDesc: contractInterface.parseTransaction(err.transaction),
          });
        }
      },
    }
  );
};
