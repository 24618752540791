import React from "react";
import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useProvider, useSigner } from "wagmi";

import { StackingOcxABI__factory } from "shared/abi/types";
import { calculateFee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { OCX_STAKE_CONTRACT_ADDRESS } from "shared/v2-sdk/constants";

import { ocxKeys } from "./ocxKeys";

type Params = {
  amountCA: CurrencyAmount<Token>;
};

export const useEstimateFeeUnstakeOcxQuery = ({ amountCA }: Params) => {
  const chain = useChain();
  const { address } = useAccount();
  const stakingOcxContractAddress = OCX_STAKE_CONTRACT_ADDRESS[chain.id];
  const { data: signer } = useSigner();
  const provider = useProvider({ chainId: chain.id });

  return useQuery(
    ocxKeys.estimateFeeUnstakeOcx(chain.id, address, stakingOcxContractAddress),
    async () => {
      invariant(signer, "useEstimateFeeUnstakeOcxQuery. signer is undefined");
      const contract = StackingOcxABI__factory.connect(
        stakingOcxContractAddress,
        signer
      );

      const estimatedGasBn = await contract.estimateGas.unstake(
        amountCA.quotient.toString(),
        "0"
      );

      const { gasPrice } = await provider.getFeeData();

      return calculateFee(gasPrice, estimatedGasBn, chain.id);
    },
    { enabled: Boolean(address && amountCA.greaterThan(0)) }
  );
};
