import { useAccount } from "wagmi";

import { useHasAllowanceQuery } from "features/approve-allowance";
import { useEstimateFeeStakeOcxQuery } from "shared/api/ocx";
import { useCheckSolvency, useCurrencyBalanceQuery } from "shared/api/token";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { OCX_STAKE_CONTRACT_ADDRESS, OWN_TOKEN } from "shared/v2-sdk/constants";

type Status =
  | "connectWallet"
  | "switchNetwork"
  | "approve"
  | "enterAmount"
  | "insufficientBalance"
  | "stake"
  | "insufficientNativeTokenBalance";

export const useStakeOcxStatus = (amountCA: CurrencyAmount<Token>) => {
  const chain = useChain();
  const ocxToken = OWN_TOKEN[chain.id];

  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();

  const hasAllowanceQuery = useHasAllowanceQuery({
    currencyAmount: amountCA,
    spenderAddress: OCX_STAKE_CONTRACT_ADDRESS[chain.id],
  });

  const estimatedFeeQuery = useEstimateFeeStakeOcxQuery({ amountCA });

  const isSolvent = useCheckSolvency({
    fee: estimatedFeeQuery.data,
    currencyAmount: amountCA,
  });

  const balanceInfoQuery = useCurrencyBalanceQuery(ocxToken);

  const isEnoughBalance = () => {
    return (
      balanceInfoQuery.isSuccess &&
      typeof ocxToken.decimals === "number" &&
      amountCA?.lessThanOrEqual(balanceInfoQuery.data.balanceCA)
    );
  };

  const getStatus = (): Status => {
    if (!isConnected) return "connectWallet";
    if (!isSupported) return "switchNetwork";
    if (amountCA.equalTo(0)) return "enterAmount";
    if (!isEnoughBalance()) return "insufficientBalance";
    if (!isSolvent) return "insufficientNativeTokenBalance";

    if (hasAllowanceQuery.data === false) return "approve";

    return "stake";
  };

  const status = getStatus();

  const getBtnDisabled = () => {
    return status === "enterAmount" || status === "insufficientBalance";
  };

  const getBtnText = () => {
    if (status === "enterAmount") return "Enter amount";
    if (status === "insufficientBalance")
      return `Insufficient ${ocxToken.symbol} balance`;

    if (status === "insufficientNativeTokenBalance")
      return `No ${Native.byChainId(chain.id).symbol} to pay fee`;

    return "Stake";
  };

  return { status, btnDisabled: getBtnDisabled(), btnText: getBtnText() };
};
