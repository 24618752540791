import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";
import { Button } from "shared/ui/button";

import { HeaderLink as HeaderLinkType } from "../constants";

type Props = {
  disabled?: boolean;
  idx: number;
  btnClassName?: string;
  onClick?: () => void;
} & HeaderLinkType &
  EmotionCSSProps;

export const HeaderLink = ({
  disabled = false,
  iconName,
  name,
  to,
  idx,
  className,
  btnClassName,
  onClick,
}: Props) => {
  const { pathname } = useLocation();

  const isActiveFn = (isActive: boolean, to: string) => {
    const isFarm = pathname.includes("farms") || pathname.includes("farm");
    const isPool = pathname.includes("pool");
    const isAdd = /^\/add\/?/.test(pathname);

    return (
      (isFarm && to === "/farm") ||
      ((isPool || isAdd) && to === "/pools") ||
      isActive
    );
  };

  return (
    <NavLink
      className={cn(
        className,
        "inline-flex cursor-default",
        disabled && "pointer-events-none"
      )}
      to={to}
      onClick={onClick}
    >
      {({ isActive }) => {
        const isCustomActive = isActiveFn(isActive, to);
        return (
          <>
            <Button
              disabled={disabled}
              id={`header-button-${name}`}
              iconName={iconName}
              // iconClassName={"text-dodgerBlue"}
              theme={"lightDodgerBlue"}
              className={cn(
                `w-[114px] max-lg:w-auto`,
                isCustomActive
                  ? "light-dodger-blue-hover-shadow pointer-events-none !border-dodgerBlue !bg-dodgerBlue !text-light"
                  : "pointer-events-auto !border-transparent opacity-75 hover:opacity-75",
                btnClassName
              )}
            >
              {name}
            </Button>
          </>
        );
      }}
    </NavLink>
  );
};
