import { errors, Transaction } from "ethers";

type ActionRejectedError = {
  action: string;
  code: "ACTION_REJECTED";
  reason: string;
  transaction: Transaction;
};

type ErrorWithTransaction = {
  transaction: Transaction;
  reason: string;
};

export const isActionRejectedError = (
  err: unknown
): err is ActionRejectedError => {
  return Boolean(
    err &&
      typeof err === "object" &&
      "code" in err &&
      err.code === errors.ACTION_REJECTED
  );
};

export const isErrorContainsTransaction = (
  err: unknown
): err is ErrorWithTransaction => {
  return Boolean(err && typeof err === "object" && "transaction" in err);
};
