import React, { PropsWithChildren, ReactNode } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";
import { Icon, IconName } from "shared/ui/icon";

type CommonProps = {
  id?: string;
  onIconClick: () => void;
  headerClassName?: string;
} & EmotionCSSProps &
  PropsWithChildren;

type PropsWithIcon = {
  icon: ReactNode;
  iconName?: never;
  onIconClick?: never;
} & CommonProps;

type PropsWithIconName = {
  icon?: never;
  iconName?: IconName;
  onIconClick?: () => void;
} & CommonProps;

export const Header = ({
  id,
  className,
  headerClassName,
  css: cssProp,
  children,
  icon,
  iconName = "close",
  onIconClick,
}: PropsWithIcon | PropsWithIconName) => {
  return (
    <div css={cssProp} className={className}>
      <header
        className={cn(
          headerClassName,
          "relative mb-[18px] mt-[10px] flex select-none items-center max-2xl:mb-3"
        )}
      >
        {typeof children === "string" ? (
          <h2
            id={id ? `${id}-h2` : undefined}
            className="inline-flex text-2xl text-black max-2xl:text-xl"
          >
            {children}
          </h2>
        ) : (
          children
        )}

        {icon ?? (
          <button
            id={id ? `${id}-icon` : undefined}
            className="hover:text-redMalibu absolute right-0 top-0 p-2 text-dodgerBlue transition-colors active:text-malibu"
            onClick={onIconClick}
          >
            <Icon name={iconName} size="22" />
          </button>
        )}
      </header>
      <div className="h-[1px] w-full bg-dodgerBlue opacity-25" />
    </div>
  );
};
