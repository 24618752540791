import { getAddress } from "ethers/lib/utils";

import { Token } from "shared/sdk-core";

import { ApiToken } from "../types";

export const deserializeToken = ({
  chainId,
  address,
  decimals,
  symbol,
  name,
  logoURI,
}: ApiToken) =>
  new Token(chainId, getAddress(address), decimals, symbol, name, logoURI);

export const deserializeTokens = (apiTokens: ApiToken[]) => {
  return apiTokens.map((t) => deserializeToken(t));
};
