import { useState } from "react";
import cn from "classnames";

import { Currency, CurrencyAmount } from "shared/sdk-core";
import { Text } from "shared/ui/text";

type Props = {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  currencyAToCurrencyBRate: CurrencyAmount<Currency> | undefined;
  currencyBToCurrencyARate: CurrencyAmount<Currency> | undefined;
  currencyAToUSDRate: CurrencyAmount<Currency> | undefined;
  currencyBToUSDRate: CurrencyAmount<Currency> | undefined;
  isHighPriceImpact: boolean;
  toggled?: boolean;
};

export const PriceCalculator = ({
  currencyA,
  currencyB,
  currencyAToCurrencyBRate,
  currencyBToCurrencyARate,
  currencyAToUSDRate,
  currencyBToUSDRate,
  isHighPriceImpact,
  toggled = false,
}: Props) => {
  const [priceToggled, togglePrice] = useState(toggled);

  return (
    <Text
      className={cn(
        "ml-auto inline-flex cursor-pointer items-center whitespace-nowrap text-sm text-osloGray max-sm:ml-0 max-sm:mt-2",
        isHighPriceImpact && "text-redOrange"
      )}
      onClick={() => togglePrice(!priceToggled)}
    >
      {priceToggled ? (
        <>
          1 {currencyA?.symbol} = {currencyAToCurrencyBRate?.toSignificant(6)}{" "}
          {currencyB?.symbol}{" "}
          {currencyBToUSDRate && (
            <span className="ml-1">
              ≈${currencyBToUSDRate.toSignificant(6)}
            </span>
          )}
        </>
      ) : (
        <>
          1 {currencyB?.symbol} = {currencyBToCurrencyARate?.toSignificant(6)}{" "}
          {currencyA?.symbol}{" "}
          {currencyAToUSDRate && (
            <span className="ml-1">
              ≈${currencyAToUSDRate.toSignificant(6)}
            </span>
          )}
        </>
      )}
    </Text>
  );
};
