import { useNetwork } from "wagmi";

import { useDefaultChain } from "shared/providers/default-chain/provider";

import { useIsSupportedChain } from "./useIsSupportedChain";

export const useChain = () => {
  const { chain, chains } = useNetwork();
  const [defaultChain] = useDefaultChain();

  const isSupported = useIsSupportedChain();

  if (!isSupported) return defaultChain;
  return chain ?? chains[0] ?? defaultChain;
};
