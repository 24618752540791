import React, { PropsWithChildren, useContext, useMemo } from "react";
import { GraphQLClient } from "graphql-request";

import { getSdk } from "shared/gql";
import { useChain } from "shared/providers/wagmi";
import { ENDPOINTS_BY_CHAIN_ID } from "shared/v2-sdk/constants";

const GraphQLContext = React.createContext<
  ReturnType<typeof getSdk> | undefined
>(undefined);

// TODO: Apollo client?
export const GraphQlProvider = ({ children }: PropsWithChildren) => {
  const chain = useChain();
  const endpoints = ENDPOINTS_BY_CHAIN_ID[chain.id];

  const sdk = useMemo(
    () => getSdk(new GraphQLClient(endpoints.graphQl)),
    [endpoints.graphQl]
  );

  return (
    <GraphQLContext.Provider value={sdk}>{children}</GraphQLContext.Provider>
  );
};

export const useGraphQlSdk = () => {
  const context = useContext(GraphQLContext);
  if (!context)
    throw new Error("useGraphQL must be used within a GraphQlProvider");

  return context;
};
