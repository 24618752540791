import tw, { css } from "twin.macro";

export const btnCss = css`
  ${tw`relative inline-flex items-center justify-center outline-none select-none border border-solid transition-colors cursor-pointer`}
`;

export const btnContentCss = css`
  ${tw`flex items-center`}
`;

export const btnContentReversedCss = css`
  ${tw`flex-row-reverse`}
`;

export const btnDisabledCss = tw`pointer-events-none opacity-50`;

export const loadingCss = css`
  ${tw`flex items-center justify-center absolute top-0 bottom-0 right-0 left-0 z-10`}
`;
