import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useProvider } from "wagmi";

import { useChain } from "shared/providers/wagmi";
import { Token } from "shared/sdk-core";

import { fetchStakingBalance } from "./helpers";
import { farmsKeys } from "./keys";

export const useStakingBalanceQuery = (
  stakingContractAddress: string | undefined,
  ltToken: Token
) => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const { address } = useAccount();

  const query = useQuery(
    farmsKeys.stakingBalance(chain.id, address, stakingContractAddress),
    async () => {
      invariant(provider, "useStakingBalanceQuery. provider is undefined");
      invariant(address, "useStakingBalanceQuery. address is undefined");
      invariant(
        stakingContractAddress,
        "useStakingBalanceQuery. address is undefined"
      );

      return await fetchStakingBalance(
        address,
        stakingContractAddress,
        provider,
        ltToken
      );
    },

    {
      enabled: Boolean(address && provider && stakingContractAddress),
    }
  );

  return query;
};
