import { PropsWithChildren, ReactNode } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

type Props = {
  icon?: ReactNode;
} & EmotionCSSProps;

export const Header = ({
  className,
  css: cssProp,
  children,
  icon,
}: PropsWithChildren<Props>) => {
  return (
    <div
      css={cssProp}
      className={cn(
        "flex items-center justify-between border-b border-solid border-tiara pb-2",
        className
      )}
    >
      {typeof children === "string" ? (
        <span className="inline-flex select-none text-lg text-black">
          {children}
        </span>
      ) : (
        children
      )}

      {icon}
    </div>
  );
};
