import React, { PropsWithChildren, useContext, useMemo } from "react";
import { ethers } from "ethers";
import invariant from "tiny-invariant";

import { useCurrenciesQuery } from "shared/api/token";
import { Currency } from "shared/sdk-core";
import { PageSpinner } from "shared/ui/spinner";

import { useCustomCurrencies } from "./custom-currencies-provider";

const CurrenciesContext = React.createContext<Currency[] | undefined>(
  undefined
);

export const CurrenciesProvider = ({ children }: PropsWithChildren) => {
  const currenciesQuery = useCurrenciesQuery();
  const [customCurrencies] = useCustomCurrencies();

  const allCurrencies = useMemo(() => {
    if (currenciesQuery.isSuccess && currenciesQuery.data) {
      return [...customCurrencies, ...currenciesQuery.data];
    }
    return undefined;
  }, [customCurrencies, currenciesQuery.data, currenciesQuery.isSuccess]);

  if (currenciesQuery.isFetching) return <PageSpinner />;

  return (
    <CurrenciesContext.Provider value={allCurrencies}>
      {children}
    </CurrenciesContext.Provider>
  );
};

export const useCurrencies = () => {
  const currencies = useContext(CurrenciesContext);
  invariant(
    currencies,
    "useCurrencies must be used within a CurrenciesProvider"
  );
  return currencies;
};

export const useCurrenciesMap = () => {
  const currencies = useContext(CurrenciesContext);
  invariant(
    currencies,
    "useCurrencies must be used within a CurrenciesProvider"
  );

  const map = useMemo(() => {
    return currencies.reduce((acc, item) => {
      if (item.isNative) {
        acc[ethers.constants.AddressZero] = item;
      } else {
        acc[item.address] = item;
      }

      return acc;
    }, {} as Record<string, Currency | undefined>);
  }, [currencies]);

  return map;
};
