import JSBI from "jsbi";

import { Token } from "shared/sdk-core";

export const ChainId = {
  MILKOMEDA_ADA_TESTNET: 200101,
  MILKOMEDA_ADA: 2001,
  HUMANODE_TESTNET: 14853,
  HUMANODE_MAINNET: 5234,
};

// https://uniswap.org/whitepaper.pdf 2.4 Protocol fee
export const LIQUIDITY_PROVIDER_FEE_PERCENT = "0.3";
export const LIQUIDITY_PROVIDER_FEE_ABS = "0.003";

export const TOKEN_LIST_MAP = {
  [ChainId.MILKOMEDA_ADA]:
    "https://raw.githubusercontent.com/OccamX-MilkomedaDEX/assets/main/blockchains/2001/tokenList.json",
  [ChainId.MILKOMEDA_ADA_TESTNET]:
    "https://raw.githubusercontent.com/OccamX-MilkomedaDEX/assets/main/blockchains/200101/tokenList.json",
  [ChainId.HUMANODE_TESTNET]:
    "https://raw.githubusercontent.com/OccamX-MilkomedaDEX/assets/main/blockchains/14853/tokenList.json",
  [ChainId.HUMANODE_MAINNET]:
    "https://raw.githubusercontent.com/OccamX-MilkomedaDEX/assets/main/blockchains/5234/tokenList.json",
};

export const FIXED_CURRENCIES = {
  [ChainId.MILKOMEDA_ADA]: {
    token1: "0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98",
    token2: "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283",
  },
  [ChainId.MILKOMEDA_ADA_TESTNET]: {
    token1: "0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98",
    token2: "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283",
  },
  [ChainId.HUMANODE_TESTNET]: {
    token1: "0xcdbe68f511b17BDE1265a2B69966f0d3EFFfFce2",
    token2: "0x0000000000000000000000000000000000000802",
  },
  [ChainId.HUMANODE_MAINNET]: {
    token1: "0x37C57a89812a0D492AeEd7691F1610CA0a8f74A1",
    token2: "0x0000000000000000000000000000000000000802",
  },
};

const milkomedaBridges = [
  {
    label: "Milkomeda",
    link: "https://occamx.gitbook.io/occamx-faq/bridge-milkomeda-cardano",
  },
  {
    label: "Symbiosis",
    link: "https://app.symbiosis.finance/swap",
  },
  {
    label: "Celer",
    link: "https://cbridge.celer.network/#/transfer",
  },
];
const humanodeBridges = [
  {
    label: "ChainPort",
    link: "https://app.chainport.io/",
  },
];

export const BRIDGES_LINKS = {
  [ChainId.MILKOMEDA_ADA]: milkomedaBridges,
  [ChainId.MILKOMEDA_ADA_TESTNET]: milkomedaBridges,
  [ChainId.HUMANODE_TESTNET]: humanodeBridges,
  [ChainId.HUMANODE_MAINNET]: humanodeBridges,
};

export const NATIVE: Record<
  string | number,
  {
    name: string;
    symbol: string;
    decimals: number;
    logoURI: string;
  }
> = {
  [ChainId.MILKOMEDA_ADA]: {
    decimals: 18,
    name: "milk ADA",
    symbol: "mADA",
    logoURI: "/tokens/cardano.svg",
  },
  [ChainId.MILKOMEDA_ADA_TESTNET]: {
    decimals: 18,
    name: "milk ADA",
    symbol: "mADA",
    logoURI: "/tokens/cardano.svg",
  },
  [ChainId.HUMANODE_TESTNET]: {
    decimals: 18,
    name: "Humanode",
    symbol: "eHMND",
    logoURI: "/tokens/humanode.png",
  },
  [ChainId.HUMANODE_MAINNET]: {
    decimals: 18,
    name: "Humanode",
    symbol: "eHMND",
    logoURI: "/tokens/humanode.png",
  },
};

export const WNATIVE: Record<string | number, Token> = {
  [ChainId.MILKOMEDA_ADA]: new Token(
    ChainId.MILKOMEDA_ADA,
    "0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9",
    18,
    "wADA",
    "Wrapped ADA",
    "/tokens/cardano.svg"
  ),
  [ChainId.MILKOMEDA_ADA_TESTNET]: new Token(
    ChainId.MILKOMEDA_ADA_TESTNET,
    "0x01BbBB9C97FC43e3393E860fc8BbeaD47B6960dB",
    18,
    "wADA",
    "Wrapped ADA",
    "/tokens/cardano.svg"
  ),
  [ChainId.HUMANODE_TESTNET]: new Token(
    ChainId.MILKOMEDA_ADA_TESTNET,
    "0x0000000000000000000000000000000000000802",
    18,
    "WeHMND",
    "Wrapped Humanode",
    "/tokens/humanode.png"
  ),
  [ChainId.HUMANODE_MAINNET]: new Token(
    ChainId.HUMANODE_MAINNET,
    "0x0000000000000000000000000000000000000802",
    18,
    "WeHMND",
    "Wrapped Humanode",
    "/tokens/humanode.png"
  ),
};

export const OWN_TOKEN: Record<string | number, Token> = {
  [ChainId.MILKOMEDA_ADA]: new Token(
    ChainId.MILKOMEDA_ADA,
    "0xf0c73e6287867baa4f865a17ee711ec989c78ac0",
    18,
    "OCX",
    "OccamX Protocol Token",
    "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/OCX.svg"
  ),
  [ChainId.MILKOMEDA_ADA_TESTNET]: new Token(
    ChainId.MILKOMEDA_ADA,
    "0xcE5eC4569b0ec9E9dE311dB566473234c337c443",
    18,
    "OCX",
    "OccamX Protocol Token",
    "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/OCX.svg"
  ),
  [ChainId.HUMANODE_TESTNET]: new Token(
    ChainId.HUMANODE_TESTNET,
    "0xcdbe68f511b17BDE1265a2B69966f0d3EFFfFce2",
    18,
    "OCX",
    "OccamX Protocol Token",
    "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/OCX.svg"
  ),
  [ChainId.HUMANODE_MAINNET]: new Token(
    ChainId.HUMANODE_MAINNET,
    "0x37C57a89812a0D492AeEd7691F1610CA0a8f74A1",
    18,
    "OCX",
    "OccamX Protocol Token",
    "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/OCX.svg"
  ),
};

export const OCX_STAKE_CONTRACT_ADDRESS: Record<string | number, string> = {
  [ChainId.MILKOMEDA_ADA]: "0xB1B481A03745C516A23E77B8D8934b13079C7b5c",
  [ChainId.MILKOMEDA_ADA_TESTNET]: "0x5C8f97bb728294AF234A33D6bb213ED1F1a2FDcd",
  [ChainId.HUMANODE_TESTNET]: "0xFAa3859eB3De3Bd1136E7989774397eBeD37cd66",
  // TODO: WRONG. the same as HUMANODE_TESTNET
  [ChainId.HUMANODE_MAINNET]: "0xFAa3859eB3De3Bd1136E7989774397eBeD37cd66",
};

export const SWAP_CURRENCY_B_ADDRESS: Record<string | number, string> = {
  [ChainId.MILKOMEDA_ADA]: "0xf0c73E6287867bAa4F865A17EE711ec989c78AC0",
  [ChainId.MILKOMEDA_ADA_TESTNET]: "0x523B7f675b4195ade5FFA3Bc9fF33618Ed9cf329",
  [ChainId.HUMANODE_TESTNET]: "0xcdbe68f511b17BDE1265a2B69966f0d3EFFfFce2",
  [ChainId.HUMANODE_MAINNET]: "0x37C57a89812a0D492AeEd7691F1610CA0a8f74A1",
};

export const FACTORY_ADDRESS_MAP: Record<string | number, string> = {
  [ChainId.MILKOMEDA_ADA_TESTNET]: "0x428779a1A596c9cFdB68f5DaEf78b14901B95566",
  [ChainId.MILKOMEDA_ADA]: "0x2ef06A90b0E7Ae3ae508e83Ea6628a3987945460",
  [ChainId.HUMANODE_TESTNET]: "0x33aAD6A78FBe97a5BA22CB76719e530C551fad76",
  [ChainId.HUMANODE_MAINNET]: "0xB3efe4fEcA11B628c279f919dCC8eC5Fa13F1ba0",
};

export const ZZAP_ADDRESS_MAP: Record<string | number, string> = {
  [ChainId.MILKOMEDA_ADA]: "0x5b96Fef215A3e53A6E20c0e4Eef30a0e0815eF2E",
  [ChainId.MILKOMEDA_ADA_TESTNET]: "0xBCE0d5DC3DAa27f04B0B9e6f627eC97Ec2f428F4",
  [ChainId.HUMANODE_TESTNET]: "0x688f36a929Ca440Feece9bB0970ee4153Ad4e91D",
  [ChainId.HUMANODE_MAINNET]: "0x8991054E6B5cA3b2647f1BB9BBd0100433a8ae8E",
};

export const EXPLORER_ADDRESS_MAP: Record<string | number, string> = {
  [ChainId.MILKOMEDA_ADA_TESTNET]:
    "https://explorer-devnet-cardano-evm.c1.milkomeda.com",
  [ChainId.MILKOMEDA_ADA]:
    "https://explorer-mainnet-cardano-evm.c1.milkomeda.com",
  [ChainId.HUMANODE_TESTNET]: "https://humanode.io",
  [ChainId.HUMANODE_MAINNET]: "https://humanode.subscan.io",
};

export const ROUTER_ADDRESS_MAP: Record<string | number, string> = {
  [ChainId.MILKOMEDA_ADA_TESTNET]: "0x602153500e5f4F331044908249B1A6457Bd1a392",
  [ChainId.MILKOMEDA_ADA]: "0x9CdcE24c0e67611B698E6C228BF7791D4ECc553A",
  [ChainId.HUMANODE_TESTNET]: "0x192D27BFFdd70F3Ed9E21a994f8ff21F4b3C0a5A",
  [ChainId.HUMANODE_MAINNET]: "0xbe0A9696F5Fb8416C528360c5455332783DC6aB6",
};

export const INIT_CODE_HASH_MAP: Record<string | number, string> = {
  [ChainId.MILKOMEDA_ADA_TESTNET]:
    "0x0103d86123641edae209ed992b2bf060ae2baab6a28f5b1eb7dad61335915104",
  [ChainId.MILKOMEDA_ADA]:
    "0x0103d86123641edae209ed992b2bf060ae2baab6a28f5b1eb7dad61335915104",
  [ChainId.HUMANODE_TESTNET]:
    "0x319b4baaa882fbc0a7b95dd46abb1507c905f22030ffa860e14b73a795eb80e1",
  [ChainId.HUMANODE_MAINNET]:
    "0x319b4baaa882fbc0a7b95dd46abb1507c905f22030ffa860e14b73a795eb80e1",
};

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000);

export const USDT = {
  [ChainId.MILKOMEDA_ADA_TESTNET]: new Token(
    ChainId.MILKOMEDA_ADA_TESTNET,
    "0x523B7f675b4195ade5FFA3Bc9fF33618Ed9cf329",
    18,
    "USDC",
    "USD Coin",
    "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/USD_Coin_icon.png"
  ),
  [ChainId.MILKOMEDA_ADA]: new Token(
    ChainId.MILKOMEDA_ADA,
    "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283",
    6,
    "ceUSDT",
    "Tether USD",
    "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png"
  ),

  [ChainId.HUMANODE_TESTNET]: new Token(
    ChainId.MILKOMEDA_ADA_TESTNET,
    "0x0000000000000000000000000000000000000802",
    18,
    "WeHMND",
    "Wrapped Humanode",
    "/tokens/humanode.png"
  ),
  [ChainId.HUMANODE_MAINNET]: new Token(
    ChainId.MILKOMEDA_ADA_TESTNET,
    "0x33E5b3e24501774598367bc0832B52787aC39Ca5",
    18,
    "USDC",
    "USD Coin",
    "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/USD_Coin_icon.png"
  ),
};

type ChainEndpoints = {
  graphQl: string;
};

console.log(
  "REACT_APP_GRAPHQL_MAINNET_ENDPOINT",
  process.env.REACT_APP_GRAPHQL_MAINNET_ENDPOINT
);
console.log(
  "REACT_APP_GRAPHQL_TESTNET_ENDPOINT",
  process.env.REACT_APP_GRAPHQL_TESTNET_ENDPOINT
);
console.log(
  "REACT_APP_GRAPHQL_HUMANODE_TESTNET_ENDPOINT",
  process.env.REACT_APP_GRAPHQL_HUMANODE_TESTNET_ENDPOINT
);
console.log(
  "REACT_APP_GRAPHQL_HUMANODE_MAINNET_ENDPOINT",
  process.env.REACT_APP_GRAPHQL_HUMANODE_MAINNET_ENDPOINT
);
console.log("REACT_APP_STAND", process.env.REACT_APP_STAND);

export const ENDPOINTS_BY_CHAIN_ID: Record<string | number, ChainEndpoints> = {
  [ChainId.MILKOMEDA_ADA]: {
    graphQl: `${
      process.env.REACT_APP_GRAPHQL_MAINNET_ENDPOINT ??
      "https://api-mainnet.occam-x.fi"
    }/query`,
  },
  [ChainId.MILKOMEDA_ADA_TESTNET]: {
    graphQl: `${
      process.env.REACT_APP_GRAPHQL_TESTNET_ENDPOINT ??
      "https://api-testnet.occam-x.fi"
    }/query`,
  },
  [ChainId.HUMANODE_TESTNET]: {
    graphQl: `${
      process.env.REACT_APP_GRAPHQL_HUMANODE_TESTNET_ENDPOINT ||
      "https://api-hmnd-testnet.occam-x.fi"
    }/query`,
  },
  [ChainId.HUMANODE_MAINNET]: {
    graphQl: `${
      // process.env.REACT_APP_GRAPHQL_HUMANODE_MAINNET_ENDPOINT ||
      "https://api-hmnd-mainnet.occam-x.fi"
    }/query`,
  },
};

export type MethodNames =
  | "swapADAForExactTokens"
  | "swapExactADAForTokens"
  | "swapExactADAForTokensSupportingFeeOnTransferTokens"
  | "swapExactTokensForADA"
  | "swapExactTokensForADASupportingFeeOnTransferTokens"
  | "swapExactTokensForTokens"
  | "swapExactTokensForTokensSupportingFeeOnTransferTokens"
  | "swapTokensForExactADA"
  | "swapTokensForExactTokens";

// TODO: refactor it
export const SWAP_METHODS_BY_CHAIN: {
  [x: number | string]: Record<string, MethodNames>;
} = {
  [ChainId.MILKOMEDA_ADA]: {
    swapExactETHForTokensSupportingFeeOnTransferTokens:
      "swapExactADAForTokensSupportingFeeOnTransferTokens",
    swapExactTokensForETHSupportingFeeOnTransferTokens:
      "swapExactTokensForADASupportingFeeOnTransferTokens",
    swapExactTokensForTokensSupportingFeeOnTransferTokens:
      "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    swapExactTokensForTokens: "swapExactTokensForTokens",
    swapTokensForExactTokens: "swapTokensForExactTokens",
    swapExactETHForTokens: "swapExactADAForTokens",
    swapTokensForExactETH: "swapTokensForExactADA",
    swapExactTokensForETH: "swapExactTokensForADA",
    swapETHForExactTokens: "swapADAForExactTokens",
  },
  [ChainId.MILKOMEDA_ADA_TESTNET]: {
    swapExactETHForTokensSupportingFeeOnTransferTokens:
      "swapExactADAForTokensSupportingFeeOnTransferTokens",
    swapExactTokensForETHSupportingFeeOnTransferTokens:
      "swapExactTokensForADASupportingFeeOnTransferTokens",
    swapExactTokensForTokensSupportingFeeOnTransferTokens:
      "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    swapExactTokensForTokens: "swapExactTokensForTokens",
    swapTokensForExactTokens: "swapTokensForExactTokens",
    swapExactETHForTokens: "swapExactADAForTokens",
    swapTokensForExactETH: "swapTokensForExactADA",
    swapExactTokensForETH: "swapExactTokensForADA",
    swapETHForExactTokens: "swapADAForExactTokens",
  },
  [ChainId.HUMANODE_TESTNET]: {
    swapExactTokensForTokensSupportingFeeOnTransferTokens:
      "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    swapExactTokensForTokens: "swapExactTokensForTokens",
    swapTokensForExactTokens: "swapTokensForExactTokens",
  },
  [ChainId.HUMANODE_MAINNET]: {
    swapExactTokensForTokensSupportingFeeOnTransferTokens:
      "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    swapExactTokensForTokens: "swapExactTokensForTokens",
    swapTokensForExactTokens: "swapTokensForExactTokens",
  },
};

// exports for internal consumption
export const ZERO = JSBI.BigInt(0);
export const ONE = JSBI.BigInt(1);
export const FIVE = JSBI.BigInt(5);
export const _997 = JSBI.BigInt(997);
export const _1000 = JSBI.BigInt(1000);
