import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useProvider } from "wagmi";

import { UniswapV2Pair__factory } from "shared/abi/types";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount } from "shared/sdk-core";
import { Pair } from "shared/v2-sdk";

import { poolKeys } from "./keys";

export const useTotalSupplyQuery = (pair: Pair | undefined) => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  return useQuery({
    queryKey: poolKeys.totalSupply(pair?.chainId, pair?.liquidityToken.address),
    queryFn: async () => {
      invariant(pair, "useTotalSupplyQuery. pair is undefined");
      const contract = UniswapV2Pair__factory.connect(
        pair.liquidityToken.address,
        provider
      );

      const totalSupplyBn = await contract.totalSupply();

      return CurrencyAmount.fromRawAmount(
        pair.liquidityToken,
        totalSupplyBn.toString()
      );
    },
    enabled: Boolean(provider && pair),
  });
};
