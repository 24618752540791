import React, { ReactNode } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";
import { Text } from "shared/ui/text";

export type Status =
  | "unsupported"
  | "connecting"
  | "connected"
  | "error"
  | undefined;

const STATUS_MAP: Record<Exclude<Status, undefined>, ReactNode> = {
  unsupported: "Comming Soon",
  connecting: "Connecting...",
  connected: <span className="text-malachite">Connected</span>,
  error: (
    <span className="max-w-[160px] text-right leading-tight text-redOrange">
      Failed to connect. Click to retry.
    </span>
  ),
};

type Props = {
  id?: string;
  disabled?: boolean;
  status?: Status;
  name: string;
  onClick?: () => void;
  logo?: ReactNode;
} & EmotionCSSProps;
export const Connector = ({
  id,
  className,
  css: cssProps,
  name,
  status,
  logo,
  disabled = false,
  onClick,
}: Props) => {
  return (
    <button
      id={id}
      css={cssProps}
      disabled={disabled}
      className={cn(
        "flex max-h-[62px] w-full items-center rounded-3xl border border-solid border-tiara bg-transparent p-4 transition-colors hover:bg-tiara hover:bg-opacity-10",
        className
      )}
      onClick={onClick}
    >
      <Text className="mr-auto inline-flex text-lg text-black">{name}</Text>
      {status && (
        <Text className="ml-auto inline-flex select-none text-osloGray">
          {STATUS_MAP[status]}
        </Text>
      )}
      {logo}
    </button>
  );
};
