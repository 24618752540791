import { toDecimal } from "utils/numbers";
import { useAccount } from "wagmi";

import { useHasAllowanceQuery } from "features/approve-allowance";
import { MiningPanel } from "shared/api/farm";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { getPairTokenDisplayData, toCurrencyAmount } from "shared/helpers";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";

type Status =
  | "connectWallet"
  | "switchNetwork"
  | "approve"
  | "enterAmount"
  | "insufficientBalance"
  | "stake";

export const useStakeStatus = (miningPanel: MiningPanel, amount: string) => {
  const chain = useChain();
  const amountCA = toCurrencyAmount(
    miningPanel.detailedPair.pair.liquidityToken,
    amount
  );

  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();
  const liquidityToken = miningPanel.detailedPair.pair.liquidityToken;

  const hasAllowanceQuery = useHasAllowanceQuery({
    currencyAmount: amountCA,
    spenderAddress: miningPanel.address,
  });

  const balanceInfoQuery = useCurrencyBalanceQuery(
    miningPanel.detailedPair.pair.liquidityToken
  );

  const isEnoughBalance = () => {
    return (
      balanceInfoQuery.isSuccess &&
      typeof liquidityToken.decimals === "number" &&
      amountCA?.lessThanOrEqual(balanceInfoQuery.data.balanceCA)
    );
  };

  const getStatus = (): Status => {
    if (!isConnected) return "connectWallet";
    if (!isSupported) return "switchNetwork";
    if (toDecimal(amount).eq(0)) return "enterAmount";
    if (!isEnoughBalance()) return "insufficientBalance";
    if (hasAllowanceQuery.data === false) return "approve";

    return "stake";
  };

  const status = getStatus();

  const getBtnDisabled = () => {
    return status === "enterAmount" || status === "insufficientBalance";
  };

  const getBtnText = () => {
    if (status === "enterAmount") return "Enter amount";
    if (status === "insufficientBalance")
      return `Insufficient ${
        getPairTokenDisplayData(miningPanel.detailedPair.pair.token0, chain.id)
          .symbol
      }/${
        getPairTokenDisplayData(miningPanel.detailedPair.pair.token1, chain.id)
          .symbol
      } balance`;

    return "Stake";
  };

  return { status, btnDisabled: getBtnDisabled(), btnText: getBtnText() };
};
