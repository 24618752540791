import { useAccount } from "wagmi";

import {
  useEstimateFeeUnstakeOcxQuery,
  useOcxStakingBalanceQuery,
} from "shared/api/ocx";
import { useCheckSolvency } from "shared/api/token";
import { useChain, useIsSupportedChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { OWN_TOKEN } from "shared/v2-sdk/constants";

type Status =
  | "connectWallet"
  | "switchNetwork"
  | "enterAmount"
  | "insufficientBalance"
  | "unstake"
  | "insufficientNativeTokenBalance";

export const useUnstakeOcxStatus = (amountCA: CurrencyAmount<Token>) => {
  const chain = useChain();
  const ocxToken = OWN_TOKEN[chain.id];

  const { isConnected } = useAccount();
  const isSupported = useIsSupportedChain();

  const estimatedFeeQuery = useEstimateFeeUnstakeOcxQuery({ amountCA });

  const isSolvent = useCheckSolvency({
    fee: estimatedFeeQuery.data,
    currencyAmount: amountCA,
  });

  const balanceInfoQuery = useOcxStakingBalanceQuery();

  const isEnoughBalance = () => {
    return (
      balanceInfoQuery.isSuccess &&
      typeof ocxToken.decimals === "number" &&
      amountCA?.lessThanOrEqual(balanceInfoQuery.data.balanceCA)
    );
  };

  const getStatus = (): Status => {
    if (!isConnected) return "connectWallet";
    if (!isSupported) return "switchNetwork";
    if (amountCA.equalTo(0)) return "enterAmount";
    if (!isEnoughBalance()) return "insufficientBalance";
    if (!isSolvent) return "insufficientNativeTokenBalance";
    // if (hasAllowanceQuery.data === false) return "approve";

    return "unstake";
  };

  const status = getStatus();

  const getBtnDisabled = () => {
    return status === "enterAmount" || status === "insufficientBalance";
  };

  const getBtnText = () => {
    if (status === "enterAmount") return "Enter amount";
    if (status === "insufficientBalance")
      return `Insufficient ${ocxToken.symbol} balance`;

    if (status === "insufficientNativeTokenBalance")
      return `No ${Native.byChainId(chain.id).symbol} to pay fee`;

    return "Unstake";
  };

  return { status, btnDisabled: getBtnDisabled(), btnText: getBtnText() };
};
