import { BigNumber, Signer } from "ethers";

import { HumanodeRouter, MilkomedaRouter } from "shared/abi/types";
import { calculateFee } from "shared/helpers";
import { isMilkomedaRouter } from "shared/helpers/is-router";

export type RemoveLiquidityNativeParams = {
  tokenAddress: string;
  ltAmount: string;
  amountTokenMin: string;
  amountNativeMin: string;
  address: string;
  deadline: number;
};

export type RemoveLiquidityTokenParams = {
  tokenAAddress: string;
  tokenBAddress: string;
  ltAmount: string;
  amountTokenAMin: string;
  amountTokenBMin: string;
  address: string;
  deadline: number;
};

type Params = {
  contract: MilkomedaRouter | HumanodeRouter;
  signer: Signer;
  params: RemoveLiquidityNativeParams | RemoveLiquidityTokenParams;
  chainId: number;
};

export const estimateRemoveLiquidityFee = async ({
  contract,
  signer,
  params,
  chainId,
}: Params) => {
  const { gasPrice } = await signer.getFeeData();

  let estimatedGas = BigNumber.from(1000000);

  try {
    if (isRemoveLiquidityNative(params)) {
      if (isMilkomedaRouter(contract)) {
        estimatedGas = await contract.estimateGas.removeLiquidityADA(
          params.tokenAddress,
          params.ltAmount,
          params.amountTokenMin,
          params.amountNativeMin,
          params.address,
          params.deadline
        );
      } else {
        console.error("estimateRemoveLiquidityFee", chainId);
      }
    } else {
      estimatedGas = await contract.estimateGas.removeLiquidity(
        params.tokenAAddress,
        params.tokenBAddress,
        params.ltAmount,
        params.amountTokenAMin,
        params.amountTokenBMin,
        params.address,
        params.deadline
      );
    }
  } catch (error) {
    console.error(error);
  }

  return calculateFee(gasPrice, estimatedGas, chainId);
};

export function isRemoveLiquidityNative(
  params: RemoveLiquidityTokenParams | RemoveLiquidityNativeParams
): params is RemoveLiquidityNativeParams {
  if ("amountNativeMin" in params) return true;
  return false;
}
