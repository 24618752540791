import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useProvider, useSigner } from "wagmi";

import { Staking__factory } from "shared/abi/types";
import { calculateFee, toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";

import { farmsKeys } from "./keys";
import { MiningPanel } from "./useMiningPanelsQuery";

type Params = {
  amount: string;
  miningPanel: MiningPanel;
};

export const useEstimateFeeUnstakeQuery = ({ amount, miningPanel }: Params) => {
  const { address } = useAccount();
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const { data: signer } = useSigner();

  const currencyAmount = toCurrencyAmount(
    miningPanel.detailedPair.pair.liquidityToken,
    amount || "0"
  );

  return useQuery(
    farmsKeys.estimateUnstake(chain.id, address, miningPanel.address),
    async () => {
      invariant(provider, "useEstimateFeeUnstakeQuery. provider is undefined");
      invariant(address, "useEstimateFeeUnstakeQuery. address is undefined");

      const contract = Staking__factory.connect(
        miningPanel.address,
        signer || provider
      );

      const estimatedGas = await contract.estimateGas.unstake(
        currencyAmount.quotient.toString(),
        "0"
      );
      const { gasPrice } = await provider.getFeeData();

      return calculateFee(gasPrice, estimatedGas, chain.id);
    },
    { enabled: Boolean(address && currencyAmount.greaterThan(0)) }
  );
};
