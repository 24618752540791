import React, { PropsWithChildren } from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

type Props = {
  id?: string;
  disabled?: boolean;
  onClick?: () => void;
} & EmotionCSSProps;
export const Item = ({
  id,
  disabled,
  className,
  css: cssProp,
  children,
  onClick,
}: PropsWithChildren<Props>) => {
  return (
    <li
      id={id}
      onClick={disabled ? undefined : onClick}
      css={cssProp}
      className={cn(
        "flex list-none items-center py-2.5 first:mt-4 first:pt-0",
        onClick && !disabled && "cursor-pointer",
        disabled && "pointer-events-none cursor-default opacity-50",
        className
      )}
    >
      {children}
    </li>
  );
};
