import { CurrencyEntity } from "entities/currency";
import Data from "pages/farm/ui/Data";
import { Fee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount, Token } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Modal } from "shared/ui/modal";
import { Native } from "shared/v2-sdk";

type Props = {
  isOpen: boolean;
  fee: Fee | undefined;
  rewardsAvailable: CurrencyAmount<Token>;
  rewardsToken: Token;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmClaimModal = ({
  isOpen,
  rewardsAvailable,
  rewardsToken,
  fee,
  onClose,
  onConfirm,
}: Props) => {
  const chain = useChain();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Body>
        <Modal.Header onIconClick={onClose}>You Receive</Modal.Header>
        <div className="mb-8 mt-3">
          <div className="flex items-center justify-between rounded-base bg-black/5 px-4 py-3 text-2xl text-black">
            <span className="mr-4 max-w-[160px] truncate">
              {rewardsAvailable.toSignificant(6)}
            </span>
            <CurrencyEntity
              symbol={rewardsToken?.symbol}
              logoUrl={rewardsToken?.logoUrl}
              bordered={false}
              size="38"
            />
          </div>
        </div>
        {fee && (
          <Data className="mb-8">
            <Data.Row
              label="Network Fee"
              value={`${fee.feeCA.toSignificant(6)} ${
                Native.byChainId(chain.id).symbol
              }`}
            />
          </Data>
        )}

        <Button size="66" className="mt-auto w-full" onClick={onConfirm}>
          Claim
        </Button>
      </Modal.Body>
    </Modal>
  );
};
