import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useProvider, useSigner } from "wagmi";

import { ERC20__factory } from "shared/abi/types";
import { calculateFee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount, MaxUint256Bn } from "shared/sdk-core";

import { tokenKeys } from "./keys";

type Params = {
  currency: Currency | undefined;
  spenderAddress: string;
  currencyAmount?: CurrencyAmount<Currency>;
};

export const useTokenApproveEstimateFeeQuery = ({
  currency,
  spenderAddress,
  currencyAmount,
}: Params) => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const signer = useSigner();

  const { address } = useAccount();

  return useQuery(
    tokenKeys.approveFee(chain.id, address, spenderAddress),
    async () => {
      invariant(currency, "useTokenApproveEstimateFee. currency is undefined");
      invariant(
        currency.isToken,
        "useTokenApproveEstimateFee. Currency is native"
      );
      const contract = ERC20__factory.connect(
        currency.address,
        signer.data || provider
      );
      const stringifiedValue =
        currencyAmount?.quotient.toString() ?? MaxUint256Bn;

      const { gasPrice } = await provider.getFeeData();
      const estimatedGas = await contract.estimateGas.approve(
        spenderAddress,
        stringifiedValue
      );

      return calculateFee(gasPrice, estimatedGas, chain.id);
    },
    {
      enabled: Boolean(
        currency && currency.isToken && currencyAmount?.greaterThan(0)
      ),
    }
  );
};
