import { ReactNode } from "react";
import cn from "classnames";

import { Currency } from "shared/sdk-core";
import { EmotionCSSProps } from "shared/types";
import placeholder from "shared/ui/icon/svg/tokenPlaceholder.svg";
import { Img } from "shared/ui/img";
import { Text } from "shared/ui/text";

type Props = {
  id?: string;
  symbol: Currency["symbol"];
  logoUrl: Currency["logoUrl"];
  name?: Currency["name"];

  size?: "38" | "32";

  bordered?: ReactNode;

  nameElement?: ReactNode;
} & EmotionCSSProps;

export const CurrencyEntity = ({
  id,
  className,
  css: cssProp,

  bordered = true,
  size = "32",
  logoUrl,
  symbol,
  name,
  nameElement,
}: Props) => {
  return (
    <div
      id={id}
      css={cssProp}
      className={cn(
        "flex justify-between",
        bordered &&
          "rounded-full border border-solid border-tiara py-0.5 pl-1 pr-2",
        className
      )}
    >
      <div className="flex flex-col overflow-hidden">
        <div
          className={cn("inline-flex items-center self-start overflow-hidden")}
        >
          <Img
            className="inline-flex h-6 w-6 flex-grow"
            src={logoUrl}
            alt={symbol}
            fallbackUrl={placeholder}
          />
          {symbol && (
            <Text
              className={cn(
                "text-black",
                size === "38" && "ml-2 text-2xl",
                size === "32" && "ml-1 text-lg"
              )}
            >
              {symbol}
            </Text>
          )}
        </div>
        {name && (
          <div className="mt-1 flex whitespace-nowrap text-sm text-osloGray">
            {name} {nameElement}
          </div>
        )}
      </div>
    </div>
  );
};
