import { useLocalStorage } from "react-use";
import cn from "classnames";
import { ethers } from "ethers";
import { getAddress } from "ethers/lib/utils.js";
import { matchSorter } from "match-sorter";
import { Address } from "wagmi";

import { AddCustomTokenModal } from "features/add-custom-token";
import { SearchCurrencyInput } from "features/search-currency";
import { useUSDRates } from "shared/api/token";
import { useCurrenciesBalancesQuery } from "shared/api/token/useCurrenciesBalancesQuery";
import { LS_KEYS } from "shared/constants";
import { useStateX } from "shared/hooks";
import { useChain, useIsHumanode } from "shared/providers/wagmi";
import { Currency } from "shared/sdk-core";
import { Icon } from "shared/ui/icon";
import { List } from "shared/ui/list";
import { Modal } from "shared/ui/modal";
import { Native } from "shared/v2-sdk";
import { FIXED_CURRENCIES } from "shared/v2-sdk/constants";

import { useCustomTokenQuery } from "../../use-custom-token-query";

import { LatestCurrencies } from "./latest-currencies";
import { Row } from "./row";

const LAST_CURRENCIES_COUNT = 8;

type Props = {
  open: boolean;

  currencies: Currency[];
  selectedCurrency: Currency | undefined;

  storageKey?: string;

  onClose: () => void;
  onSelect: (currency: Currency) => void;
  onSearch?: (value: string) => void;
};

type State = {
  search: string;
  sortType: "asc" | "desc" | "balance";
  isAddCustomTokenOpen: boolean;
};

export const ModalSelector = ({
  open,

  currencies,
  selectedCurrency,

  storageKey,

  onClose,
  onSearch,
  onSelect,
}: Props) => {
  const isHumanode = useIsHumanode();
  const chain = useChain();
  const [latestSelectedCurrenciesSymbols, storeCurrenciesSymbols] =
    useLocalStorage<string[]>(LS_KEYS.tokenSelector(chain.id, storageKey), []);
  const [{ search, sortType, isAddCustomTokenOpen }, setState] =
    useStateX<State>({
      search: "",
      sortType: "balance",
      isAddCustomTokenOpen: false,
    });

  const token1 = currencies.find((c) =>
    c.isToken
      ? getAddress(c.address) === getAddress(FIXED_CURRENCIES[chain.id].token1)
      : undefined
  );
  const token2 = currencies.find((c) =>
    c.isToken
      ? getAddress(c.address) === getAddress(FIXED_CURRENCIES[chain.id].token2)
      : undefined
  );
  const fixedCurrencies: Currency[] = [
    isHumanode ? undefined : Native.byChainId(chain.id),
    token1,
    token2,
  ].filter(Boolean);

  const latestCurrencies = fixedCurrencies;

  const currenciesBalancesQueries = useCurrenciesBalancesQuery(currencies);
  const usdRates = useUSDRates(currencies);

  const store = (currency: Currency) => {
    if (!currency.symbol) return;
    if (!latestSelectedCurrenciesSymbols) {
      storeCurrenciesSymbols([currency.symbol]);
      return;
    }
    const symbols = Array.from(
      new Set([currency.symbol, ...latestSelectedCurrenciesSymbols])
    );

    storeCurrenciesSymbols(symbols.slice(0, LAST_CURRENCIES_COUNT));
  };

  const handleSelect = (currency: Currency) => {
    store(currency);
    onSelect(currency);
  };

  const handleSearch = (value: string) => {
    setState({ search: value });
    onSearch?.(value);
  };

  const handleSort = () => {
    setState({
      sortType:
        sortType === "asc" ? "balance" : sortType === "desc" ? "asc" : "desc",
    });
  };

  const searchedCurrencies = matchSorter(currencies, search, {
    keys: ["symbol", "address", "name"],
  });

  const customTokenQuery = useCustomTokenQuery(search as Address, {
    enabled: Boolean(search && ethers.utils.isAddress(search)),
  });

  const customTokenData =
    customTokenQuery.isSuccess && customTokenQuery.data
      ? customTokenQuery.data
      : undefined;

  const currenciesWithBalances = searchedCurrencies
    .map((currency, idx) => {
      const usdRate = usdRates?.find((rate) =>
        rate.trade?.inputAmount.currency.equals(currency)
      );

      return {
        currency,
        usdRate,
        balance: currenciesBalancesQueries.find((query) =>
          query.data?.currency.equals(currency)
        )?.data,
      };
    })
    .sort((a, b) => {
      if (sortType === "balance") {
        if (!a.usdRate?.trade && !b.usdRate?.trade) return 0;
        if (a.usdRate?.trade && !b.usdRate?.trade) return -1;
        if (!a.usdRate?.trade && b.usdRate?.trade) return 1;
        return a.usdRate?.trade?.outputAmount.greaterThan(
          b.usdRate?.trade?.outputAmount || "0"
        )
          ? -1
          : 1;
      }

      if (sortType === "asc") return 1;
      if (sortType === "desc") return -1;
      return 0;
    });

  return (
    <>
      <Modal open={open && !isAddCustomTokenOpen} onClose={onClose}>
        <Modal.Body className="w-[500px] px-0 max-sm:w-full">
          <Modal.Header
            id="token-select-modal-header"
            className="px-10 max-sm:px-0"
            onIconClick={onClose}
            iconName="close"
          >
            Select Token
          </Modal.Header>
          <SearchCurrencyInput
            className="mt-8 px-10 max-sm:px-0"
            initialSearch={search}
            onChange={handleSearch}
          />
          {latestCurrencies && (
            <LatestCurrencies
              selectedCurrency={selectedCurrency}
              currencies={latestCurrencies}
              onSelect={handleSelect}
            />
          )}
          <List.Header
            className="mx-10 mt-8 max-sm:mx-0"
            icon={
              <Icon
                className={cn(
                  "cursor-pointer",
                  sortType === "balance" && "text-dodgerBlue",
                  sortType === "desc" && "rotate-180"
                )}
                name="sort"
                size="14"
                onClick={handleSort}
              />
            }
          >
            Token
          </List.Header>
          <List className="mt-5 h-[300px]">
            {currenciesWithBalances.map(({ currency, balance }) => {
              return (
                <List.Item
                  id={`token-${currency.symbol}`}
                  className="px-10 transition-colors hover:bg-tiara hover:bg-opacity-20 max-sm:px-0"
                  disabled={selectedCurrency?.symbol === currency.symbol}
                  key={currency.symbol}
                  onClick={() => {
                    handleSelect(currency);
                  }}
                >
                  <Row token={currency} balance={balance?.formatted} />
                </List.Item>
              );
            })}
            {customTokenData && currenciesWithBalances.length === 0 && (
              <List.Item
                id={`token-${customTokenData.token.symbol}`}
                className="px-10 transition-colors hover:bg-tiara hover:bg-opacity-20 max-sm:px-0"
                disabled={
                  selectedCurrency?.symbol === customTokenData.token.symbol
                }
                key={customTokenData.token.address}
                onClick={() => {
                  // handleSelect(customToken);
                }}
              >
                <Row
                  onAdd={() => {
                    setState({
                      isAddCustomTokenOpen: true,
                    });
                  }}
                  token={customTokenData.token}
                  balance={customTokenData.balance?.formatted}
                />
              </List.Item>
            )}
          </List>
        </Modal.Body>
      </Modal>
      {customTokenData && (
        <AddCustomTokenModal
          token={customTokenData.token}
          onClose={() => {
            setState({ isAddCustomTokenOpen: false });
          }}
          onAdd={() => {
            setState({ isAddCustomTokenOpen: false });
          }}
          open={Boolean(customTokenData && isAddCustomTokenOpen)}
        />
      )}
    </>
  );
};
