import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useAccount, useProvider } from "wagmi";

import { Staking__factory } from "shared/abi/types";
import { calculateFee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";

import { farmsKeys } from "./keys";
import { MiningPanel } from "./useMiningPanelsQuery";

export const useEstimateFeeClaimQuery = (miningPanel: MiningPanel) => {
  const chain = useChain();
  const { address } = useAccount();
  const provider = useProvider({ chainId: chain.id });

  return useQuery(
    farmsKeys.estimateClaim(chain.id, address, miningPanel.address),
    async () => {
      invariant(address, "useEstimateFeeClaimQuery. provider is undefined");
      const contract = Staking__factory.connect(miningPanel.address, provider);

      const { gasPrice } = await provider.getFeeData();
      const estimatedGas = await contract.estimateGas.getReward();
      return calculateFee(gasPrice, estimatedGas, chain.id);
    },
    {
      enabled: Boolean(address && provider),
    }
  );
};
