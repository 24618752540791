import { useGraphQlSdk } from "shared/providers/graph-ql-client";
import { Currency } from "shared/sdk-core";

export const poolKeys = {
  all: ["pool"],
  list: (chainId: number | string) => [
    ...poolKeys.all,
    "list",
    chainId.toString(),
  ],
  miningList: (address = "", graphqlSdk: ReturnType<typeof useGraphQlSdk>) => [
    ...poolKeys.all,
    "mining",
    "list",
    address,
    graphqlSdk,
  ],
  miningPanels: (chainId: number, address: string | undefined) => [
    ...poolKeys.all,
    "mining-panels",
    chainId,
    address,
  ],
  pairs: ["pairs"],
  allPairs: (
    chainId: number,
    currenciesMap: Record<string, Currency | undefined> | undefined
  ) => [...poolKeys.pairs, chainId, currenciesMap],
  addLiquidityFee: (chainId: number) => [
    ...poolKeys.all,
    "fee",
    "add",
    chainId,
  ],
  removeLiquidityFee: (chainId: number) => [
    ...poolKeys.all,
    "fee",
    "remove",
    chainId,
  ],

  totalSupply: (chainId: number | undefined, ltAddress: string | undefined) => [
    ...poolKeys.all,
    "totalSupply",
    chainId,
    ltAddress,
  ],
};
