import cn from "classnames";
import { toDecimal } from "utils/numbers";

import { ReactComponent as Handle } from "app/images/farm/handle.svg";
import { MiningPanel } from "shared/api/farm";
import { CurrencyAmount, Percent, Token } from "shared/sdk-core";
import { Icon } from "shared/ui/icon";
import { Tooltip } from "shared/ui/tooltip";

import { AdvancedInfo } from "./advanced-info";

const TWENTY_PERCENT = new Percent(20, 100);
const EIGHTY_PERCENT = new Percent(80, 100);

type Props = {
  capitalEfficiencyPercent?: Percent;
  calcStakingBalance?: CurrencyAmount<Token>;
  calcPoolShare?: Percent;
  dailyRate?: string;
  miningPanel: MiningPanel;
};

export function Stats({
  calcPoolShare,
  dailyRate,
  capitalEfficiencyPercent,
  calcStakingBalance,
  miningPanel,
}: Props) {
  return (
    <>
      <div className="mt-4 rounded-lg bg-light p-4">
        <div className="flex items-center justify-between text-osloGray">
          <span>Your Capital Efficiency</span>
          {capitalEfficiencyPercent && (
            <span
              id="capital-efficiency"
              className={cn(
                capitalEfficiencyPercent?.lessThanOrEqual(
                  TWENTY_PERCENT.asFraction
                ) && "text-redOrange",
                capitalEfficiencyPercent?.greaterThanOrEqual(
                  TWENTY_PERCENT.asFraction
                ) &&
                  capitalEfficiencyPercent.lessThan(
                    EIGHTY_PERCENT.asFraction
                  ) &&
                  "text-orangePeel",
                capitalEfficiencyPercent.greaterThanOrEqual(
                  EIGHTY_PERCENT.asFraction
                ) && "text-malachite"
              )}
            >
              {capitalEfficiencyPercent.toSignificant(4)}%
            </span>
          )}
        </div>
        <div className="my-4 flex items-center">
          {/* <Handle className="relative z-20 h-[15px] w-[106px] shrink-0" /> */}
          <div className="relative flex grow items-center">
            <div
              className="relative z-10 h-[10px] rounded-[10px] bg-dodgerBlue transition-[width]"
              id="sword"
              style={{
                width: `calc(${capitalEfficiencyPercent?.toSignificant(
                  4
                )}% + 2%)`,
              }}
            />
            <div className="absolute h-[10px] w-full rounded-[10px] bg-athensGray" />
          </div>
        </div>
        <div className="flex items-center justify-between text-osloGray">
          <span>Your Stake</span>
          {calcStakingBalance && (
            <span id="stake-balance" className="text-black">
              {calcStakingBalance.toSignificant(6)} LT
            </span>
          )}
        </div>
      </div>
      <div className="my-5 flex items-center px-2 text-osloGray">
        {calcPoolShare && (
          <span
            id="pool-share"
            className="mr-auto inline-flex whitespace-nowrap"
          >
            Pool Share {calcPoolShare.toSignificant(4)}%
          </span>
        )}

        {dailyRate && (
          <div className="flex items-center  space-x-1">
            <span id="daily-rate" className="whitespace-nowrap">
              {dailyRate}
            </span>
          </div>
        )}

        <Tooltip
          className="ml-1"
          element={
            <AdvancedInfo
              apy={toDecimal(miningPanel.apy)
                .mul(100)
                .toDecimalPlaces(2)
                .toFixed()}
              tvl={toDecimal(miningPanel.detailedPair.advancedInfo.tvl)
                .mul(miningPanel.detailedPair.advancedInfo.priceLT.priceLTD)
                .toDecimalPlaces(2)
                .toFixed()}
              totalStaked={miningPanel.totalStakedLT}
            />
          }
        >
          <Icon name="info" size="14" />
        </Tooltip>
      </div>
    </>
  );
}
