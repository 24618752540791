import { useState } from "react";
import { useMedia } from "react-use";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { useChain } from "shared/providers/wagmi";
import { EmotionCSSProps } from "shared/types";
import { Button } from "shared/ui/button";
import { Dropdown, DropdownItem } from "shared/ui/dropdown";
import { Icon } from "shared/ui/icon";
import { Link } from "shared/ui/link";
import { Logo } from "shared/ui/logo";
import { Portal } from "shared/ui/portal";
import { Text } from "shared/ui/text";
import { BRIDGES_LINKS } from "shared/v2-sdk/constants";

import { HeaderLink } from "../HeaderLink";
import { useHeaderLinks } from "../use-header-links";

export const MobileMenu = ({ className }: EmotionCSSProps) => {
  const chain = useChain();
  const links = useHeaderLinks();

  const bridgesLinks = BRIDGES_LINKS[chain.id];

  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMedia("(max-width: 768px)");

  return (
    <>
      <button
        className={cn(
          "hover:text-redMalibu p-1 text-dodgerBlue outline-none transition-colors active:text-malibu",
          className
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name="menu" size="22" />
      </button>

      <Portal className="z-50">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="absolute inset-0 z-50 flex flex-col bg-strokeDark bg-opacity-30"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className="ml-auto flex w-3/4 max-w-xs flex-grow flex-col rounded-l-3xl border border-tiara bg-athensGray p-5"
              >
                <header className="flex items-center justify-between">
                  <Logo />
                  <div
                    className="my-2 rounded-lg bg-black bg-opacity-5 p-2"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <Icon
                      name="close"
                      size="14"
                      className="text-dodgerBlue active:text-malibu"
                    />
                  </div>
                </header>
                <div className="stroke-gradient h-[1px] w-full" />

                <ul className="mt-6 flex list-none flex-col">
                  {links.map((link, idx) => {
                    return (
                      <li
                        key={link.to}
                        className="w-full rounded-full px-5 pb-3 pt-2"
                      >
                        <HeaderLink
                          onClick={() => setIsOpen(false)}
                          className="flex w-full"
                          btnClassName="!w-full !justify-start"
                          idx={idx}
                          {...link}
                        />
                      </li>
                    );
                  })}

                  <li className="w-full px-5 pb-3 pt-2">
                    <Dropdown
                      dropdownClassName="w-[200px] mt-2 z-50"
                      placement="bottom-start"
                      button={
                        <div>
                          <Button
                            id={`header-button-bridge`}
                            className="w-full flex-col !items-stretch opacity-75"
                            iconName="exit"
                            iconClassName="text-dodgerBlue"
                            theme="transparentLight50"
                          >
                            Bridge
                            <Icon
                              className="ml-auto"
                              name="chevronDown"
                              size="14"
                            />
                          </Button>
                        </div>
                      }
                    >
                      {bridgesLinks.map((bridge) => {
                        return (
                          <DropdownItem key={bridge.link}>
                            <Link
                              className="w-full justify-between text-black"
                              iconClass="text-dodgerBlue"
                              showIcon
                              to={bridge.link}
                            >
                              <Text className="inline-flex text-black">
                                {bridge.label}
                              </Text>
                            </Link>
                          </DropdownItem>
                        );
                      })}
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Portal>
    </>
  );
};
