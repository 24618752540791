import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toDecimal } from "utils/numbers";

import { CurrencyEntity } from "entities/currency";
import { FarmBenefits } from "entities/farm";
import { useStakingInfo } from "entities/farm/hooks/use-staking-info";
import { ConnectWalletButton } from "features/connect-wallet";
import { SwitchNetworkButton } from "features/switch-network";
import { FarmOutletContext } from "pages/farm/types";
import { useClaimMutation, useEstimateFeeClaimQuery } from "shared/api/farm";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Tooltip } from "shared/ui/tooltip";

import { useClaimStatus } from "./hooks/use-claim-status";
import { ConfirmClaimModal } from "./ui/confirm-claim-modal";

export const ClaimPage = () => {
  const { miningPanel } = useOutletContext<FarmOutletContext>();
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const stakingInfo = useStakingInfo(miningPanel);
  const rewardsToken = miningPanel.rewardsToken;

  const { status, btnDisabled, btnText } = useClaimStatus(miningPanel);
  const claimMutation = useClaimMutation();

  const feeQuery = useEstimateFeeClaimQuery(miningPanel);

  return (
    <>
      <div className="rounded-[15px] border border-tiara p-4">
        <div className="flex flex-col space-y-4 text-sm text-osloGray">
          <div className="flex items-center justify-between space-x-4 rounded-base bg-black/5 px-4 py-3 text-[1.375rem] text-black">
            <span>
              {stakingInfo?.rewardsAvailableCA?.toSignificant(6) ?? "0"}
            </span>
            <CurrencyEntity
              symbol={rewardsToken?.symbol}
              logoUrl={rewardsToken?.logoUrl}
              bordered={false}
              size="38"
            />
          </div>
          <div className="flex items-center justify-between">
            <span>{rewardsToken?.symbol} Earned</span>
            <span className="text-black">
              {stakingInfo?.rewardsEarnedCA?.toSignificant(6) ?? "0"}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span>{rewardsToken?.symbol} Claimed</span>
            <span className="text-black">
              {stakingInfo?.rewardsClaimedCA?.toSignificant(6) ?? "0"}
            </span>
          </div>
        </div>
      </div>
      <div className="my-5 flex items-center justify-between px-2 text-osloGray">
        <span className="whitespace-nowrap">
          Pool Share {stakingInfo?.poolSharePercent.toSignificant(4)}%
        </span>
        <div className="flex items-center space-x-1">
          <span className="whitespace-nowrap">
            {`${stakingInfo?.dailyRateCA?.toSignificant(6) ?? "0"} ${
              rewardsToken?.symbol
            } daily`}
          </span>
          <Tooltip
            className="flex items-center text-osloGray"
            element={
              <FarmBenefits
                apy={toDecimal(miningPanel.apy)
                  .mul(100)
                  .toDecimalPlaces(2)
                  .toFixed()}
                tvl={toDecimal(miningPanel.detailedPair.advancedInfo.tvl)
                  .mul(miningPanel.detailedPair.advancedInfo.priceLT.priceLTD)
                  .toDecimalPlaces(2)
                  .toFixed()}
                totalStaked={miningPanel.totalStakedLT}
              />
            }
          >
            <Icon name="info" size="14" />
          </Tooltip>
        </div>
      </div>
      {status === "connectWallet" && <ConnectWalletButton />}
      {status === "switchNetwork" && <SwitchNetworkButton className="w-full" />}
      {["nothingToClaim", "claim"].includes(status) && (
        <Button
          disabled={btnDisabled}
          className="w-full !py-4 !text-2xl"
          onClick={() => {
            status === "claim" && setConfirmOpen(true);
          }}
        >
          {btnText}
        </Button>
      )}

      {stakingInfo &&
        miningPanel.rewardsToken &&
        stakingInfo.rewardsAvailableCA && (
          <ConfirmClaimModal
            isOpen={isConfirmOpen}
            rewardsToken={miningPanel.rewardsToken}
            rewardsAvailable={stakingInfo.rewardsAvailableCA}
            onClose={() => {
              setConfirmOpen(false);
            }}
            fee={feeQuery.data}
            onConfirm={() => {
              setConfirmOpen(false);
              if (!stakingInfo.rewardsAvailableCA) return;
              claimMutation.mutate({
                amount: stakingInfo.rewardsAvailableCA.toExact(),
                address: miningPanel.address,
                rewardTokenSymbol: rewardsToken?.symbol ?? "",
              });
            }}
          />
        )}
    </>
  );
};
