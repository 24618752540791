import { useIsHumanode } from "shared/providers/wagmi";

import { HeaderLink } from "./constants";

export const useHeaderLinks = () => {
  const isHumanode = useIsHumanode();
  const links: HeaderLink[] = [
    {
      iconName: "repeat",
      name: "Swap",
      to: "/",
      disabled: false,
    },
    {
      iconName: "chess",
      name: "Pool",
      to: "/pools",
      disabled: false,
    },
    {
      iconName: "harvester",
      name: "Farm",
      to: "/farm",
      disabled: false,
    },
    {
      iconName: "suitcase",
      name: "Stake",
      to: "/ocx/stake",
      disabled: isHumanode,
    },
  ];
  return links;
};
