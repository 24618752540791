import tw, { css } from "twin.macro";

export const toastContainerCss = css`
  min-width: 370px;
  ${tw`mt-20`}
  .Toastify__toast {
    ${tw`bg-athensGray border border-solid border-tiara shadow-lg rounded-2xl p-4 cursor-default`}
  }

  .Toastify__toast-body {
    ${tw`p-0`}
  }

  .Toastify__progress-bar {
    ${tw`bg-dodgerBlue h-[3px]`}
  }

  .Toastify__toast-body {
    ${tw`items-start`}
  }
  .Toastify__toast-icon {
    ${tw`mt-1`}
  }
`;
