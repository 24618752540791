import invariant from "tiny-invariant";

import { Currency, NativeCurrency, Token } from "shared/sdk-core";

import { NATIVE, WNATIVE } from "../constants";

export class Native extends NativeCurrency {
  /**
   *
   * @param chainId {@link BaseCurrency#chainId}
   * @param decimals {@link BaseCurrency#decimals}
   * @param symbol {@link BaseCurrency#symbol}
   * @param name {@link BaseCurrency#name}
   * @param logoUrl {@link BaseCurrency#logoUrl}
   */
  public constructor(
    chainId: number,
    decimals: number,
    symbol?: string,
    name?: string,
    logoUrl?: string
  ) {
    super(chainId, decimals, symbol, name, logoUrl);
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Currency): boolean {
    return other.isNative && this.chainId === other.chainId;
  }

  private static cache: { [chainId: number]: Native } = {};

  public static byChainId(chainId: number): Native {
    if (chainId in this.cache) {
      return this.cache[chainId];
    }
    let native = NATIVE[chainId];
    const { decimals, name, symbol, logoURI } = native;
    return (this.cache[chainId] = new Native(
      chainId,
      decimals,
      symbol,
      name,
      logoURI
    ));
  }

  /**
   * Return this token, which does not need to be wrapped
   */
  public get wrapped(): Token {
    const wnative = WNATIVE[this.chainId];
    invariant(wnative, "WRAPPED");
    return wnative;
  }
}
