import { Fraction, Percent } from "shared/sdk-core";

const FIVE_PERCENT = new Fraction(5, 100);
const THREE_PERCENT = new Fraction(3, 100);

type Data =
  | {
      formatted: string;
      status: "normal";
      cls: "text-osloGray";
    }
  | {
      formatted: string;
      status: "medium";
      cls: "text-orangePeel text-opacity-60";
    }
  | {
      formatted: string;
      status: "high";
      cls: "text-redOrange";
    };

export const getPriceImpactData = (
  priceImpact: Percent | null | undefined
): Data => {
  if (!priceImpact)
    return {
      formatted: "",
      status: "normal",
      cls: "text-osloGray",
    };

  const formatted = `${priceImpact.toSignificant(2)}${
    priceImpact.isPercent ? "%" : ""
  }`;

  if (priceImpact.asFraction.greaterThan(FIVE_PERCENT))
    return {
      formatted,
      status: "high",
      cls: "text-redOrange",
    };
  if (priceImpact.asFraction.greaterThan(THREE_PERCENT))
    return {
      formatted,
      status: "medium",
      cls: "text-orangePeel text-opacity-60",
    };

  return {
    formatted,
    status: "normal",
    cls: "text-osloGray",
  };
};
