import { BigNumber, Signer } from "ethers";

import { HumanodeRouter, MilkomedaRouter } from "shared/abi/types";
import { calculateFee } from "shared/helpers";
import { isMilkomedaRouter } from "shared/helpers/is-router";

export type AddLiquidityNativeParams = {
  tokenAddress: string;
  tokenCurrencyAmount: string;
  tokenMinimalReceived: string;
  nativeMinimalReceived: string;
  accountAddress: string;
  deadline: number;
  nativeCurrencyAmount: string;
};

export type AddLiquidityTokenParams = {
  tokenAAddress: string;
  tokenBAddress: string;
  currencyAmountIn: string;
  currencyAmountOut: string;
  minimalReceivedAmountIn: string;
  minimalReceivedAmountOut: string;
  accountAddress: string;
  deadline: number;
};

type AddLiquidityParams = AddLiquidityNativeParams | AddLiquidityTokenParams;

export const estimateAddLiquidityFee = async (
  contractRouter: MilkomedaRouter | HumanodeRouter,
  params: AddLiquidityParams,
  signer: Signer,
  chainId: number
) => {
  const { gasPrice } = await signer.getFeeData();

  let estimatedGas = BigNumber.from(1000000);

  let promise: Promise<BigNumber> | undefined;

  if (isAddLiquidityNative(params)) {
    if (isMilkomedaRouter(contractRouter)) {
      promise = contractRouter.estimateGas.addLiquidityADA(
        params.tokenAddress,
        params.tokenCurrencyAmount,
        params.tokenMinimalReceived,
        params.nativeMinimalReceived,
        params.accountAddress,
        params.deadline,
        { value: params.nativeCurrencyAmount }
      );
    }
  } else {
    promise = contractRouter.estimateGas.addLiquidity(
      params.tokenAAddress,
      params.tokenBAddress,
      params.currencyAmountIn,
      params.currencyAmountOut,
      params.minimalReceivedAmountIn,
      params.minimalReceivedAmountOut,
      params.accountAddress,
      params.deadline
    );
  }

  try {
    if (promise) estimatedGas = await promise;
  } catch (error) {
    console.error(error);
  }

  return calculateFee(gasPrice, estimatedGas, chainId);
};

export function isAddLiquidityNative(
  params: AddLiquidityParams
): params is AddLiquidityNativeParams {
  if ("nativeCurrencyAmount" in params) return true;
  return false;
}
