import { SerializedStyles } from "@emotion/react";
import { TwStyle } from "twin.macro";

import { EmotionCSSProps } from "shared/types";

import { IconName, IconSize } from "../icon";

import { SHAPES, SIZES, THEMES } from "./styles";

type GetSize<T> = T extends `size${infer U}` ? U : never;
export type Size = GetSize<keyof typeof SIZES>;

export const BUTTON_SIZE_TO_ICON_MAP: Record<Size, IconSize> = {
  "38": "18",
  "40": "20",
  "66": "32",
} as const;

export type CustomProps = {
  theme?: keyof typeof THEMES;
  shape?: keyof typeof SHAPES;
  size?: Size;

  loading?: boolean;
  reverse?: boolean;
  disabled?: boolean;
  outline?: boolean;

  iconName?: IconName;
  iconCss?: SerializedStyles | TwStyle;
  iconClassName?: string;
} & EmotionCSSProps;
