import classNames from "classnames";

import { getPriceImpactData } from "entities/price";
import { useAdvancedDetails } from "pages/swap/hooks";
import { useChain } from "shared/providers/wagmi";
import { Native } from "shared/v2-sdk";
import { TradeTypes } from "shared/v2-sdk/hooks";

type Props = {
  trade: TradeTypes;
};

export const AdvancedDetails = ({ trade }: Props) => {
  const chain = useChain();

  const { lpFee, feeCA, minimalReceivedCA, allowedSlippage } =
    useAdvancedDetails(trade);

  const priceImpactData = getPriceImpactData(trade.priceImpact);
  return (
    <>
      <div className="grid grid-cols-2 border-b border-b-tiara pb-1">
        <div className="text-osloGray">Liquidity Provider Fee</div>
        <div className="text-right">
          {`${lpFee.toSignificant(6)} ${trade.inputAmount.currency.symbol}`}
        </div>
        <div className="mt-1 text-osloGray">Network Fee</div>
        <div className="mt-1 text-right">
          {feeCA
            ? `${feeCA.toSignificant(6)} ${Native.byChainId(chain.id).symbol}`
            : "Unknown"}
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div className="mt-1 text-osloGray">Price Impact</div>
        <div
          className={classNames(
            "mt-1 text-right ",
            priceImpactData.status === "high" && "text-redOrange",
            priceImpactData.status === "medium" && "text-orangePeel",
            priceImpactData.status === "normal" && "text-osloGray"
          )}
        >
          {trade.priceImpact.toSignificant(2)}{" "}
          {trade.priceImpact.isPercent ? "%" : ""}
        </div>
        <div className="mt-1 text-osloGray">Minimum Received</div>
        <div className="mt-1 text-right">
          {minimalReceivedCA?.toSignificant(6) ?? "-"}{" "}
          {trade.outputAmount.currency.symbol}
        </div>
        <div className="mt-1 text-osloGray">Slippage Tolerance</div>
        <div className="mt-1 text-right text-osloGray">
          {allowedSlippage?.toSignificant()}%
        </div>
      </div>
    </>
  );
};
