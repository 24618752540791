import { useBlockNumber } from "wagmi";

import { Icon } from "shared/ui/icon";

export const BlockNumber = () => {
  const blockQuery = useBlockNumber({
    watch: true,
  });

  return (
    <div className="inline-flex items-center text-sm text-dodgerBlue">
      Block {blockQuery.data?.toLocaleString()}
      <Icon className="ml-1 text-malibu" name="indicator" />
    </div>
  );
};
