import cn from "classnames";
import { ethers } from "ethers";
import { useAccount } from "wagmi";

import { useCurrenciesMap } from "entities/currency";
import { getPriceImpactData } from "entities/price";
import { ApproveAllowanceButton } from "features/approve-allowance";
import { ConnectWalletButton } from "features/connect-wallet";
import { InputCurrency } from "features/input-currency";
import { SwitchNetworkButton } from "features/switch-network";
import { useEstimateSwapFeeQuery, useSwapMutation } from "shared/api/swap";
import { useCurrencyBalanceQuery, useUSDExchangeRate } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useStateX } from "shared/hooks";
import { useChain } from "shared/providers/wagmi";
import { Currency, TradeType } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Modal } from "shared/ui/modal";
import { NATIVE, ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";
import { useBestTrade } from "shared/v2-sdk/hooks";
import { CurrencySelector } from "widgets/currency-selector";
import { TransactionSettingsModal } from "widgets/settings-modal";

import { ReactComponent as Line } from "../../ui/line.svg";
import {
  SWAP_STATUSES,
  useMaxNativeCurrencyValue,
  useSwapQueryParams,
  useSwapStatus,
  useTradeOptions,
} from "../hooks";

import { Animations } from "./animations";
import { ConfirmModal } from "./confirm-modal";
import { Details } from "./details";

type State = {
  isSettingsOpen: boolean;
  isConfirmModalOpen: boolean;
  direction: "up" | "down";

  amountIn: string;
  amountOut: string;
  tradeType: TradeType;
};

export const SwapPage = () => {
  const chain = useChain();
  const currenciesMap = useCurrenciesMap();
  const tradeOptions = useTradeOptions();
  const { address } = useAccount();

  const [
    {
      currencyA: [currencyAAddress],
      currencyB: [currencyBAddress],
    },
    setQuery,
  ] = useSwapQueryParams();

  const selectedCurrencyA = currenciesMap[currencyAAddress];
  const selectedCurrencyB = currenciesMap[currencyBAddress];

  const balanceAQuery = useCurrencyBalanceQuery(selectedCurrencyA);
  const balanceBQuery = useCurrencyBalanceQuery(selectedCurrencyB);

  const [
    {
      isConfirmModalOpen,
      isSettingsOpen,
      direction,
      amountIn,
      amountOut,
      tradeType,
    },
    setState,
  ] = useStateX<State>({
    isConfirmModalOpen: false,
    isSettingsOpen: false,
    direction: "down",

    amountIn: "",
    amountOut: "",
    tradeType: TradeType.EXACT_INPUT,
  });

  const potentialAmountInCA = toCurrencyAmount(selectedCurrencyA, amountIn);
  const potentialAmountOutCA = toCurrencyAmount(selectedCurrencyB, amountOut);

  const { trade } = useBestTrade(
    selectedCurrencyA,
    selectedCurrencyB,
    potentialAmountInCA,
    tradeType,
    potentialAmountOutCA
  );

  const swapFeeQuery = useEstimateSwapFeeQuery(trade, tradeOptions);

  const currencyAmountIn =
    tradeType === TradeType.EXACT_INPUT
      ? potentialAmountInCA
      : trade?.inputAmount;

  const currencyAmountOut =
    tradeType === TradeType.EXACT_OUTPUT
      ? potentialAmountOutCA
      : trade?.outputAmount;

  const currencyAToUSDRate = useUSDExchangeRate(currencyAmountIn);
  const currencyBToUSDRate = useUSDExchangeRate(currencyAmountOut);

  const maxValue = useMaxNativeCurrencyValue({ currencyB: selectedCurrencyB });

  const onSelect = (
    currency: Currency,
    tokenSelectorType: "tokenA" | "tokenB"
  ) => {
    let newCurrencyA =
      tokenSelectorType === "tokenA" ? currency : selectedCurrencyA;
    let newCurrencyB =
      tokenSelectorType === "tokenB" ? currency : selectedCurrencyB;

    if (
      (selectedCurrencyA && currency.equals(selectedCurrencyA)) ||
      (selectedCurrencyB && currency.equals(selectedCurrencyB))
    ) {
      [newCurrencyA, newCurrencyB] = [selectedCurrencyB, selectedCurrencyA];
    }

    const newCurrencyAAdress = newCurrencyA?.isNative
      ? ethers.constants.AddressZero
      : newCurrencyA?.address;
    const newCurrencyBAddress = newCurrencyB?.isNative
      ? ethers.constants.AddressZero
      : newCurrencyB?.address;

    setQuery({
      currencyA: newCurrencyAAdress ? [newCurrencyAAdress] : [],
      currencyB: newCurrencyBAddress ? [newCurrencyBAddress] : [],
    });
  };

  const swapMutation = useSwapMutation({
    onTx: () => {
      setState({ isConfirmModalOpen: false });
    },
  });

  const onSwap = () => {
    if (!trade || !address || !tradeOptions || !swapFeeQuery.data) return;

    swapMutation.mutate({
      trade,
      tradeOptions,
      swapFee: swapFeeQuery.data,
    });
  };

  const onAmountInChange = (value: string) => {
    setState({
      amountIn: value,
      amountOut: value ? amountOut : "",
      tradeType: TradeType.EXACT_INPUT,
    });
  };

  const onAmountOutChange = (value: string) => {
    setState({
      amountIn: value ? amountIn : "",
      amountOut: value,
      tradeType: TradeType.EXACT_OUTPUT,
    });
  };

  const { status, isBtnDisabled } = useSwapStatus({
    currencyA: selectedCurrencyA,
    currencyB: selectedCurrencyB,
    fee: swapFeeQuery.data,
    trade,
    currencyAmountIn,
    currencyAmountOut,
  });

  const priceImpactData = getPriceImpactData(trade?.priceImpact);

  const resultAmountIn =
    tradeType === TradeType.EXACT_INPUT
      ? amountIn
      : currencyAmountIn?.toSignificant() ?? "";

  const resultAmountOut =
    tradeType === TradeType.EXACT_OUTPUT
      ? amountOut
      : currencyAmountOut?.toSignificant() ?? "";

  const isLoadingTrade = Boolean(
    !trade && amountIn && status !== "insufficientLiquidity"
  );
  return (
    <>
      <Animations>
        <Modal.Header
          id="swap-settings-modal-header"
          iconName="settings"
          onIconClick={() => {
            setState({ isSettingsOpen: true });
          }}
        >
          Swap
        </Modal.Header>

        <InputCurrency
          id="tokenA-input"
          className="mb-4 mt-8"
          currency={selectedCurrencyA}
          value={resultAmountIn}
          balance={balanceAQuery.data?.formatted}
          price={currencyAToUSDRate}
          onChange={onAmountInChange}
          maxBtnLoading={
            (selectedCurrencyA?.isNative && !maxValue) ||
            balanceAQuery.isFetching
          }
          onMaxClick={() => {
            if (selectedCurrencyA?.isNative && maxValue) {
              onAmountInChange(maxValue.toExact());
              return;
            }
            onAmountInChange(balanceAQuery.data?.balanceCA.toExact() || "");
          }}
        >
          <CurrencySelector
            storageKey="swap"
            id="tokenA-select"
            selectedCurrency={selectedCurrencyA}
            onSelect={(currency) => {
              onSelect(currency, "tokenA");
            }}
          />
        </InputCurrency>
        <div className="flex items-center justify-between">
          <Line />
          <Icon
            id="direction-icon"
            className={cn(
              "cursor-pointer select-none self-center text-dodgerBlue",
              direction === "up" && "rotate-180"
            )}
            size="32"
            name="direction"
            onClick={() => {
              setState({
                direction: direction === "up" ? "down" : "up",
                tradeType: TradeType.EXACT_INPUT,
              });

              const newCurrencyAAddress = selectedCurrencyB?.isNative
                ? ethers.constants.AddressZero
                : selectedCurrencyB?.address;

              const newCurrencyBAddress = selectedCurrencyA?.isNative
                ? ethers.constants.AddressZero
                : selectedCurrencyA?.address;

              setQuery({
                currencyA: newCurrencyAAddress ? [newCurrencyAAddress] : [],
                currencyB: newCurrencyBAddress ? [newCurrencyBAddress] : [],
              });
            }}
          />
          <Line />
        </div>

        <InputCurrency
          id="tokenB-input"
          value={resultAmountOut}
          currency={selectedCurrencyB}
          balance={balanceBQuery.data?.formatted}
          price={currencyBToUSDRate}
          priceImpact={trade?.priceImpact}
          className="mt-4"
          maxBtnLoading={
            (selectedCurrencyB?.isNative && !maxValue) ||
            balanceBQuery.isFetching
          }
          onMaxClick={() => {
            if (selectedCurrencyB?.isNative && maxValue) {
              onAmountOutChange(maxValue.toSignificant());
              return;
            }
            onAmountOutChange(balanceBQuery.data?.balanceCA.toExact() || "");
          }}
          onChange={onAmountOutChange}
        >
          <CurrencySelector
            storageKey="swap"
            id="tokenB-select"
            selectedCurrency={selectedCurrencyB}
            onSelect={(currency) => {
              onSelect(currency, "tokenB");
            }}
          />
        </InputCurrency>

        {selectedCurrencyA && selectedCurrencyB && (
          <Details trade={trade} isLoading={isLoadingTrade} />
        )}
        {status === "connectWallet" && (
          <ConnectWalletButton className="mt-auto" />
        )}
        {status === "wrongNetwork" && (
          <SwitchNetworkButton className="mt-auto" />
        )}
        {status === "approve" &&
          selectedCurrencyA &&
          selectedCurrencyA.isToken &&
          currencyAmountIn && (
            <ApproveAllowanceButton
              className="mt-auto"
              currencyAmount={currencyAmountIn}
              spenderAddress={ROUTER_ADDRESS_MAP[chain.id]}
            />
          )}
        {SWAP_STATUSES.includes(status) && (
          <Button
            loading={isLoadingTrade}
            disabled={isBtnDisabled || swapMutation.isLoading}
            size="66"
            className={cn(
              "!mt-auto",
              priceImpactData.status === "high" &&
                status === "swapAnyway" &&
                "!border-redOrange !bg-redOrange"
            )}
            onClick={() => {
              (status === "swap" || status === "swapAnyway") &&
                setState({ isConfirmModalOpen: true });
            }}
          >
            {status === "selectCurrencyA" && "Select token in"}
            {status === "selectCurrencyB" && "Select token out"}
            {status === "enterAmount" && "Enter amount"}
            {status === "insufficientLiquidity" && "Insufficient Liquidity"}
            {status === "insufficientNativeTokenBalance" &&
              `No ${NATIVE[chain.id].symbol} to pay fee`}
            {status === "insufficientBalance" &&
              `Insufficient ${selectedCurrencyA?.symbol} balance`}
            {status === "swapAnyway" && "Swap Anyway"}
            {status === "swap" && !swapMutation.isLoading && "Swap"}
            {status === "swap" && swapMutation.isLoading && "Swap In Progress"}
          </Button>
        )}
      </Animations>

      {trade && selectedCurrencyA && selectedCurrencyB && swapFeeQuery.data && (
        <ConfirmModal
          trade={trade}
          open={isConfirmModalOpen}
          onClose={() => {
            setState({ isConfirmModalOpen: false });
          }}
          onConfirm={() => {
            setState({ isConfirmModalOpen: true });
            onSwap();
          }}
        />
      )}

      <TransactionSettingsModal
        open={isSettingsOpen}
        onClose={() => {
          setState({
            isSettingsOpen: false,
          });
        }}
      />
    </>
  );
};
