import { useState } from "react";

import { CurrencyEntity } from "entities/currency";
import { ConnectWalletButton } from "features/connect-wallet";
import { SwitchNetworkButton } from "features/switch-network";
import { AdvancedInfo } from "pages/farm/ui/advanced-info";
import { ConfirmClaimModal } from "pages/farm-claim-page/ui/confirm-claim-modal";
import {
  useClaimOcxMutation,
  useEstimateFeeClaimOcxQuery,
} from "shared/api/ocx";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Tooltip } from "shared/ui/tooltip";
import { OCX_STAKE_CONTRACT_ADDRESS, OWN_TOKEN } from "shared/v2-sdk/constants";

import { useStakingOcxInfo } from "../hooks";
import { useClaimOcxStatus } from "../hooks/useClaimOcxStatus";

import { StakingInfo } from "./StakingInfo";

export const ClaimOcxPage = () => {
  const chain = useChain();
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const ocxToken = OWN_TOKEN[chain.id];

  const stakingInfo = useStakingOcxInfo();

  const { status, btnDisabled, btnText } = useClaimOcxStatus(stakingInfo);
  const claimMutation = useClaimOcxMutation();

  const feeQuery = useEstimateFeeClaimOcxQuery();

  return (
    <>
      <div className="rounded-[15px] border border-tiara p-4">
        <div className="flex flex-col space-y-4 text-sm text-osloGray">
          <div className="flex items-center justify-between space-x-4 rounded-base bg-black/5 px-4 py-3 text-[1.375rem] text-black">
            <span>{stakingInfo?.rewardsAvailableCA?.toSignificant(6)}</span>
            <CurrencyEntity
              symbol={ocxToken?.symbol}
              logoUrl={ocxToken?.logoUrl}
              bordered={false}
              size="38"
            />
          </div>
          <div className="flex items-center justify-between">
            <span>{ocxToken?.symbol} Earned</span>
            <span className="text-black">
              {stakingInfo?.rewardsEarnedCA?.toSignificant(6)}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span>{ocxToken?.symbol} Claimed</span>
            <span className="text-black">
              {stakingInfo?.rewardsClaimedCA?.toSignificant(6)}
            </span>
          </div>
        </div>
      </div>
      <div className="my-5 flex items-center justify-between px-2 text-osloGray">
        <span className="whitespace-nowrap">
          Pool Share {stakingInfo?.poolSharePercent?.toSignificant(4) ?? "0"}%
        </span>
        <div className="flex items-center space-x-1">
          <span className="whitespace-nowrap">
            {`${stakingInfo?.dailyRateCA?.toSignificant(6) ?? "0"} ${
              ocxToken?.symbol
            } daily`}
          </span>
          <Tooltip
            className="flex items-center text-osloGray"
            element={
              <AdvancedInfo
                apy={stakingInfo?.apy}
                tvl={stakingInfo?.tvl || "0"}
                totalStaked={
                  stakingInfo?.totalStakedCA?.toSignificant(6) || "0"
                }
              />
            }
          >
            <Icon name="info" size="14" />
          </Tooltip>
        </div>
      </div>
      {status === "connectWallet" && <ConnectWalletButton />}
      {status === "switchNetwork" && <SwitchNetworkButton className="w-full" />}
      {["nothingToClaim", "claim"].includes(status) && (
        <Button
          disabled={btnDisabled}
          className="w-full !py-4 !text-2xl"
          onClick={() => {
            status === "claim" && setConfirmOpen(true);
          }}
        >
          {btnText}
        </Button>
      )}

      <StakingInfo className="mt-8" stakingInfo={stakingInfo} />

      {stakingInfo && stakingInfo.rewardsAvailableCA && (
        <ConfirmClaimModal
          isOpen={isConfirmOpen}
          rewardsToken={ocxToken}
          rewardsAvailable={stakingInfo.rewardsAvailableCA}
          onClose={() => {
            setConfirmOpen(false);
          }}
          fee={feeQuery.data}
          onConfirm={() => {
            if (!stakingInfo.rewardsAvailableCA) return;
            setConfirmOpen(false);
            claimMutation.mutate({
              amount: stakingInfo.rewardsAvailableCA.toExact(),
              address: OCX_STAKE_CONTRACT_ADDRESS[chain.id],
            });
          }}
        />
      )}
    </>
  );
};
