import { CurrencyEntity } from "entities/currency";
import Data from "pages/farm/ui/Data";
import { Fee } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Button } from "shared/ui/button";
import { Modal } from "shared/ui/modal";
import { Native } from "shared/v2-sdk";
import { OWN_TOKEN } from "shared/v2-sdk/constants";

import { StakingOcxInfo } from "../hooks/useStakingOcxInfo";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  amount: string;
  stakingInfo: NonNullable<StakingOcxInfo>;
  fee: Fee | undefined;
  onConfirm: () => void;
  btnText?: string;
  title?: string;
};

export const ConfirmModal = ({
  isOpen,
  onClose,
  amount,
  stakingInfo,
  fee,
  title = "You Stake",
  btnText = "Confirm Stake",
  onConfirm,
}: Props) => {
  const chain = useChain();
  const ocxToken = OWN_TOKEN[chain.id];
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Body>
        <Modal.Header id="stake-confirm-modal" onIconClick={onClose}>
          {title}
        </Modal.Header>
        <div className="mb-8 mt-3">
          <div className="flex items-center justify-between rounded-base bg-black/5 px-4 py-3 text-2xl text-black">
            <span
              id="stake-confirm-modal-amount"
              className="mr-4 max-w-[160px] truncate"
            >
              {amount}
            </span>
            <CurrencyEntity
              symbol={ocxToken.symbol}
              logoUrl={ocxToken.logoUrl}
              bordered={false}
              size="38"
            />
          </div>
        </div>
        <Data className="mb-8">
          <Data.Row
            id="stake-confirm-modal-current-stake-balance"
            label="Your Current Staking Balance"
            value={`${stakingInfo.stakingBalance?.formatted} OCX`}
          />
          <Data.Row
            id="stake-confirm-modal-new-stake-balance"
            label="Your New Staking Balance"
            value={`${stakingInfo.calcStakingBalance?.toSignificant(6)} OCX`}
          />
          <Data.Row
            id="stake-confirm-modal-fee"
            label="Network Fee"
            value={
              fee
                ? `≈${fee.feeCA.toSignificant(6)} ${
                    Native.byChainId(chain.id).symbol
                  }`
                : "Unknown"
            }
          />
        </Data>
        <Button
          id="stake-confirm-modal-btn"
          className="w-full !py-4 !text-2xl"
          onClick={onConfirm}
        >
          {btnText}
        </Button>
      </Modal.Body>
    </Modal>
  );
};
