import { useState } from "react";
import { Link } from "react-router-dom";

import { useCurrencies } from "entities/currency";
import { Price } from "entities/price";
import { ApproveAllowanceButton } from "features/approve-allowance";
import { ConnectWalletButton } from "features/connect-wallet";
import { InputCurrency } from "features/input-currency";
import { SwitchNetworkButton } from "features/switch-network";
import {
  useAddLiquidityDetails,
  useAddLiquidityStatus,
  useMaxNativeCurrencyValue,
} from "pages/pool/hooks";
import { ConfirmModal } from "pages/pool/ui/AddLiquidity/ConfirmModal";
import { FormWrapper } from "pages/ui";
import { useAddLiquidityMutation } from "shared/api/pool/useAddLiquidityMutation";
import useCalculateSecondTokenValue from "shared/api/pool/useCalculateSecondTokenValue";
import { useDetailedPairsQuery } from "shared/api/pool/useDetailedPairsQuery";
import { useEstimateAddLiquidityFeeQuery } from "shared/api/pool/useEstimateAddLiquidityFeeQuery";
import { useCurrencyBalanceQuery } from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useStateX } from "shared/hooks";
import { useChain } from "shared/providers/wagmi";
import { Currency, Price as UniPrice } from "shared/sdk-core";
import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";
import { Modal } from "shared/ui/modal";
import { PriceCalculator } from "shared/ui/price-calculator";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP, WNATIVE } from "shared/v2-sdk/constants";
import { CurrencySelector } from "widgets/currency-selector";
import { TransactionSettingsModal } from "widgets/settings-modal";

type State = {
  amountIn: string;
  amountOut: string;
  isConfirmOpen: boolean;
  isSettingsOpen: boolean;
};

export default function AddLiquidityGeneral() {
  const chain = useChain();

  const wnativeToken = WNATIVE[chain.id];

  const currencies = useCurrencies();

  const [{ amountIn, amountOut, isConfirmOpen, isSettingsOpen }, setState] =
    useStateX<State>({
      amountIn: "",
      amountOut: "",
      isConfirmOpen: false,
      isSettingsOpen: false,
    });

  const addLiquidityMutation = useAddLiquidityMutation();

  const [currencyA, setCurrencyA] = useState<Currency | undefined>(undefined);
  const [currencyB, setCurrencyB] = useState<Currency | undefined>(undefined);

  const currencyAmountIn = toCurrencyAmount(currencyA, amountIn);
  const currencyAmountOut = toCurrencyAmount(currencyB, amountOut);

  const currencyABalanceQuery = useCurrencyBalanceQuery(currencyA);
  const currencyBBalanceQuery = useCurrencyBalanceQuery(currencyB);

  const feeQuery = useEstimateAddLiquidityFeeQuery({
    currencyAmountIn,
    currencyAmountOut,
    currencyA,
    currencyB,
  });

  const detailedPairsQuery = useDetailedPairsQuery({
    select: (detailedPairs) => {
      return currencyA && currencyB
        ? detailedPairs.find(
            ({ pair }) =>
              pair.involvesToken(currencyA.wrapped) &&
              pair.involvesToken(currencyB.wrapped)
          )
        : undefined;
    },
  });

  const detailedPair = detailedPairsQuery.data;

  const liquidityDetails = useAddLiquidityDetails({
    currencyA,
    currencyB,
    currencyAmountIn,
    currencyAmountOut,
    detailedPair,
  });

  const { status, btnDisabled } = useAddLiquidityStatus({
    currencyA,
    currencyB,
    fee: feeQuery.data,
    currencyAmountIn,
    currencyAmountOut,
  });

  const {
    currencyAPriceInUSDT,
    currencyBPriceInUSDT,
    currencyAToUSDTRate,
    currencyBToUSDTRate,
    tradeCurrencyAToCurrencyB,
    tradeCurrencyBToCurrencyA,
    poolShare,
  } = liquidityDetails;

  const maxValueCA = useMaxNativeCurrencyValue({
    currencyA,
    currencyB,
    pair: detailedPair?.pair,
  });

  const hasPool = Boolean(detailedPair?.pair.liquidityToken);

  const calcField = useCalculateSecondTokenValue({ currencyA, currencyB });

  const handleTokenAMaxClick = () => {
    if (currencyA && currencyA.isNative && maxValueCA) {
      handleInputAChange(maxValueCA.toExact());
      return;
    }
    handleInputAChange(currencyABalanceQuery.data?.balanceCA.toExact() || "");
  };

  const handleTokenBMaxClick = () => {
    if (currencyB && currencyB.isNative && maxValueCA) {
      handleInputBChange(maxValueCA.toExact());
      return;
    }
    handleInputBChange(currencyBBalanceQuery.data?.balanceCA.toExact() || "");
  };

  const handleConfirmClick = () => {
    if (currencyA && currencyB) {
      addLiquidityMutation.mutate({
        currencyA,
        currencyB,
        currencyAmountIn: toCurrencyAmount(currencyA, amountIn),
        currencyAmountOut: toCurrencyAmount(currencyB, amountOut),
      });
      setState({ isConfirmOpen: false });
    }
  };

  const handleInputAChange = (amountIn: string) => {
    if (!currencyA) {
      return;
    }
    const calc = calcField(currencyA.wrapped, amountIn);

    setState({
      amountIn,
      amountOut: hasPool ? calc : amountOut,
    });
  };

  const handleInputBChange = (amountOut: string) => {
    if (!currencyB) {
      return;
    }
    const calc = calcField(currencyB.wrapped, amountOut);

    setState({
      amountIn: hasPool ? calc : amountIn,
      amountOut,
    });
  };

  const showRates = currencyA && currencyB;

  const onSelectA = (currency: Currency) => {
    if (
      (currencyB && currencyB.equals(currency)) ||
      (currencyB &&
        currencyB.isNative &&
        currency.isToken &&
        wnativeToken.equals(currency)) ||
      (currencyB &&
        currency.isNative &&
        currencyB.isToken &&
        currencyB.equals(wnativeToken))
    ) {
      setCurrencyB(undefined);
    }

    setCurrencyA(currency);
  };

  const onSelectB = (currency: Currency) => {
    if (
      (currencyA && currencyA.equals(currency)) ||
      (currencyA &&
        currencyA.isNative &&
        currency.isToken &&
        currency.equals(wnativeToken)) ||
      (currencyA &&
        currency.isNative &&
        currencyA.isToken &&
        currencyA.equals(wnativeToken))
    ) {
      setCurrencyA(undefined);
    }

    setCurrencyB(currency);
  };

  const priceCalculatorProps = {
    currencyA,
    currencyB,
    currencyBToCurrencyARate: tradeCurrencyBToCurrencyA?.outputAmount,
    currencyAToUSDRate: currencyAToUSDTRate,
    currencyAToCurrencyBRate: tradeCurrencyAToCurrencyB?.outputAmount,
    currencyBToUSDRate: currencyBToUSDTRate,
    isHighPriceImpact: false,
    toggled: true,
  };

  const executionPrice =
    currencyAmountIn && currencyAmountOut && status === "createAPool"
      ? new UniPrice(
          currencyAmountIn?.currency,
          currencyAmountOut.currency,
          currencyAmountIn.quotient,
          currencyAmountOut.quotient
        )
      : tradeCurrencyAToCurrencyB?.executionPrice;

  return (
    <>
      <FormWrapper>
        <Modal.Header
          id="swap-settings-modal-header"
          iconName="settings"
          onIconClick={() => {
            setState({ isSettingsOpen: true });
          }}
        >
          <div className="flex grow items-center justify-between">
            <div className="flex items-center">
              <Link to="/pool" className="cursor-pointer">
                <Icon
                  name="back"
                  size="20"
                  className="flex flex-col justify-center"
                />
              </Link>
              <div className="ml-6 mr-3 text-[1.375rem] leading-[1.6rem] text-black">
                Pool
              </div>
            </div>
          </div>
          <div className="w-12" />
        </Modal.Header>

        <div className="mb-6 h-[1px] w-full" />

        <InputCurrency
          id="token-input"
          className="mb-4 mt-0"
          currency={currencyA}
          value={amountIn}
          maxBtnLoading={
            (currencyA?.isNative && !maxValueCA) ||
            currencyABalanceQuery.isFetching
          }
          balance={
            currencyA ? currencyABalanceQuery.data?.formatted : undefined
          }
          maxLabel={currencyA ? "Balance" : ""}
          price={currencyAPriceInUSDT}
          onChange={handleInputAChange}
          onMaxClick={handleTokenAMaxClick}
        >
          <CurrencySelector
            id="token-select"
            disabled={false}
            loading={false}
            currencies={currencies}
            storageKey="add"
            selectedCurrency={currencyA}
            onSelect={onSelectA}
          />
        </InputCurrency>

        <div className="my-0 flex justify-center text-dodgerBlue">
          <Icon size="25" name="add" />
        </div>

        <InputCurrency
          id="token-input"
          className="mb-4 mt-4"
          currency={currencyB}
          value={amountOut}
          maxBtnLoading={
            (currencyB?.isNative && !maxValueCA) ||
            currencyBBalanceQuery.isFetching
          }
          balance={
            currencyB ? currencyBBalanceQuery.data?.formatted : undefined
          }
          maxLabel={currencyB ? "Balance" : ""}
          price={currencyBPriceInUSDT}
          onChange={handleInputBChange}
          onMaxClick={handleTokenBMaxClick}
        >
          <CurrencySelector
            id="token-select"
            disabled={false}
            loading={false}
            currencies={currencies}
            storageKey="add"
            selectedCurrency={currencyB}
            onSelect={onSelectB}
          />
        </InputCurrency>

        <div className="my-5 flex items-center justify-between px-2 text-sm text-osloGray">
          <span className="whitespace-nowrap">
            {showRates
              ? `Pool Share ${poolShare
                  .mul(100)
                  .toSignificantDigits(2)
                  .toFixed()}%`
              : ""}
          </span>
          {executionPrice && (
            <Price
              price={executionPrice}
              initialInvert={true}
              priceImpact={tradeCurrencyAToCurrencyB?.priceImpact}
            />
          )}

          {status === "createAPool" && executionPrice && <span>{}</span>}
        </div>

        {status === "connectWallet" && <ConnectWalletButton />}
        {status === "wrongNetwork" && <SwitchNetworkButton />}
        {status === "approveCurrencyA" &&
          currencyA?.isToken &&
          currencyAmountIn && (
            <ApproveAllowanceButton
              spenderAddress={ROUTER_ADDRESS_MAP[chain.id]}
              currencyAmount={currencyAmountIn}
            />
          )}
        {status === "approveCurrencyB" &&
          currencyB?.isToken &&
          currencyAmountOut && (
            <ApproveAllowanceButton
              spenderAddress={ROUTER_ADDRESS_MAP[chain.id]}
              currencyAmount={currencyAmountOut}
            />
          )}
        {[
          "enterAmount",
          "insufficientCurrencyABalance",
          "insufficientCurrencyBBalance",
          "insufficientNativeTokenBalance",
          "addLiquidity",
          "createAPool",
        ].includes(status) && (
          <Button
            id={"add-liquidity-btn"}
            className="mt-auto"
            disabled={btnDisabled}
            size="66"
            onClick={() => {
              if (status === "addLiquidity" || status === "createAPool") {
                setState({ isConfirmOpen: !isConfirmOpen });
              }
            }}
          >
            {status === "enterAmount" && "Enter amount"}
            {status === "insufficientCurrencyABalance" &&
              `Insufficient ${currencyA?.symbol} balance`}
            {status === "insufficientCurrencyBBalance" &&
              `Insufficient ${currencyB?.symbol} balance`}
            {status === "insufficientNativeTokenBalance" &&
              `No ${Native.byChainId(chain.id).symbol} to pay fee`}
            {status === "addLiquidity" && "Supply"}
            {status === "createAPool" && "Create a pool"}
          </Button>
        )}
      </FormWrapper>
      <TransactionSettingsModal
        open={isSettingsOpen}
        onClose={() => setState({ isSettingsOpen: false })}
      />
      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={() => setState({ isConfirmOpen: !isConfirmOpen })}
        currencyA={currencyA}
        currencyB={currencyB}
        currencyAmountIn={currencyAmountIn}
        currencyAmountOut={currencyAmountOut}
        details={liquidityDetails}
        fee={feeQuery.data}
        onConfirm={handleConfirmClick}
      />
    </>
  );
}
