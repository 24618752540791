import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  CurrenciesProvider,
  CustomCurrenciesProvider,
} from "entities/currency";
import { TransactionModalProvider } from "entities/transaction";
import { DefaultChainProvider } from "shared/providers/default-chain";
import { GraphQlProvider } from "shared/providers/graph-ql-client";
import { QueryClientProvider } from "shared/providers/query-client";
import { TxHistoryProvider } from "shared/providers/TxHistory";
import { TxSettingsProvider } from "shared/providers/TxSettings";
import { WagmiProvider } from "shared/providers/wagmi";
import { ToastContainer } from "shared/ui/toast";
import { WalletModalProvider } from "widgets/wallet";

import { Routes } from "./routes";

function App() {
  return (
    <>
      <BrowserRouter>
        <DefaultChainProvider>
          <WagmiProvider>
            <GraphQlProvider>
              <QueryClientProvider>
                <TxHistoryProvider>
                  <TxSettingsProvider>
                    <TransactionModalProvider>
                      <WalletModalProvider>
                        <CustomCurrenciesProvider>
                          <CurrenciesProvider>
                            <Routes />
                          </CurrenciesProvider>
                        </CustomCurrenciesProvider>
                      </WalletModalProvider>
                    </TransactionModalProvider>
                  </TxSettingsProvider>
                </TxHistoryProvider>
                <ToastContainer />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </GraphQlProvider>
          </WagmiProvider>
        </DefaultChainProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
