import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccount, useProvider, useSigner } from "wagmi";

import { StackingOcxABI__factory } from "shared/abi/types";
import { useChain } from "shared/providers/wagmi";
import { OWN_TOKEN } from "shared/v2-sdk/constants";

import { tokenKeys } from "../token/keys";
import { useTxMutation, useTxWaitMutation } from "../transtaction";

import { ocxKeys } from "./ocxKeys";

type Params = {
  address: string;
  amount: string;
};

export const useClaimOcxMutation = () => {
  const chain = useChain();
  const provider = useProvider({ chainId: chain.id });
  const { data: signer } = useSigner();

  const queryClient = useQueryClient();
  const { address } = useAccount();

  const txMutation = useTxMutation();
  const txWaitMutation = useTxWaitMutation();

  const ocxToken = OWN_TOKEN[chain.id];

  return useMutation(
    async ({ amount, address }: Params) => {
      const desc = `Claim ${amount} ${ocxToken.symbol}`;
      const contract = StackingOcxABI__factory.connect(
        address,
        signer || provider
      );

      const estimatedGas = await contract.estimateGas.getReward();

      const txPromise = contract.getReward({
        gasLimit: estimatedGas.mul(120).div(100),
      });

      const tx = await txMutation.mutateAsync({ txPromise, desc });

      const receipt = await txWaitMutation.mutateAsync({ tx, desc });
      return receipt;
    },
    {
      onSuccess: (data, params) => {
        queryClient.refetchQueries(
          tokenKeys.balance(chain.id, address, ocxToken.address)
        );
        queryClient.refetchQueries(ocxKeys.all);
      },
    }
  );
};
