import { useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { FormWrapper } from "pages/ui";
import { useIsHumanode } from "shared/providers/wagmi";
import { Modal } from "shared/ui/modal";
import { TabType, TerminalTabs } from "shared/ui/terminal-tabs";
import { TransactionSettingsModal } from "widgets/settings-modal";

const tabs = [
  {
    title: "Stake",
    route: "ocx/stake",
    render: () => <div>Stake</div>,
  },
  {
    title: "Unstake",
    route: "ocx/unstake",
    render: () => <div>Unstake</div>,
  },
  {
    title: "Claim",
    route: "ocx/claim",
    render: () => <div>Claim</div>,
  },
] as TabType[];

export const OcxFarmWrapper = () => {
  const isHumanode = useIsHumanode();
  const location = useLocation();
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const navigate = useNavigate();

  const activeTab =
    tabs.find((tab) => location.pathname.includes(tab.route)) ?? tabs[0];

  if (isHumanode) return <Navigate to="/" />;

  return (
    <>
      <div className="absolute left-0 top-[70%] flex h-1/2 w-1/4 -translate-y-1/2 bg-farmMonkey bg-contain bg-no-repeat" />
      <FormWrapper>
        <>
          <Modal.Header
            className="mb-8"
            headerClassName="max-sm:flex-wrap max-sm:gap-y-3"
            id="swap-settings-modal-header"
            iconName="settings"
            onIconClick={() => {
              setSettingsOpen(true);
            }}
          >
            <div className="mr-3 whitespace-nowrap text-[1.375rem] leading-[1.6rem] text-black max-2xl:text-xl">
              {activeTab.title} OCX
            </div>
            <TerminalTabs
              className="ml-auto mr-12 max-2xl:ml-0 max-sm:ml-0"
              activeTab={activeTab.route}
              onClick={(tab) => {
                navigate(`/${tab.route}`);
              }}
              tabs={tabs}
            />
          </Modal.Header>
          <Outlet />
        </>
      </FormWrapper>

      <TransactionSettingsModal
        open={isSettingsOpen}
        onClose={() => {
          setSettingsOpen(false);
        }}
      />
    </>
  );
};
