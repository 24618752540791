import { Fragment } from "react";

import { Icon } from "shared/ui/icon";
import { ImageStack } from "shared/ui/image-stack";
import { Img } from "shared/ui/img";
import { Spinner } from "shared/ui/spinner";
import { Text } from "shared/ui/text";
import { Tooltip } from "shared/ui/tooltip";
import { TradeTypes } from "shared/v2-sdk/hooks";

type Props = {
  isLoading: boolean;
  trade: TradeTypes | undefined;
};

const V2_DEFAULT_FEE_TIER_PERCENT = 0.3;

export const AutoRouter = ({ trade, isLoading }: Props) => {
  const renderAutoRouteTooltip = () => {
    return (
      <div className="flex items-center text-osloGray">
        <Img
          className="h-[18px] w-[18px]"
          src={trade?.route.input.logoUrl}
          alt={trade?.route.input.symbol}
        />
        <Icon
          size="14"
          name="chevronDown"
          className="mx-2 !h-2 !w-2 -rotate-90"
        />
        {trade?.route.pairs.map((pair) => {
          return (
            <Fragment key={pair.liquidityToken.address}>
              <div className="flex items-center rounded-2xl bg-dark bg-opacity-5 p-1">
                <ImageStack
                  images={[
                    { src: pair.token0.logoUrl, alt: pair.token0.symbol },
                    { src: pair.token1.logoUrl, alt: pair.token1.symbol },
                  ]}
                />
                <span className="ml-1 ">{V2_DEFAULT_FEE_TIER_PERCENT}%</span>
              </div>
              <Icon
                size="14"
                name="chevronDown"
                className="mx-2 !h-2 !w-2 -rotate-90"
              />
            </Fragment>
          );
        })}
        <Img
          className="h-[18px] w-[18px]"
          src={trade?.route.output.logoUrl}
          alt={trade?.route.output.symbol}
        />
      </div>
    );
  };

  return (
    <Tooltip
      disabled={!trade}
      className="flex items-center"
      element={renderAutoRouteTooltip()}
    >
      {isLoading ? (
        <Spinner size="12" theme="gray" />
      ) : (
        <Icon name="route" className="text-osloGray" size="14" />
      )}
      <Text className="ml-1 inline-flex text-sm text-osloGray">Auto Route</Text>
    </Tooltip>
  );
};
