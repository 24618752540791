import { Fee, toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { Currency, CurrencyAmount } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";

import { useCurrencyBalanceQuery } from "./useCurrencyBalanceQuery";

type Params = {
  currencyAmount: CurrencyAmount<Currency> | undefined;
  fee: Fee | undefined;
};

export const useCheckSolvency = ({ fee, currencyAmount }: Params) => {
  const chain = useChain();
  const currency = currencyAmount?.currency;
  const nativeBalanceQuery = useCurrencyBalanceQuery(
    Native.byChainId(chain.id)
  );

  // TODO: true or false
  if (
    !nativeBalanceQuery.isSuccess ||
    !nativeBalanceQuery.data ||
    !fee ||
    !currency
  )
    return true;

  const nativeBalanceData = nativeBalanceQuery.data;

  const realBalance = nativeBalanceData.balanceCA.subtract(
    currency.isNative
      ? currencyAmount
      : toCurrencyAmount(Native.byChainId(chain.id), "0")
  );

  return Boolean(realBalance.greaterThanOrEqual(fee.feeCA));
};
