import { SerializedStyles } from "@emotion/react";
import tw, { css, TwStyle } from "twin.macro";

type Theme = {
  default: TwStyle | SerializedStyles;
  active: TwStyle | SerializedStyles;
};

// LIGHT
const lightDefault = css`
  ${tw`text-osloGray bg-transparent py-0.5 px-3 rounded-xl`}

  &:hover {
    ${tw`bg-malibu bg-opacity-5`}
  }
`;
const lightActive = tw`text-dodgerBlue bg-malibu bg-opacity-20 hover:bg-malibu hover:bg-opacity-20 hover:text-dodgerBlue`;
export const light: Theme = {
  default: lightDefault,
  active: lightActive,
};

// DARK
const darkDefault = css`
  ${tw`text-light text-lg py-0.5 px-2 rounded-full bg-transparent`}
  &:hover {
    ${tw`bg-light bg-opacity-5`}
  }
`;
const darkActive = tw`text-black bg-light rounded-full hover:bg-light`;
export const dark: Theme = {
  default: darkDefault,
  active: darkActive,
};
