import { TxSettingsState, useTxSettings } from "shared/providers/TxSettings";
import { Modal } from "shared/ui/modal";

import { Footer } from "./footer";
import { SlippageTolerance } from "./slippage-tolerance";
import { TransactionDeadline } from "./transaction-deadline";

type Props = {
  open: boolean;
  onChange?: (settings: TxSettingsState) => void;
  onClose: () => void;
};

export const SettingsModal = ({ open, onClose, onChange }: Props) => {
  const [lsState, setLsState] = useTxSettings();

  const { slippageTolerance = 5, transactionDeadline = 30 } = lsState ?? {};

  const handleChange = (newState: Partial<TxSettingsState>) => {
    const settings = {
      slippageTolerance: newState.slippageTolerance ?? slippageTolerance,
      transactionDeadline: newState.transactionDeadline ?? transactionDeadline,
    };
    setLsState(settings);
    onChange?.(settings);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <Modal.Header
          id="settings-modal-header"
          iconName="close"
          onIconClick={onClose}
        >
          Settings
        </Modal.Header>
        <SlippageTolerance
          className="mt-8"
          value={slippageTolerance}
          onChange={handleChange}
        />
        <TransactionDeadline
          className="mt-8"
          value={transactionDeadline}
          onChange={handleChange}
        />
        <Footer className="mt-11" />
      </Modal.Body>
    </Modal>
  );
};
