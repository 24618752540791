import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Decimal } from "decimal.js";
import invariant from "tiny-invariant";
import { toDecimal } from "utils/numbers";
import { useAccount, useProvider } from "wagmi";

import { StackingOcxABI__factory } from "shared/abi/types";
import { useChain } from "shared/providers/wagmi";
import { CurrencyAmount } from "shared/sdk-core";
import { OCX_STAKE_CONTRACT_ADDRESS, OWN_TOKEN } from "shared/v2-sdk/constants";

import { ocxKeys } from "./ocxKeys";

export const useOcxStakingBalanceQuery = () => {
  const chain = useChain();
  const { address } = useAccount();
  const provider = useProvider({ chainId: chain.id });
  const stakingOcxContractAddress = OCX_STAKE_CONTRACT_ADDRESS[chain.id];
  const ocxToken = OWN_TOKEN[chain.id];

  const query = useQuery(
    ocxKeys.stakingBalance(chain.id, address, stakingOcxContractAddress),
    async () => {
      invariant(provider, "useOcxStakingBalanceQuery. provider is undefined");
      invariant(address, "useOcxStakingBalanceQuery. address is undefined");

      const contract = StackingOcxABI__factory.connect(
        stakingOcxContractAddress,
        provider
      );

      const balanceBN = await contract.stakes(address);

      const balanceD = toDecimal(balanceBN.toString()).div(
        toDecimal(10).pow(ocxToken.decimals)
      );

      return {
        currency: ocxToken,
        balanceD,
        balanceCA: CurrencyAmount.fromRawAmount(ocxToken, balanceBN.toString()),
        formatted: balanceD
          .toSignificantDigits(6, Decimal.ROUND_DOWN)
          .toFixed(),
      };
    },

    {
      enabled: Boolean(address && provider && stakingOcxContractAddress),
    }
  );

  return query;
};
