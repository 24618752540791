import { isHumanode } from "shared/providers/wagmi";
import { Token } from "shared/sdk-core";
import { NATIVE, WNATIVE } from "shared/v2-sdk/constants";

export function getPairTokenDisplayData(token: Token, chainId: number) {
  const displayToken =
    token.equals(WNATIVE[chainId]) && !isHumanode(chainId)
      ? NATIVE[chainId]
      : token;
  return { symbol: displayToken.symbol, name: displayToken.name };
}
