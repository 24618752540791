import { useTxHistory } from "shared/providers/TxHistory";

import { HistoryTransaction } from "./history-transaction";

const History = () => {
  const { items } = useTxHistory();

  return (
    <div className="flex flex-col">
      <span className="mb-5 mt-8 flex text-osloGray">Transaction history</span>
      <ul className="flex list-none flex-col">
        {items.map((tx) => {
          return <HistoryTransaction key={tx.hash} tx={tx} />;
        })}
      </ul>
    </div>
  );
};

export default History;
