import React from "react";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

import tokenPlaceholder from "../icon/svg/tokenPlaceholder.svg";

type Props = { fallbackUrl?: string } & EmotionCSSProps &
  React.ImgHTMLAttributes<HTMLImageElement>;

export const Img = ({
  className,
  fallbackUrl = tokenPlaceholder,
  ...props
}: Props) => {
  return (
    <img
      {...props}
      src={props.src ?? fallbackUrl}
      className={cn("select-none", className)}
      alt={props.alt}
      onError={(e) => {
        if (!fallbackUrl) return;
        e.currentTarget.onerror = null;
        e.currentTarget.src = fallbackUrl;
      }}
    />
  );
};
