import Decimal from "decimal.js";
import { BigNumber, ethers } from "ethers";

import { CurrencyAmount, Token } from "shared/sdk-core/entities";

function toDecimal(n: string | number | undefined | null) {
  try {
    return new Decimal(n ?? "0");
  } catch (error) {
    return new Decimal(0);
  }
}

function parseUnits(v: string, decimals: number) {
  const n = Number(v).toFixed(decimals);
  return ethers.utils.parseUnits(n, decimals);
}

const convertHexToNumber = (
  hex: string | BigNumber | undefined,
  decimals = 18
) => {
  if (!hex) return "0";
  //console.log(`Converting to number ${hex} with ${decimals} decimals`)
  return ethers.utils.formatUnits(hex, decimals);
};

const toSignificant = (
  token: Token | undefined,
  amount: string | undefined
) => {
  if (!token || !amount) {
    return "N/A";
  }
  //const parsed = parseUnits(amount, token.decimals).toString();

  const parsed = new Decimal(amount)
    .mul(new Decimal(10).pow(token.decimals))
    .toFixed(0);

  return CurrencyAmount.fromRawAmount(token, parsed).toSignificant(6);
};

export { convertHexToNumber, parseUnits, toDecimal, toSignificant };
