import React, { PropsWithChildren } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import cn from "classnames";

import { EmotionCSSProps } from "shared/types";

import { Icon } from "../icon";
import { Text } from "../text";

type Props = {
  showIcon?: boolean;
  iconClass?: string;
} & LinkProps &
  React.RefAttributes<HTMLAnchorElement> &
  EmotionCSSProps;

export const Link = ({
  className,
  css: cssProp,
  to: toProp,
  showIcon,
  children,
  iconClass,
  ...props
}: PropsWithChildren<Props>) => {
  const isExternal = isExternalLink(toProp.toString());

  const content = (
    <>
      {typeof children === "string" ? (
        <Text className="text-inherit inline-flex">{children}</Text>
      ) : (
        children
      )}
      {showIcon && (
        <Icon
          className={cn("ml-1.5 inline-flex", iconClass)}
          name="link"
          size="18"
        />
      )}
    </>
  );

  const cls = cn(
    "inline-flex cursor-pointer items-center text-dodgerBlue transition-colors hover:text-redMalibu",
    className
  );

  return isExternal ? (
    <a
      className={cls}
      css={cssProp}
      target="_blank"
      rel="noreferrer"
      href={toProp.toString()}
      {...props}
    >
      {content}
    </a>
  ) : (
    <RouterLink className={cls} css={cssProp} {...props} to={toProp}>
      {content}
    </RouterLink>
  );
};

function isExternalLink(url: string) {
  try {
    const instance = new URL(url);
    return instance.host !== window.location.host;
  } catch (error) {
    return false;
  }
}
