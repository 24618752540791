import { ApproveAllowanceButton } from "features/approve-allowance";
import {
  useCurrencyAllowance,
  useTokenApproveMutation,
} from "shared/api/token";
import { toCurrencyAmount } from "shared/helpers";
import { useChain } from "shared/providers/wagmi";
import { MaxUint256Bn } from "shared/sdk-core";
import { Native } from "shared/v2-sdk";
import { ROUTER_ADDRESS_MAP } from "shared/v2-sdk/constants";

export const Dev = () => {
  const chain = useChain();
  const query = useCurrencyAllowance(
    Native.byChainId(chain.id).wrapped,
    ROUTER_ADDRESS_MAP[chain.id]
  );

  return (
    <div>
      {Native.byChainId(chain.id).wrapped.symbol} amount has been approved by
      Router {ROUTER_ADDRESS_MAP[chain.id]}:{" "}
      <span className="text-dodgerBlue">{query.data?.toExact()}</span>
      <div className="mt-2">
        Reset {Native.byChainId(chain.id).wrapped.symbol} allowance
      </div>
      <div className="mt-1">
        <ApproveAllowanceButton
          currencyAmount={toCurrencyAmount(
            Native.byChainId(chain.id).wrapped,
            "100000000000000000000"
          )}
          spenderAddress={ROUTER_ADDRESS_MAP[chain.id]}
        />
      </div>
    </div>
  );
};
