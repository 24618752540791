import cn from "classnames";

import { Input, Validator } from "shared/ui/input";

type Props = {
  id?: string;
  value: string;
  className?: string;
  onChange: (value: string) => void;
  validators?: Validator[];
};

export const TabInput = ({
  id,
  value,
  className,
  onChange,
  validators = [],
}: Props) => {
  return (
    <Input
      id={id}
      className="rounded-2xl border border-solid border-tiara bg-opacity-0 p-1"
      inputClassName={cn(
        "w-16 !p-0 h-[28px] bg-opacity-0 border-none text-center !text-dodgerBlue rounded-xl",
        value && "bg-malibu !bg-opacity-20 leading-5",
        className
      )}
      placeholder="Custom"
      value={value}
      validators={validators}
      onChange={({ value, isValid }) => {
        if (!isValid) return;
        onChange(value);
      }}
    />
  );
};
