import React, { Suspense } from "react";
import { Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { withSentryReactRouterV6Routing } from "@sentry/react";

import { Dev } from "pages/dev/dev";
import { FarmWrapper, StakePage, UnstakePage } from "pages/farm";
import FarmPage from "pages/farm/ui/FarmsPage";
import { ClaimPage } from "pages/farm-claim-page";
import { OcxFarmWrapper, StakeOcxPage } from "pages/ocx";
import { ClaimOcxPage, UnstakeOcxPage } from "pages/ocx/ui";
import {
  AddLiquidity,
  AddLiquidityGeneral,
  Pools,
  PoolWrapper,
} from "pages/pool";
import { RemoveLiquidity } from "pages/pool/ui/RemoveLiquidity";
import { SwapPage } from "pages/swap";
import { Layout } from "pages/ui";

import { SuspenseFallback } from "./suspense-fallback";

const ZzapPage = React.lazy(() => import("pages/zzap"));

const SentryRoutes = withSentryReactRouterV6Routing(ReactRouterRoutes);

export const Routes = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <SentryRoutes>
        <Route path="/dev" element={<Dev />} />
        <Route path="/" element={<Layout />}>
          {/* <Route path="/dev" element={<DevPage />} /> */}
          <Route index element={<SwapPage />} />
          <Route path="/pools" element={<Pools />} />
          <Route path="/pool" element={<PoolWrapper />}>
            <Route path="/pool/add/:address" element={<AddLiquidity />} />
            <Route path="/pool/remove/:address" element={<RemoveLiquidity />} />
          </Route>
          <Route path="/farms" element={<FarmPage />} />
          <Route path="/farm" element={<FarmWrapper />}>
            <Route path="/farm/stake/:address" element={<StakePage />} />
            <Route path="/farm/unstake/:address" element={<UnstakePage />} />
            <Route path="/farm/claim/:address" element={<ClaimPage />} />
          </Route>
          <Route path="/zzap" element={<ZzapPage />} />
          <Route path="/add" element={<AddLiquidityGeneral />} />

          <Route path="/ocx" element={<OcxFarmWrapper />}>
            <Route path="/ocx/stake" element={<StakeOcxPage />} />
            <Route path="/ocx/unstake" element={<UnstakeOcxPage />} />
            <Route path="/ocx/claim" element={<ClaimOcxPage />} />
          </Route>
        </Route>
      </SentryRoutes>
    </Suspense>
  );
};
