import React, { createContext, PropsWithChildren, useMemo } from "react";

import { useStateX } from "shared/hooks";

import { Wallet } from "./ui/wallet";

type WalletContext = {
  open: boolean;
  step: "connector" | "list";
};

const WalletModalContext = createContext<
  | [WalletContext, React.Dispatch<React.SetStateAction<WalletContext>>]
  | undefined
>(undefined);

export const Provider = ({ children }: PropsWithChildren) => {
  const [state, setWalletModalState] = useStateX<WalletContext>({
    open: false,
    step: "list",
  });
  const value = useMemo(
    () =>
      [state, setWalletModalState] as [
        WalletContext,
        React.Dispatch<React.SetStateAction<WalletContext>>
      ],
    [state, setWalletModalState]
  );

  return (
    <WalletModalContext.Provider value={value}>
      {children}
      <Wallet
        open={state.open}
        step={state.step}
        onChange={(step) => {
          setWalletModalState({
            step,
          });
        }}
        onClose={() => setWalletModalState({ open: false })}
      />
    </WalletModalContext.Provider>
  );
};

export const useWalletModal = () => {
  const context = React.useContext(WalletModalContext);
  if (context === undefined) {
    throw new Error("useWalletModal must be used within a WalletModalContext");
  }
  return context;
};
