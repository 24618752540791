import { useEffect, useRef } from "react";
import { ConnectorData, useAccount } from "wagmi";

export const useChangeNetwork = (cb: (chain: ConnectorData) => void) => {
  // TODO: useEventCallback
  const refCb = useRef(cb);
  // TODO: useLayoutEffect
  refCb.current = cb;

  const { connector } = useAccount();

  useEffect(() => {
    const callback = refCb.current;

    connector?.on("change", callback);
    return () => {
      connector?.off("change", callback);
    };
  }, [connector]);
};
